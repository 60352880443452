/*
 * Copyright 2020 Conversity Ltd.
 */
'use strict';

/* global require, navigator */

var angular = (typeof window !== "undefined" ? window['angular'] : typeof global !== "undefined" ? global['angular'] : null);
var domainId = (typeof window !== "undefined" ? window['domainId'] : typeof global !== "undefined" ? global['domainId'] : null);
var processId = (typeof window !== "undefined" ? window['processId'] : typeof global !== "undefined" ? global['processId'] : null);
var resourceUrl = (typeof window !== "undefined" ? window['resourceUrl'] : typeof global !== "undefined" ? global['resourceUrl'] : null);
var ripplMultiLanguage = (typeof window !== "undefined" ? window['ripplMultiLanguage'] : typeof global !== "undefined" ? global['ripplMultiLanguage'] : null);

/*eslint-disable */
var restUrl = (typeof window !== "undefined" ? window['restUrl'] : typeof global !== "undefined" ? global['restUrl'] : null);
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var cometdLib = require('cometd');
var G_vmlCanvasManager = require('excanvas');
var isEmbeddedMode = false;
/*eslint-enable */

require('angular-resource');
require('angular-route');
require('angular-cookies');
require('angular-sanitize');
require('angular-animate');
require('angular-translate');
require('angular-translate-loader-url');
require('angular-translate-storage-local');
require('angular-translate-storage-cookie');
require('countup.js');
require('jquery-ui');

var frontendApp = angular.module('frontendApp', [
    'ngAnimate',
    'ngRoute',
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.slider',
    'ui.bootstrap.carousel',
    'ui.bootstrap.typeahead',
    'angularFileUpload',
    'pascalprecht.translate',
    'moduleActions',
    'UtilServices',
    'Chart'])
    .config(['$httpProvider', '$routeProvider', '$sceDelegateProvider', '$translateProvider', '$provide',
        function ($httpProvider, $routeProvider, $sceDelegateProvider, $translateProvider, $provide) {

            if (ripplMultiLanguage) {
                $translateProvider
                    .useUrlLoader(restUrl + '/service/lookup/load-translator-dictionary')
                    .preferredLanguage('en-GB')
                    .fallbackLanguage('en-GB')
                    .useSanitizeValueStrategy('escapeParameters')
                    .useLocalStorage()
                    .keepContent(true);
            } else {
                $provide.decorator('translateDirective', [function () {
                    return {};
                }]);
                $provide.decorator('translateFilter', [function () {
                    return function(input) {
                        return input;
                    };
                }]);
            }

            $sceDelegateProvider.resourceUrlWhitelist([
                // Allow same origin resource loads.
                'self',
                // Allow loading from our assets domain.  Notice the difference between * and **.
                resourceUrl + '/**'
            ]);

            // configuration adjustment before injection
            $routeProvider
                .when('/login', {
                    templateUrl: resourceUrl + '/partials/frontend/login.html',
                    controller: 'LoginCtrl',
                    resolve: {
                        systemDictionary: ['commonService', 'layoutFacade', function (commonService, layoutFacade) {
                            layoutFacade.hideLeft();
                            return commonService.get({
                                module: 'lookup',
                                action: 'viewSystemDictionaryByChannel'
                            }).$promise;
                        }]
                    }
                })
                .when('/user/:id', {
                    templateUrl: resourceUrl + '/partials/frontend/custom.html',
                    controller: 'CustomCtrl',
                    resolve: {
                        systemDictionary: ['commonService', function (commonService) {
                            return commonService.get({
                                module: 'lookup',
                                action: 'viewSystemDictionaryByChannel'
                            }).$promise;
                        }],
                        user: ['contextService', function (contextService) {
                            return contextService.loadUser();
                        }]
                    }
                })
                // for compare page
                .when('/user/:id/:templateId', {
                    templateUrl: resourceUrl + '/partials/frontend/custom.html',
                    controller: 'CustomCtrl',
                    resolve: {
                        systemDictionary: ['commonService', function (commonService) {
                            return commonService.get({
                                module: 'lookup',
                                action: 'viewSystemDictionaryByChannel'
                            }).$promise;
                        }],
                        user: ['contextService', function (contextService) {
                            return contextService.loadUser();
                        }]
                    }
                })
                .when('/', {
                    template: '',
                    controller: 'StartCtrl',
                    resolve: {
                        autoLogin: ['contextService', function (contextService) {
                            return contextService.autoLogin();
                        }]
                    }
                })
                .otherwise({
                    redirectTo: function () {
                        return '/user/home';
                    }
                });


            $httpProvider.interceptors.push('timeZoneInterceptor');
            $httpProvider.interceptors.push('timeStampInterceptor');
            $httpProvider.interceptors.push('multiTenantPathInterceptor');
            $httpProvider.responseInterceptors.push('authInterceptor');
            $httpProvider.responseInterceptors.push('connectionTimeoutInterceptor');
        }])
    .run(['$rootScope', '$http', '$location', 'tokenFacade', '$locale', function ($rootScope, $http, $location, tokenFacade, $locale) {
        // configuration adjustment after injection

        $rootScope.isMobile = ( navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        );

        $rootScope.navVersion = navigator.userAgent;
        $rootScope.log = "";
        $rootScope.initialURL = $location.path();
        $http.defaults.headers.common["Accept-Language"] = $locale.id;
        if (tokenFacade.isTokenEmpty()) {
            $rootScope.oldLocation = "/user/home";
            $location.path("/");
        } else {
            $http.defaults.headers.common['Auth-Token'] = tokenFacade.getToken();
        }

        var locationChangeSuccess = $rootScope.$on('$locationChangeSuccess', function (event, newUrl) {
            if (newUrl.indexOf('/login') <= 0 && newUrl.indexOf('#/') != newUrl.length - 2) {
                if (tokenFacade.isTokenEmpty()) {
                    event.preventDefault();
                    $location.path("/");
                } else {
                    $http.defaults.headers.common['Auth-Token'] = tokenFacade.getToken();
                }
            }
        });
        $rootScope.$on('$destroy', locationChangeSuccess);
    }]);

frontendApp.factory('timeZoneInterceptor', [function () {
    return {
        request: function (config) {
            config.headers['Time-Zone-Offset'] = new Date().getTimezoneOffset();
            return config;
        }
    };
}]);

frontendApp.factory('timeStampInterceptor', [function () {
    return {
        request: function (config) {
            if (config.method == "GET" && config.url.match(/service/i)
                    && (navigator.userAgent.match(/rv:11.0/i)
                        || (navigator.userAgent.match(/Windows Phone/i)
                                && navigator.userAgent.match(/IEMobile/i)))) {

                config.params = config.params || {};
                config.params['current_timestamp'] = new Date().getTime();
            }
            return config;
        }
    };
}]);

/* Intercept http errors */
frontendApp.factory('authInterceptor', ['$rootScope', '$q', '$location', 'tokenFacade',
    function ($rootScope, $q, $location, tokenFacade) {

        function success(response) {
            return response;
        }

        function error(response) {
            if (!$rootScope.changePin && 403 === response.status) {
                tokenFacade.destroy();
                if (!$rootScope.authFailed) {
                    $rootScope.$broadcast('EVT_USER_SESSION_EXPIRED');
                    $location.path('/');
                }
            }
            delete $rootScope.changePin;
            return $q.reject(response);
        }

        return function (promise) {
            return promise.then(success, error);
        };
    }]);

frontendApp.factory('connectionTimeoutInterceptor', ['$rootScope', '$q', function ($rootScope, $q) {

    function success(response) {
        $rootScope.$broadcast("connectionBack");
        return response;
    }

    function error(response) {

        if ([408, 502, 503, 504, 0].indexOf(response.status) > -1) {
            $rootScope.$broadcast("connectionLost");
        }
        return $q.reject(response);
    }

    return function (promise) {
        return promise.then(success, error);
    };
}]);

frontendApp.factory('multiTenantPathInterceptor', function () {
    return {
        request: function (config) {
            if (domainId) {
                config.headers['Domain-Id'] = domainId;
            }
            if (processId) {
                config.headers['Process-Id'] = processId;
            }
            return config;
        }
    };
});


/*
 * Copyright 2020 Conversity Ltd.
 */
/* global angular, restUrl, document, resourceUrl, $, window, isEmbeddedMode, globalWrapperVariables, ripplMultiLanguage */

var actionsModule = angular.module('moduleActions', ['ngResource']);

actionsModule.factory('actions-push-to-microsite', [
    '$q', 'sessionService', '$modal',
    function ($q, sessionService, $modal) {

        function sessionPromise(scope) {
            var defer = $q.defer();
            if (scope.saleSessionManager.isSessionStarted()) {
                defer.resolve(true);
            } else {
                defer.resolve(false);
            }
            return defer.promise;
        }

        function getData(scope) {
            var defer = $q.defer();
            sessionService.get({
                    action: 'push-to-microsite-data',
                    interaction: scope.saleSessionManager.getInteractionKey()
                },
                function (data) {
                    defer.resolve(data);
                }
            );
            return defer.promise;
        }

        return {
            check: function (scope) {
                return $q.all({
                    sessionStarted: sessionPromise(scope)
                });
            },
            openModal: function (scope) {
                return $modal.open({
                    templateUrl: resourceUrl + '/partials/frontend/pushToMicrosite.html',
                    backdrop: true,
                    scope: scope,
                    controller: ['$scope', '$modalInstance',
                        function ($scope, $modalInstance) {
                            $scope.fromBasket = false;
                            $scope.fromSession = false;

                            getData(scope).then(function (data) {
                                $scope.basketItems = data.basket;
                                $scope.recentItems = data.recent;
                                $scope.microsites = data.microsites;

                                $scope.pushed = [];
                                for (var ind in $scope.basketItems) {
                                    if ($scope.basketItems.hasOwnProperty(ind)) {
                                        var group = $scope.basketItems[ind];
                                        for (var index in group.items) {
                                            if (group.items.hasOwnProperty(index)) {
                                                $scope.pushed.push({
                                                    templateId: group.templateId,
                                                    itemId: group.items[index].id
                                                });
                                            }
                                        }
                                    }
                                }

                                for (var recInd in $scope.recentItems) {
                                    if ($scope.recentItems.hasOwnProperty(recInd)) {
                                        var group2 = $scope.recentItems[recInd];
                                        for (var recIndex in group2.items) {
                                            if (group2.items.hasOwnProperty(recIndex)) {
                                                $scope.pushed.push({
                                                    templateId: group2.templateId,
                                                    itemId: group2.items[recIndex].id
                                                });
                                            }
                                        }
                                    }
                                }
                            });

                            $scope.microsite = {};

                            $scope.email = "";

                            $scope.isInvalidFormat = false;
                            /*eslint-disable */
                            $scope.pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            /*eslint-enable */

                            $scope.submit = function () {
                                if ($scope.pattern.test($scope.email) && $scope.microsite.id) {
                                    var params = {
                                        email: $scope.email,
                                        websiteTemplateId: $scope.microsite.id
                                    };
                                    for (var i = 0; i < $scope.pushed.length; i++) {
                                        var pushed = $scope.pushed[i];
                                        //todo: some item type id can be the same as another item type id.
                                        //todo: need to be reimplement.
                                        params[pushed.itemId] = pushed.templateId;
                                    }
                                    $modalInstance.close(params);
                                } else {
                                    $scope.isInvalidFormat = true;
                                }
                            };

                            $scope.cancel = function () {
                                $modalInstance.dismiss();
                            };

                            $scope.pushItem = function (templateId, itemId) {
                                var index = $scope.getSelectedIndex(templateId, itemId);
                                if (index === -1) {
                                    $scope.pushed.push({templateId: templateId, itemId: itemId});
                                } else {
                                    $scope.pushed.splice(index, 1);
                                }
                            };

                            $scope.getSelectedIndex = function (templateId, itemId) {
                                var index = -1;
                                for (var i = 0, len = $scope.pushed.length; i < len; i++) {
                                    if ($scope.pushed[i].templateId === templateId
                                        && $scope.pushed[i].itemId === itemId) {
                                        index = i;
                                        break;
                                    }
                                }

                                return index;
                            };
                        }]
                });
            }
        };
    }]
);

actionsModule.factory('actions-share-template', [
    '$q', 'sessionService', '$modal',
    function ($q, sessionService, $modal) {

        function sessionPromise(scope) {
            var defer = $q.defer();
            if (scope.saleSessionManager.isSessionStarted()) {
                defer.resolve(true);
            } else {
                defer.resolve(false);
            }
            return defer.promise;
        }

        function firstMicrosite(scope) {
            var defer = $q.defer();
            sessionService.get({
                    action: 'check-is-first-shared-microsite',
                    interaction: scope.saleSessionManager.getInteractionKey()
                },
                function (first) {
                    if ('t' === first[0]) {
                        defer.resolve(true);
                    } else {
                        defer.resolve(false);
                    }
                }
            );
            return defer.promise;
        }

        return {
            check: function (scope) {
                return $q.all({
                    sessionStarted: sessionPromise(scope),
                    firstMicrosite: firstMicrosite(scope)
                });
            },
            openModal: function (scope, staticViewId) {
                var options = {
                    backdrop: true,
                    scope: scope,
                    controller: ['$scope', '$modalInstance',
                        function ($scope, $modalInstance) {
                            $scope.title = 'Share Interaction Template';
                            $scope.isInvalidFormat = false;
                            $scope.info = {
                                email: null
                            };

                            /*eslint-disable */
                            $scope.pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            /*eslint-enable */

                            $scope.submit = function () {
                                if ($scope.pattern.test($scope.info.email)) {
                                    $modalInstance.close($scope.info.email);
                                } else {
                                    $scope.isInvalidFormat = true;
                                }
                            };

                            $scope.cancel = function () {
                                $modalInstance.dismiss();
                            };
                        }]
                };
                if (staticViewId) {
                    options['template'] = '<div class="modal-content" static-modal view-id="' + staticViewId + '"></div>';
                } else {
                    options['templateUrl'] = resourceUrl + '/partials/frontend/addMicrosite.html';
                }
                return $modal.open(options);
            }
        };
    }]
);

actionsModule.factory('actions-add-to-microsite', [
    '$q', 'sessionService', '$modal',
    function ($q, sessionService, $modal) {

        function sessionPromise(scope) {
            var defer = $q.defer();
            if (scope.saleSessionManager.isSessionStarted()) {
                defer.resolve(true);
            } else {
                defer.resolve(false);
            }
            return defer.promise;
        }

        function firstMicrosite(scope, websiteTemplateId) {
            var defer = $q.defer();
            sessionService.get({
                    action: 'check-is-first-microsite-instance',
                    templateId: websiteTemplateId,
                    interaction: scope.saleSessionManager.getInteractionKey()
                },
                function (first) {
                    if ('t' === first[0]) {
                        defer.resolve(true);
                    } else {
                        defer.resolve(false);
                    }
                }
            );
            return defer.promise;
        }

        function getEmail(scope) {
            var defer = $q.defer();
            sessionService.get({
                    action: 'get-email',
                    interaction: scope.saleSessionManager.getInteractionKey()
                },
                function (result) {
                    defer.resolve(result.string);
                },
                function () {
                    defer.resolve(null);
                }
            );
            return defer.promise;
        }

        return {
            check: function (scope, websiteTemplateId) {
                return $q.all({
                    sessionStarted: sessionPromise(scope),
                    firstMicrosite: firstMicrosite(scope, websiteTemplateId)
                });
            },
            openModal: function (scope) {
                return $modal.open({
                    templateUrl: resourceUrl + '/partials/frontend/addMicrosite.html',
                    backdrop: true,
                    scope: scope,
                    controller: ['$scope', '$modalInstance',
                        function ($scope, $modalInstance) {
                            $scope.title = 'Add to Microsite';
                            $scope.isInvalidFormat = false;
                            $scope.info = {
                                email: null
                            };

                            getEmail(scope).then(function (result) {
                                $scope.info.email = result;
                            });

                            /*eslint-disable */
                            $scope.pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            /*eslint-enable */

                            $scope.submit = function () {
                                if ($scope.pattern.test($scope.info.email)) {
                                    $modalInstance.close($scope.info.email);
                                } else {
                                    $scope.isInvalidFormat = true;
                                }
                            };

                            $scope.cancel = function () {
                                $modalInstance.dismiss();
                            };
                        }]
                });
            }
        };
    }]
);

actionsModule.factory('actions-preorder', [
    '$modal', '$compile', 'preorderService', 'contentService', 'layoutFacade', 'trackingServices', 'pdfService',
    function ($modal, $compile, preorderService, contentService, layoutFacade, trackingServices, pdfService) {

        function showSuccessWindow(scope, viewId, id) {
            $modal.open({
                template: '<div class="modal-header"></div>' +
                '<div class="modal-body">' +
                '<div class="ng-scope" style="text-align:center;">' +
                '    <p>Pre-order submitted successfully!</p>' +
                '    <br>' +
                '    <div class="btn btn-primary btn-lg ng-scope" title="Print Pre-order request">' +
                '        <div ng-click="next()" class="ng-binding">Print Pre-order request</div>' +
                '    </div>' +
                '</div>' +
                '</div>' +
                '<div class="modal-footer">' +
                '<div>' +
                '<a class="btn btn-default" ng-click="ok();" role="button">OK</a>' +
                '</div>' +
                '</div>',
                controller: ['$scope', '$modalInstance',
                    function ($scope, $modalInstance) {
                        $scope.next = function () {
                            var source = {};
                            source.actionId = 'Print Form';
                            source.viewId = viewId;
                            source.parameters = {};
                            source.parameters['preOrderItemId'] = id;
                            source.parameters['viewId'] = viewId;

                            trackingServices.trackAction(scope, source);
                            pdfService.getPreorderPDF(scope, source.parameters);
                        };

                        $scope.ok = function () {
                            $modalInstance.close();
                        };
                    }],
                backdrop: true,
                scope: scope.$new(false)
            });
        }

        function executePreorder(scope, viewId, content, info) {
            return $modal.open({
                template: content,
                controller: ['$scope', '$modalInstance',
                    function ($scope, $modalInstance) {
                        $scope.owner = scope;
                        $scope.viewId = viewId;
                        $scope.preorderAvailable = info.preorderAvailable;
                        $scope.preorderInfo = info;
                        $scope.selectedService = null;
                        $scope.showAds = true;

                        $scope.goToSale = function () {
                            window.top.open("http://shop.ee.co.uk/retailLanding", "_blank");
                            $modalInstance.close();
                        };

                        $scope.goToPreorder = function () {
                            $scope.showAds = false;
                        };

                        $scope.onFormSubmit = function (formId, payload) {
                            $scope.serviceSelectionFailed = false;
                            if ($scope.selectedService) {
                                $scope.preorderInfo.linkedService = angular.fromJson($scope.selectedService);
                                var pri = angular.copy($scope.preorderInfo);
                                pri.form = payload;
                                preorderService.post({action: 'create', formId: formId}, pri,
                                    function (result) {
                                        showSuccessWindow(scope, scope.viewId, result.id);
                                        $modalInstance.close({'serviceSelectionFailed': true});
                                    },
                                    function (result) {
                                        layoutFacade.showTopMessage(result.message);
                                        $modalInstance.dismiss();
                                    }
                                );
                            } else {
                                $modalInstance.dismiss();
                            }
                        };

                        $scope.onFormCancel = function () {
                            $modalInstance.dismiss();
                        };

                        $scope.closeModal = function () {
                            $modalInstance.dismiss();
                        };
                    }],
                backdrop: true,
                scope: scope.$new(false)
            });
        }

        return {
            execute: function (scope, viewId, info) {
                contentService.post({
                    action: 'preorder-form',
                    interaction: scope.saleSessionManager.getInteractionKey()
                }, info, function (result) {
                    executePreorder(scope, viewId, result.string, info);
                });
            }
        };
    }]);

actionsModule.factory('actions-handler', [
    '$log',
    '$q',
    '$location',
    '$route',
    'actions-service',
    'layoutFacade',
    'saleSessionHolder',
    '$window',
    '$modal',
    'isEnabledCookies',
    'contentService',
    'sessionService',
    'alertMessageFacade',
    'commonService',
    '$rootScope',
    '$timeout',
    '$animate',
    'actions-preorder',
    function ($log, $q, $location, $route, service, layoutFacade, saleSessionHolder, $window, $modal, isEnabledCookies,
              contentService, sessionService, alertMessageFacade, commonService, $rootScope, $timeout, $animate,
              preorders) {
        return {
            'navigate': function (scope, viewId, event) {
                var path = event.destination;
                if ($location.url() === path) {
                    $location.url(path);
                    // reload current page with same route.
                    $route.reload();
                } else if (path.indexOf('http://') === 0 || path.indexOf('https://') === 0) {
                    $window.location.href = path;
                } else {
                    $location.url(path);
                }
            },
            'message': function (scope, viewId, event) {
                layoutFacade.showTopMessage(event.message);
            },
            'update-compare-panel': function () {
                layoutFacade.updateComparePanel();
            },
            'update-recent': function () {
                layoutFacade.updateRecentPanel();
            },
            'offering-select': function (scope, viewId, event) {
                layoutFacade.markAsSelected(event);
            },
            'offering-update': function (scope, viewId, event) {
                layoutFacade.updateSelectedCount(viewId, event);
            },
            'update-basket': function () {
                layoutFacade.updateBasket();
            },
            'update-mini-basket': function () {
                layoutFacade.updateMiniBasket();
            },
            'hide-mini-basket': function () {
                layoutFacade.hideMiniBasket();
            },
            'show-addon': function (scope, viewId, event) {
                layoutFacade.showAddons(event);
            },
            'reload-build-bundle': function () {
                layoutFacade.reloadBuildBundle();
            },
            'sale-session': function (scope, viewId, event) {
                $log.debug('Sale session status changed for ' + event.interaction + ' to ' + event.status);
                scope.saleSessionManager.setInteractionKey(event.interaction);
                layoutFacade.reloadExpressions();
                //todo: implement like in sessionStartButton directive.
            },
            'window-back': function () {
                $window.history.back();
            },
            'expire-cookie': function (scope, viewId, event) {
                if (isEnabledCookies) {
                    document.cookie = event.cookieName + '=;expires=' + new Date().toUTCString();
                }
            },
            'journey-meter-refresh': function () {
                layoutFacade.refreshJourneyMeter();
            },
            'journey-meter-start': function () {
                layoutFacade.startJourneyMeter();
            },
            'update-content': function () {
                layoutFacade.updateContent();
            },
            'print-form': function (scope) {
                scope.printForm();
            },
            'populate-rpm': function (scope, viewId, event) {
                layoutFacade.populateRpm(event);
            },
            'shared-template': function (scope, viewId, event) {
                var template;
                if (event.confirmViewId !== null) {
                    template = '<div static-modal view-id="' + event.confirmViewId + '" url="' + event.url + '" email="' + event.email + '"></div>';
                    alertMessageFacade.openWithoutFooterHeader(template, scope.saleSessionManager.getInteractionKey());
                } else if (event.customerCare) {
                    template = "<div><div>Interaction Template was successfully shared. <a href='" + event.url + "' target='_blank'>Click here</a></div>";
                    var callback = function () {
                        layoutFacade.changeAccess(scope.saleSessionManager.getInteractionKey(), event.websiteTemplateId);
                    };
                    alertMessageFacade.openCustomerCare(scope, template, event.websiteTemplateId, scope.saleSessionManager.getInteractionKey())
                        .result.then(callback, callback);
                } else {
                    template = "Interaction Template was successfully shared. <a href='" + event.url + "' target='_blank'>Click here</a>";
                    alertMessageFacade.openWithHtml(template);
                }
            },
            'added-to-microsite': function (scope, viewId, event) {
                var template;
                if (event.customerCare) {
                    template = "<div><div><b>" + event.itemName + "</b>" + " was successfully added to Microsite. <a href='" + event.url + "' target='_blank'>Click here</a></div>";
                    var callback = function () {
                        layoutFacade.changeAccess(scope.saleSessionManager.getInteractionKey(), event.websiteTemplateId);
                    };
                    alertMessageFacade.openCustomerCare(scope, template, event.websiteTemplateId, scope.saleSessionManager.getInteractionKey())
                        .result.then(callback, callback);
                } else {
                    template = "" +
                        "<div class='mSiteSetControl mSiteSetControlBase custom-font-2 text-center'>" +
                        "<div class='mSiteSetControlHead custom-bg-1 custom-color-4'><div class='proIcons modalX' ng-click='ok();'>x</div></div>" +
                        "<div class='mSiteSetControlCircle'></div>" +
                        "<div class='mSiteSetControlItem custom-color-6'><strong class='custom-font-1 custom-font-700'>" + event.itemName + "</strong><div class='textLabel'>was successfully added to microsite.</div><a href='" + event.url + "' target='_blank'>View microsite &gt;</a></div>" +
                        "</div>";
                    if (scope.templateViewId === null) {
                        alertMessageFacade.openWithHtml(template);
                    } else {
                        alertMessageFacade.openWithoutFooterHeader(template, scope.saleSessionManager.getInteractionKey());
                    }
                }
            },
            'pushed-to-microsite': function (scope, viewId, event) {
                if (event.customerCare) {
                    var callback = function () {
                        layoutFacade.changeAccess(scope.saleSessionManager.getInteractionKey(), event.websiteTemplateId);
                    };
                    alertMessageFacade.openCustomerCare(scope,
                        "<div><div>Selected items was successfully pushed to to Microsite." +
                        "<a href='" + event.url + "' target='_blank'>Click here</a></div>",
                        event.websiteTemplateId, scope.saleSessionManager.getInteractionKey())
                        .result.then(callback, callback);
                } else {
                    alertMessageFacade.openWithHtml("Selected items was successfully pushed to to Microsite. <a href='" + event.url + "' target='_blank'>Click here</a>");
                }
            },
            'show-preorder-form': function (scope, viewId, event) {
                preorders.execute(scope, viewId, event.info);
            },
            'print-view': function (scope, viewId, event) {
                scope.$emit('printViews', event);
            }
        };
    }
]);

actionsModule.factory('actions-behaviours', [
    '$log',
    '$q',
    '$location',
    '$route',
    'actions-service',
    'layoutFacade',
    'saleSessionHolder',
    '$window',
    '$modal',
    'isEnabledCookies',
    'contentService',
    'sessionService',
    'alertMessageFacade',
    'commonService',
    '$rootScope',
    '$timeout',
    '$animate',
    'actions-preorder',
    'actions-add-to-microsite',
    'actions-share-template',
    'actions-push-to-microsite',
    'actions-handler',
    'lockOperationsFacade',
    function ($log, $q, $location, $route, service, layoutFacade, saleSessionHolder, $window, $modal, isEnabledCookies,
              contentService, sessionService, alertMessageFacade, commonService, $rootScope, $timeout, $animate,
              preorders, addToMicrosite, shareTemplate, pushToMicrosite, handlers, lockOperationsFacade) {

        function handleEvent(scope, viewId, ev) {
            $log.debug('Action event received: ' + ev.name);
            var handler = handlers[ev.name];
            if (handler) {
                handler(scope, viewId, ev);
            } else {
                $log.error('Action event processor not found for [' + ev.name + '].');
            }
        }

        function executeServerBehaviour(scope, viewId, action, behaviour, attrs) {
            var defer = $q.defer();
            var payload = {
                'viewId': viewId,
                'actionId': action.id,
                'behavior': behaviour.definition,
                'behaviorInternalId': behaviour.id,
                'parameters': {}
            };

            payload.parameters.interaction = scope.saleSessionManager.getInteractionKey();
            if (scope.processInfo) {
                payload.parameters.processDefinitionId = scope.processInfo.processDefinitionId;
            }

            if (scope.actionOffering) {
                payload.parameters.offeringId = scope.actionOffering.id;
                payload.parameters.offeringTypeId = scope.actionOffering.typeId;
                payload.parameters.offeringName = scope.actionOffering.name;
            }

            for (var attr in attrs) {
                if (attrs.hasOwnProperty(attr) && payload.parameters[attr] === undefined) {
                    payload.parameters[attr] = attrs[attr];
                }
            }
            
            if (payload && payload.parameters) {
                Object.keys(payload.parameters).forEach(key => {
                    if (typeof payload.parameters[key] === 'string') {
                        payload.parameters[key] = HtmlEntities.encode(payload.parameters[key]);
                    }
                });
            }
            
            service.post({action: 'execute'}, payload,
                function (result) {
                    $log.debug('Behaviour ' + behaviour.definition + ' finished.');

                    if (scope.animationClass) {
                        $animate.addClass($('.mainBody'), scope.animationClass);
                    }
                    angular.forEach(result.events, function (ev) {
                        handleEvent(scope, viewId, ev);
                    });
                    if (scope.animationClass) {
                        $animate.removeClass($('.mainBody'), scope.animationClass);
                    }

                    defer.resolve();
                },
                function (response) {
                    $log.error('Action failed: ' + response);
                    defer.reject();
                }
            );

            return defer.promise;
        }

        function getParameter(parameters, name) {
            for (var i = 0; i < parameters.length; i++) {
                var param = parameters[i];
                if (param.name === name) {
                    return param.value;
                }
            }
            $log.debug('Parameter ' + name + ' not found.');
            return null;
        }


        return {
            'default': executeServerBehaviour,
            'SHOW_OVERLAY': function () {
                lockOperationsFacade.lock();
                return $q.when();
            },
            'TOGGLE_LEFT_MENU': function () {
                layoutFacade.reloadExpressions();
                layoutFacade.toggleLeftMenu();
                return $q.when();
            },
            'CLOSE_LEFT_MENU': function () {
                layoutFacade.hideLeft();
                return $q.when();
            },
            'TOGGLE_RIGHT_MENU': function () {
                layoutFacade.toggleRightMenu();
                return $q.when();
            },
            'CLOSE_RIGHT_MENU': function () {
                layoutFacade.hideRight();
                return $q.when();
            },
            'SELECT_ALL': function (scope, viewId, config, behaviour) {
                var defer = $q.defer();
                var offerings = {};
                var items = angular.element("[view-id='" + viewId + "'] [offering-id]");
                for (var i = 0; i < items.length; i++) {
                    var item = angular.element(items[i]);
                    offerings[item.attr("offering-id")] = item.attr("offering-name");
                }
                // replace original attributes with offerings.
                // todo: reimplement.
                defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour, offerings));
                return defer.promise;
            },
            'ADD_TO_MICROSITE': function (scope, viewId, config, behaviour) {
                var defer = $q.defer();
                var websiteTempateId = getParameter(config.parameters, 'websiteTemplateId');
                addToMicrosite.check(scope, websiteTempateId)
                    .then(
                        function (result) {
                            if (result.sessionStarted && result.firstMicrosite) {
                                addToMicrosite.openModal(scope).result
                                    .then(function (email) {
                                            defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour, {email: email}));
                                        }, function () {
                                            defer.resolve();
                                        }
                                    );
                            } else {
                                defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour));
                            }
                        }
                    );

                return defer.promise;
            },
            'PUSH_TO_MICROSITE': function (scope, viewId, config, behaviour) {
                var defer = $q.defer();
                pushToMicrosite.check(scope)
                    .then(
                        function (result) {
                            if (result.sessionStarted) {
                                pushToMicrosite.openModal(scope).result
                                    .then(function (params) {
                                            defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour, params));
                                        }, function () {
                                            defer.resolve();
                                        }
                                    );
                            } else {
                                defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour));
                            }
                        }
                    );
                return defer.promise;
            },
            'SHARE_TEMPLATE': function (scope, viewId, config, behaviour) {
                var defer = $q.defer();
                shareTemplate.check(scope)
                    .then(
                        function (result) {
                            if (result.sessionStarted && result.firstMicrosite) {
                                var staticViewId = getParameter(config.parameters, 'inputViewId');
                                shareTemplate.openModal(scope, staticViewId).result
                                    .then(function (email) {
                                            defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour, {email: email}));
                                        }, function () {
                                            defer.resolve();
                                        }
                                    );
                            } else {
                                defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour));
                            }
                        }
                    );
                return defer.promise;
            },
            'PUSH_TO_TILL': function (scope, viewId, config, behaviour) {
                var defer = $q.defer();
                if (scope.saleSessionManager.isSessionStarted()) {
                    $modal.open({
                        template: ' <div class="modal-header"><h3>Push to Till</h3></div><div class="modal-body">' +
                        '<p><strong>Customer Transaction has been pushed to the Till.</strong></p>' +
                        '<p><strong>Unique Reference number: <span class="text-overflow">{{referenceNumber}}</span></strong></p>' +
                        '</div><div class="modal-footer"><button class="btn btn-primary" ng-click="ok()">OK</button></div>',
                        backdrop: true,
                        scope: scope,
                        controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                            $scope.referenceNumber = (Math.random() * 10000000000).toFixed(0);
                            $scope.ok = function () {
                                $modalInstance.close({'reference': $scope.referenceNumber});
                            };
                        }]
                    }).result
                        .then(function (params) {
                            defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour, params));
                        }, function () {
                            defer.resolve();
                        });
                } else {
                    defer.resolve();
                }
                return defer.promise;
            },
            'LOGOUT': function (scope, viewId, action, behaviour, attrs) {
                layoutFacade.hideLeft();
                layoutFacade.hideRight();
                var reset = "resetLastPage" in attrs;
                contentService.get({action: 'logout-content'},
                    function (result) {
                        var _modalBody = '<div class="modal-body">' +
                            '<div class="modal-logout"><div class="modal-header"><h3>Logout</h3></div><div class="logout-Msg">Are you sure you want to logout?</div></div></div>' +
                            '<div class="modal-footer"><div>' +
                            '<a class="btn btn-default" ng-click="yes();" role="button">YES</a>' +
                            '<a class="btn btn-default" ng-click="no();" role="button">NO</a>' +
                            '</div></div>';

                        if (result.string != null && result.string != '') {
                            _modalBody = result.string;
                        }

                        $modal.open({
                            template: _modalBody,
                            controller: ['$scope', '$modalInstance', '$rootScope', 'loginService', 'tokenFacade', '$http',
                                function ($scope, $modalInstance, $rootScope, loginService, tokenFacade, $http) {
                                    $scope.yes = function () {
                                        $modalInstance.close();
                                        loginService.logout({interaction: scope.saleSessionManager.getInteractionKey()}, function () {
                                            delete $rootScope.user;
                                            delete $http.defaults.headers.common['Auth-Token'];
                                            delete $rootScope.activeClient;
                                            tokenFacade.destroy();
                                            if (reset) {
                                                $rootScope.oldLocation = "/user/home";
                                            } else {
                                                $rootScope.oldLocation = $location.path();
                                            }
                                            $rootScope.$broadcast('logoutSuccessEvt');
                                            $location.path("/login");
                                        });
                                    };
                                    $scope.no = function () {
                                        $modalInstance.close();
                                    };
                                }],
                            backdrop: true
                        });
                    });
                return $q.when();
            },
            'SWITCH_USER': function () {
                commonService.query({module: 'locations', action: 'locationUsers'},
                    function (users) {
                        $modal.open({
                            templateUrl: resourceUrl + '/template/modal/userSelect.html',
                            controller: ['$scope', '$modalInstance', 'loginService', 'callCenterService', '$http', 'tokenFacade',
                                function ($scope, $modalInstance, loginService, callCenterService, $http, tokenFacade) {
                                    $scope.users = users;

                                    function getSelectedUser(users) {
                                        for (var i = 0; i < users.length; i++) {
                                            if (users[i].externalId === layoutFacade.user().externalId) {
                                                return users[i];
                                            }
                                        }
                                        return users[0];
                                    }

                                    $scope.selectedUser = getSelectedUser(users);
                                    $scope.pattern = "";
                                    $scope.location = layoutFacade.locationInfo().fields;
                                    $scope.clickSelect = function () {
                                        $modalInstance.close($scope.selectedUser.externalId);
                                    };

                                    $scope.clickCancel = function () {
                                        $modalInstance.dismiss('cancel');
                                    };

                                    $scope.selectUser = function (selection) {
                                        $scope.selectedUser = selection;
                                        for (var ind = 0; ind < users.length; ind++) {
                                            if ($scope.users.hasOwnProperty(ind)) {
                                                $scope.users[ind].selected = false;
                                            }
                                        }
                                        selection.selected = true;
                                    };

                                    $scope.changeUser = function (newUser) {
                                        layoutFacade.changePin(true);
                                        loginService.authenticate(
                                            {
                                                username: newUser.userName,
                                                password: "",
                                                pin: newUser.pin
                                            }, null,
                                            function (user) {
                                                if (user.token) {
                                                    postProcess(user, newUser);
                                                } else {
                                                    loginService.restoreSession(
                                                        {
                                                            username: newUser.userName,
                                                            password: null,
                                                            pin: newUser.pin
                                                        }, null,
                                                        function (result) {
                                                            postProcess(result, newUser);
                                                        }
                                                    );
                                                }
                                            },
                                            function (response) {
                                                layoutFacade.changePin(false);
                                                if (401 === response.status) {
                                                    alert('Invalid Credential');
                                                }
                                            });
                                    };

                                    function postProcess(user, newUser) {
                                        //todo: put to right place.
                                        layoutFacade.changePin(false);
                                        layoutFacade.user(user);
                                        $http.defaults.headers.common['Auth-Token'] = user.token;
                                        tokenFacade.setToken(user.token);
                                        if (newUser.locationId) {
                                            commonService.post({
                                                    module: 'locations',
                                                    action: 'store'
                                                }, newUser.locationId,
                                                function (result) {
                                                    layoutFacade.locationInfo(angular.copy(result));
                                                    callCenterService.init(user.interaction);
                                                },
                                                function () {
                                                    callCenterService.init(user.interaction);
                                                });
                                        } else {
                                            callCenterService.init(user.interaction);
                                        }
                                        $modalInstance.close($scope.selectedUser.externalId);
                                        $scope.saleSessionManager.setInteractionKey(user.interaction);
                                        layoutFacade.updateContent();
                                        layoutFacade.updateComparePanel();
                                    }
                                }
                            ],
                            backdrop: 'static',
                            keyboard: false,
                            backdropClass: 'switch-user-modal-back',
                            windowClass: 'switch-user-modal'
                        });
                    }
                );
                return $q.when();
            },
            'FEEDBACK': function () {
                //todo: extract modal template
                $modal.open({
                    templateUrl: 'feedbackDialog',
                    controller: ['$scope', '$modalInstance', 'feedbackService', '$translate',
                        function ($scope, $modalInstance, feedbackService, $translate) {
                            $scope.categories = [];
                            if (ripplMultiLanguage) {
                                $scope.categories = [
                                    'SYS_FEEDBACK_CATEGORY_SYSTEM',
                                    'SYS_FEEDBACK_CATEGORY_DATA',
                                    'SYS_FEEDBACK_CATEGORY_ACCESS',
                                    'SYS_FEEDBACK_CATEGORY_OTHER'];
                            } else {
                                $scope.categories = [
                                    'System',
                                    'Data',
                                    'Access',
                                    'Other'];
                            }

                            $scope.feedback = {
                                category: '',
                                subject: '',
                                description: ''
                            };

                            $scope.validateForm = false;

                            $scope.sendFeedback = function (form) {
                                $scope.validateForm = true;
                                if (form.$valid) {
                                    $modalInstance.close();
                                    if (ripplMultiLanguage) {
                                        $translate($scope.feedback.category).then(function (value) {
                                            $scope.feedback.category = value;
                                            feedbackService.send($scope.feedback);
                                        }, function (id) {
                                            $scope.feedback.category = id;
                                        });
                                    } else {
                                        feedbackService.send($scope.feedback);
                                    }
                                }
                            };
                            $scope.closeDialog = function () {
                                $modalInstance.close();
                            };
                        }],
                    backdrop: true
                });
                return $q.when();
            },
            'SEARCH': function (scope, viewId, config) {
                var id = config.parameters[0].value;
                var value = angular.element("input[id='" + id + "']").val();
                $location.path("/user/search").search({query: value});
                return $q.when();
            },
            'SESSION_RESUME': function (scope, viewId, config, behaviour, attrs) {
                var defer = $q.defer();
                scope.saleSessionManager.setInteractionKey(attrs.sessionId);
                defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour, attrs));
                return defer.promise;
            },
            'SHOW_IN_MODAL': function (scope, viewId, config, behaviour) {
                var configuredView;
                if (viewId === 'Rating') {
                    configuredView = getParameter(config.parameters, 'viewId');
                } else {
                   configuredView = getParameter(behaviour.parameters, 'viewId');
                }
                var paramsModal = {
                    action: 'get-view-for-modal',
                    interaction: scope.saleSessionManager.getInteractionKey(),
                    viewId: configuredView,
                    timestamp: (new Date()).getTime()
                };
                if (isEmbeddedMode) {
                    paramsModal.embeddedModeURL = globalWrapperVariables.externalNgcUrl;
                }
				
				if (scope.processInfo) {
					paramsModal.processDefinitionId = scope.processInfo.processDefinitionId;
				}

                contentService.get(paramsModal,
                    function (result) {
                        $modal.open({
                            template: '<div class="modal-body">' + result.string + '</div>',
                            scope: scope,
                            controller: ['$scope', '$modalInstance',
                                function ($scope, $modalInstance) {
                                    $scope.onFormSubmitFinish = function (formId, payload) {
                                        $modalInstance.close(payload);
                                    };

                                    $scope.onFormCancel = function () {
                                        $modalInstance.dismiss();
                                    };

                                    $scope.closeModal = function () {
                                        $modalInstance.dismiss();
                                    };
                                }
                            ],
                            backdrop: true
                        });
                    });
                return $q.when();
            },
            'SELLME': function (scope) {
                if (scope.actionOffering) {
                    contentService.get({
                            action: 'get-sell-me-content',
                            offeringId: scope.actionOffering.id,
                            offeringTypeId: scope.actionOffering.typeId
                        },
                        function (result) {
                            $modal.open({
                                template: '<div class="modal-body">' + result.string + '</div>',
                                scope: scope,
                                controller: ['$scope', '$modalInstance',
                                    function ($scope, $modalInstance) {
                                        $scope.onFormCancel = function () {
                                            $modalInstance.dismiss();
                                        };

                                        $scope.closeModal = function () {
                                            $modalInstance.dismiss();
                                        };
                                    }
                                ],
                                backdrop: true
                            });
                        });
                }
                return $q.when();
            },
            'FORM_SUBMIT': function (scope, viewId, config, behaviour) {
                $log.debug('Submit form data in ' + viewId);
                var defer = $q.defer();
                var submitParam = getParameter(config.parameters, "submit");
                scope.getFormData(submitParam).then(
                    function (payload) {
                        for (var i = 0; i < config.parameters.length; i++) {
                            var param = config.parameters[i];
                            var name = param.name;
                            var value = param.value;
                            if (payload.hasOwnProperty(name) && param.value) {
                                payload[name] = value;
                            }
                        }
                        defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour, payload));
                    },
                    function () {
                        defer.reject();
                    }
                );
                return defer.promise;
            },
            'REPORT_DEAL': function (scope, viewId, config, behaviour) {
                var defer = $q.defer();
                $modal.open({
                    templateUrl: resourceUrl + '/template/modal/reportDeal.html',
                    backdrop: true,
                    controller: ['$scope', '$modalInstance',
                        function ($scope, $modalInstance) {
                            commonService.query({
                                    module: 'report-deal',
                                    action: 'providers'
                                },
                                function (result) {
                                    $scope.providers = result;
                                }
                            );

                            $scope.reportDeal = {
                                user: $scope.user.name
                            };

                            $scope.onRetailerSelect = function () {
                                if (!$scope.reportDeal.retailer) {
                                    $scope.reportDeal.retailerOther = "";
                                }
                            };

                            $scope.onRadioUnlimitedClick = function (sourceRadio, targetInput) {
                                if ($scope.reportDeal[sourceRadio]) {
                                    $scope.reportDeal[targetInput] = "";
                                }
                            };

                            $scope.onInputValueChange = function (sourceInput, targetRadio) {
                                if ($scope.reportDeal[sourceInput].length > 0) {
                                    $scope.reportDeal[targetRadio] = "";
                                }
                            };

                            $scope.isFormValid = function () {
                                return (
                                    ($scope.reportDeal.retailer || $scope.reportDeal.retailerOther) &&
                                    $scope.reportDeal.network &&
                                    ($scope.reportDeal.manufacturer || $scope.reportDeal.handset) &&
                                    ($scope.reportDeal.minutes || $scope.reportDeal.minutesUnlimited) &&
                                    ($scope.reportDeal.texts || $scope.reportDeal.textsUnlimited) &&
                                    ($scope.reportDeal.data || $scope.reportDeal.dataUnlimited) &&
                                    $scope.reportDeal.monthlyCost &&
                                    ($scope.reportDeal.mobileNumber || $scope.reportDeal.noMobileNumber) &&
                                    $scope.reportDeal.outcome
                                );
                            };

                            $scope.save = function () {
                                if ($scope.isFormValid()) {
                                    $modalInstance.close($scope.reportDeal);
                                }
                            };

                            $scope.cancel = function () {
                                $modalInstance.dismiss();
                            };
                        }
                    ]
                }).result
                    .then(
                        function (payload) {
                            defer.resolve(executeServerBehaviour(scope, viewId, config, behaviour, payload));
                        }, function () {
                            defer.resolve();
                        }
                    );
                return defer.promise;
            },
            'PRINT_DEAL': function () {
                $window.print();
                return $q.when();
            }
        };
    }]);


// @todo deprecated
actionsModule.directive('offeringId', [function () {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elm, attrs) {
            scope.actionOffering = {
                'id': attrs.offeringId,
                'typeId': attrs.offeringTypeId,
                'name': attrs.offeringName
            };
        }
    };
}]);

actionsModule.factory('actions-manager', ['$log', 'actions-behaviours', '$q', 'actions-config', function ($log, behaviours, $q, cs) {
    var list = [];

    function chainBehaviour(behaviour, scope, config, attrs) {
        var viewId = attrs.viewId || scope.viewId;
        var method = behaviours[behaviour.definition];
        $log.debug('Executing behaviour ' + behaviour.definition + ".");
        if (method) {
            return method(scope, viewId, config, behaviour, attrs);
        } else {
            return behaviours['default'](scope, viewId, config, behaviour, attrs);
        }
    }

    function stopChain() {
        var stopChain = $q.defer();
        stopChain.resolve();
        return stopChain.promise;
    }

    function syncCall(scope, config, attrs) {
        if (list && list.length > 0) {
            return chainBehaviour(list.shift(), scope, config, attrs)
                .then(
                    function () {
                        return syncCall(scope, config, attrs);
                    },
                    function () {
                        return stopChain();
                    }
                );
        } else {
            return stopChain();
        }
    }

    function executeIt(scope, config, attrs) {
        list = angular.copy(config.elements);
        return $q.all(syncCall(scope, config, attrs));
    }

    return {
        submitForm: function (scope, viewId, actionId, payload) {
            var deferred = $q.defer();
            $log.debug('Retrieve action details for ' + actionId);
            cs.get({'viewId': viewId, 'actionId': actionId}, function (result) {
                deferred.resolve(executeIt(scope, result, payload));
            }, function (response) {
                $log.error('Failed to retrieve action details for ' + actionId + ': ' + response);
                deferred.reject();
            });
            return deferred.promise;
        }
    };
}]);

actionsModule.directive('actionId', ['actions-config', '$log', '$q', 'actions-behaviours', 'trackingServices', 'DISABLE_BUTTON_CLASS',
    function (config, $log, $q, behaviours, trackingServices, DISABLE_BUTTON_CLASS) {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, elm, attrs) {
                scope.animationClass = attrs.animationClass;
                scope.checkParentFormState = attrs.checkParentFormState;
                scope.invalidWatchedFields = [];
                var list = [];

                addWatchForOperation(scope, elm, attrs);
                addWatchForOperationStatus(scope, elm, attrs);

                if (scope.checkParentFormState) {
                    scope.$on('checkSubmitButtonState', function (event, field, validationMethod) {
                        validationMethod().then(function (validationResult) {
                            var index = scope.invalidWatchedFields.indexOf(field);
                            if (!validationResult.valid) {
                                if (index === -1) {
                                    scope.invalidWatchedFields.push(field);
                                }
                            } else {
                                if (index !== -1) {
                                    scope.invalidWatchedFields.splice(index, 1);
                                }
                            }

                            var tagCssClasses = attrs.class.replace(DISABLE_BUTTON_CLASS, '');
                            if (scope.invalidWatchedFields.length == 0) {
                                attrs.$set("class", tagCssClasses);
                            } else {
                                attrs.$set("class", tagCssClasses + " " + DISABLE_BUTTON_CLASS);
                            }
                        });
                    });
                }

                function addWatchForOperation(scope, elm, attrs){
                    scope.$watch(function() {
                        return elm.attr('operation');
                    }, function(newValue){
                        if(newValue && attrs.operation != newValue) {
                            attrs.$set("operation", newValue);
                        }
                    });
                }

                function addWatchForOperationStatus(scope, elm, attrs){
                    scope.$watch(function() {
                        return elm.attr('operation-status');
                    }, function(newValue){
                        if(newValue && attrs.operationStatus != newValue) {
                            attrs.$set("operationStatus", newValue);
                        }
                    });
                }

                function chainBehaviour(behaviour, scope, config, attrs) {
                    var viewId = getViewId(scope, attrs);
                    if(attrs.actionId && attrs.actionId === 'Rating'){
                        viewId = attrs.actionId;
                    }
                    var method = behaviours[behaviour.definition];
                    $log.debug('Executing behaviour ' + behaviour.definition + ".");
                    if (method) {
                        return method(scope, viewId, config, behaviour, attrs);
                    } else {
                        return behaviours['default'](scope, viewId, config, behaviour, attrs);
                    }
                }

                function stopChain() {
                    var stopChain = $q.defer();
                    stopChain.resolve();
                    bindElement(); // enable element event after chain will be completed.
                    return stopChain.promise;
                }

                function syncCall(scope, config, attrs) {
                    if (list && list.length > 0) {
                        return chainBehaviour(list.shift(), scope, config, attrs)
                            .then(
                                function () {
                                    return syncCall(scope, config, attrs);
                                },
                                function () {
                                    return stopChain();
                                }
                            );
                    } else {
                        return stopChain();
                    }
                }

                function executeIt(scope, config, attrs) {
                    list = config.elements;
                    return $q.all(syncCall(scope, config, attrs));
                }

                function getViewId(scope, attrs) {
                    // use action attribute instead of scope variable.
                    return attrs.viewId || scope.viewId;
                }

                function needToFire(ev) {
                    if (ev.target.nodeName.toLowerCase() === 'input') {
                        var keycode = (ev.keyCode ? ev.keyCode : ev.which);
                        if (keycode !== 13) {
                            //any key pressed on input element except ENTER.
                            return false;
                        }
                    }
                    return true;
                }

                function bindElement() {
                    elm.on('click keypress', function (event) {
                        if (!scope.checkParentFormState || scope.invalidWatchedFields.length == 0) {
                            $log.debug('Action execution started: ' + attrs.actionId);
                            // because twice call of default action and pressed button
                            event.stopPropagation();

                            if (needToFire(event)) {
                                var viewId = getViewId(scope, attrs);
                                if (scope.viewId === undefined) {
                                    // not all directives set view id in scope.
                                    scope.viewId = viewId;
                                }
                                elm.off(event); // disable handler for prevent double click.
                                $log.debug('Retrieve action details for ' + attrs.actionId);
                                config.get({'viewId': viewId, 'actionId': attrs.actionId}, function (result) {
                                    //@todo: extract to another place.
                                    trackingServices.trackAction(scope, {actionId: result.id, viewId: viewId});
                                    executeIt(scope, result, attrs);
                                });
                            }
                        }
                    });
                }

                bindElement();
            }
        };
    }]);

actionsModule.directive('executeOnClick', function() {
    return {
        priority: 1,
        restrict: 'A',
        scope: false,
        link: function (scope, elm, attrs) {
            function bindElement() {
                elm.on('click keypress', function () {
                    eval(attrs.executeOnClick);
                });
            }
            bindElement();
        }
    };
});

actionsModule.factory('actions-config', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/view-actions/details/:action', {},
        {
            get: {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }
        }
    );
}]);

actionsModule.factory('actions-service', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/action/:action', {},
        {
            post: {
                method: 'POST'
            }
        }
    );
}]);



/*------------------------------------------------------------
BEGIN HtmlEntities
------------------------------------------------------------*/

var HtmlEntities = function() { };

HtmlEntities.map = {
    "'": "&apos;",
    "<": "&lt;",
    ">": "&gt;",
    /* " ": "&nbsp;", */
    "¡": "&iexcl;",
    "¢": "&cent;",
    "£": "&pound;",
    "¤": "&curren;",
    "¥": "&yen;",
    "¦": "&brvbar;",
    "§": "&sect;",
    "¨": "&uml;",
    "©": "&copy;",
    "ª": "&ordf;",
    "«": "&laquo;",
    "¬": "&not;",
    "®": "&reg;",
    "¯": "&macr;",
    "°": "&deg;",
    "±": "&plusmn;",
    "²": "&sup2;",
    "³": "&sup3;",
    "´": "&acute;",
    "µ": "&micro;",
    "¶": "&para;",
    "·": "&middot;",
    "¸": "&cedil;",
    "¹": "&sup1;",
    "º": "&ordm;",
    "»": "&raquo;",
    "¼": "&frac14;",
    "½": "&frac12;",
    "¾": "&frac34;",
    "¿": "&iquest;",
    "À": "&Agrave;",
    "Á": "&Aacute;",
    "Â": "&Acirc;",
    "Ã": "&Atilde;",
    "Ä": "&Auml;",
    "Å": "&Aring;",
    "Æ": "&AElig;",
    "Ç": "&Ccedil;",
    "È": "&Egrave;",
    "É": "&Eacute;",
    "Ê": "&Ecirc;",
    "Ë": "&Euml;",
    "Ì": "&Igrave;",
    "Í": "&Iacute;",
    "Î": "&Icirc;",
    "Ï": "&Iuml;",
    "Ð": "&ETH;",
    "Ñ": "&Ntilde;",
    "Ò": "&Ograve;",
    "Ó": "&Oacute;",
    "Ô": "&Ocirc;",
    "Õ": "&Otilde;",
    "Ö": "&Ouml;",
    "×": "&times;",
    "Ø": "&Oslash;",
    "Ù": "&Ugrave;",
    "Ú": "&Uacute;",
    "Û": "&Ucirc;",
    "Ü": "&Uuml;",
    "Ý": "&Yacute;",
    "Þ": "&THORN;",
    "ß": "&szlig;",
    "à": "&agrave;",
    "á": "&aacute;",
    "â": "&acirc;",
    "ã": "&atilde;",
    "ä": "&auml;",
    "å": "&aring;",
    "æ": "&aelig;",
    "ç": "&ccedil;",
    "è": "&egrave;",
    "é": "&eacute;",
    "ê": "&ecirc;",
    "ë": "&euml;",
    "ì": "&igrave;",
    "í": "&iacute;",
    "î": "&icirc;",
    "ï": "&iuml;",
    "ð": "&eth;",
    "ñ": "&ntilde;",
    "ò": "&ograve;",
    "ó": "&oacute;",
    "ô": "&ocirc;",
    "õ": "&otilde;",
    "ö": "&ouml;",
    "÷": "&divide;",
    "ø": "&oslash;",
    "ù": "&ugrave;",
    "ú": "&uacute;",
    "û": "&ucirc;",
    "ü": "&uuml;",
    "ý": "&yacute;",
    "þ": "&thorn;",
    "ÿ": "&yuml;",
    "Œ": "&OElig;",
    "œ": "&oelig;",
    "Š": "&Scaron;",
    "š": "&scaron;",
    "Ÿ": "&Yuml;",
    "ƒ": "&fnof;",
    "ˆ": "&circ;",
    "˜": "&tilde;",
    "Α": "&Alpha;",
    "Β": "&Beta;",
    "Γ": "&Gamma;",
    "Δ": "&Delta;",
    "Ε": "&Epsilon;",
    "Ζ": "&Zeta;",
    "Η": "&Eta;",
    "Θ": "&Theta;",
    "Ι": "&Iota;",
    "Κ": "&Kappa;",
    "Λ": "&Lambda;",
    "Μ": "&Mu;",
    "Ν": "&Nu;",
    "Ξ": "&Xi;",
    "Ο": "&Omicron;",
    "Π": "&Pi;",
    "Ρ": "&Rho;",
    "Σ": "&Sigma;",
    "Τ": "&Tau;",
    "Υ": "&Upsilon;",
    "Φ": "&Phi;",
    "Χ": "&Chi;",
    "Ψ": "&Psi;",
    "Ω": "&Omega;",
    "α": "&alpha;",
    "β": "&beta;",
    "γ": "&gamma;",
    "δ": "&delta;",
    "ε": "&epsilon;",
    "ζ": "&zeta;",
    "η": "&eta;",
    "θ": "&theta;",
    "ι": "&iota;",
    "κ": "&kappa;",
    "λ": "&lambda;",
    "μ": "&mu;",
    "ν": "&nu;",
    "ξ": "&xi;",
    "ο": "&omicron;",
    "π": "&pi;",
    "ρ": "&rho;",
    "ς": "&sigmaf;",
    "σ": "&sigma;",
    "τ": "&tau;",
    "υ": "&upsilon;",
    "φ": "&phi;",
    "χ": "&chi;",
    "ψ": "&psi;",
    "ω": "&omega;",
    "ϑ": "&thetasym;",
    "ϒ": "&Upsih;",
    "ϖ": "&piv;",
    "–": "&ndash;",
    "—": "&mdash;",
    "‘": "&lsquo;",
    "’": "&rsquo;",
    "‚": "&sbquo;",
    "“": "&ldquo;",
    "”": "&rdquo;",
    "„": "&bdquo;",
    "†": "&dagger;",
    "‡": "&Dagger;",
    "•": "&bull;",
    "…": "&hellip;",
    "‰": "&permil;",
    "′": "&prime;",
    "″": "&Prime;",
    "‹": "&lsaquo;",
    "›": "&rsaquo;",
    "‾": "&oline;",
    "⁄": "&frasl;",
    "€": "&euro;",
    "ℑ": "&image;",
    "℘": "&weierp;",
    "ℜ": "&real;",
    "™": "&trade;",
    "ℵ": "&alefsym;",
    "←": "&larr;",
    "↑": "&uarr;",
    "→": "&rarr;",
    "↓": "&darr;",
    "↔": "&harr;",
    "↵": "&crarr;",
    "⇐": "&lArr;",
    "⇑": "&UArr;",
    "⇒": "&rArr;",
    "⇓": "&dArr;",
    "⇔": "&hArr;",
    "∀": "&forall;",
    "∂": "&part;",
    "∃": "&exist;",
    "∅": "&empty;",
    "∇": "&nabla;",
    "∈": "&isin;",
    "∉": "&notin;",
    "∋": "&ni;",
    "∏": "&prod;",
    "∑": "&sum;",
    "−": "&minus;",
    "∗": "&lowast;",
    "√": "&radic;",
    "∝": "&prop;",
    "∞": "&infin;",
    "∠": "&ang;",
    "∧": "&and;",
    "∨": "&or;",
    "∩": "&cap;",
    "∪": "&cup;",
    "∫": "&int;",
    "∴": "&there4;",
    "∼": "&sim;",
    "≅": "&cong;",
    "≈": "&asymp;",
    "≠": "&ne;",
    "≡": "&equiv;",
    "≤": "&le;",
    "≥": "&ge;",
    "⊂": "&sub;",
    "⊃": "&sup;",
    "⊄": "&nsub;",
    "⊆": "&sube;",
    "⊇": "&supe;",
    "⊕": "&oplus;",
    "⊗": "&otimes;",
    "⊥": "&perp;",
    "⋅": "&sdot;",
    "⌈": "&lceil;",
    "⌉": "&rceil;",
    "⌊": "&lfloor;",
    "⌋": "&rfloor;",
    "⟨": "&lang;",
    "⟩": "&rang;",
    "◊": "&loz;",
    "♠": "&spades;",
    "♣": "&clubs;",
    "♥": "&hearts;",
    "♦": "&diams;",
    "℠": "&#8480;",
    "℗": "&copysr;"
};

HtmlEntities.decode = function(string) {
    if (!Number.isInteger(string) && string != "") {
        var entityMap = HtmlEntities.map;
        for (var key in entityMap) {
            var entity = entityMap[key];
            var regex = new RegExp(entity, 'g');
            string = string.replace(regex, key);
        }

        string = string.replace(/"/g, '&quot;');
        string = string.replace(/&amp;/g, '&');
        string = string.replace(/&quot;/g, '"');
    }
    return string;
};

HtmlEntities.encode = function(string) {
    if (!Number.isInteger(string) && string != "") {
        var entityMap = HtmlEntities.map;
        string = string.replace(/"/g, '&quot;');
        for (var key in entityMap) {
            var entity = entityMap[key];
            var regex = new RegExp(key, 'g');
            string = string.replace(regex, entity);
        }
    }
    return string;
};

/*------------------------------------------------------------
END HtmlEntities
------------------------------------------------------------*/

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, $, resourceUrl, window, ripplMultiLanguage */

/* Controllers */

frontendApp.controller('RootCtrl', ['$scope', '$rootScope', '$location', '$timeout', '$anchorScroll', 'sessionService',
    'pdfService', '$modal', 'commonService', '$document', 'trackingServices', 'layoutFacade',
    'saleSessionManagerProvider', 'saleSessionHolder', '$log', '$translate',
    function ($scope, $rootScope, $location, $timeout, $anchorScroll, sessionService, pdfService, $modal, commonService,
              $document, trackingServices, layoutFacade, saleSessionManagerProvider, saleSessionHolder, $log, $translate) {

        $scope.page = {};
        $scope.customerCareInfo = {micrositeId: ""};
        $scope.customerInfo = $rootScope.customerInfo;
        $scope.leftPanelMinimized = false;
        $scope.topMessage = null;
        $rootScope.predefinedLanguage;

        $scope.saleSessionManager = saleSessionManagerProvider.$get($scope, saleSessionHolder);

        $rootScope.lastScreenActivity = new Date();

        $document.find('body').on("keydown mousedown touchstart", function () {
            $rootScope.lastScreenActivity = new Date();
        });

        $scope.scrollTo = function (elementId) {
            var off = $scope.$on('$locationChangeStart', function (event) {
                off();
                event.preventDefault();
            });
            $location.hash(elementId);
            $anchorScroll();
        };

        $scope.selectTab = function (value) {
            trackingServices.trackAction($scope, value);
        };

        $scope.open = function (url) {
            $location.path(url);
        };

        $scope.isActivePageTag = function (tag) {
            if ($rootScope.activePageTag) {
                return $rootScope.activePageTag === tag;
            }
            return false;
        };

        var collapsedIndustries = [];

        $scope.toggleIndustryCollapsed = function (industryId) {
            //var index = collapsedIndustries.indexOf(industryId);
            var index = getIndustryIndex(collapsedIndustries, industryId);
            if (index == -1) {
                collapsedIndustries.push(industryId);
            } else {
                collapsedIndustries.splice(index, 1);
            }
        };

        function getIndustryIndex(industries, industryId) {
            for (var ind in industries) {
                if (industries.hasOwnProperty(ind) && industryId === industries[ind]) {
                    return ind;
                }
            }
            return -1;
        }

        $scope.isIndustryCollapsed = function (industryId) {
            var index = getIndustryIndex(collapsedIndustries, industryId);
            return (index != -1);
            //return collapsedIndustries.indexOf(industryId) != -1;
        };

        $scope.$on('showAddonRecommendations', function (event, offeringId, templateId) {
            $rootScope.loading = true;
            $scope.offeringId = offeringId;
            $scope.templateId = templateId;

            $modal.open({
                templateUrl: resourceUrl + '/partials/frontend/addons.html',
                controller: 'AddonRecommendationCtrl',
                windowClass: 'add-to-bundle-modal',
                backdrop: true,
                scope: $scope
            });
        });

        $scope.openHelp = function () {
            $location.path("/user/help");
            $scope.$emit('ToggleRight');
        };

        $scope.$on('ToggleRight', function () {
            $log.debug('ToggleRight');
            $scope.rightPanelShown = !$scope.rightPanelShown;
            trackingServices.trackAction($scope, 'ToggleRight');
            $('.mainBody').toggleClass('showRight');
            $('.feedback').toggle();
            if ($scope.rightPanelShown) {
                $scope.$broadcast('refreshRecentItems');
            }
        });

        var SaveNewSaleJourney = $scope.$on("SaveNewSaleJourney", function (event, journeyId) {
            sessionService.get({
                    action: 'start-journey',
                    journeyId: journeyId,
                    interaction: $scope.saleSessionManager.getInteractionKey()
                },
                function () {
                    layoutFacade.refreshJourneyMeter();
                });
        });

        $scope.$on('$destroy', SaveNewSaleJourney);

        var ShowJourneys = $scope.$on('ShowJourneys', function (event, data) {
            var journeysInstance = $modal.open({
                templateUrl: 'journeysList',
                controller: 'JourneysModalCtrl',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {journeys: data};
                    }
                }
            });

            journeysInstance.result.then(function () {
            }, function () {
            });
        });
        $scope.$on('$destroy', ShowJourneys);

        var StartJourney = $scope.$on('StartJourney', function () {
            sessionService.query({action: 'get-journeys', interaction: $scope.saleSessionManager.getInteractionKey()},
                function (result) {
                    if (result.length > 1) {
                        $scope.$emit("ShowJourneys", result);
                    } else if (result.length == 1) {
                        $scope.$emit("SaveNewSaleJourney", result[0].id);
                    } else {
                        //$scope.$broadcast('updateLabelStartSessionButton', result);
                    }
                }
            );
        });
        $scope.$on('$destroy', StartJourney);

        var ShowTopMessage = $scope.$on('ShowTopMessage', function (event, message) {
            $scope.topMessage = message;
            $timeout(function () {
                $scope.$emit('CloseTopMessage');
            }, 2000);
        });
        $scope.$on('$destroy', ShowTopMessage);

        $scope.$on('CloseTopMessage', function () {
            $scope.topMessage = null;
        });

        $scope.$on("RemoveComparable", function (event, typeName, subTypeId, ri) {
            commonService.remove({
                    module: 'session', action: 'compare-remove', templateId: subTypeId, id: ri
                },
                function () {
                    $scope.$broadcast("refreshCompare", typeName, subTypeId, ri);
                });
        });

        $scope.$on("ClearComparable", function (event, typeName, subTypeId) {
            commonService.remove({
                    module: 'session', action: 'compare-clear', type: typeName, subType: subTypeId
                },
                function () {
                    $scope.$broadcast("ComparableCleared", typeName, subTypeId);
                });
        });

        $scope.$on('basketPageReloading', function () {
            $scope.$broadcast("updateBasket");
        });

        $scope.$on('addToComparedActionExecuted', function (event, offeringTypeId) {
            $scope.$broadcast('refreshCompare', offeringTypeId);
        });

        $scope.$on('LSQUpdatedEvent', function () {
            $scope.$broadcast('CriteriaUpdatedEvent');
        });

        $scope.$on('LSQResetEvent', function (event, questionnaire) {
            commonService.post({
                    module: 'filters',
                    action: 'clear-lifestyle',
                    interaction: $scope.saleSessionManager.getInteractionKey(),
                    pageId: $scope.activePageId,
                    questionnaireId: questionnaire.id
                }, {},
                function (result) {
                    result.clearLifestyle = true;
                    $scope.$broadcast('CriteriaUpdatedEvent', result);
                    $scope.$broadcast('LSQAnswerUpdatedEvent', result);
                    $scope.$broadcast('LSQUpdatedGroupsEvent');
                });
        });

        $scope.$on('LSQSaveChanges', function (event, questionnaire, group, currentChanges) {
            commonService.update({
                    module: 'filters',
                    action: 'lsq-save-changes',
                    interaction: $scope.saleSessionManager.getInteractionKey(),
                    pageId: $scope.activePageId,
                    questionnaireId: questionnaire.id
                }, currentChanges,
                function (result) {
                    if (result) {
                        for (var i = 0; i < result.length; i++) {
                            $scope.$broadcast('LSQAnswerUpdatedEvent', result[i]);
                        }
                        $scope.$broadcast('CriteriaUpdatedEvent');
                        $scope.$broadcast('LSQUpdatedGroupsEvent', group.id);
                    }
                });
        });

        $scope.$on('printViews', function (event, options) {
            pdfService.getPDF($scope, options);
        });

        $scope.$on('trackClickEvent', function (event, label) {
            trackingServices.trackAction($scope, label);
        });

        $scope.$on('changeAccess', function (event, interaction, websiteTemplateId) {
            commonService.put({module: 'customer-care', action: 'change-control'}, {
                    ccAccessType: 'CUSTOMER',
                    sessionId: interaction,
                    websiteTemplateId: websiteTemplateId
                },
                function () {
                });
        });

        $scope.$on('releaseSaleSession', function () {
            $scope.saleSessionManager.dropSession();
        });

        if (ripplMultiLanguage) {
            $rootScope.predefinedLanguage = $location.search().language;
            if ($translate.use() != $rootScope.predefinedLanguage) {
                $translate.use($rootScope.predefinedLanguage);
            }

            $scope.$on('changeLanguage', function (event, lang) {
                $translate.use(lang);
            });
            $rootScope.$on('$translateChangeSuccess', function () {
                $scope.$broadcast('updateTranslations', $translate.use());
            });
        }
    }]);

frontendApp.controller('StartCtrl', ['$rootScope', '$route', '$location', 'tokenFacade', function ($rootScope, $route, $location, tokenFacade) {
    var autoLogin = $route.current.locals.autoLogin;

    if (autoLogin.message == "success" || !tokenFacade.isTokenEmpty()) {
        if ($rootScope.initialURL != '' && $rootScope.initialURL.indexOf('/login') < 0 && $rootScope.initialURL.indexOf('#/') != $rootScope.initialURL.length - 2) {
            $location.path($rootScope.initialURL);
        } else {
            $location.path('/user/home');
        }
    } else {
        if (autoLogin.message == "failed") {
            $location.path('/login');
        }
    }
}]);

frontendApp.controller('LoginCtrl', [
    '$scope',
    '$rootScope',
    '$location',
    '$http',
    'loginService',
    '$route',
    '$interval',
    'commonService',
    '$q',
    '$modal',
    'tokenFacade',
    'trackingServices',
    'lockOperationsFacade',
    function ($scope, $rootScope, $location, $http, loginService, $route, $interval, commonService, $q,
              $modal, tokenFacade, trackingServices, lockOperationsFacade) {
        lockOperationsFacade.unlock(true);
        $rootScope.loginLayoutClass = 'loginBody';
        $scope.walkmeTimer = null;
        $scope.promoUrlTimer = null;
        $scope.credential = {};
        $scope.promoUrl = {url: '', delay: 0};
        $scope.systemDictionary = $route.current.locals.systemDictionary;
        $scope.showLanguageSwitcher = ripplMultiLanguage;

        if ($rootScope.selectedUser) {
            $scope.credential.username = $rootScope.selectedUser;
        }

        $scope.stopTimer = function () {
            if (angular.isDefined($scope.walkmeTimer)) {
                $interval.cancel($scope.walkmeTimer);
                $scope.walkmeTimer = undefined;
            }
        };

        $scope.$on('$destroy', function () {
            // Make sure that the interval is destroyed too
            $scope.stopTimer();
            $scope.stopPromoUrlTimer();
        });

        $scope.walkmeTimer = $interval(function () {
            $('#walkme-player').hide();
        }, 100);

        commonService.get({module: 'lookup', action: 'get_login_promo'},
            function (result) {
                $scope.promoUrl = result;
            }
        );

        $scope.stopPromoUrlTimer = function () {
            if (angular.isDefined($scope.promoUrlTimer)) {
                $interval.cancel($scope.promoUrlTimer);
                $scope.promoUrlTimer = undefined;
            }
        };

        $scope.showLoginPromo = function () {
            if (angular.isDefined($rootScope.lastScreenActivity) && $scope.promoUrl.url != '' && $scope.promoUrl.delay > 0) {
                var delay = $scope.promoUrl.delay * 1000;
                var idle = (new Date().getTime()) - ($rootScope.lastScreenActivity.getTime() + delay);

                if (idle > 0) {
                    $('#lpin').trigger('blur');
                    $('.loginPromoBox').html('<div class="loginPromoBoxIn1"><div class="loginPromoBoxIn2"><img src="' + $scope.promoUrl.url + '" style="max-width:100%;"></div></div>');
                    $('.loginPromoBox').show();
                    $scope.stopPromoUrlTimer();
                }
            }
        };

        $scope.hideLoginPromo = function () {
            $('.loginPromoBox').hide();
            $scope.startPromoUrlTimer();
            $('#lpin').trigger('focus');
        };

        $scope.startPromoUrlTimer = function () {
            $scope.promoUrlTimer = $interval(function () {
                $scope.showLoginPromo();
            }, 1000);
        };

        $scope.startPromoUrlTimer();

        var autoLogin = $route.current.locals.autoLogin;
        $scope.page = {};
        $scope.page.dictionary = $route.current.locals.dictionary;

        if (autoLogin && "success" == autoLogin.message) {
            $location.path("/");
        }

        $scope.isSubmitDisabled = function () {
            var nameDefine = ($scope.credential.username && $scope.credential.username.length > 0);
            var passwordDefine = ($scope.credential.password && $scope.credential.password.length > 0);
            var pinDefine = ($scope.credential.pin && $scope.credential.pin.length > 0);
            return !nameDefine || !(passwordDefine || pinDefine);
        };

        $scope.login = function () {
            delete $scope.threadsLimit;
            delete $scope.invalidCredential;
            delete $rootScope.locationInfo;
            if ($scope.credential.password) {
                delete $rootScope.oldStore;
            }
            function checkSession() {
                $modal.open({
                    template: "" +
                    "<div class='modal-header restore-session-modal custom-font-2'>Restore last session?<div class='close ng-binding' ng-click='cancel()'>X</div></div>" +
                    "<div class='modal-body restore-session-modal custom-font-2'>" +
                    "    <div class='line1'>You left before the last session was complete.</div>" +
                    "    <div class='line2'>Would you like to restore your previous session?</div>" +
                    "    <div class='controls'>" +
                    "       <div class='btn btn-lg ng-binding' ng-click='restore()'>Yes please</div>" +
                    "       <div class='btn btn-lg btn-type2 ng-binding' ng-click='proceed()'>No thanks, start a new session</div>" +
                    "    </div>" +
                    "</div>",
                    controller: ['$scope', '$modalInstance', 'loginService', 'params',
                        function ($scope, $modalInstance, loginService, params) {
                            $scope.restore = function () {
                                $scope.owner = params.owner;
                                loginService.restoreSession($.param({
                                        username: $scope.owner.credential.username,
                                        password: $scope.owner.credential.password,
                                        pin: $scope.owner.credential.pin
                                    }),
                                    function (result) {
                                        $scope.saleSessionManager.dropSession();
                                        $scope.saleSessionManager.setInteractionKey(result.interaction);
                                        postProcess(result);
                                    }
                                );
                                $modalInstance.close();

                            };

                            $scope.proceed = function () {
                                $scope.owner = params.owner;
                                loginService.getNewSession($.param({
                                        username: $scope.owner.credential.username,
                                        password: $scope.owner.credential.password,
                                        pin: $scope.owner.credential.pin
                                    }),
                                    function (result) {
                                        $scope.saleSessionManager.dropSession();
                                        postProcess(result);
                                    }
                                );
                                $modalInstance.close();
                            };
                            $scope.cancel = function () {
                                $modalInstance.close();
                            };
                        }
                    ],
                    backdrop: true,
                    scope: $scope.$new(false),
                    resolve: {
                        params: function () {
                            return {
                                owner: $scope
                            };
                        }
                    }
                });
            }

            loginService.authenticate($.param({
                    username: $scope.credential.username,
                    password: $scope.credential.password,
                    pin: $scope.credential.pin
                }),
                function (user) {
                    if (user.token) {
                        $scope.saleSessionManager.dropSession();
                        if (user.interaction) {
                            $scope.saleSessionManager.setInteractionKey(user.interaction);
                        }
                        postProcess(user);
                    } else {
                        checkSession();
                    }
                },
                function (response) {
                    $rootScope.authFailed = true;
                    if (401 === response.status) {
                        $scope.invalidCredential = true;
                    }
                    if (509 === response.status) {
                        $scope.threadsLimit = true;
                    }

                });
        };

        function postProcess(user) {
            delete $rootScope.authFailed;
            if (0 === user.locations.length) {
                processDirection(user);
            } else {
                processLocations(user)
                    .then(function () {
                        return typePin(user, $scope.credential.username);
                    })
                    .then(function () {
                        return processDirection(user);
                    });
            }
        }

        function processLocations(user) {
            var defer = $q.defer();
            if ($rootScope.oldStore) {
                saveStore(user, $rootScope.oldStore);
                defer.resolve();
            } else if (1 === user.locations.length) {
                saveStore(user, user.locations[0]);
                defer.resolve();
            } else {
                selectLocations(user.locations).then(function (result) {
                    saveStore(user, result);
                    defer.resolve();
                });
            }
            return defer.promise;
        }

        function saveStore(user, storeName) {
            $http.defaults.headers.common['Auth-Token'] = user.token;
            $rootScope.oldStore = storeName;
            if (storeName) {
                commonService.post({module: 'locations', action: 'store'}, storeName,
                    function (result) {
                        $rootScope.locationInfo = angular.copy(result);
                    },
                    function () {
                        delete $http.defaults.headers.common['Auth-Token'];
                    });
            }
        }

        function processDirection(user) {
            $http.defaults.headers.common['Auth-Token'] = user.token;
            tokenFacade.setToken(user.token);

            delete $rootScope.selectedUser;
            trackingServices.trackAction($scope, 'restoreSession');
            if ($rootScope.oldLocation != undefined && $rootScope.oldLocation.indexOf('/login') == -1) {
                $location.path($rootScope.oldLocation);
                delete $rootScope.oldLocation;
            } else {
                $location.path("/user/home");
            }
        }

        function typePin(user, username) {
            var defer = $q.defer();
            if (!user.pin) {
                requestPin($scope.username).then(function (pin) {
                    commonService.post({module: 'user', action: 'savePin', username: username, pin: pin}, {},
                        function () {
                            defer.resolve();
                        }, function () {
                            defer.reject();
                        });
                }, function () {
                    defer.resolve();
                });
            } else {
                defer.resolve();
            }
            return defer.promise;
        }

        function selectLocations(locations) {
            var defer = $q.defer();

            var selectLocationDialogOption = {
                templateUrl: resourceUrl + '/template/modal/locationSelect.html',
                controller: 'SelectLocationCtrl',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    locations: function () {
                        return locations;
                    }
                }
            };

            var modalInstance = $modal.open(selectLocationDialogOption);
            modalInstance.result.then(function (selectedLocation) {
                return defer.resolve(selectedLocation);
            });

            return defer.promise;
        }

        function requestPin(username) {
            var defer = $q.defer();

            var selectPinDialogOption = {
                templateUrl: resourceUrl + '/template/modal/pinType.html',
                controller: 'PinTypeCtrl',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    username: function () {
                        return username;
                    }
                }
            };

            var modalInstance = $modal.open(selectPinDialogOption);
            modalInstance.result.then(function (pin) {
                return defer.resolve(pin);
            }, function () {
                defer.reject();
            });

            return defer.promise;
        }

    }]);

frontendApp.controller('BasketCtrl', [
    '$scope',
    '$rootScope',
    '$location',
    '$route',
    '$modal',
    'sessionService',
    'commonService',
    '$q',
    'trackingServices',
    function ($scope, $rootScope, $location, $route, $modal, sessionService, commonService, $q, trackingServices) {
        $scope.loaded = false;
        $scope.basket = [];
        $scope.modalData = [];
        $scope.dictionaries = $route.current.locals.dictionaries;
        $scope.isEdit = false;

        if (!$scope.customerInfo) {
            $scope.customerInfo = {};
        }
        $scope.customerInfoTitle = $scope.customerInfo.title;
        $scope.customerInfoFirstName = $scope.customerInfo.firstName;
        $scope.customerInfoLastName = $scope.customerInfo.lastName;
        $scope.customerInfoPhoneNumber = $scope.customerInfo.phoneNumber;
        $scope.customerInfoAddress = $scope.customerInfo.address;
        $scope.customerInfoEmail = $scope.customerInfo.customerEmail;
        $scope.customerAccountNumber = $scope.customerInfo.accountNumber;
        $scope.customerSortCode = $scope.customerInfo.sortCode;


        $scope.loadBasket = function () {
            sessionService.get({action: 'basket-view-list', interaction: $scope.saleSessionManager.getInteractionKey()},
                function (result) {
                    $scope.basket = result.basketItems;
                    $scope.loaded = true;
                });
        };

        $scope.loadBasket();

        $scope.edit = function () {
            trackingServices.trackAction($scope, 'editYourDetails');
            $scope.isEdit = true;
        };

        $scope.isBasketEmpty = function () {
            return ($scope.basket == null || $scope.basket.length == 0) && $scope.loaded;
        };

        $scope.$on('updateBasket', function () {
            $scope.loadBasket();
        });

        $scope.continueShopping = function () {
            trackingServices.trackAction($scope, 'CONTINUE_SHOPPING');
            $location.path('/');
        };

        $scope.printDeal = function () {
            window.print();
            trackingServices.trackAction($scope, 'PRINT_DEAL');
        };

        $scope.saveCustomerDetails = function (form) {
            if (form.$invalid) {
                return;
            }

            if ($scope.isEnteredOnlyAccountInfo() || $scope.isEnteredOnlySourceCodeInfo()) {
                return;
            }

            function defaultPromise() {
                var deferred = $q.defer();
                deferred.resolve({valid: true});
                return deferred.promise;
            }

            var emailPromise = defaultPromise();
            var bankAccountPromise = defaultPromise();

            if (!$scope.emailValidationProcessed && $scope.customerInfoEmail != null && $scope.customerInfoEmail.length > 0) {
                emailPromise = commonService.get({
                    module: 'integration',
                    action: "validate-email",
                    email: $scope.customerInfoEmail
                }).$promise;
            }

            if ($scope.isBankAccountChanged && !$scope.isEmptyBankAccountInfo()) {
                bankAccountPromise = commonService.get({
                    module: 'integration',
                    action: 'isDirectDebitAvailable',
                    accountNumber: $scope.customerAccountNumber,
                    sortCode: $scope.customerSortCode
                }).$promise;
            }

            $q.all([emailPromise, bankAccountPromise])
                .then(function (results) {
                    var emailResult = results[0];
                    $scope.isEmailValid = emailResult.valid;
                    $scope.emailErrorMessage = emailResult.message;
                    $scope.emailValidationProcessed = true;

                    var bankAccountResult = results[1];
                    $scope.isBankAccountValid = bankAccountResult.valid;

                    if ($scope.isEmailValid && $scope.isBankAccountValid) {
                        $scope.save();
                    }
                });
        };

        $scope.save = function () {
            $scope.customerInfo.title = $scope.customerInfoTitle;
            $scope.customerInfo.firstName = $scope.customerInfoFirstName;
            $scope.customerInfo.lastName = $scope.customerInfoLastName;
            $scope.customerInfo.phoneNumber = $scope.customerInfoPhoneNumber;
            $scope.customerInfo.address = $scope.customerInfoAddress;
            $scope.customerInfo.customerEmail = $scope.customerInfoEmail;
            $scope.customerInfo.accountNumber = $scope.customerAccountNumber;
            $scope.customerInfo.sortCode = $scope.customerSortCode;

            $scope.isEdit = false;
        };

        $scope.emailValidationProcessed = true;
        $scope.isEmailValid = true;
        $scope.isBankAccountChanged = false;
        $scope.isBankAccountValid = true;

        $scope.isEmptyBankAccountInfo = function () {
            var ret = true;
            if ($scope.customerAccountNumber != null && $scope.customerAccountNumber.length > 0
                && $scope.customerSortCode != null && $scope.customerSortCode.length > 0) {

                ret = false;
            }

            return ret;
        };

        $scope.isEnteredOnlyAccountInfo = function () {
            var ret = false;
            if (($scope.customerAccountNumber != null && $scope.customerAccountNumber.length > 0)
                && ($scope.customerSortCode == null || $scope.customerSortCode.length == 0)) {
                ret = true;
            }

            return ret;
        };

        $scope.isEnteredOnlySourceCodeInfo = function () {
            var ret = false;
            if (($scope.customerSortCode != null && $scope.customerSortCode.length > 0)
                && ($scope.customerAccountNumber == null || $scope.customerAccountNumber.length == 0)) {
                ret = true;
            }

            return ret;
        };
    }]);

frontendApp.controller('RecentCtrl', [
    '$scope',
    '$rootScope',
    '$location',
    '$route',
    'contentService',
    'trackingServices',
    'commonService',
    function ($scope, $rootScope, $location, $route, contentService, trackingServices, commonService) {

        $scope.view = $route.current.locals.recent;
        processActions();

        $scope.removeItem = function (type, subType, id) {
            $rootScope.loading = true;
            trackingServices.trackAction($scope, 'removeFromRecent');
            commonService.remove({
                    module: 'session',
                    action: 'recent-remove', templateId: subType, id: id,
                    interaction: $scope.saleSessionManager.getInteractionKey()
                },
                function () {
                    $rootScope.loading = false;
                    refresh();
                },
                function () {
                    $rootScope.loading = false;
                }
            );
        };

        function refresh() {
            $rootScope.loading = true;
            contentService.get(
                {action: 'recent-detail', type: $route.current.params.type, subType: $route.current.params.subType},
                function (result) {
                    $scope.view = result;
                    processActions();
                    $rootScope.loading = false;
                },
                function () {
                    $rootScope.loading = false;
                }
            );
        }

        function processActions() {
            $scope.view.details.regularAction = [];
            for (var ind in $scope.view.actionsInfo) {
                if ($scope.view.actionsInfo.hasOwnProperty(ind)) {
                    var action = $scope.view.actionsInfo[ind];
                    if (action.mainAction) {
                        $scope.view.details.defaultAction = angular.copy(action);
                        if (action.showAsButton) {
                            $scope.view.details.regularAction.push(action);
                        }
                    } else {
                        $scope.view.details.regularAction.push(action);
                    }
                }
            }
        }

    }]);

frontendApp.controller('CustomCtrl', [
    '$rootScope',
    '$scope',
    '$routeParams',
    '$route',
    'contentService',
    '$modal',
    'CUSTOM_EVENTS',
    'layoutFacade',
    function ($rootScope, $scope, $routeParams, $route, contentService, $modal, CUSTOM_EVENTS, layoutFacade) {
        $('#walkme-player').show();
        $rootScope.loginLayoutClass = '';
        $scope.dictionary = {};
        $scope.dictionary.positions = ['HORIZONTAL', 'VERTICAL'];
        $scope.dictionary.typeDate = 'DATE';
        $scope.systemDictionary = $route.current.locals.systemDictionary;
        $scope.page = {};
        $scope.page.dragActive = false;
        $scope.page.hasCompare = false;

        if (ripplMultiLanguage) {
            $rootScope.supportedLanguages = $scope.systemDictionary.items['SUPPORTED_LANGUAGES'].value;
        }

        layoutFacade.updateFloatingTop('');
        layoutFacade.updateFloatingBottom('');
        $rootScope.$broadcast('UpdateFormData');

        $scope.page.pageParameters = {};

        $scope.selectedPage = $routeParams.id;


        for (var key in $routeParams) {
            if ($routeParams.hasOwnProperty(key)) {
                $scope.page.pageParameters[key] = $routeParams[key];
            }
        }

        $scope.$on('ViewsShow', function () {
            $scope.$broadcast('ViewShow');
        });

        $scope.$on('ViewsHide', function () {
            $scope.$broadcast('ViewHide');
        });

        $scope.$on(CUSTOM_EVENTS.RPM_SWIPE_LEFT, function () {
            $scope.$broadcast(CUSTOM_EVENTS.RPM_PREV);
        });

        $scope.$on(CUSTOM_EVENTS.RPM_SWIPE_RIGHT, function () {
            $scope.$broadcast(CUSTOM_EVENTS.RPM_NEXT);
        });

        $scope.sessionRecallSelected = function (sessionId) {
            $scope.$broadcast("sessionReloaded", sessionId);
        };

        $scope.$on('OpenRecallSessionDialog', function (event, prevSessions, phone, email) {
            var recallSessionDialogOption = {
                templateUrl: resourceUrl + '/partials/frontend/common/recallSession.html',
                controller: 'RecallSessionCtrl',
                backdrop: true,
                resolve: {
                    params: function () {
                        return {
                            prevSessions: prevSessions,
                            phone: phone,
                            email: email,
                            owner: $scope
                        };
                    }
                }
            };

            $modal.open(recallSessionDialogOption);
        });

    }]);

frontendApp.controller('ViewBasketTableCtrl', ['$scope', '$sce',
    function ($scope, $sce) {
        $scope.to_trusted = function (html_code) {
            return $sce.trustAsHtml(html_code);
        };
    }]);

frontendApp.controller('LifestyleQuestionnaireCtrl', [
    '$scope',
    '$rootScope',
    '$routeParams',
    '$filter',
    '$location',
    'questionnaireService',
    'dataTool',
    'SLIDER_REPLACEMENT',
    '$modal',
    'lsqRenderingService',
    'trackingServices',
    function ($scope, $rootScope, $routeParams, $filter, $location, questionnaireService, dataTool, SLIDER_REPLACEMENT,
              $modal, lsqRenderingService, trackingServices) {
        $rootScope.activePageTag = 'lifestyle-questionnaire';
        $scope.questionnaire = {};
        $scope.rows = [];
        $scope.deal = {};
        $scope.isFirstQuestion = true;
        $scope.isLastQuestion = false;
        $scope.dealsCount = 0;

        $scope.$on('SessionStarted', function () {
            $scope.loadQuestionnaire(true);
        });

        $scope.loadQuestionnaire = function (updated) {
            questionnaireService.query({
                action: 'lifestyle', questionnaireId: $routeParams.templateId,
                interaction: $scope.saleSessionManager.getInteractionKey()
            }, function (result) {
                $scope.questionnaire = result;
                //$scope.questionnaire.groups = $filter('orderBy')($scope.questionnaire.groups, ['y', 'x']);
                for (var i = 0; i < $scope.questionnaire.groups.length; i++) {
                    var question = $scope.questionnaire.groups[i];
                    var index = -1;
                    for (var ind in $scope.rows) {
                        if ($scope.rows.hasOwnProperty(ind)
                            && $scope.rows[ind] === question.y) {
                            index = ind;
                        }
                    }
                    if (index == -1) {
                        $scope.rows.push(question.y);
                    }
                }
                $scope.updateDealsCount(result.dealsCount);
                $scope.updateSelectedCriteria();
                if (updated) {
                    $scope.$emit('LSQUpdatedEvent', result);
                }
            });
        };

        $scope.createModal = function () {
            $modal.open({
                templateUrl: "clearPopup",
                backdrop: true,
                controller: 'ClearPopupCtrl',
                scope: $scope
            });
        };

        function animateOpen(group) {
            var target = $("#group-" + group.id);
            var offset = target.offset();
            var secondScreen = $(".secondScreen");
            secondScreen.css('width', target.width());
            secondScreen.css('height', target.height());
            secondScreen.css('left', offset.left - $(".leftNav").width() + 15);
            secondScreen.css('top', offset.top - 66);
            secondScreen.animate({
                left: 0,
                top: 69,
                height: $('.lsQuests').height() - 76,
                //height: $('.mainBody').height() - 232,    for fullscreen view
                width: '100%'
            }, {
                duration: 300
            });
        }

        function changeGroup(group, indexFunc) {
            var index = -1;
            for (var ind in $scope.questionnaire.groups) {
                if ($scope.questionnaire.groups.hasOwnProperty(ind)
                    && group.id === $scope.questionnaire.groups[ind].id) {
                    index = ind;
                }
            }
            index = indexFunc(index);
            if (index >= 0 && index < $scope.questionnaire.groups.length) {
                var newGroup = $scope.questionnaire.groups[index];
                $scope.openGroup($scope.questionnaire.id, newGroup.id, 0 == index, ($scope.questionnaire.groups.length - 1) == index);
            }
        }

        $scope.updateSelectedCriteria = function () {
            $scope.selectedCriteria = {
                mandatoryQuestions: [],
                completedQuestions: []
            };
            var groupInd = "g";
            var questionInd = "q";
            for (var ind in $scope.questionnaire.groups) {
                if ($scope.questionnaire.groups.hasOwnProperty(ind)) {
                    var group = $scope.questionnaire.groups[ind];
                    for (var index in group.questions) {
                        if (group.questions.hasOwnProperty(index)) {
                            var q = group.questions[index];
                            var selected = null;
                            if ((q.type === 'BUTTONS' || q.type === 'LIST_BOX')
                                && q.selectedOptionIds && q.selectedOptionIds != null && q.selectedOptionIds.length > 0) {
                                selected = q;
                            } else if (q.type == 'DROP_DOWN' && q.selectedOptionId != null) {
                                selected = q;
                            } else if ((q.type == 'SLIDER') && (q.value != null || q.selectedOptionId != null)) {
                                selected = q;
                            }

                            if (selected != null) {
                                if ($scope.selectedCriteria.completedQuestions.indexOf(selected.id) == -1) {
                                    $scope.selectedCriteria.completedQuestions.push(selected.id);
                                }
                                if (group.mandatory
                                    && $scope.selectedCriteria.mandatoryQuestions.indexOf(groupInd + group.id) == -1) {
                                    $scope.selectedCriteria.mandatoryQuestions.push(groupInd + group.id);
                                } else if (selected.mandatory
                                    && $scope.selectedCriteria.mandatoryQuestions.indexOf(questionInd + selected.id) == -1) {
                                    $scope.selectedCriteria.mandatoryQuestions.push(questionInd + selected.id);
                                }
                            }
                        }
                    }
                }
            }
        };

        $scope.disableViewResult = function () {
            return $scope.questionnaire && $scope.questionnaire.criteria && $scope.selectedCriteria
                && (($scope.selectedCriteria.mandatoryQuestions.length < $scope.questionnaire.criteria.mandatoryCount)
                || ($scope.selectedCriteria.completedQuestions.length < $scope.questionnaire.criteria.completedCount));
        };

        $scope.updateDealsCount = function (value) {
            if ($scope.dealsCount == value) {
                $scope.dealsCount = 0;
            }
            $scope.dealsCount = value;
        };

        $scope.$on('OpenLSQModal', function () {
            $modal.open({
                templateUrl: 'lsq-group-modal',
                controller: 'LsqGroupModalCtrl',
                scope: $scope,
                backdrop: true,
                windowClass: 'lsq-group-modal'
            });
        });

        $scope.isFutureProofSelectedInGroup = function (group) {
            for (var i = 0; i < group.questions.length; i++) {
                var question = group.questions[i];
                if (angular.isDefined(question.futureProofValue) && question.futureProofValue != null && question.futureProofValue > 0) {
                    return true;
                }
            }

            return false;
        };

        $scope.openGroup = function (questionnaireId, groupId, isFirst, isLast, animate) {
            var group;
            for (var ind in $scope.questionnaire.groups) {
                if ($scope.questionnaire.groups.hasOwnProperty(ind)
                    && groupId == $scope.questionnaire.groups[ind].id) {
                    group = angular.copy($scope.questionnaire.groups[ind]);
                }
            }
            if (animate) {
                animateOpen(group);
            }

            $scope.$broadcast('OpenLSQModal');

            $scope.activeField = null;
            $scope.activeQuestion = {};
            $scope.activeGroup = group;
            $scope.futureProofSelected = $scope.isFutureProofSelectedInGroup(group);

            $scope.initialGroupState = angular.copy(group);
            $scope.isFirstQuestion = isFirst;
            $scope.isLastQuestion = isLast;
            $scope.currentChanges = [];
        };

        $scope.formatData = function (data, toFixed) {
            return dataTool.formatData(data, toFixed);
        };

        $scope.getGroupClassHome = function (group) {
            var ngClass = {};
            ngClass['active'] = lsqRenderingService.isSliderQuestionResolvedPartially(group);
            return ngClass;
        };

        $scope.getQuestionClass = function (question) {
            var ngClass = {};
            ngClass['col-xs-' + question.width] = true;
            ngClass['active'] = lsqRenderingService.isSliderQuestionResolvedPartially(question);
            return ngClass;
        };

        function isSliderQuestionResolvedFully(group) {
            for (var i = 0; i < group.questions.length; i++) {
                var q = group.questions[i];
                if (q.type === 'BUTTONS' || q.type === 'LIST_BOX') {
                    return false;
                } else if (q.type == 'DROP_DOWN' && q.selectedOptionId == null) {
                    return false;
                } else if (q.type == 'SLIDER'
                    && (q.value == null
                    && (q.selectedOptionId == null && q.options && q.options.length > 0))) {
                    return false;
                }
            }
            return true;
        }

        $scope.isAllQuestionsResolved = function () {
            if (!($scope.questionnaire && $scope.questionnaire.groups)) {
                return false;
            }
            for (var i = 0; i < $scope.questionnaire.groups.length; i++) {
                var group = $scope.questionnaire.groups[i];
                if (!lsqRenderingService.isSliderQuestionResolvedPartially(group)) {
                    return false;
                }
            }
            return true;
        };

        $scope.isAllMandatoryQuestionsResolvedInGroup = function (group) {
            return lsqRenderingService.isAllMandatoryQuestionsResolved(group);
        };

        $scope.isComposite = function (group) {
            return group.type === 'COMPOSITE';
        };

        $scope.isTextTypeMulti = function (group) {
            var result = false;
            for (var ind in group.questions) {
                if (group.questions.hasOwnProperty(ind)
                    && ('SLIDER' != group.questions[ind].type || 'RULES' === group.questions[ind].filterType)) {
                    result = true;
                }
            }
            return result;
        };

        $scope.prevGroup = function (group) {
            $scope.saveChanges(group);
            changeGroup(group, function (index) {
                return --index;
            });
        };

        $scope.nextGroup = function (group) {
            $scope.saveChanges(group);
            changeGroup(group, function (index) {
                return ++index;
            });
        };

        $scope.changeFutureProof = function () {
            $scope.futureProofSelected = $scope.futureProofSelected != true;
        };

        $scope.saveChanges = function (group) {
            var hasChanges = false;
            if ($scope.currentChanges.length > 0) {
                if ($scope.futureProofSelected) {
                    for (var i = 0; i < $scope.currentChanges.length; i++) {
                        $scope.currentChanges[i].futureProofValue = group.futureProofValue;
                    }
                }
                hasChanges = true;
            }

            // save changed FutureProof for non-changed answers
            if ($scope.futureProofSelected != $scope.isFutureProofSelectedInGroup($scope.initialGroupState)) {

                for (var k = 0; k < $scope.initialGroupState.questions.length; k++) {

                    if (lsqRenderingService.isQuestionsInCompositeGroupResolved($scope.initialGroupState.questions[k])) {
                        var alreadyInCurrentChanges = false;
                        for (var j = 0; j < $scope.currentChanges.length; j++) {
                            if ($scope.currentChanges[j].questionId == $scope.initialGroupState.questions[k].id) {
                                alreadyInCurrentChanges = true;
                            }
                        }

                        if (!alreadyInCurrentChanges) {
                            var change = {};
                            var q = $scope.initialGroupState.questions[k];
                            change.key = q.id;
                            change.optionId = null;
                            change.sliderValue = null;
                            if ($scope.futureProofSelected) {
                                change.futureProofValue = group.futureProofValue;
                            }
                            change.selected = false;
                            change.futureProofUpdatedOnly = true;
                            $scope.currentChanges.unshift(change);
                            hasChanges = true;
                        }
                    }
                }
            }

            if (hasChanges) {
                $scope.$emit('LSQSaveChanges', $scope.questionnaire, group, $scope.currentChanges);
            }
            $scope.$broadcast('CloseLSQModal');
        };

        $scope.onBackButtonClick = function (group) {
            $scope.saveChanges(group);
        };

        $scope.onCloseButtonClick = function () {
            for (var i = 0; i < $scope.questionnaire.groups.length; i++) {
                var group = $scope.questionnaire.groups[i];
                if (group.id == $scope.initialGroupState.id) {
                    $scope.questionnaire.groups[i] = angular.copy($scope.initialGroupState);
                }
            }
            $scope.$broadcast('CloseLSQModal');
        };

        // Simple questions.
        $scope.updateSimpleSelection = function (group, question, option, multi) {
            question.selectedOptionId = option.id;
            $scope.addToCurrentChanges(question.id, option.id, null, true, multi);
            if (isSliderQuestionResolvedFully(group)) {
                $scope.saveChanges(group);
            }
        };

        $scope.deselectSelection = function (group, question, option, multi) {
            $scope.addToCurrentChanges(question.id, option.id, null, false, multi);
            if (isSliderQuestionResolvedFully(group)) {
                $scope.saveChanges(group);
            }
        };

        $scope.isSelected = function (optionId, question) {
            return lsqRenderingService.isSelected(optionId, question);
        };

        $scope.updateSelection = function (group, question, option) {
            if (option.enabled) {
                if (lsqRenderingService.isSelected(option.id, question)) {
                    var isMultiSelect = question.type === 'BUTTONS' || question.type === 'LIST_BOX';
                    if (isMultiSelect) {
                        var selectedIdx = $.inArray(option.id, question.selectedOptionIds);
                        if (selectedIdx > -1) {
                            question.selectedOptionIds.splice(selectedIdx, 1);
                        }
                        question.selectedOptionId = undefined;
                    } else {
                        question.selectedOptionId = undefined;
                    }
                    $scope.deselectSelection(group, question, option, isMultiSelect);
                    lsqRenderingService.toggleLinkedOptions(option.links, $scope.questionnaire.groups, group, question, true);
                } else {
                    if (lsqRenderingService.isLinkedSelected(option.links, $scope.questionnaire.groups)) {
                        $scope.warningGroup = group;
                        $scope.warningQuestion = question;
                        $scope.warningOption = option;
                        $modal.open({
                            templateUrl: 'lsq-warning-modal',
                            controller: 'LsqWarningModalCtrl',
                            scope: $scope,
                            backdrop: true,
                            windowClass: 'lsq-warning-modal'
                        });
                    } else {
                        $scope.processSelection(group, question, option);
                    }
                    lsqRenderingService.toggleLinkedOptions(option.links, $scope.questionnaire.groups, group, question, false);
                }
                $scope.$broadcast('SelectionUpdatedEvent');
            }
        };

        $scope.processSelection = function (group, question, option) {
            if (question.type === 'BUTTONS' || question.type === 'LIST_BOX') {
                if (!question.selectedOptionIds) {
                    question.selectedOptionIds = [];
                }
                question.selectedOptionIds.push(option.id);
                $scope.updateSimpleSelection(group, question, option, true);
            } else {
                $scope.updateSimpleSelection(group, question, option, false);
            }

        };

        // Slider questions.
        $scope.onSelectQuestion = function (question) {
            $scope.activeQuestion = question;
        };


        // Common Slider questions.
        $scope.updateSliderValue = function (group, question, commonSliderValue) {
            $scope.addToCurrentChanges(question.id, null, commonSliderValue, null);
            if (isSliderQuestionResolvedFully(group)) {
                $scope.saveChanges(group);
            }
        };

        $scope.updateRuleSliderValue = function (group, question, option) {
            question.selectedOptionId = option;
            $scope.addToCurrentChanges(question.id, question.selectedOptionId, null, true);
            if (isSliderQuestionResolvedFully(group)) {
                $scope.saveChanges(group);
            }
        };

        $scope.addToCurrentChanges = function (questionKey, optionId, sliderValue, selected, multi) {
            var change = {};
            change.key = questionKey;
            change.optionId = optionId;
            change.sliderValue = sliderValue;
            change.futureProofValue = null;
            change.futureProofUpdatedOnly = false;
            if (selected == null) {
                change.selected = false;
            } else {
                change.selected = selected;
            }

            if (!multi) {
                for (var i = 0; i < $scope.currentChanges.length; i++) {
                    if ($scope.currentChanges[i].key == change.key) {
                        $scope.currentChanges[i] = change;
                        return;
                    }
                }
            }

            $scope.currentChanges.unshift(change);
        };

        $scope.clearAnswersReq = function () {
            $scope.$emit('LSQResetEvent', $scope.questionnaire);
            $scope.changeResultsMode(true);
            trackingServices.trackAction($scope, 'LSQ_CLEAR_ANSWERS');
        };

        $scope.$on('ClearAnswersReq', function () {
            $scope.clearAnswersReq();
        });


        $scope.changeResultsMode = function (onlyShowQuestionsMode) {
            if (!$('.lsQuests').hasClass('selected')) {
                $('.lsQuests').addClass('selected');
                $('.lsQuests').slideDown(500);
                $('#resultsViewButtonLSQ').show();
                $('#resultsBackButtonLSQ').hide();
                trackingServices.trackAction($scope, 'LSQ_BACK_TO_QUESTIONS');
            } else if (!onlyShowQuestionsMode) {
                $('#scrollScreen').animate({scrollTop: 0}, 500);
                $('.lsQuests').removeClass('selected');
                $('.lsQuests').slideUp(500);
                $('#resultsViewButtonLSQ').hide();
                $('#resultsBackButtonLSQ').show();
                trackingServices.trackAction($scope, 'LSQ_VIEW_RESULTS');
            }
        };

        $scope.processCriteriaUpdate = function (result) {
            if (result && result.lsqUpdateInfo && result.lsqUpdateInfo.id == $scope.questionnaire.id) {
                $scope.updateDealsCount(result.lsqUpdateInfo.dealsCount);
                for (var j = 0; j < result.lsqUpdateInfo.questions.length; j++) {
                    var cq = result.lsqUpdateInfo.questions[j];
                    for (var g = 0; g < $scope.questionnaire.groups.length; g++) {
                        var gp = $scope.questionnaire.groups[g];
                        for (var i = 0; i < gp.questions.length; i++) {
                            var question = gp.questions[i];
                            if (question.id == cq.id) {
                                if (question.selected
                                    && !result.clearLifestyle) {
                                    cq.selected = question.selected;
                                }
                                gp.questions[i] = cq;
                            }
                        }
                    }
                }

                $scope.updateSelectedCriteria();
            }
        };

        $scope.getCompositeMaxValue = function (group) {
            var max = 0;
            for (var i = 0; i < group.questions.length; i++) {
                var question = group.questions[i];
                var coeff = 1;
                if (question.futureProofValue != null && question.futureProofValue > 0) {
                    coeff = question.futureProofValue;
                }
                if (lsqRenderingService.isNumber(question.sliderWidth) && question.sliderWidth != null) {
                    max = max + (question.sliderWidth * coeff);
                } else if (lsqRenderingService.isNumber(question.value) && question.value != null) {
                    max = max + (question.higher * coeff);
                } else if (question.options != null) {
                    var m = 0;
                    for (var j = 0; j < question.options.length; j++) {
                        var option = question.options[j];
                        if (option.value != null) {
                            if ((option.value * coeff) > m) {
                                m = option.value * coeff;
                            }
                        }
                    }
                    max += m;
                }
            }
            return max;
        };

        $scope.getCompositeValue = function (group) {
            var value = 0;
            if (lsqRenderingService.isSliderQuestionResolvedPartially(group)) {
                for (var i = 0; i < group.questions.length; i++) {
                    var question = group.questions[i];
                    var coeff = 1;
                    if (question.futureProofValue != null && question.futureProofValue > 0) {
                        coeff = question.futureProofValue;
                    }
                    if (lsqRenderingService.isNumber(question.value) && question.value != null) {
                        value = value + (question.value * coeff);
                    } else if (question.options != null) {
                        for (var j = 0; j < question.options.length; j++) {
                            var option = question.options[j];
                            if (option.value != null) {
                                if (option.id == question.selectedOptionId) {
                                    if (lsqRenderingService.isNumber(option.sliderWidth) && option.sliderWidth != null) {
                                        value = value + (option.sliderWidth * coeff);
                                    } else {
                                        value = value + (option.value * coeff);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return value;
        };

        $scope.getCompositeValueLabel = function (group) {
            var value = 0;
            var valueStr = '';
            var max = 0;
            var min = 0;
            if (lsqRenderingService.isSliderQuestionResolvedPartially(group)) {
                for (var i = 0; i < group.questions.length; i++) {
                    var question = group.questions[i];
                    var coeff = 1;
                    if (question.futureProofValue != null && question.futureProofValue > 0) {
                        coeff = question.futureProofValue;
                    }
                    if (lsqRenderingService.isNumber(question.value) && question.value != null) {
                        max += question.higher;
                        min += question.lower;
                        value = value + (question.value * coeff);
                    } else if (question.options != null) {
                        var m = 0;
                        var n = 0;
                        for (var j = 0; j < question.options.length; j++) {
                            var option = question.options[j];
                            if (option.value != null) {
                                if ((option.value * coeff) > m) {
                                    m = option.value * coeff;
                                }
                                if ((j == 0) || (option.value * coeff) < n) {
                                    n = option.value * coeff;
                                }
                                if (option.id == question.selectedOptionId) {
                                    value = value + (option.value * coeff);
                                    valueStr += option.value;
                                }
                            }
                        }
                        max += m;
                        min += n;
                    }
                }
            }
            if (valueStr.length > 0) {
                if (group.lowerValueLabel && value == min) {
                    return group.lowerValueLabel;
                } else if (group.higherValueLabel && value == max) {
                    return group.higherValueLabel;
                } else if (group.measureMode == 'DATA') {
                    return $scope.formatData(value, 2);
                } else if (group.measureMode == 'TIME') {
                    return '' + value + '<em>min</em>';
                } else if (group.measureMode == 'CUSTOM') {
                    if (group.valueLabel) {
                        return group.valueLabel.replace('{value}', value);
                    }
                }
                return '' + value;
            } else {
                return '';
            }
        };

        $scope.$on('LSQAnswerUpdatedEvent', function (event, result) {
            $scope.processCriteriaUpdate(result);
        });

        $scope.sliderBarEnabled = function (group) {
            if (group.showSliderBar == true) {
                if (group.type == 'COMPOSITE') {
                    return true;
                } else if (group.type == 'CONTAINER') {
                    return group.questions.length == 1;
                }
            }
            return false;
        };

        $scope.loadQuestionnaire(false);
    }
]);


frontendApp.controller('AddonsForBasketCtrl', [
    '$scope',
    '$rootScope',
    'sessionService',
    'contentService',
    function ($scope, $rootScope, sessionService, contentService) {
        $scope.activeProduct = {};
        $scope.products = [];
        $scope.isByProduct = false;
        contentService.get({
            action: "get-addon-tabs",
            interaction: $scope.saleSessionManager.getInteractionKey()
        }, function (result) {
            $scope.isByProduct = "BY_ADDON_CATEGORY" != result.groupType;
        });

        sessionService.query({
                action: 'basket-for-addons-list',
                interaction: $scope.saleSessionManager.getInteractionKey()
            },
            function (result) {
                var j = 0;
                for (var i = 0; i < result.length; i++) {
                    var items = result[i].items;
                    for (var e = 0; e < items.length; e++) {
                        $scope.products[j] = {id: items[e].id, name: items[e].name, templateId: result[i].templateId};
                        j++;
                    }
                }
                $scope.$emit('showAddonForProduct', $scope.products[0]);
            });

        $scope.$on("showAddonForProduct", function (event, product) {
            $scope.activeProduct = product;
        });

    }]);

frontendApp.controller('BasketMiniTableCtrl', [
    '$scope',
    '$rootScope',
    '$location',
    'contentService',
    function () {
    }]);

frontendApp.controller('AddonRecommendationCtrl', ['$scope', '$modalInstance', function ($scope, $modalInstance) {
    $scope.onCloseButtonClick = function () {
        $modalInstance.dismiss();
    };
    $scope.$on("closeAddon", function () {
        $modalInstance.dismiss();
    });
}]);

frontendApp.controller('JourneysModalCtrl', ['$scope', '$rootScope', '$modalInstance', 'params', function ($scope, $rootScope, $modalInstance, params) {
    $scope.journeys = params.journeys;
    $scope.selecetedJourney = {};
    $scope.selectJourney = function (journeyId) {
        $rootScope.$broadcast("SaveNewSaleJourney", journeyId);
        $modalInstance.close();
    };
}]);

frontendApp.controller('SelectLocationCtrl', ['$scope', '$modalInstance', 'locations', function ($scope, $modalInstance, locations) {
    $scope.locations = locations;
    $scope.selected = locations[0];

    $scope.clickSelect = function () {
        $modalInstance.close($scope.selected);
    };

    $scope.selectLocation = function (selection) {
        $scope.selected = selection;
    };
}]);

frontendApp.controller('PinTypeCtrl', ['$scope', '$modalInstance', 'username', function ($scope, $modalInstance, username) {
    $scope.username = username;
    $scope.pin = '';

    $scope.clickSelect = function () {
        $modalInstance.close($scope.pin);
    };

    $scope.clickCancel = function () {
        $modalInstance.dismiss('cancel');
    };
}]);

frontendApp.controller('RecallSessionCtrl', ['$scope', '$rootScope', '$modalInstance', 'params', 'sessionService',
    function ($scope, $rootScope, $modalInstance, params, sessionService) {
        $scope.owner = params.owner;
        $scope.prevSessions = params.prevSessions;
        $scope.phone = params.phone;
        $scope.email = params.email;
        $scope.step = 1;
        $scope.selectedSession = {};

        $scope.closeDialog = function () {
            $modalInstance.close();
        };

        $scope.clickCancel = function () {
            $scope.step = 1;
        };

        $scope.importSession = function () {
            sessionService.get({
                    action: 'import-session',
                    oldKey: $scope.selectedSession.key,
                    interaction: $scope.owner.saleSessionManager.getInteractionKey()
                },
                function () {
                    $scope.step = 3;
                    $scope.owner.sessionRecallSelected($scope.selectedSession.key);
                },
                function () {
                }
            );
        };

        $scope.selectSession = function (selectedSession) {
            if ($scope.owner.saleSessionManager.getInteractionKey() != selectedSession.key) {
                $scope.selectedSession = selectedSession;
                $scope.step = 2;
            }
        };
    }]);

frontendApp.controller('ClearPopupCtrl', ['$scope', '$modalInstance', function ($scope, $modalInstance) {
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.clearAnswers = function () {
        $scope.$emit('ClearAnswersReq');
        $modalInstance.dismiss('cancel');
    };
}]);

frontendApp.controller('LsqGroupModalCtrl', ['$scope', '$modalInstance', function ($scope, $modalInstance) {
    $scope.$on('CloseLSQModal', function () {
        $modalInstance.dismiss();
    });
}]);

frontendApp.controller('LsqWarningModalCtrl', ['$scope', '$modalInstance', 'lsqRenderingService',
    function ($scope, $modalInstance, lsqRenderingService) {
        $scope.lsqWaringMessage = lsqRenderingService.getWarningMessage($scope.warningQuestion.question,
            $scope.warningOption.name, $scope.warningOption.links, $scope.questionnaire.groups);

        $scope.closeWarning = function () {
            $modalInstance.dismiss();
        };
        $scope.continueQuestionnaire = function () {
            $scope.$parent.processSelection($scope.warningGroup, $scope.warningQuestion, $scope.warningOption);
            lsqRenderingService.toggleLinkedOptions($scope.warningOption.links, $scope.questionnaire.groups, $scope.warningGroup, $scope.warningQuestion, false);
            $modalInstance.close();
        };
    }]);

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular */

frontendApp.constant('BUTTONS', {
    OK: 'OK',
    CANCEL: 'CANCEL'
});

// @todo behaviours
frontendApp.directive('sessionReleaseButton', ['$rootScope', '$location', '$modal', function ($rootScope, $location, $modal) {
    return {
        restrict: 'A',
        scope: true,
        link: {
            pre: function (scope) {
                scope.fieldsAccessors = [];
                scope.registerElement = function (accessor) {
                    scope.fieldsAccessors.push(accessor);
                };
            },
            post: function (scope, element, attrs) {
                scope.attributes = {};
                angular.forEach(scope.fieldsAccessors, function (myVal) {
                    var entry = myVal();
                    scope.attributes[entry.name] = entry.element.val();
                });
                var sessionId = attrs.sessionId;
                element.bind('click', function ($event) {
                    $event.stopPropagation();
                    if (sessionId === undefined) {
                        sessionId = scope.saleSessionManager.getInteractionKey();
                    }
                    if (attrs.modalViewId) {
                        $modal.open({
                            template: '<div class="modal-content" static-modal view-id="' + attrs.modalViewId + '"></div>',
                            controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                                $scope.save = function ($event) {
                                    $event.stopPropagation();
                                    release();
                                    $modalInstance.close();
                                };
                                $scope.cancel = function ($event) {
                                    $event.stopPropagation();
                                    $modalInstance.close();
                                };
                            }],
                            backdrop: true
                        });
                    } else {
                        release();
                    }

                });

                function release() {
                    scope.saleSessionManager.releaseSession(sessionId, scope.attributes)
                        .then(function () {
                            if (attrs.href) {
                                $location.url(attrs.href);
                            } else {
                                $rootScope.$broadcast('UpdateContent');
                            }
                        });
                }
            }
        }
    };
}]);

// @todo behaviours
frontendApp.directive('sessionResumeButton', ['$rootScope', 'lockOperationsFacade', '$location', 'trackingServices', '$modal', 'messagesService', '$q', '$route',
    function ($rootScope, lockOperationsFacade, $location, trackingServices, $modal, messagesService, $q, $route) {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, element, attrs) {

                var resuming = false;

                var unlockResume = function () {
                    resuming = false;
                };
                scope.isResumeFailed = false;
                function resume() {
                    lockOperationsFacade.lock();
                    scope.saleSessionManager.resumeSession(attrs.sessionId)["catch"](
                        function () {
                            unlockResume();
                            scope.isResumeFailed = true;
                        })
                        .then(function () {
                            lockOperationsFacade.unlock();
                            if (!scope.isResumeFailed) {
                                if (attrs.href) {
                                    if ($location.url() == attrs.href) {
                                        $route.reload();
                                    } else {
                                        $location.url(attrs.href);
                                    }
                                } else {
                                    $rootScope.$broadcast('UpdateContent');
                                }
                            } else {
                                scope.isResumeFailed = false;
                            }
                        }, function () {
                            lockOperationsFacade.unlock();
                            messagesService.warn("Session is already in use.");
                            scope.isResumeFailed = false;
                        });
                }

                var lockResume = function () {
                    if (resuming) {
                        return false;
                    }
                    resuming = true;
                    return true;
                };

                scope.execute = function ($event) {
                    $event.stopPropagation();
                    if (lockResume()) {
                        trackingServices.trackAction(scope, "resume");
                        var interaction = scope.saleSessionManager.getInteractionKey();
                        if (interaction) {
                            messagesService
                                .confirm('You already have customer session. If you resume another session, current session will be lost. Are you sure?')
                                .then(function () {
                                    lockOperationsFacade.lock();
                                    scope.saleSessionManager.releaseSession(interaction).then(resume, unlockResume);
                                }, unlockResume);
                        } else {
                            resume();
                        }
                    }
                };
            }
        };
    }]);

frontendApp.directive('contentSelectedItems', [
    function () {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, element, attrs) {
                scope.$on('contentSelectedItemsUpdate', function (event, viewId, amount) {
                    if (viewId === attrs.viewId) {
                        element.html("" + amount);
                    }
                });
            }
        };
    }]);

frontendApp.directive('basketMini', ['contentService', '$route', 'sessionService', '$compile', 'trackingServices',
    function (contentService, $route, sessionService, $compile, trackingServices) {
        return {
            restrict: 'A',
            scope: true,
            replace: true,
            transclude: true,
            template: '<div class="miniBasket"></div>',
            link: function (scope, element, attrs) {
                scope.showContent = false;
                scope.basketSize = attrs.basketSize;

                scope.loadMiniBasket = function () {
                    contentService.get({
                            action: 'show-basket',
                            mode: 'MINI',
                            interaction: scope.saleSessionManager.getInteractionKey()
                        },
                        function (result) {
                            element.html($compile(result.string)(scope));
                        });
                };

                scope.clickMiniBasket = function () {
                    scope.showFlag = !scope.showFlag;
                    trackingServices.trackAction(scope, 'miniBasket');
                };

                var fnDestroyUpdateBasketContent = scope.$on('updateMiniBasketContent', function () {
                    scope.loadMiniBasket();
                });

                scope.$on('hideMiniBasket', function () {
                    scope.showFlag = false;
                });

                scope.isDialogShow = function () {
                    return scope.showFlag;
                };

                element.bind('$destroy', fnDestroyUpdateBasketContent);

                scope.loadMiniBasket();
            }
        };
    }]);

// @todo behaviours
frontendApp.directive('searchAction', ['$location', 'trackingServices',
    function ($location, trackingServices) {
        return {
            restrict: 'A',
            scope: true,
            template: '<form class="headerSearchForm" ng-submit="search();"><input type="text" class="form-control headerSearchTextField ng-pristine ng-valid" id="search" placeholder="Enter text to search" ng-model="searchQuery"><button class="proIcons headerSearchFormBut">S</button></form>',
            link: function (scope, element, attrs) {
                scope.viewId = attrs.viewId;
                scope.searchQuery = "";
                scope.search = function () {
                    trackingServices.trackAction(scope, 'searchClick');
                    $location.path("/user/search").search({query: scope.searchQuery});
                };

                scope.execute = function ($event) {
                    $event.stopPropagation();
                };
            }
        };
    }]);


// @todo behaviours
//frontendApp.directive('customerCareNavigationAction', ['$rootScope', 'executionServices',
//    function ($rootScope, executionServices) {
//        return {
//            restrict: 'A',
//            scope: true,
//            link: function (scope, element, attrs) {
//                scope.viewId = attrs.viewId;
//                scope.actionId = attrs.customerCareNavigationAction;
//                scope.attributes = {};
//                for (var property in attrs) {
//                    if (attrs.hasOwnProperty(property)) {
//                        scope.attributes[property] = attrs[property];
//                    }
//                }
//                scope.attributes['requestType'] = 'CARE_PAGE';
//                scope.execute = function ($event) {
//                    $event.stopPropagation();
//
//                    executionServices.execute(scope, {
//                            actionId: scope.actionId,
//                            viewId: attrs.viewId,
//                            parameters: scope.attributes
//                        },
//                        function (result) {
//                            if (result.success) {
//                                $rootScope.$broadcast("getContent");
//                            }
//                        });
//                };
//            }
//        };
//    }]);

frontendApp.directive('sharedSessionsList', ['$rootScope', '$route', 'sessionService', '$compile', 'trackingServices', 'cometd', 'alertMessageFacade', '$modal', '$q', '$log', 'commonService',
    function ($rootScope, $route, sessionService, $compile, trackingServices, cometd, alertMessageFacade, $modal, $q, $log, commonService) {
        return {
            restrict: 'A',
            scope: true,
            replace: true,
            transclude: true,
            template: '<div class="shared-sessions"></div>',
            link: function (scope, element) {
                scope.showContent = false;
                scope.eventsCount = 0;
                scope.events = {};

                scope.loadSessions = function () {
                    commonService.get({module: 'content-interaction', action: 'show-shared-sessions'},
                        function (result) {
                            element.html($compile(result.string)(scope));
                        });
                };

                scope.toggleList = function () {
                    scope.showContent = !scope.showContent;
                    trackingServices.trackAction(scope, 'sharedSessionsList');
                };

                var UpdateContent = scope.$on("UpdateContent", function () {
                    scope.loadSessions();
                });
                element.bind('$destroy', UpdateContent);

                scope.isListShown = function () {
                    return scope.showContent;
                };

                scope.getEventsCount = function () {
                    var result = 0;
                    for (var vv in scope.events) {
                        if (scope.events.hasOwnProperty(vv)) {
                            result++;
                        }
                    }
                    return result;
                };

                scope.hasNewEvents = function () {
                    for (var vv in scope.events) {
                        if (scope.events.hasOwnProperty(vv)) {
                            return true;
                        }
                    }
                    return false;
                };

                function subscribe(scope) {
                    scope.subscriptions = [];
                    scope.subscriptions.push(cometd.subscribe('/care/**', function (message) {
                        $log.debug("Incoming message from /care/**");
                        var id = message.channel.split('/')[3];
                        if (undefined === scope.events[id]) {
                            scope.events[id] = 1;
                        }
                        scope.$broadcast('SharedSessionIncoming', id);
                    }));
                }

                scope.$on("$destroy", function () {
                    if (scope.subscriptions) {
                        for (var ind in scope.subscriptions) {
                            if (scope.subscriptions.hasOwnProperty(ind)) {
                                var subscription = scope.subscriptions[ind];
                                $log.debug("Unsubscribe " + subscription.channel);
                                cometd.unsubscribe(subscription);
                            }
                        }
                        delete scope.subscriptions;
                    }
                });

                scope.selectTemplate = function (templates) {
                    var defer = $q.defer();
                    if (templates && templates.length > 0) {
                        if (templates.length > 1) {
                            $modal.open({
                                template: '<div class="modal-header"></div>' +
                                '<div class="modal-body"><select ng-model="template" ng-options="option.websiteTemplateName for option in templates track by option.websiteTemplateId"></select></div>' +
                                '<div class="modal-footer">' +
                                '<div>' +
                                '<a class="btn btn-default" ng-click="yes();" role="button">YES</a>' +
                                '<a class="btn btn-default" ng-click="no();" role="button">NO</a>' +
                                '</div>' +
                                '</div>',
                                controller: ['$scope', '$modalInstance',
                                    function ($scope, $modalInstance) {
                                        $scope.templates = templates;
                                        $scope.template = undefined;
                                        $scope.yes = function () {
                                            if ($scope.template) {
                                                $modalInstance.close();
                                                defer.resolve($scope.template);
                                            }
                                        };
                                        $scope.no = function () {
                                            $modalInstance.close();
                                            defer.reject();
                                        };
                                    }],
                                backdrop: true
                            });
                        } else {
                            defer.resolve(templates[0]);
                        }
                    } else {
                        defer.reject();
                    }
                    return defer.promise;
                };

                scope.openCustomerCare = function (interaction) {
                    if (scope.events[interaction] != undefined) {
                        delete scope.events[interaction];
                    }
                    sessionService.query({action: 'get-interaction-templates', interaction: interaction},
                        function (list) {
                            scope.selectTemplate(list).then(function (result) {
                                if (result.customerCare) {
                                    scope.showContent = false;
                                    var callback = function () {
                                        $rootScope.$broadcast('changeAccess', interaction, result.websiteTemplateId);
                                    };
                                    if (scope.closeCustomerCare) {
                                        scope.closeCustomerCare(function (scope) {
                                            alertMessageFacade.openCustomerCare(scope, undefined, result.websiteTemplateId, interaction)
                                                .result.then(callback, callback);
                                        });
                                    } else {
                                        alertMessageFacade.openCustomerCare(scope, undefined, result.websiteTemplateId, interaction)
                                            .result.then(callback, callback);
                                    }
                                }
                            });
                        }, function () {
                            alertMessageFacade.openWithHtml("<div style='text-align:center;'><p>No microsites available!</p></div>");
                        });
                };

                function init(scope) {
                    cometd.connect(function () {
                        subscribe(scope);
                    });
                }

                init(scope);

                scope.loadSessions();
            }
        };
    }]);

frontendApp.directive('sharedSessionEntry', [function () {
    return {
        restrict: 'A',
        scope: true,
        replace: false,
        link: function (scope, element, attrs) {
            scope.sessionId = attrs.sharedSessionEntry;
            scope.highlighted = false;

            scope.getStyle = function () {
                var style = '';
                if (scope.highlighted) {
                    style += ' highlighted';
                }
                if (scope.saleSessionManager.getInteractionKey() == scope.sessionId) {
                    style += ' active';
                }
                return style;
            };

            scope.$on('SharedSessionIncoming', function (event, sessionId) {
                scope.$apply(function () {
                    scope.highlighted |= scope.sessionId == sessionId;
                });
            });
        }
    };
}]);

frontendApp.directive('resumeSessionForm', ['lockOperationsFacade', '$rootScope', '$location', 'trackingServices', 'messagesService', 'messagesService',
    function (lockOperationsFacade, $rootScope, $location, trackingServices, messagesService) {
        return {
            restrict: 'A',
            scope: true,
            replace: false,
            link: {
                post: function (scope, elem, attr) {
                    function proceed(source, callback) {
                        var data = [];
                        var params = "?";
                        angular.forEach(source, function (myVal) {
                            var entry = myVal();
                            this.push({
                                'ruleType': entry.ruleType,
                                'field': entry.field,
                                'expression': entry.element.val(),
                                'operator': entry.operator
                            });
                            if (entry.ruleType === 'FORM') {
                                params += 'searchName=' + entry.element.val() + "&";
                            }
                        }, data);

                        scope.saleSessionManager.findFree(data).then(function (result) {
                            scope.saleSessionManager
                                .resumeSession(result.key)
                                .then(function () {
                                    $location.url(attr.page);
                                    callback();
                                }, function () {
                                    scope.hasError = true;
                                    callback();
                                });
                        }, function (results) {
                            callback();
                            if (results.length === 0) {
                                scope.hasError = true;
                            } else {
                                $location.url(attr.alternative + params);
                            }
                        });
                    }

                    elem.on('submit', function () {
                        trackingServices.trackAction(scope, "resume");
                        scope.hasError = false;
                        var interaction = scope.saleSessionManager.getInteractionKey();
                        if (interaction) {
                            messagesService
                                .confirm('You already have customer session. If you resume another session, current session will be lost. Are you sure?')
                                .then(function () {
                                        lockOperationsFacade.lock();
                                        scope.saleSessionManager.releaseSession(interaction).then(
                                            function () {
                                                proceed(scope.fieldsAccessors, function () {
                                                    lockOperationsFacade.unlock();
                                                });
                                            },
                                            function () {
                                                lockOperationsFacade.unlock();
                                            }
                                        );
                                    }
                                );
                        } else {
                            lockOperationsFacade.lock();
                            proceed(scope.fieldsAccessors, function () {
                                lockOperationsFacade.unlock();
                            });
                        }
                    });
                },
                pre: function (scope) {
                    scope.fieldsAccessors = [];
                    scope.hasError = false;
                    scope.registerElement = function (accessor) {
                        scope.fieldsAccessors.push(accessor);
                    };
                }
            }
        };
    }]);

frontendApp.directive('sessionField', [function () {
    return {
        restrict: 'A',
        scope: true,
        replace: false,
        link: {
            post: function (scope, element, attr) {
                scope.registerElement(function () {
                    var fields = attr.sessionField.split(".");
                    var field = attr.sessionField;
                    var ruleType = 'ATTRIBUTE';
                    if ('forms' === fields[0]) {
                        ruleType = 'FORM';
                        field = field.replace(/forms\./g, '');
                    }
                    return {
                        'name': attr.sessionField,
                        'ruleType': ruleType,
                        'field': field,
                        'element': element,
                        'operator': attr.operator ? attr.operator : 'EQUAL'
                    };
                });
            }
        }
    };
}]);

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp */

/* global resourceUrl */

frontendApp.directive('sliderBar', ['lsqRenderingService', 'SLIDER_REPLACEMENT', function (lsqRenderingService, SLIDER_REPLACEMENT) {
    return {
        restrict: 'A',
        scope: true,
        templateUrl: resourceUrl + '/template/views/sliderBar.html',
        link: function (scope) {
            function getSliderBarWidth(sliderValue, sliderBarOptions) {
                if (sliderBarOptions != null && sliderBarOptions.length > 0) {
                    var percentPerGroup = 100 / (sliderBarOptions.length - 1);
                    for (var i = 1; i < sliderBarOptions.length; i++) {
                        var min = sliderBarOptions[i - 1].value;
                        var max = sliderBarOptions[i].value;
                        if (min <= sliderValue && sliderValue <= max) {
                            return (((sliderValue - min) / (max - min) + i - 1) * percentPerGroup).toFixed(2);
                        }
                    }

                    var lastOption = sliderBarOptions[sliderBarOptions.length - 1];
                    if (sliderValue > lastOption.value) {
                        return 100;
                    } else {
                        return 0;
                    }
                }
                return 0;
            }

            function getSliderItemWidth(sliderBarOptions) {
                if (sliderBarOptions != null && sliderBarOptions.length > 0) {
                    return Math.floor((100 / (sliderBarOptions.length - 1)) * 100) / 100;
                }
                return 0;
            }

            function createSliderBarOptions(ticksCount, maxValue, valueLabel) {
                var sliderBarOptions = [];
                var perTick = (maxValue / (ticksCount - 1)).toFixed(0);
                for (var i = 0; i < ticksCount; i++) {
                    var tick = i * perTick;
                    sliderBarOptions.push({
                        title: renderValueLabel(tick, valueLabel),
                        value: tick
                    });
                }
                return sliderBarOptions;
            }

            function renderValueLabel(value, valueLabel) {
                if (valueLabel != null) {
                    return valueLabel.replace(SLIDER_REPLACEMENT.VALUE, value);
                }
                return value;
            }

            function updateSliderBar() {
                var barWidth = 0;
                var itemWidth = 0;
                var sliderValue = 0;
                var sliderTitle = '';
                var sliderBarOptions = [];

                function processLinearSliderBar() {
                    if (scope.activeGroup.type == 'COMPOSITE') {
                        sliderBarOptions = createSliderBarOptions(
                            scope.activeGroup.sliderBarTicksCount, scope.getCompositeMaxValue(scope.activeGroup), scope.activeGroup.valueLabel);
                        sliderValue = scope.getCompositeValueLabel(scope.activeGroup);
                        barWidth = (scope.getCompositeValue(scope.activeGroup) / scope.getCompositeMaxValue(scope.activeGroup)) * 100;
                        itemWidth = getSliderItemWidth(sliderBarOptions);
                    } else if (scope.activeGroup.type == 'CONTAINER') {
                        var question = scope.activeGroup.questions[0];
                        if (lsqRenderingService.isNumber(question.value) && question.value >= 0) {
                            sliderBarOptions = createSliderBarOptions(scope.activeGroup.sliderBarTicksCount, question.higher, question.valueLabel);
                            sliderValue = renderValueLabel(question.value, question.valueLabel);
                            barWidth = (question.value / question.higher) * 100;
                            itemWidth = getSliderItemWidth(sliderBarOptions);
                        } else if (question.type == 'SLIDER' && question.selectedOptionId != null && 'FIELD' === question.filterType) {
                            var value = 0;
                            var maxValue = 0;
                            for (var j = 0; j < question.options.length; j++) {
                                var option = question.options[j];
                                if (option.tile != null) {
                                    if (option.id == scope.question.selectedOptionId) {
                                        value = Number(option.tile);
                                    }
                                    if (option.tile > maxValue) {
                                        maxValue = Number(option.tile);
                                    }
                                }
                            }
                            if (maxValue > 0) {
                                sliderValue = renderValueLabel(value, question.valueLabel);
                                barWidth = (value / maxValue) * 100;
                                sliderBarOptions = createSliderBarOptions(scope.activeGroup.sliderBarTicksCount, maxValue, question.valueLabel);
                                itemWidth = getSliderItemWidth(sliderBarOptions);
                            }
                        }
                    }
                }

                function processExponentialSliderBar() {
                    sliderBarOptions = scope.activeGroup.sliderBarOptions;
                    if (scope.activeGroup.type == 'COMPOSITE') {
                        sliderValue = scope.getCompositeValueLabel(scope.activeGroup);
                        barWidth = getSliderBarWidth(scope.getCompositeValue(scope.activeGroup), sliderBarOptions);
                        itemWidth = getSliderItemWidth(sliderBarOptions);
                    } else if (scope.activeGroup.type == 'CONTAINER') {
                        var question = scope.activeGroup.questions[0];
                        if (lsqRenderingService.isNumber(question.value) && question.value >= 0) {
                            sliderValue = renderValueLabel(question.value, question.valueLabel);
                            barWidth = (question.value / question.higher) * 100;
                            itemWidth = getSliderItemWidth(sliderBarOptions);
                        } else if (question.type == 'SLIDER' && question.selectedOptionId != null && 'FIELD' === question.filterType) {
                            var value = 0;
                            for (var j = 0; j < question.options.length; j++) {
                                var option = question.options[j];
                                if (option.tile != null) {
                                    if (option.id == scope.question.selectedOptionId) {
                                        value = Number(option.tile);
                                    }
                                }
                            }
                            sliderValue = renderValueLabel(value, question.valueLabel);
                            barWidth = getSliderBarWidth(value, sliderBarOptions);
                            itemWidth = getSliderItemWidth(sliderBarOptions);
                        }
                    }
                }

                if (scope.activeGroup.sliderBarType == 'LINEAR') {
                    processLinearSliderBar();
                } else if (scope.activeGroup.sliderBarType == 'EXPONENTIAL') {
                    processExponentialSliderBar();
                }

                scope.slider = {
                    value: sliderValue,
                    title: sliderTitle,
                    options: sliderBarOptions,
                    barStyle: {width: barWidth + '%'},
                    itemStyle: {width: itemWidth + '%'},
                    isInverted: barWidth > 70
                };
            }

            scope.sliderBarEnabled = function () {
                if (scope.activeGroup.showSliderBar == true) {
                    if (scope.activeGroup.type == 'COMPOSITE') {
                        return true;
                    } else if (scope.activeGroup.type == 'CONTAINER') {
                        return scope.activeGroup.questions.length == 1;
                    }
                }
                return false;
            };

            scope.$on('SelectionUpdatedEvent', function () {
                updateSliderBar();
            });

            updateSliderBar();
        }
    };
}]);




/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, $, window, ripplMultiLanguage */

frontendApp.directive('viewTableBodySearch', ['$compile', 'contentService', '$routeParams', 'attributesTool',
    function ($compile, contentService, $routeParams, attributesTool) {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element) {
                        scope.totalItems = 0;
                        scope.loadedPagesCount = 1;
                        scope.currentLayout = scope.defaultLayout;
                        scope.sorting = {};

                        scope.setLoadedPagesCount = function (count) {
                            scope.loadedPagesCount = count;
                        };

                        scope.load = function () {
                            var request = {
                                action: 'table-layout-search',
                                viewId: scope.viewId,
                                layoutType: scope.currentLayout,
                                processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null,
                                micrositeId: scope.customerCareInfo && scope.customerCareInfo.micrositeId ? scope.customerCareInfo.micrositeId : "",
                                interaction: scope.saleSessionManager.getInteractionKey()
                            };

                            var attris = attributesTool.buildAttributes($routeParams, scope.requestAttributes);
                            for (var prop in attris) {
                                if (attris.hasOwnProperty(prop)) {
                                    request[prop] = attris[prop];
                                }
                            }

                            contentService.get(request,
                                function (result) {
                                    scope.totalItems = result.totalItems;
                                    scope.subTypeName = result.subTypeName;
                                    element.html($compile(result.content)(scope));
                                });
                        };

                        scope.$on('UpdateTableBodyEvent', function () {
                            scope.load();
                        });

                        scope.changeCurrentLayout = function (layout) {
                            scope.currentLayout = layout;
                            scope.load();
                        };

                        scope.applySorting = function (fieldName, sortingMode) {
                            scope.sorting.fieldName = fieldName;
                            scope.sorting.sortingMode = sortingMode;
                            scope.$broadcast('onSortOrderChanged', fieldName);
                        };
                    },
                    post: function (scope) {
                        scope.load();
                    }
                };
            }
        };
    }]);

frontendApp.directive('pricingMatrix', ['contentService', 'collectionsUtils', 'CUSTOM_EVENTS', '$compile', 'viewVisibilityFacade', 'commonService', '$sce',
    function (contentService, collectionsUtils, CUSTOM_EVENTS, $compile, viewVisibilityFacade, commonService, $sce) {
        return {
            restrict: 'EA',
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element, attrs) {
                        scope.viewId = attrs.viewId;
                        scope.offeringTypeId = attrs.offeringTypeId;
                        scope.filters = {};
                        scope.filtersArr = [];
                        scope.view = {};
                        scope.view.data = false;

                        scope.getLastUpdateDate = function () {
                            //return $scope.page.dictionaries.lastUpdateDateRPM;
                            return 'LAST UPDATED DATE';
                        };
                    },
                    post: function (scope, element, attrs) {

                        scope.page.benefits = false;
                        scope.page.isPricingMatrix = true;
                        scope.page.prepolated = false;
                        scope.page.updating = false;

                        scope.arr = [];

                        scope.$on('clickHome', function () {
                            scope.page.benefits = false;
                            scope.page.selectFilter = false;
                            scope.clearFilters();
                        });

                        scope.clickBenefits = function () {
                            scope.page.benefits = !scope.page.benefits;
                            if (scope.page.benefits) {
                                if (scope.view2 && scope.view2.details) {
                                    scope.view2.details.layout = "";
                                }
                                var param = {};
                                param.viewId = scope.viewId;
                                param.offeringTypeId = scope.offeringTypeId;
                                param.parameters = scope.page.pageParameters;
                                contentService.post({
                                        action: 'view-benefits',
                                        interaction: scope.saleSessionManager.getInteractionKey()
                                    },
                                    param,
                                    function (result) {
                                        scope.view2 = result.string;
                                        scope.page.updating = false;
                                    },
                                    function () {
                                        if (scope.view2) {
                                            scope.view2 = "";
                                        }
                                        scope.page.updating = false;
                                    }
                                );
                            }
                        };

                        scope.clearFilters = function () {
                            // clear only first filter
                            for (var filterIndex in scope.filters) {
                                if (0 === scope.filters[filterIndex].index) {
                                    scope.filters[filterIndex].selected = null;
                                    scope.$broadcast('FilterChangedEvent', scope.filters[filterIndex]);
                                    scope.page.selectFilter = false;
                                    return;
                                }
                            }
                            scope.view.data = false;
                            viewVisibilityFacade.showViews(attrs.resultViewId);
                        };

                        scope.checkFilters = function () {
                            if (scope.allSelected()) {
                                viewVisibilityFacade.hideViews(attrs.resultViewId);
                                scope.page.selectFilter = true;
                                var param = {};
                                param.viewId = scope.viewId;
                                param.offeringTypeId = scope.offeringTypeId;
                                param.parameters = scope.page.pageParameters;
                                param.parameters['resultViewId'] = attrs.resultViewId;
                                commonService.post(
                                    {
                                        module: 'content',
                                        action: 'view-pricing-matrix-last-filter',
                                        interaction: scope.saleSessionManager.getInteractionKey()
                                    },
                                    param,
                                    function (result) {
                                        var e = $compile(result.string)(scope);
                                        element.contents('#pricing-matrix').contents('#result').children().remove();
                                        element.contents('#pricing-matrix').contents('#result').append(e);
                                        scope.page.updating = false;
                                        scope.view.data = true;
                                    },
                                    function () {
                                        if (scope.view.data) {
                                            scope.view.data = true;
                                        }
                                        scope.page.updating = false;
                                    }
                                );
                                contentService.post(
                                    {
                                        action: 'view-pricing-matrix-insurance',
                                        interaction: scope.saleSessionManager.getInteractionKey()
                                    },
                                    param,
                                    function (result) {
                                        scope.insurance = result.string;
                                    },
                                    function () {
                                        scope.insurance = "";
                                    }
                                );
                            } else {
                                element.contents('#pricing-matrix').contents('#result').children().remove();
                                scope.view.data = false;
                                if (scope.isFiltersAvailable()) {
                                    for (var filterIndex in scope.filters) {
                                        if (0 === scope.filters[filterIndex].index && scope.filters[filterIndex].selected) {
                                            viewVisibilityFacade.hideViews(attrs.resultViewId);
                                            scope.page.selectFilter = true;
                                            return;
                                        }
                                    }
                                }
                                scope.page.selectFilter = false;
                                viewVisibilityFacade.showViews(attrs.resultViewId);
                            }
                        };

                        scope.allSelected = function () {
                            if (scope.isFiltersAvailable()) {
                                for (var filterIndex in scope.filters) {
                                    if (!scope.filters[filterIndex].selected && scope.filters[filterIndex].options.length > 0) {
                                    //if (!scope.filters[filterIndex].selected) {
                                        return false;
                                    }
                                }
                                return true;
                            }
                            return false;
                        };

                        scope.to_trusted = function (html_code) {
                            return $sce.trustAsHtml(html_code);
                        };

                        scope.getFilters = function () {
                            if (scope.filters) {
                                return collectionsUtils.createArray(scope.filters);
                            } else {
                                return [];
                            }
                        };

                        scope.isElementSelected = function (index) {
                            return !!scope.getFilters()[index].selected;
                        };

                        function callNext() {
                            if (scope.arr.length > 0) {
                                predefineFilter(scope.arr[scope.arr.length - 1].filterName, scope.arr[scope.arr.length - 1].filterValue);
                                scope.arr.length = scope.arr.length - 1;
                            }
                        }

                        scope.$on('Popular', function (e, data) {
                            var sortedFilters = scope.filtersArr;
                            for (var ind in sortedFilters) {
                                if (sortedFilters.hasOwnProperty(ind)
                                    && data.fields.hasOwnProperty(sortedFilters[ind].name)) {
                                    scope.arr.unshift(createPopulatedElement(sortedFilters[ind].name, data.fields[sortedFilters[ind].name]));
                                }
                            }

                            callNext();
                        });

                        function createPopulatedElement(filterName, filterValue) {
                            return {
                                filterName: filterName,
                                filterValue: filterValue
                            };
                        }

                        function predefineFilter(filterName, filterValue) {
                            var keys = collectionsUtils.getKeys(scope.filters);
                            for (var index in keys) {
                                if (keys.hasOwnProperty(index)) {
                                    var filter = scope.filters[keys[index]];
                                    if (filter.name === filterName) {
                                        filter.selected = filterValue;
                                        scope.$broadcast('FilterChangedEvent', filter);
                                    }
                                }
                            }
                        }

                        scope.$on(CUSTOM_EVENTS.RPM_PREV, function () {
                            scope.page.updating = true;
                            var filters = scope.filtersArr;
                            var lastFilter = filters[filters.length - 1];
                            if (lastFilter.selected) {
                                var currentIndex = getOptionIndex(lastFilter.options, lastFilter.selected);
                                if (currentIndex > 0) {
                                    lastFilter.selected = lastFilter.options[currentIndex - 1].value;
                                    scope.$broadcast('FilterChangedEvent', lastFilter);
                                }
                            }
                        });

                        scope.$on(CUSTOM_EVENTS.RPM_NEXT, function () {
                            scope.page.updating = true;
                            var filters = scope.filtersArr;
                            var lastFilter = filters[filters.length - 1];
                            if (lastFilter.selected) {
                                var currentIndex = getOptionIndex(lastFilter.options, lastFilter.selected);
                                if (currentIndex < lastFilter.options.length) {
                                    lastFilter.selected = lastFilter.options[currentIndex + 1].value;
                                    scope.$broadcast('FilterChangedEvent', lastFilter);
                                }
                            }
                        });

                        function getOptionIndex(options, value) {
                            var result = -1;
                            for (var index in options) {
                                if (options.hasOwnProperty(index)
                                    && options[index].value === value) {
                                    result = index;
                                }
                            }
                            return result;
                        }

                        scope.isNotEmpty = function (source) {
                            for (var key in source) {
                                if (source.hasOwnProperty(key)) {
                                    return true;
                                }
                            }
                            return false;
                        };

                        scope.loadFilters = function () {
                            commonService.get({
                                    module: 'filters',
                                    action: 'rpm-filters',
                                    viewId: scope.viewId,
                                    interaction: scope.saleSessionManager.getInteractionKey()
                                },
                                function (result) {
                                    scope.filters = result.filters;
                                    scope.checkFilters();
                                    scope.filtersArr = collectionsUtils.createArray(scope.filters);
                                });
                        };

                        scope.isFiltersAvailable = function () {
                            return scope.isNotEmpty(scope.filters);
                        };

                        scope.isFilterSelectionPanelVisible = function () {
                            if (scope.filters != null && scope.filters.length > 0) {
                                for (var i in scope.filters) {
                                    var predefinedFilter = scope.filters[i];
                                    if (predefinedFilter.selected instanceof Array) {
                                        if (predefinedFilter.selected.length > 0) {
                                            return true;
                                        }
                                    } else if (predefinedFilter.selected != null) {
                                        return true;
                                    }
                                }
                            }
                            return false;
                        };

                        scope.loadFilters();

                        scope.clearFilterOption = function (filter, option) {
                            if (filter.filterType == 'BUTTON' || filter.filterType == 'LIST_BOX_MULTI_SELECT') {
                                var index = -1;
                                for (var ind in filter.selected) {
                                    if (filter.selected.hasOwnProperty(ind)
                                        && (filter.selected[ind] === option || filter.selected[ind] === option.value)) {
                                        index = ind;
                                    }
                                }
                                //var index = filter.selected.indexOf(option);
                                if (index > -1) {
                                    filter.selected.splice(index, 1);
                                }
                            } else {
                                filter.selected = null;
                            }
                            //$scope.$broadcast('FilterChangedEvent', filter);
                        };

                        scope.$on('CriteriaUpdatedEvent', function (event, result) {
                            if (result.filtersUpdateInfo) {
                                var filtersInfo = result.filtersUpdateInfo[scope.viewId];
                                if (filtersInfo) {
                                    var keys = collectionsUtils.getKeys(filtersInfo);
                                    for (var ind in keys) {
                                        if (keys.hasOwnProperty(ind)) {
                                            if (collectionsUtils.getKeys(filtersInfo).hasOwnProperty(ind)) {
                                                scope.$broadcast('FilterUpdateReceivedEvent', filtersInfo[collectionsUtils.getKeys(filtersInfo)[ind]]);
                                            }
                                        }
                                    }
                                }
                                scope.checkFilters();
                            }
                            callNext();
                        });
                    }
                };
            }
        };
    }]);


frontendApp.directive('recentPanel', ['commonService',
    function (commonService) {
        return {
            restrict: 'A',
            template: '<div class="row"><tabset class="col-md-16" recent-content></tabset></div>',
            link: function (scope) {

                scope.active = 1;

                // todo move to actions
                scope.removeFromTray = function (templateId, id) {
                    commonService.remove({
                            module: 'session',
                            action: 'recent-remove', templateId: templateId, id: id,
                            interaction: scope.saleSessionManager.getInteractionKey()
                        },
                        function () {
                            scope.$broadcast("refreshRecentItems");
                        }
                    );
                };
            }
        };
    }]);

frontendApp.directive('recentContent', ['contentService', '$compile', function (contentService, $compile) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element) {

            scope.loadContent = function () {
                contentService.get({
                        action: 'recent-content',
                        interaction: scope.saleSessionManager.getInteractionKey()
                    },
                    function (result) {
                        element.html($compile(result.string)(scope));
                    });
            };

            scope.$on('refreshRecentItems', function () {
                scope.loadContent();
            });

            scope.loadContent();
        }
    };
}]);

frontendApp.directive('comparePanel', ['contentService', '$rootScope', '$compile', 'commonService',
    function (contentService, $rootScope, $compile, commonService) {
        return {
            restrict: 'A',
            replace: true,
            link: function (scope, element) {
                scope.active = 1;

                scope.$on('refreshCompare', function () {
                    refresh();
                });

                scope.removeFromTray = function (type, subType, id) {
                    commonService.remove({
                            module: 'session', action: 'compare-remove', templateId: subType, id: id
                        },
                        function () {
                            refresh();
                        });
                };

                function refresh() {
                    contentService.get({action: 'compare-list'},
                        function (result) {
                            element.html($compile(result.string)(scope));
                        });
                }

                refresh();
            }
        };
    }]);

frontendApp.directive('comparePage', ['contentService', 'commonService', '$rootScope', '$compile',
    function (contentService, commonService, $rootScope, $compile) {
        return {
            restrict: 'A',
            replace: true,
            link: function (scope, element) {
                scope.collapsed = [];

                scope.removeItem = function (templateId, id, sourceType) {
                    if ("RECENT" == sourceType) {
                        commonService.remove({
                                module: 'session',
                                action: 'recent-remove', templateId: templateId, id: id,
                                interaction: scope.saleSessionManager.getInteractionKey()
                            },
                            function () {
                                refresh(templateId, sourceType);
                            });
                    } else {
                        commonService.remove({
                                module: 'session',
                                action: 'compare-remove',
                                templateId: templateId,
                                id: id
                            },
                            function () {
                                refresh(templateId, sourceType);
                            });
                    }
                };

                function refresh(templateId, sourceType) {
                    contentService.get(
                        {
                            action: 'compare-detail',
                            templateId: templateId,
                            sourceType: sourceType,
                            processDefinitionId: scope.processInfo == undefined ? null : scope.processInfo.processDefinitionId
                        },
                        function (result) {
                            element.html($compile(result.string)(scope));
                        },
                        function () {
                        }
                    );
                }

                scope.toggleRows = function (current) {
                    var index = $.inArray(current, scope.collapsed);
                    if (index == -1) {
                        scope.collapsed.push(current);
                    } else {
                        scope.collapsed.splice(index, 1);
                    }
                };

                scope.hideGroup = function (current) {
                    return $.inArray(current, scope.collapsed) != -1;
                };
            }
        };
    }]);

frontendApp.directive('basketFull', ['contentService', '$route', 'sessionService', '$compile', '$log',
    function (contentService, $route, sessionService, $compile, $log) {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, element) {
                scope.loadBasket = function () {
                    $log.info('basketFull.show-basket');
                    contentService.get({
                            action: 'show-basket',
                            mode: 'FULL',
                            interaction: scope.saleSessionManager.getInteractionKey()
                        },
                        function (result) {
                            element.html($compile(result.string)(scope));
                        });
                };

                scope.$on('updateBasketContent', function () {
                    scope.loadBasket();
                });
            }
        };
    }]);

frontendApp.directive('searchResult', [
    function () {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element, attrs) {
                        scope.viewId = attrs.viewId;
                        scope.itemsPerPage = attrs.recordsPerPage;
                        scope.defaultLayout = attrs.defaultLayout;
                    },
                    post: function () {
                    }
                };
            }
        };
    }]);

frontendApp.directive('preOrderList', ['$compile', 'preorderService', '$modal', 'SORT_ORDERS',
    function ($compile, preorderService, $modal, SORT_ORDERS) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element, attrs) {
                scope.ITEMS_PER_PAGE = 10;
                scope.FIRST_PAGE = 0;
                scope.DATE_FIELD = "preorderedDate";
                scope.LAST_NAME_FIELD = "lastName";
                scope.TRANSACTION_NUMBER_FIELD = "transactionNumber";
                scope.MOBILE_NUMBER_FIELD = "mobileNumber";

                scope.viewId = attrs.viewId;

                scope.filter = {};
                scope.filter.itemsPerPage = scope.ITEMS_PER_PAGE;
                scope.filter.showCompletedOnly = false;
                scope.filter.startPage = scope.FIRST_PAGE;
                scope.filter.pageCount = 1;
                scope.filter.sortingField = scope.DATE_FIELD;
                scope.filter.sortingMode = SORT_ORDERS.DESC;
                scope.filter.searchField = "";
                scope.filter.searchValue = "";

                scope.$broadcast('onFilterChanged');

                scope.resetFilter = function () {
                    scope.filter.startPage = scope.FIRST_PAGE;
                    scope.filter.pageCount = 1;
                    scope.filter.searchField = "";
                    scope.filter.searchValue = "";
                };

                scope.sortBy = function (fieldName, order) {
                    scope.filter.startPage = scope.FIRST_PAGE;
                    scope.filter.sortingField = fieldName;
                    scope.filter.sortingMode = order;
                    scope.$broadcast('onFilterChanged');
                };

                scope.sortUp = function (fieldName) {
                    scope.sortBy(fieldName, SORT_ORDERS.DESC);
                };

                scope.sortDown = function (fieldName) {
                    scope.sortBy(fieldName, SORT_ORDERS.ASC);
                };

                scope.isSortedUp = function (fieldName) {
                    return scope.filter.sortingField == fieldName && scope.filter.sortingMode == SORT_ORDERS.DESC;
                };

                scope.isSortedDown = function (fieldName) {
                    return scope.filter.sortingField == fieldName && scope.filter.sortingMode == SORT_ORDERS.ASC;
                };

                scope.getAllUncompleted = function () {
                    scope.resetFilter();
                    scope.filter.showCompletedOnly = false;
                    scope.$broadcast('onFilterChanged');
                };

                scope.searchCompletedOnly = function () {
                    scope.resetFilter();
                    scope.filter.showCompletedOnly = true;
                    scope.$broadcast('onFilterChanged');
                };

                scope.searchByMobilePhone = function (value) {
                    scope.resetFilter();
                    scope.filter.searchField = scope.MOBILE_NUMBER_FIELD;
                    scope.filter.searchValue = value;
                    scope.$broadcast('onFilterChanged');
                };

                scope.searchByTransactionNumber = function (value) {
                    scope.resetFilter();
                    scope.filter.searchField = scope.TRANSACTION_NUMBER_FIELD;
                    scope.filter.searchValue = value;
                    scope.$broadcast('onFilterChanged');
                };

                scope.searchForm = function (formLabel, callbackFunction) {
                    $modal.open({
                        templateUrl: 'searchPreorderFormModal',
                        controller: ['$scope', '$modalInstance', 'callback', 'label', function ($scope, $modalInstance, callback, label) {
                            $scope.callback = callback;
                            $scope.searchStr = "";
                            $scope.label = label;
                            $scope.search = function () {
                                callback($scope.searchStr);
                                $modalInstance.close();
                            };
                            $scope.cancel = function () {
                                $modalInstance.dismiss();
                            };
                        }],
                        backdrop: true,
                        resolve: {
                            callback: function () {
                                return callbackFunction;
                            },
                            label: function () {
                                return formLabel;
                            }
                        }
                    });
                };
            }
        };
    }]);

frontendApp.directive('preOrderListContent', ['$compile', 'contentService', '$modal', 'preorderService',
    function ($compile, contentService, $modal, preorderService) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element) {

                // Reload content when filter was updated
                scope.$on('onFilterChanged', function () {
                    contentService.post({
                            action: 'preorder-list-content',
                            viewId: scope.viewId,
                            interaction: scope.saleSessionManager.getInteractionKey()
                        }, scope.filter,
                        function (result) {
                            element.html($compile(result.string)(scope));
                        });
                });

                // Bind event for infinite scroll mode.
                angular.element(document.querySelector('#scrollScreen')).off('scroll').on('scroll', function (event) {
                    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
                        // End of scroll bar.
                        scope.filter.startPage = scope.filter.pageCount;
                        scope.filter.pageCount = scope.filter.pageCount + 1;
                        contentService.post({
                                action: 'preorder-list-content',
                                viewId: scope.viewId,
                                interaction: scope.saleSessionManager.getInteractionKey()
                            }, scope.filter,
                            function (result) {
                                element.append($compile(result.string)(scope));
                            });
                    }
                });

                // Change item status and reload table
                scope.updateList = function (itemId, newStatus, phone) {
                    scope.filter.startPage = scope.FIRST_PAGE;

                    preorderService.get({
                            action: 'update-preorder',
                            preorderItemId: itemId,
                            status: newStatus,
                            phone: phone
                        },
                        function () {
                            contentService.post({
                                    action: 'preorder-list-content',
                                    viewId: scope.viewId,
                                    interaction: scope.saleSessionManager.getInteractionKey()
                                },
                                scope.filter,
                                function (result) {
                                    element.html($compile(result.string)(scope));
                                });
                        });
                };

                scope.setStatus = function (itemId, newStatus, label) {
                    if (newStatus == 'COMPLETED') {
                        openCompleteWindow(itemId, newStatus);
                    } else {
                        openConfirmationWindow(itemId, newStatus, label);
                    }
                };

                function openCompleteWindow(itemId, newStatus) {
                    $modal.open({
                        template: "<div class='modal-body ng-scope preorderCompleteModal'>" +
                        "<p>Please record the active customer mobile number</p>" +
                        "<div class='form-horizontal'>" +
                        "    <div class='form-group'>" +
                        "        <label class='control-label col-md-3 text-right'>CTN</label>" +
                        "        <div class='col-md-8'>" +
                        "           <input class='form-control input-lg' type='text' ng-model='phone' />" +
                        "           <div class='alert alert-danger text-left' ng-show='required || format'>" +
                        "                <div ng-show='required'>The phone is required.</div>" +
                        "                <div ng-show='format'>Mobile Phone number cannot contain alphabets and special characters.</div>" +
                        "           </div>" +
                        "        </div>" +
                        "       <div class='col-md-3'>" +
                        "           <div class='btn btn-primary btn-lg ng-scope'>" +
                        "               <div ng-click='complete()' class='ng-binding'>Submit</div>" +
                        "           </div>" +
                        "       </div>" +
                        "    </div>" +

                        "</div>" +
                        "</div>",
                        controller: ['$scope', '$modalInstance',
                            function ($scope, $modalInstance) {
                                $scope.validatePhone = function (phone) {
                                    var re = /^[0-9]+$/;
                                    return re.test(phone);
                                };

                                $scope.validate = function () {
                                    $scope.required = false;
                                    $scope.format = false;
                                    if ($scope.phone == "") {
                                        $scope.required = true;
                                        return false;
                                    } else {
                                        if ($scope.validatePhone($scope.phone)) {
                                            $scope.format = false;
                                            return true;
                                        } else {
                                            $scope.format = true;
                                            return false;
                                        }
                                    }
                                };

                                $scope.complete = function () {
                                    if ($scope.validate()) {
                                        scope.updateList(itemId, newStatus, $scope.phone);
                                        $modalInstance.close();
                                    }
                                };
                            }
                        ],
                        backdrop: true
                    });
                }

                function openConfirmationWindow(itemId, newStatus, label) {
                    $modal.open({
                        template: "<div class='modal-body ng-scope' style='text-align:center;'>" +
                        "<p>Do you wish to " + label + " this order?</p>" +
                        "<br/>" +
                        "    <div class='btn btn-primary btn-lg ng-scope'>" +
                        "        <div ng-click='yes()' class='ng-binding'>" + label + "</div>" +
                        "    </div>" +
                        "    <div class='btn btn-primary btn-lg ng-scope'>" +
                        "        <div ng-click='no()' class='ng-binding'>Cancel</div>" +
                        "    </div>" +
                        "</div>",
                        controller: ['$scope', '$modalInstance',
                            function ($scope, $modalInstance) {
                                $scope.yes = function () {
                                    scope.updateList(itemId, newStatus);
                                    $modalInstance.close();
                                };
                                $scope.no = function () {
                                    $modalInstance.close();
                                };
                            }
                        ],
                        backdrop: true
                    });
                }
            }
        };
    }]);

frontendApp.factory('lsqRenderingService',
    [
        function () {
            function replaceParams(string, replacements) {
                return string.replace(/\{(\d+)\}/g, function () {
                    return replacements[arguments[1]];
                });
            }

            function getLinkedList(linkedOptionIds, groups) {
                var linkedList = [];
                for (var gIdx = 0; gIdx < groups.length; gIdx++) {
                    var group = groups[gIdx];
                    var questions = group.questions;
                    for (var qIdx = 0; qIdx < questions.length; qIdx++) {
                        var question = questions[qIdx];
                        if (linkedOptionIds) {
                            for (var oIdx = 0; oIdx < linkedOptionIds.length; oIdx++) {
                                var optionId = linkedOptionIds[oIdx];
                                var options = question.options;
                                if (options) {
                                    for (var i = 0; i < options.length; i++) {
                                        var option = options[i];
                                        if (optionId == option.id) {
                                            linkedList.push({option: option, question: question});
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                return linkedList;
            }

            function isAllQuestionOptionsDisabled(question) {

                var ret = true;
                if (question.type != 'SLIDER') {
                    for (var i = 0; i < question.options.length; i++) {
                        if (question.options[i].enabled) {
                            ret = false;
                        }
                    }
                } else {
                    ret = false;
                }

                return ret;
            }

            return {
                isSliderQuestionResolvedPartially: function (group) {
                    for (var i = 0; i < group.questions.length; i++) {
                        var q = group.questions[i];
                        if ((q.type === 'BUTTONS' || q.type === 'LIST_BOX')
                            && q.selectedOptionIds && q.selectedOptionIds != null && q.selectedOptionIds.length > 0) {
                            return true;
                        } else if (q.type == 'DROP_DOWN' && q.selectedOptionId != null) {
                            return true;
                        } else if ((q.type == 'SLIDER') && (q.value != null || q.selectedOptionId != null)) {
                            return true;
                        }
                    }
                    return false;
                },
                isAllMandatoryQuestionsResolved: function (group) {
                    var notAnswered = 0;
                    for (var i = 0; i < group.questions.length; i++) {
                        var q = group.questions[i];
                        if (group.mandatory && group.type == 'COMPOSITE') {
                            if ((q.type === 'BUTTONS' || q.type === 'LIST_BOX')
                                && (!q.selectedOptionIds || q.selectedOptionIds == null || q.selectedOptionIds.length == 0)) {
                                notAnswered++;
                            } else if (q.type == 'DROP_DOWN' && q.selectedOptionId == null) {
                                notAnswered++;
                            } else if ((q.type == 'SLIDER') && q.value == null && q.selectedOptionId == null) {
                                notAnswered++;
                            }
                        } else if (q.mandatory && group.type == 'CONTAINER' && !isAllQuestionOptionsDisabled(q)) {
                            if ((q.type === 'BUTTONS' || q.type === 'LIST_BOX')
                                && (!q.selectedOptionIds || q.selectedOptionIds == null || q.selectedOptionIds.length == 0)) {
                                return false;
                            } else if (q.type == 'DROP_DOWN' && q.selectedOptionId == null) {
                                return false;
                            } else if ((q.type == 'SLIDER') && q.value == null && q.selectedOptionId == null) {
                                return false;
                            }
                        }
                    }

                    return notAnswered != group.questions.length;
                },
                isQuestionsInCompositeGroupResolved: function (q) {

                    if ((q.type === 'BUTTONS' || q.type === 'LIST_BOX') && (!q.selectedOptionIds || q.selectedOptionIds == null || q.selectedOptionIds.length == 0)) {
                        return false;
                    } else if (q.type == 'DROP_DOWN' && q.selectedOptionId == null) {
                        return false;
                    } else if ((q.type == 'SLIDER') && q.value == null && q.selectedOptionId == null) {
                        return false;
                    }

                    return true;
                },
                isNumber: function (n) {
                    return Number(n) === n;
                },
                isSelected: function (id, question) {
                    if (id == question.selectedOptionId) {
                        return true;
                    } else if (question.selectedOptionIds) {
                        for (var i = 0; i < question.selectedOptionIds.length; i++) {
                            if (id === question.selectedOptionIds[i]) {
                                return true;
                            }
                        }
                    }
                    return false;
                },
                isLinkedSelected: function (optionsId, groups) {
                    var items = getLinkedList(optionsId, groups);
                    for (var i = 0; i < items.length; i++) {
                        var item = items[i];
                        if (this.isSelected(item.option.id, item.question)) {
                            return true;
                        }
                    }
                    return false;
                },
                getWarningMessage: function (currentQuestion, currentOption, optionsId, groups) {
                    var message = "You have selected '{0}' for the '{1}', this means that '{2}' cannot be set to '{3}'";
                    var items = getLinkedList(optionsId, groups);
                    for (var i = 0; i < items.length; i++) {
                        var item = items[i];
                        if (this.isSelected(item.option.id, item.question)) {
                            return replaceParams(message, [currentQuestion, currentOption, item.question.question, item.option.name]);
                        }
                    }
                },
                toggleLinkedOptions: function (optionsId, groups, activeGroup, question, enabled) {
                    var questionOptions = question.options;
                    if (questionOptions) {
                        for (var j = 0; j < questionOptions.length; j++) {
                            var option = questionOptions[j];
                            if (question.selectedOptionId != option.id) {
                                var linkeds = getLinkedList(option.links, groups);
                                for (var k = 0; k < linkeds.length; k++) {
                                    var linked = linkeds[k];
                                    linked.option.enabled = true;
                                }
                            }
                        }
                    }
                    var items = getLinkedList(optionsId, groups);
                    for (var i = 0; i < items.length; i++) {
                        var item = items[i];
                        item.option.enabled = enabled;
                        if (activeGroup) {
                            for (var j2 = 0; j2 < activeGroup.questions.length; j2++) {
                                if (item.question.id == activeGroup.questions[j2].id) {
                                    for (var e = 0; e < activeGroup.questions[j2].options.length; e++) {
                                        if (item.option.id == activeGroup.questions[j2].options[e].id) {
                                            activeGroup.questions[j2].options[e].enabled = enabled;
                                        }
                                    }
                                }
                            }
                        }
                    }

                },
                getAnswer: function (group, question) {
                    var result = '';
                    var compositeStyle = {};
                    if (this.isSliderQuestionResolvedPartially(group)) {
                        if ((question.type != 'SLIDER'
                            && (question.selectedOptionId != null
                            || (question.selectedOptionIds != null && question.selectedOptionIds.length > 0)))
                            || 'RULES' === question.filterType) {
                            for (var j = 0; j < question.options.length; j++) {
                                var option = question.options[j];
                                if (this.isSelected(option.id, question)) {
                                    result = result + ' ' + option.tile;
                                }
                            }
                        } else if (this.isNumber(question.value) && question.value > 0) {
                            if (question.tileLabel) {
                                compositeStyle = {width: (question.value / question.higher) * 100 + "%"};
                                result = result + question.tileLabel.replace('{value}', question.value);
                            }
                        } else if (question.type == 'SLIDER' && question.selectedOptionId != null
                            && 'FIELD' === question.filterType) {
                            var m = 0;
                            var value = 0;
                            var valueLabel = 0;
                            for (var j3 = 0; j3 < question.options.length; j3++) {
                                var option2 = question.options[j3];
                                if (option2.tile != null) {
                                    if (option2.id == question.selectedOptionId) {
                                        if (!isNaN(option2.sliderWidth))
                                            value = Number(option2.sliderWidth);
                                        else
                                            value = Number(option2.tile);
                                        valueLabel = Number(option2.tile);
                                    }
                                    if (option2.tile > m) {
                                        m = Number(option2.sliderWidth);
                                    }
                                }
                            }
                            if (value > 0) {
                                compositeStyle = {width: (value / m) * 100 + "%"};
                                if (group.valueMeasure != null) {
                                    if ('SLIDER' === question.type) {
                                        result = valueLabel;
                                    } else {
                                        result = group.valueMeasurePrefix + valueLabel + group.valueMeasureSuffix;
                                    }
                                } else {
                                    result = valueLabel;
                                }
                            }
                        }
                    }
                    return {label: result, compositeStyle: compositeStyle};
                }
            };
        }]);

frontendApp.directive('lsqGroup', ['$compile', '$rootScope', 'commonService',
    function ($compile, $rootScope, commonService) {

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {

                scope.$on('LSQUpdatedGroupsEvent', function (event, groupId) {
                    if (groupId) {
                        if (groupId == attrs.groupId) {
                            updateGroup();
                        }
                    } else {
                        updateGroup();
                    }
                });

                function updateGroup() {
                    commonService.get({
                        module: 'questionnaire',
                        action: 'get-group-layout',
                        questionnaireId: attrs.questionnaireId,
                        groupId: attrs.groupId,
                        interaction: scope.saleSessionManager.getInteractionKey()
                    }, function (result) {
                        if (element) {
                            var childElement = angular.element(element.children()[0]);
                            if (childElement) {
                                childElement.replaceWith($compile(result.string)(scope));
                            }
                        }
                    }, function () {

                    });
                }
            }
        };
    }]);

frontendApp.directive('lsqDealCount', ['contentService', '$compile', 'commonService',
    function (contentService, $compile, commonService) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element, attrs) {
                scope.$on('LSQUpdatedGroupsEvent', function () {
                    commonService.get({
                        module: 'questionnaire',
                        action: 'get-deals-exists',
                        questionnaireId: attrs.questionnaireId,
                        interaction: scope.saleSessionManager.getInteractionKey()
                    }, function (result) {
                        element.contents('#counter').html(result.string);
                    }, function () {

                    });
                });
            }

        };
    }]);


frontendApp.directive('benefitView', ['trackingServices',
    function (trackingServices) {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return {
                    pre: function () {
                    },
                    post: function (scope) {
                        scope.track = function (message) {
                            trackingServices.trackAnalytics(message);
                        };
                    }
                };
            }
        };
    }]);


frontendApp.directive('productTabs', [function () {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope, element, attrs) {
            scope.active = 1;
            scope.offeringId = attrs['offeringId'];
            scope.templateId = attrs['templateId'];

            scope.showTab = function (productIndex, productId, templateId) {
                scope.active = productIndex;
                scope.offeringId = productId;
                scope.templateId = templateId;
            };
        }
    };
}]);

frontendApp.directive('addonTabs', ['contentService', '$compile', function (contentService, $compile) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope, element, attrs) {
            var isPage = "";
            scope.oldOfferingId = "";
            scope.active = 1;
            scope.tabName = "";
            if (attrs['isPage']) {
                isPage = attrs['isPage'];
            }
            scope.load = function () {
                contentService.get({
                    action: "get-addon-tabs",
                    offeringId: scope.offeringId,
                    templateId: scope.templateId,
                    isPage: isPage,
                    interaction: scope.saleSessionManager.getInteractionKey()
                }, function (result) {
                    element.html($compile(result.string)(scope));
                });
            };

            scope.$watch("offeringId",
                function (newVal) {
                    if (scope.offeringId != ""
                        && scope.oldOfferingId != newVal) {
                        scope.oldOfferingId = scope.offeringId;
                        scope.load();
                    }
                });

            scope.showTab = function (tab, tabName) {
                scope.active = tab;
                scope.tabName = tabName;
            };
        }
    };
}]);

frontendApp.directive('addonTab', ['contentService', '$compile', function (contentService, $compile) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope, element) {
            scope.oldTab = "";
            scope.showTab = function () {
                contentService.get({
                    action: "get-addon-tab",
                    offeringId: scope.offeringId,
                    templateId: scope.templateId,
                    tabName: scope.tabName,
                    interaction: scope.saleSessionManager.getInteractionKey()
                }, function (result) {
                    element.html($compile(result.string)(scope));
                });
            };
            scope.$watch("tabName",
                function (newVal) {
                    if (scope.tabName != ""
                        && scope.oldTab != newVal) {
                        scope.oldTab = scope.tabName;
                        scope.showTab();
                    }
                });
        }
    };
}]);

frontendApp.directive('viewTableTab', [function () {
    return {
        restrict: 'A',
        scope: true,
        compile: function (element, attributes) {
            return {
                pre: function (scope) {
                    scope.viewId = attributes.viewId;
                    scope.itemsPerPage = attributes.itemsPerPage;
                    scope.defaultLayout = attributes.defaultLayout;
                    if (attributes.requestAttributesKeys) {
                        var requestAttributesKeys = attributes.requestAttributesKeys.split(",");
                        var requestAttributesValues = attributes.requestAttributesValues.split(",");
                        scope.requestAttributes = {};
                        for (var i = 0; i < requestAttributesKeys.length; i++) {
                            var key = requestAttributesKeys[i].replace("[", "").replace("]", "").trim();
                            var value = requestAttributesValues[i].replace("[", "").replace("]", "").trim();
                            if (value != "null") {
                                scope.requestAttributes[key] = value;
                            }
                        }
                    }

                    scope.sorting = {};

                    scope.applySorting = function (fieldName, sortingMode) {
                        scope.sorting.fieldName = fieldName;
                        scope.sorting.sortingMode = sortingMode;
                        scope.$broadcast('onSortOrderChanged');
                    };

                    scope.$broadcast('onSortOrderChanged');
                },
                post: function () {
                }
            };
        }
    };
}]);

frontendApp.directive('buildBundleTabs', ['contentService', '$compile', function (contentService, $compile) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope, element) {
            scope.activeTab = {};
            scope.active = 1;
            scope.elements = [];
            scope.tabs = [];
            scope.page.pageParameters = {id: "tab"};
            contentService.get({
                action: 'get-tabs',
                interaction: scope.saleSessionManager.getInteractionKey()
            }, function (result) {
                element.html($compile(result.string)(scope));
            });

            scope.showTab = function (tab, tabName) {
                scope.active = tab;
                scope.tabName = tabName;
            };
        }
    };
}]);

frontendApp.directive('buildBundleTab', ['contentService', '$compile', function (contentService, $compile) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope, element) {
            scope.oldTab = "";
            scope.activeRuleI = 1;
            scope.activeRuleName = "";
            scope.requestAttributes = {};
            scope.showTab = function () {
                contentService.get({
                    action: "get-build-bundle-tab",
                    tabName: scope.tabName,
                    interaction: scope.saleSessionManager.getInteractionKey()
                }, function (result) {
                    element.html($compile(result.string)(scope));
                });
            };
            scope.$watch("tabName",
                function (newVal) {
                    if (scope.tabName != ""
                        && scope.oldTab != newVal) {
                        scope.oldTab = scope.tabName;
                        scope.showTab();
                    }
                });

            scope.filterByRule = function (index, ruleName, ruleValue) {
                if (scope.activeRuleName == "") {
                    scope.requestAttributes = {};
                } else if (scope.activeRuleName && scope.requestAttributes.hasOwnProperty(scope.activeRuleName)) {
                    delete scope.requestAttributes[scope.activeRuleName];
                }
                scope.activeRuleI = index;
                scope.activeRuleName = ruleName;
                scope.requestAttributes[ruleName] = ruleValue;
                scope.$broadcast("UpdateTableBodyEvent");
            };
        }
    };
}]);


frontendApp.directive('buildBundleTabTable', [function () {
    return {
        restrict: 'A',
        scope: true,
        compile: function (element, attributes) {
            return {
                pre: function (scope) {
                    if (attributes.ruleName) {
                        scope.requestAttributes[attributes.ruleName] = attributes.ruleValue;
                    }
                    scope.viewId = attributes.viewId;
                    scope.itemsPerPage = attributes.itemsPerPage;
                    scope.defaultLayout = attributes.defaultLayout;

                    scope.sorting = {};

                    scope.applySorting = function (fieldName, sortingMode) {
                        scope.sorting.fieldName = fieldName;
                        scope.sorting.sortingMode = sortingMode;
                        scope.$broadcast('onSortOrderChanged');
                    };

                    scope.$broadcast('onSortOrderChanged');
                },
                post: function () {
                }
            };
        }
    };
}]);

frontendApp.directive('viewProcess', ['$rootScope', '$timeout', '$compile', 'contentService', '$window', '$routeParams', 'dragDropFacade',
    'layoutFacade', 'lockOperationsFacade', 'attributesTool', 'cometd', '$log', 'actions-handler', '$translate', '$filter',
    function ($rootScope, $timeout, $compile, contentService, $window, $routeParams, dragDropFacade,
              layoutFacade, lockOperationsFacade, attributesTool, cometd, $log, handlers, $translate, $filter) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            link: function (scope, element, attrs) {
                scope.translationFilter = $filter("translate"); // eslint-disable-line no-unused-vars

                var attributes = attributesTool.buildAttributes($routeParams, {}, $window);

                if (!scope.processInfo) {
                    scope.processInfo = {};
                }
                scope.processInfo.processDefinitionId = attrs.processDefinitionId;

                if (ripplMultiLanguage) {
                    scope.$on('updateTranslations', function (event, lang) {
                        contentService.post({
                                action: 'change-current-language',
                                interaction: scope.saleSessionManager.getInteractionKey(),
                                language: lang
                            },{},
                            function () {
                            },
                            function () {
                            });
                    });
                }

                lockOperationsFacade.setSimplePage(false);

                var refreshInnerPage = scope.$on('refreshInnerPage', function (event, processDefinitionId) {
                    $log.debug('refreshInnerPage' + JSON.stringify(event));
                    if (attrs.processDefinitionId === processDefinitionId) {
                        lockOperationsFacade.setSimplePage(false);
                        getProcessContent(attributesTool.buildAttributes($routeParams, {}, $window));
                    }
                });

                element.on('$destroy', function () {
                    scope.$destroy();
                    if (scope.workflowSubscription) {
                        cometd.unsubscribe(scope.workflowSubscription);
                    }
                    refreshInnerPage();
                });

                function getProcessContent(attributes) {
                    var width = $window.outerWidth || $window.document.documentElement.clientWidth;
                    var id = "ID" + new Date().getMilliseconds();
                    $log.debug('Start loading process content: ' + id);
                    var started = new Date().getMilliseconds();

                    if (ripplMultiLanguage) {
                        attributes.selectedLanguage = $translate.use();
                    }

                    contentService.post({
                            action: 'view-process',
                            processDefinitionId: attrs.processDefinitionId,
                            viewId: attrs.viewId,
                            width: width,
                            templateId: scope.customerCareInfo.micrositeId ? scope.customerCareInfo.micrositeId : "",
                            interaction: scope.saleSessionManager.getInteractionKey()
                        }, attributes,
                        function (result) {
                            $log.debug('Updating process content: ' + id);
                            if (window.ga) {
                                window.ga('send', {
                                    hitType: 'pageview', title: result.name,
                                    page: '/#/user/' + result.path
                                });
                                window.ga('send', 'timing', 'Page Load', result.path,
                                    new Date().getMilliseconds() - started, result.name);
                            }

                            if (result.baseBgColorChildren) {
                                $rootScope.baseBgColorChildren = result.baseBgColorChildren;
                            } else {
                                $rootScope.baseBgColorChildren = '';
                            }
                            if (scope.page) {
                                scope.page.hasCompare = result.hasCompare;
                                layoutFacade.updateFloatingTop(result.floatingPanelTop);
                                layoutFacade.updateFloatingBottom(result.floatingPanelBottom);
                            }
                            dragDropFacade.setHasCompareTray(result.hasCompare);
                            scope.$emit('scrollTop');
                            element.html($compile(result.content)(scope));
                            lockOperationsFacade.unlock(true);
                            if (result.journeyRefresh) {
                                layoutFacade.refreshJourneyMeter();
                            }
                        },
                        function (response) {
                            $log.debug('Process content update failed: ' + JSON.stringify(response));
                            element.html("Please wait.");
                        }
                    );
                }

                var handleMessage = function (message) {
                    $log.debug('Incoming notification message: ' + JSON.stringify(message));
                    // @todo behaviours
                    var payload = message.data;
                    if (payload.action == 'notify') {
                        element.html(message.message);
                    } else if (payload.action == 'update') {
                        getProcessContent(attributes);
                        // @todo
                        //message.path
                        //messsage.absolute
                    } else {
                        var ev = payload.action;
                        $log.debug('Action event received: ' + ev);
                        var handler = handlers[ev];
                        if (handler) {
                            handler(scope, attrs.viewId, payload);
                        } else {
                            $log.error('Action event processor not found for [' + ev + '].');
                        }
                    }
                };

                cometd.connect(function (daemon) {
                    var clientId = scope.user.channelClientId;
                    var interaction = scope.saleSessionManager.getInteractionKey();
                    var channel = '/workflow/notify/' + clientId + '/' + interaction + '/' + attrs.processDefinitionId;
                    $log.debug('Subscribe for [' + channel + ']');
                    scope.workflowSubscription = daemon.subscribe(channel, handleMessage, function (subscribeReply) {
                        if (subscribeReply.successful) {
                            $log.debug('Get content for [' + channel + ']');
                            getProcessContent(attributes);
                        }
                    });
                });
            }
        };
    }]);

frontendApp.directive('viewNotifications', ['$compile', 'SORT_ORDERS', 'sessionService', 'lockOperationsFacade',
    function ($compile, SORT_ORDERS, sessionService, lockOperationsFacade) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element, attrs) {
                scope.ITEMS_PER_PAGE = 10;
                scope.FIRST_PAGE = 0;
                scope.DATE_FIELD = "generationDate";
                scope.IDENTIFICATION = "identification";
                scope.STATUS = "status";

                scope.viewId = attrs.viewId;

                scope.sortingMode = SORT_ORDERS.DESC;
                lockOperationsFacade.lock();
                sessionService.get({
                        action: 'expire-sessions',
                        interaction: scope.saleSessionManager.getInteractionKey()
                    },
                    function () {
                        lockOperationsFacade.unlock(true);
                        scope.$broadcast('onFilterChanged');
                    }, function () {
                        lockOperationsFacade.unlock(true);
                    });

                scope.sortBy = function (fieldName, order) {
                    scope.sortingMode = order;
                    scope.$broadcast('onFilterChanged');
                };

                scope.sortUp = function (fieldName) {
                    scope.sortBy(fieldName, SORT_ORDERS.DESC);
                };

                scope.sortDown = function (fieldName) {
                    scope.sortBy(fieldName, SORT_ORDERS.ASC);
                };

                scope.isSortedUp = function () {
                    return scope.sortingMode == SORT_ORDERS.DESC;
                };

                scope.isSortedDown = function () {
                    return scope.sortingMode == SORT_ORDERS.ASC;
                };
            }
        };
    }]);

frontendApp.directive('notificationsListContent', ['$compile', 'contentService', '$interval', 'lockOperationsFacade',
    function ($compile, contentService, $interval, lockOperationsFacade) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element) {

                // Reload content when filter was updated
                scope.$on('onFilterChanged', function () {
                    contentService.get({
                            action: 'notifications-list-content',
                            viewId: scope.viewId,
                            sortingMode: scope.sortingMode
                        },
                        function (result) {
                            element.html($compile(result.string)(scope));
                            lockOperationsFacade.unlock(true);
                        }, function () {
                            lockOperationsFacade.unlock(true);
                        });
                });
            }
        };
    }]);

frontendApp.directive('refreshableSessionView', ['$routeParams', '$compile', 'commonService', '$interval', 'lockOperationsFacade', 'attributesTool',
    function ($routeParams, $compile, commonService, $interval, lockOperationsFacade, attributesTool) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element, attrs) {

                var stopTime = $interval(function () {
                    var request = {
                        module: 'content-interaction',
                        action: 'sale-session-list',
                        viewId: attrs.viewId
                    };

                    var attris = attributesTool.buildAttributes($routeParams, scope.requestAttributes);
                    for (var prop in attris) {
                        if (attris.hasOwnProperty(prop)) {
                            request[prop] = attris[prop];
                        }
                    }

                    lockOperationsFacade.lock();
                    commonService.get(request,
                        function (result) {
                            element.html($compile(result.string)(scope));
                            lockOperationsFacade.unlock(true);
                        }, function () {
                            lockOperationsFacade.unlock(true);
                        });
                }, attrs.interval * 1000);

                // listen on DOM destroy (removal) event, and cancel the next UI update
                // to prevent updating time after the DOM element was removed.
                element.bind('$destroy', function () {
                    $interval.cancel(stopTime);
                });

                scope.$on('EVT_USER_SESSION_EXPIRED', function () {
                    $interval.cancel(stopTime);
                });
            }
        };
    }]);


frontendApp.directive('refreshableSessionNotificationView', ['$routeParams', '$compile', 'commonService', '$interval', 'lockOperationsFacade', 'attributesTool',
    function ($routeParams, $compile, commonService, $interval, lockOperationsFacade, attributesTool) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element, attrs) {

                var stopTime = $interval(function () {
                    var request = {
                        module: 'content-interaction',
                        action: 'notifications-list-content',
                        viewId: attrs.viewId
                    };

                    var attris = attributesTool.buildAttributes($routeParams, scope.requestAttributes);
                    for (var prop in attris) {
                        if (attris.hasOwnProperty(prop)) {
                            request[prop] = attris[prop];
                        }
                    }

                    lockOperationsFacade.lock();
                    commonService.get(request,
                        function (result) {
                            element.html($compile(result.string)(scope));
                            lockOperationsFacade.unlock(true);
                        }, function () {
                            lockOperationsFacade.unlock(true);
                        });
                }, attrs.interval * 1000);

                // listen on DOM destroy (removal) event, and cancel the next UI update
                // to prevent updating time after the DOM element was removed.
                element.bind('$destroy', function () {
                    $interval.cancel(stopTime);
                });

                scope.$on('EVT_USER_SESSION_EXPIRED', function () {
                    $interval.cancel(stopTime);
                });
            }
        };
    }]);

frontendApp.directive('saleSessionListContainter', [
    function () {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, element, attrs) {
                scope.loadedPagesCount = scope.loadedPagesCount || parseInt(attrs.pageNumber) || 1;
                scope.viewId = attrs.viewId;
            }
        };
    }]);

frontendApp.directive('saleSessionListContent', ['$compile', 'commonService', '$routeParams', 'attributesTool',
    function ($compile, commonService, $routeParams, attributesTool) {
        return {
            restrict: 'A',
            scope: true,
            replace: true,
            link: function (scope, element) {
                scope.scrollInProgress = false;

                var baseRequest = {
                    module: 'content-interaction',
                    action: 'sale-session-list'
                };

                var attris = attributesTool.buildAttributes($routeParams, scope.requestAttributes);
                for (var prop in attris) {
                    if (attris.hasOwnProperty(prop)) {
                        baseRequest[prop] = attris[prop];
                    }
                }

                // Bind event for infinite scroll mode.
                angular.element(document.querySelector('#scrollScreen')).off('scroll').on('scroll', function (event) {
                    if (!scope.scrollInProgress && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
                        // End of scroll bar.
                        scope.scrollInProgress = true;
                        var request = angular.copy(baseRequest);
                        request['pageNumber'] = scope.loadedPagesCount + 1;
                        request['viewId'] = scope.viewId;
                        commonService.get(request,
                            function (result) {
                                var e = $compile(result.string)(scope);
                                element.replaceWith(e);
                                scope.loadedPagesCount = scope.loadedPagesCount + 1;
                                scope.scrollInProgress = false;
                            }, function () {
                                scope.scrollInProgress = false;
                            });
                    }
                });
            }
        };
    }]);


/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, $, Webcam, G_vmlCanvasManager, resourceUrl, window, ripplMultiLanguage */

frontendApp.directive('clientPage', ['$rootScope', '$timeout', '$compile', 'contentService', 'commonService', '$window',
    '$routeParams', 'dragDropFacade', 'layoutFacade', 'lockOperationsFacade', 'attributesTool', '$animate', '$location', '$translate',
    function ($rootScope, $timeout, $compile, contentService, commonService, $window, $routeParams, dragDropFacade,
              layoutFacade, lockOperationsFacade, attributesTool, $animate, $location, $translate) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: '',
            link: function (scope, element, attrs) {
                scope.loadPageContent = function () {
                    var started = new Date().getMilliseconds();
                    contentService.post({
                            action: 'page',
                            interaction: scope.saleSessionManager.getInteractionKey()
                        }, attributesTool.buildAttributes($routeParams, attrs.$attr, $window),
                        function (result) {
                            if (window.ga) {
                                window.ga('send', {
                                    hitType: 'pageview',
                                    location: $location.absUrl(),
                                    title: result.name,
                                    page: '/#/user/' + result.path
                                });
                                window.ga('send', 'timing', 'Page Load', result.path,
                                    new Date().getMilliseconds() - started, result.name);
                            }
                            lockOperationsFacade.unlock(true);
                            lockOperationsFacade.setSimplePage(true);
                            $rootScope.baseBgColorChildren = '';
                            scope.page.hasCompare = result.hasCompare;
                            layoutFacade.updateFloatingTop(result.floatingPanelTop);
                            layoutFacade.updateFloatingBottom(result.floatingPanelBottom);
                            dragDropFacade.setHasCompareTray(result.hasCompare);
                            if (result.interactionKey) {
                                var isStart = scope.saleSessionManager.getInteractionKey() != undefined && result.interactionKey == scope.saleSessionManager.getInteractionKey();
                                scope.saleSessionManager.setInteractionKey(result.interactionKey);
                                if (!isStart) {
                                    $rootScope.$broadcast('UpdateFormData');
                                    if (ripplMultiLanguage) {
                                        $translate("SYS_CUSTOMER_SESSION_STARTED").then(function (value) {
                                            layoutFacade.showTopMessage(value);
                                        }, function () {
                                        });
                                    } else {
                                        layoutFacade.showTopMessage('A new customer session has started.');
                                    }
                                    $rootScope.$broadcast('StartJourney');
                                }
                            } else {
                                if (scope.saleSessionManager.getInteractionKey() != undefined) {
                                    scope.saleSessionManager.dropSession();
                                }
                            }
                            element.html($compile(result.content)(scope));
                            $timeout(function () {
                                scope.isLoading = true;
                            }, 100);
                            $rootScope.activePageTag = result.tag;
                            $rootScope.activePageId = result.id;
                            $rootScope.activePageName = result.name;
                            // @todo to be refactored
                            if ($rootScope.animationClass != result.animationClass) {
                                $timeout(function () {
                                    $animate.removeClass($('.mainBody'), $rootScope.animationClass, function () {
                                        $animate.addClass($('.mainBody'), result.animationClass);
                                    });
                                    $rootScope.animationClass = result.animationClass;
                                }, 1000);
                            }
                            scope.timeouts = [];
                            angular.element(element).ready(function () {
                                if (result.triggerWaitIntervals) {
                                    for (var trigger in result.triggerWaitIntervals) {
                                        scope.timeouts.push(setTimeout(function () {
                                            commonService.get({
                                                    module: 'session',
                                                    action: 'track-timeout-trigger',
                                                    triggerId: trigger
                                                },
                                                function () {
                                                    layoutFacade.refreshJourneyMeter();
                                                });
                                        }, result.triggerWaitIntervals[trigger]));
                                    }
                                }
                            });
                            if (result.journeyRefresh) {
                                layoutFacade.refreshJourneyMeter();
                            }
                        },
                        function () {
                            $rootScope.loading = false;
                            element.html("Access Denied");
                        }
                    );
                };

                scope.loadPageContent();
                var UpdateContent = scope.$on("UpdateContent", scope.loadPageContent);
                element.on('$destroy', UpdateContent);
            }
        };
    }]);

frontendApp.directive('basketTable', ['contentService', '$compile', 'trackingServices', '$log',
    function (contentService, $compile, trackingServices, $log) {
        return {
            restrict: 'A',
            replace: false,
            link: function (scope, element) {

                scope.removeItem = function () {
                    trackingServices.trackAction(scope, 'removeFromBasket');
                };

                scope.openModal = function (itemId, templateId) {
                    trackingServices.trackAction(scope, 'showAddonRecommendations');
                    scope.$emit("showAddonRecommendations", itemId, templateId);
                };

                scope.reload = function () {
                    $log.info('basketTable.show-basket');
                    contentService.get({
                        action: 'show-basket',
                        interaction: scope.saleSessionManager.getInteractionKey()
                    }, function (result) {
                        element.html($compile(result.content)(scope));
                        scope.$emit("refreshMiniBasketSize");
                    });
                };

                scope.$on('updateBasket', function () {
                    scope.reload();
                });

                scope.reload();
            }
        };
    }]);

// @deprecated
frontendApp.directive('basketViewDetailed', ['contentService', '$compile', function (contentService, $compile) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope, element, attrs) {
            contentService.get({action: 'show-view', viewId: attrs.id, id: attrs.offeringId},
                function (result) {
                    scope.view = result;
                    scope.data = scope.view.data.offeringData;
                    element.replaceWith($compile(result.content)(scope));
                });
        }
    };
}]);

frontendApp.directive('basketMiniViewTable', [function () {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        controller: 'BasketMiniTableCtrl',
        link: function () {
        }
    };
}]);

/* Temporary for compatibility with old imnlementation. Used in Server-side generated views. */
frontendApp.directive('draggableItemDetailed', ['dragDropFacade', function (dragDropFacade) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            if (attrs.draggableEnabled != "true") {
                return;
            }

            var ev;

            element.attr("draggable", true);

            scope.$on('CancelScroll', function () {
                ev.preventDefault();
            });

            element.bind("dragstart", function () {
                dragDropFacade.dragAddCompare(createOfferData(attrs, null));
            });

            element.bind("touchstart", function (eventObject) {
                dragDropFacade.dragAddCompare(createOfferData(attrs, eventObject.originalEvent.changedTouches[0]));
            });

            element.bind("touchmove", function (eventObject) {
                ev = eventObject;
                dragDropFacade.moveAddCompare(eventObject.originalEvent.changedTouches[0]);
                //eventObject.preventDefault();
            });
            element.bind("touchcancel", function () {
                dragDropFacade.cancelAddCompare();
            });

            element.bind("touchend", function (eventObject) {
                dragDropFacade.dropAddCompare(eventObject.originalEvent.changedTouches[0], scope);
            });

            function createOfferData(attrs, touchEvent) {
                var data = {};
                data.offeringId = attrs.offeringId;
                data.offeringName = attrs.offeringName;
                data.offeringTypeId = attrs.offeringTypeId;
                data.viewId = attrs.viewId;
                data.time = (new Date()).getTime();
                if (touchEvent) {
                    data.position = {};
                    data.position.pageX = touchEvent.pageX;
                    data.position.pageY = touchEvent.pageY;
                }
                return data;
            }

            scope.$on('markAsSelected', function (event, result) {
                for (var i = 0; i < result.length; i++) {
                    var item = result[i];
                    if (attrs.offeringId === item.offeringId) {
                        if (item.selected) {
                            $(element).addClass("selectedItem");
                        } else {
                            $(element).removeClass("selectedItem");
                        }
                    }
                }
            });
        }
    };
}]);

/* Drag and Drop directive for destination 'Add to Compare'. */
frontendApp.directive('dropTargetCompare', ['dragDropFacade', function (dragDropFacade) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element) {

            element.bind("dragover", function (eventObject) {
                if (scope.startedAddCompare) {
                    eventObject.preventDefault();
                }
            });

            element.bind("dragend", function () {
            });

            element.bind("drop", function () {
                dragDropFacade.dropAddCompare(null, scope);
            });

            element.bind("touchmove", function () {
            });

            scope.$on('dragAddCompare', function () {
                scope.startedAddCompare = true;
            });

            scope.$on('cancelDragAddCompare', function () {
                scope.startedAddCompare = false;
            });
        }
    };
}]);

/* Drag and Drop directive for source to 'Remove from compare' . */
frontendApp.directive('dragRemoveCompare', ['dragDropFacade', function (dragDropFacade) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            element.attr("draggable", true);

            element.bind("dragstart", function () {
                var data = {};
                data.subType = attrs.offeringTypeId;
                data.id = attrs.offeringId;
                dragDropFacade.dragRemoveCompare(data);
            });

            element.bind("touchstart", function () {
                var data = {};
                data.subType = attrs.offeringTypeId;
                data.id = attrs.offeringId;
                dragDropFacade.dragRemoveCompare(data);
            });

            element.bind("touchmove", function (eventObject) {
                dragDropFacade.dropRemoveCompare();
                eventObject.preventDefault();
            });
        }
    };
}]);

/* Drag and Drop directive for destination to 'Remove from compare' . */
frontendApp.directive('dropRemoveCompare', ['dragDropFacade', function (dragDropFacade) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element) {

            element.bind("dragover", function (eventObject) {
                if (scope.startedRemoveCompare) {
                    eventObject.preventDefault();
                }
            });

            element.bind("dragend", function () {
            });

            element.bind("drop", function () {
                dragDropFacade.dropRemoveCompare();
            });

            scope.$on('dragRemoveCompare', function () {
                scope.startedRemoveCompare = true;
            });

            scope.$on('cancelDragRemoveCompare', function () {
                scope.startedRemoveCompare = false;
            });
        }
    };
}]);

frontendApp.directive('myTable', [function () {
    return {
        restrict: 'A',
        link: function (scope, element) {
            var options = {
                "bStateSave": true,
                "iCookieDuration": 2419200, /* 1 month */
                "bJQueryUI": true,
                "bPaginate": false,
                "bLengthChange": false,
                "bFilter": false,
                "bInfo": false,
                "bDestroy": true
            };
            element.dataTable(options);
        }
    };
}]);

// Carousel swipe attribute.
frontendApp.directive('swipe', [function () {
    return {
        require: '^carousel',
        restrict: 'A',
        link: function (scope, element, attrs, ctr) {

            var pageX = 0;
            var beginTime = 0;

            element.bind("touchstart", function (eventObject) {
                pageX = eventObject.originalEvent.changedTouches[0].pageX;
                beginTime = (new Date()).getTime();
            });

            element.bind("touchmove", function () {
                //eventObject.preventDefault();
            });

            element.bind("touchcancel", function () {
                pageX = 0;
                beginTime = 0;
            });

            element.bind("touchend", function (eventObject) {
                if (((new Date()).getTime() - beginTime < 200) || Math.abs(eventObject.originalEvent.changedTouches[0].pageX - pageX) < 30) {
                    return;
                }
                if (eventObject.originalEvent.changedTouches[0].pageX > pageX) {
                    prev();
                } else {
                    next();
                }
            });

            function next() {
                var newIndex = (ctr.indexOfSlide(ctr.currentSlide) + 1) % ctr.slides.length;
                ctr.select(ctr.slides[newIndex], 'next');
            }

            function prev() {
                var currentIndex = ctr.indexOfSlide(ctr.currentSlide);
                var newIndex = currentIndex - 1 < 0 ? ctr.slides.length - 1 : currentIndex - 1;
                ctr.select(ctr.slides[newIndex], 'prev');
            }
        }
    };
}]);

frontendApp.directive('swipe2', [function () {
    return {
        restrict: 'A',
        link: function (scope, element) {

            var pageX = 0;
            var beginTime = 0;

            element.bind("touchstart", function (eventObject) {
                pageX = eventObject.originalEvent.changedTouches[0].pageX;
                beginTime = (new Date()).getTime();
            });

            element.bind("touchmove", function () {
            });

            element.bind("touchcancel", function () {
                pageX = 0;
                beginTime = 0;
            });

            element.bind("touchend", function (eventObject) {
                if (((new Date()).getTime() - beginTime < 200) || Math.abs(eventObject.originalEvent.changedTouches[0].pageX - pageX) < 30) {
                    return;
                }
                if (eventObject.originalEvent.changedTouches[0].pageX > pageX) {
                    prev();
                } else {
                    next();
                }
            });

            function next() {
                element.carousel('next');
            }

            function prev() {
                element.carousel('prev');
            }
        }
    };
}]);

frontendApp.directive('disableNgAnimate', ['$animate', function ($animate) {
    return {
        restrict: 'A',
        link: function (scope, element) {
            $animate.enabled(false, element);
        }
    };
}]);

frontendApp.directive('doubleClick', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            var firstClick = false;
            element.bind("touchstart", function () {
                if (firstClick) {
                    scope.$emit('onClickCell', scope.$eval(attrs.doubleClickView), scope.$eval(attrs.doubleClickLabelx), scope.$eval(attrs.doubleClickLabely));
                    firstClick = false;
                } else {
                    scope.$emit('clickCell', true);
                    firstClick = true;
                    setTimeout(function () {
                        scope.$emit('clickCell', true);
                        firstClick = false;
                    }, 300);
                }
            });
        }
    };
}]);

frontendApp.directive('customerDetails', [function () {
    return {
        restrict: 'A',
        templateUrl: resourceUrl + '/partials/frontend/customer.html'
    };
}]);

frontendApp.directive('shoppingCart', [function () {
    return {
        restrict: 'A',
        templateUrl: resourceUrl + '/partials/frontend/basket.html'
    };
}]);

frontendApp.directive('agentView', [function () {
    return {
        restrict: 'A'
    };
}]);

frontendApp.directive('salesView', [function () {
    return {
        restrict: 'A'
    };
}]);

frontendApp.directive('addOns', [function () {
    return {
        restrict: 'A',
        templateUrl: resourceUrl + '/partials/frontend/addons-for-basket.html'
    };
}]);

frontendApp.directive('compareRecent', [function () {
    return {
        restrict: 'A',
        controller: 'RecentCtrl',
        templateUrl: resourceUrl + '/partials/frontend/compare.html'
    };
}]);

// Know AngularJS issue https://github.com/angular/angular.js/issues/7986
frontendApp.directive('forceBlur', [function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {

            function callBlur() {
                $(element).blur();
            }

            if (ctrl && ctrl.$viewChangeListeners) {
                ctrl.$viewChangeListeners.push(callBlur);
            }
        }
    };
}]);


frontendApp.directive('swipeRpm', ['$rootScope', 'CUSTOM_EVENTS', function ($rootScope, CUSTOM_EVENTS) {
    return {
        restrict: 'AC',
        link: function (scope, element) {
            var pageX = 0;
            var beginTime = 0;
            var swipe = false;

            element.bind("touchstart", function (eventObject) {
                $rootScope.log = "touchstart";
                pageX = eventObject.originalEvent.changedTouches[0].pageX;
                beginTime = (new Date()).getTime();
            });

            element.bind("touchmove", function () {
            });

            element.bind("touchcancel", function () {
                $rootScope.log = "";
                pageX = 0;
                beginTime = 0;
            });

            element.bind("touchend", function (eventObject) {
                $rootScope.log = $rootScope.log + ' ' + "touchend";
                if (((new Date()).getTime() - beginTime < 200) || Math.abs(eventObject.originalEvent.changedTouches[0].pageX - pageX) < 30) {
                    return;
                }
                if (eventObject.originalEvent.changedTouches[0].pageX > pageX) {
                    scope.$emit(CUSTOM_EVENTS.RPM_SWIPE_LEFT);
                } else {
                    scope.$emit(CUSTOM_EVENTS.RPM_SWIPE_RIGHT);
                }
            });

            element.bind("pointerdown", function (eventObject) {
                $rootScope.log = "pointerdown";
                pageX = eventObject.originalEvent.clientX;
                beginTime = (new Date()).getTime();
                swipe = true;
            });

            element.bind("pointerup", function () {
                $rootScope.log = $rootScope.log + ' ' + "pointerup";
                swipe = false;
            });

            element.bind("pointerleave", function () {
                $rootScope.log = $rootScope.log + ' ' + "pointerleave";
            });
            element.bind("pointerout", function () {
                $rootScope.log = $rootScope.log + ' ' + "pointerout";
            });
            element.bind("pointerover", function (eventObject) {
                $rootScope.log = $rootScope.log + ' ' + "pointerover";
                //console.log("pointerover");
                if (!swipe) {
                    return;
                }
                if (((new Date()).getTime() - beginTime < 200) || Math.abs(eventObject.originalEvent.clientX - pageX) < 50) {
                    return;
                }
                swipe = false;
                if (eventObject.originalEvent.clientX > pageX) {
                    scope.$emit(CUSTOM_EVENTS.RPM_SWIPE_LEFT);
                } else {
                    scope.$emit(CUSTOM_EVENTS.RPM_SWIPE_RIGHT);
                }
            });
        }
    };
}]);

frontendApp.directive('buildBundle', [function () {
    return {
        restrict: 'A',
        templateUrl: resourceUrl + '/partials/frontend/buildBundle.html'
    };
}]);

frontendApp.directive('buildBundleTable', ['contentService', 'sessionService', '$compile', 'trackingServices', '$modal',
    function (contentService, sessionService, $compile, trackingServices, $modal) {
        return {
            restrict: 'A',
            replace: false,
            link: function (scope, element) {

                scope.addAdditionalDevice = function () {
                    $modal.open({
                        templateUrl: resourceUrl + '/partials/frontend/addToBundle.html',
                        backdrop: 'static',
                        windowClass: 'add-to-bundle-modal',
                        scope: scope,
                        controller: ['$scope', '$modalInstance',
                            function ($scope, $modalInstance) {
                                $scope.page = {};
                                //$scope.attrs = $scope.content;
                                //$scope.$parent.attrs = $scope.content;

                                $scope.onCloseButtonClick = function () {
                                    $modalInstance.close();
                                    $scope.$emit('reloadBuildBundle');
                                };
                            }
                        ]
                    });
                };

                scope.reload = function () {
                    contentService.get({
                        action: 'show-build-bundle',
                        interaction: scope.saleSessionManager.getInteractionKey()
                    }, function (result) {
                        element.html($compile(result.string)(scope));
                    });
                };

                scope.$on('reloadBuildBundle', function () {
                    scope.reload();
                });

                scope.removeItem = function (templateId, itemId) {
                    trackingServices.trackAction(scope, 'removeFromBundle');
                    sessionService.get({action: 'basket-remove', templateId: templateId, id: itemId},
                        function () {
                            scope.reload();
                            scope.$emit('updateBasketContent');
                        }
                    );
                };
            }
        };
    }]);

frontendApp.directive('progressMeterSprite', ['$compile', 'commonService',
    function ($compile, commonService) {
        return {
            restrict: 'A',
            replace: true,
            link: function (scope, element) {
                var load = function () {
                    if (scope.saleSessionManager.isSessionStarted()) {
                        commonService.get({
                                module: 'journey',
                                action: 'refresh-meter',
                                interaction: scope.saleSessionManager.getInteractionKey()
                            },
                            function (result) {
                                if (result.string) {
                                    element.html($compile(result.string)(scope));
                                }
                            }
                        );
                    } else {
                        element.html($compile("<div></div>")(scope));
                    }
                };

                element.bind('$destroy', scope.$on('JourneyMeterRefresh', load));

                load();
            }
        };
    }]);

frontendApp.directive('salesJourneyStats', ['$http', '$compile', 'commonService', 'drawChartService',
    function ($http, $compile, commonService, drawChartService) {
        return {
            restrict: 'A',
            replace: true,
            link: function (scope, element) {

                var reportType;
                var reportPeriod;

                var canvas;
                var ctx;

                commonService.get({
                        module: 'journey',
                        action: 'journey-stats',
                        interaction: scope.saleSessionManager.getInteractionKey()
                    },
                    function (result) {
                        var e = $compile(result.string)(scope);
                        element.replaceWith(e);
                        canvas = document.getElementById("cs");
                        if (canvas) {
                            if (!canvas.getContext) {
                                G_vmlCanvasManager.initElement(canvas);
                            }
                            ctx = canvas.getContext("2d");
                        }
                    }
                );

                scope.selectReportType = function (type) {
                    reportType = type;
                    if (reportType && reportPeriod) {
                        refreshBody(reportType, reportPeriod);
                    }
                };

                scope.selectReportPeriod = function (period) {
                    reportPeriod = period;
                    if (reportType && reportPeriod) {
                        refreshBody(reportType, reportPeriod);
                    }
                };

                function refreshBody(reportType, reportPeriod) {
                    commonService.get({
                            module: 'journey',
                            action: 'journey-stats-body',
                            report_type: reportType,
                            time_period: reportPeriod
                        },
                        function (result) {
                            drawChartService.drawChart(canvas, ctx, result);
                        }
                    );
                }
            }
        };
    }]);

frontendApp.directive('customHeader',
    ['FORM_FILED_ROLES', 'commonService', '$compile', '$routeParams', 'attributesTool',
        function (FORM_FILED_ROLES, commonService, $compile, $routeParams, attributesTool) {

            return {
                restrict: 'A',
                replace: true,
                scope: true,
                template: '<div></div>',
                link: function (scope, element) {

                    scope.$on('loginSuccessEvt', function () {
                        scope.updateContent();
                    });

                    scope.$on('logoutSuccessEvt', function () {
                        var header = $('.headerBody');
                        header.css('display', 'none');
                    });

                    scope.updateContent = function () {
                        var request = attributesTool.buildAttributes($routeParams);
                        request['module'] = 'call-center';
                        request['action'] = 'header';
                        commonService.get(request,
                            function (result) {
                                if (result.string) {
                                    var e = $compile(result.string)(scope);
                                    e.addClass("headerBody");
                                    element.html(e);
                                    var header = $('.headerBody');
                                    header.css('display', 'block');
                                }
                            }
                        );
                    };
                }
            };
        }]);

frontendApp.directive('customFooter',
    ['FORM_FILED_ROLES', 'commonService', '$compile', '$routeParams', 'attributesTool',
        function (FORM_FILED_ROLES, commonService, $compile, $routeParams, attributesTool) {

            return {
                restrict: 'A',
                replace: true,
                scope: true,
                template: '<div></div>',
                link: function (scope, element) {

                    scope.$on('loginSuccessEvt', function () {
                        updateContent();
                    });

                    scope.$on('logoutSuccessEvt', function () {
                        element.html($compile("")(scope));
                    });

                    function updateContent() {
                        var request = attributesTool.buildAttributes($routeParams);
                        request['module'] = 'call-center';
                        request['action'] = 'footer';
                        commonService.get(request,
                            function (result) {
                                if (result.string) {
                                    var e = $compile(result.string)(scope);
                                    element.replaceWith(e);
                                    element = e;
                                }
                            }
                        );
                    }
                }
            };
        }]);

frontendApp.directive('customLeftMenu',
    ['FORM_FILED_ROLES', 'commonService', '$compile', '$routeParams', 'attributesTool',
        function (FORM_FILED_ROLES, commonService, $compile, $routeParams, attributesTool) {

            return {
                restrict: 'A',
                replace: true,
                scope: true,
                template: '<div></div>',
                link: function (scope, element) {
                    scope.leftPanelMinimized = false;
                    scope.leftPanelShown = false;

                    scope.$on('toggleLeftMenu', function () {
                        var mb = $('.mainBody');
                        if (!scope.leftPanelShown) {
                            mb.addClass('showLeft');
                            scope.leftPanelShown = true;
                            scope.leftPanelMinimized = false;
                        } else {
                            scope.leftPanelMinimized = !scope.leftPanelMinimized;

                            if (scope.leftPanelMinimized) {
                                mb.removeClass('showLeft');
                                mb.addClass('showLeftMin');
                            } else {
                                mb.addClass('showLeft');
                                mb.removeClass('showLeftMin');
                            }
                        }
                    });

                    scope.$on('HideLeftMenu', function () {
                        var mb = $('.mainBody');
                        mb.removeClass('showLeftMin');
                        mb.removeClass('showLeft');
                        scope.leftPanelMinimized = false;
                        scope.leftPanelShown = false;
                    });

                    scope.$on('MinimizeLeftMenu', function () {
                        var mb = $('.mainBody');
                        scope.leftPanelShown = true;
                        scope.leftPanelMinimized = true;
                        mb.removeClass('showLeft');
                        mb.addClass('showLeftMin');
                    });

                    scope.$on('loginSuccessEvt', function () {
                        updateContent();
                    });

                    scope.$on('logoutSuccessEvt', function () {
                        element.html($compile("")(scope));
                    });

                    function updateContent() {
                        var request = attributesTool.buildAttributes($routeParams);
                        request['module'] = 'call-center';
                        request['action'] = 'left-menu';
                        commonService.get(request,
                            function (result) {
                                if (result.string) {
                                    var e = $compile(result.string)(scope);
                                    element.replaceWith(e);
                                    element = e;
                                }
                            }
                        );
                    }
                }
            };
        }]);

frontendApp.directive('customRightMenu',
    ['FORM_FILED_ROLES', 'commonService', '$compile', '$routeParams', 'attributesTool',
        function (FORM_FILED_ROLES, commonService, $compile, $routeParams, attributesTool) {

            return {
                restrict: 'A',
                replace: true,
                scope: true,
                template: '<div></div>',
                link: function (scope, element) {
                    scope.rightPanelShown = false;

                    scope.$on('toggleRightMenu', function (event, param) {
                        if (param != false) {
                            scope.rightPanelShown = !scope.rightPanelShown;
                            $('.mainBody').toggleClass('showRight');
                            $('.feedback').toggle();
                            if (scope.rightPanelShown) {
                                //todo: remove call
                                scope.$broadcast('JourneyMeterRefresh');
                                scope.$broadcast('refreshRecentItems');
                            }
                        }
                    });

                    scope.$on('HideRightMenu', function () {
                        var mb = $('.mainBody');
                        mb.removeClass('showRight');
                        scope.rightPanelShown = false;
                    });

                    scope.$on('loginSuccessEvt', function () {
                        updateContent();
                    });

                    scope.$on('logoutSuccessEvt', function () {
                        element.html($compile("")(scope));
                    });

                    function updateContent() {
                        var request = attributesTool.buildAttributes($routeParams);
                        request['module'] = 'call-center';
                        request['action'] = 'right-menu';
                        commonService.get(request,
                            function (result) {
                                if (result.string) {
                                    var e = $compile(result.string)(scope);
                                    element.replaceWith(e);
                                    element = e;
                                }
                            }
                        );
                    }
                }
            };
        }]);

// @deprecated
frontendApp.directive('currentUserField', ['$rootScope', function ($rootScope) {
    return {
        restrict: 'A',
        replace: true,
        template: "",
        link: function (scope, element, attrs) {
            var key = attrs.name.replace(new RegExp(String.fromCharCode(32), 'g'), String.fromCharCode(160));
            if (angular.isDefined($rootScope.user) && angular.isDefined($rootScope.user.fields)) {
                if (angular.isDefined($rootScope.user.fields[key])) {
                    element.html(angular.copy($rootScope.user.fields[key]));
                } else if (angular.isDefined($rootScope.user.fields[attrs.name])) {
                    element.html(angular.copy($rootScope.user.fields[attrs.name]));
                }
            }
        }
    };
}]);

// @deprecated
frontendApp.directive('currentLocationField', ['$rootScope', function ($rootScope) {
    return {
        restrict: 'A',
        replace: true,
        template: "",
        link: function (scope, element, attrs) {
            var key = attrs.name.replace(new RegExp(String.fromCharCode(32), 'g'), String.fromCharCode(160));
            if (angular.isDefined($rootScope.locationInfo) && angular.isDefined($rootScope.locationInfo.fields)) {
                if (angular.isDefined($rootScope.locationInfo.fields[key])) {
                    element.html(angular.copy($rootScope.locationInfo.fields[key]));
                } else if (angular.isDefined($rootScope.locationInfo.fields[attrs.name])) {
                    element.html(angular.copy($rootScope.locationInfo.fields[attrs.name]));
                }
            }
        }
    };
}]);

frontendApp.directive('industryMenu', ['contentService', '$rootScope', '$location', 'trackingServices', 'layoutFacade',
    function (contentService, $rootScope, $location, trackingServices, layoutFacade) {

        return {
            restrict: 'A',
            replace: true,
            scope: true,
            link: function (scope) {

                var collapsedIndustries = [];

                scope.toggleIndustryCollapsed = function (industryId) {
                    var index = -1;
                    for (var ind in collapsedIndustries) {
                        if (collapsedIndustries.hasOwnProperty(ind)
                            && industryId === collapsedIndustries[ind]) {
                            index = ind;
                        }
                    }
                    if (index == -1) {
                        collapsedIndustries.push(industryId);
                    } else {
                        collapsedIndustries.splice(index, 1);
                    }
                };

                scope.isIndustryCollapsed = function (industryId) {
                    var index = -1;
                    for (var ind in collapsedIndustries) {
                        if (collapsedIndustries.hasOwnProperty(ind)
                            && industryId === collapsedIndustries[ind]) {
                            index = ind;
                        }
                    }
                    return (index != -1);
                };

                scope.minimizeAndOpen = function (url, eventLabel) {
                    layoutFacade.hideMiniBasket();
                    if (!scope.leftPanelMinimized) {
                        scope.$emit("MinimizeLeftMenu");
                    }
                    if (eventLabel) {
                        trackingServices.trackAnalytics(eventLabel);
                    }
                    if (url.indexOf('http') === 0) {
                        window.top.location.href = url;
                    } else {
                        $location.path(url);
                    }
                };

                scope.isActivePageTag = function (tag) {
                    if ($rootScope.activePageTag) {
                        return $rootScope.activePageTag === tag;
                    }
                    return false;
                };
            }
        };
    }]);

frontendApp.directive('micrositeChecker', ['$rootScope', '$interval', '$compile', 'commonService',
    '$window', 'CUSTOMER_CARE_ACCESS_TYPES', '$q', 'cometd', '$log',
    function ($rootScope, $interval, $compile, commonService, $window, CUSTOMER_CARE_ACCESS_TYPES, $q, cometd, $log) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            link: function (scope, element, attrs) {

                $log.debug("Start micrositeChecker for " + scope.$id);

                scope.customerCareInfo = {};
                scope.customerCareInfo.micrositeId = attrs.micrositeChecker;
                scope.interaction = scope.saleSessionManager.getInteractionKey();
                scope.hasControl = CUSTOMER_CARE_ACCESS_TYPES.CUSTOMER;
                scope.$on("updateAccessType", function (event, type) {
                    getContent(scope, false);
                    setBlock(type, element);
                });

                scope.$on("getContent", function () {
                    getContent(scope, true);
                });

                function cometContent() {
                    var subscriptions = [];
                    $log.debug("Subscribe on " + '/care/page/' + scope.interaction);
                    subscriptions.push(cometd.subscribe('/care/page/' + scope.interaction, function (message) {
                        if (scope.hasControl != CUSTOMER_CARE_ACCESS_TYPES.AGENT) {
                            $log.debug("Incoming message from " + message.channel);
                            getContent(scope, false);
                        }
                    }));
                    $log.debug("Subscribe on " + '/care/fieldUpdated/' + scope.interaction);
                    subscriptions.push(cometd.subscribe('/care/fieldUpdated/' + scope.interaction, function (message) {
                        if (scope.hasControl != CUSTOMER_CARE_ACCESS_TYPES.AGENT) {
                            $log.debug("Incoming message from " + message.channel + " " + message.data.fieldName + " = " + message.data.fieldValue);
                            $rootScope.$broadcast("customerCareFormUpdate", message.data);
                        }
                    }));
                    return subscriptions;
                }

                function getContent(scope, reload) {
                    if (scope.hasControl != CUSTOMER_CARE_ACCESS_TYPES.AGENT || reload == true) {
                        var width = $window.outerWidth || $window.document.documentElement.clientWidth;
                        commonService.get({
                                module: 'content',
                                action: 'customer-care-content',
                                width: width,
                                templateId: scope.customerCareInfo.micrositeId ? scope.customerCareInfo.micrositeId : "",
                                interaction: scope.saleSessionManager.getInteractionKey()
                            },
                            function (result) {
                                $log.debug("Update micrositeChecker content for " + scope.$id);
                                $log.debug("Element: " + element.contents());
                                $log.debug("New content: " + result.string);
                                element.html($compile(result.string)(scope));
                                if (reload) {
                                    cometd.publish('/care/page/' + scope.saleSessionManager.getInteractionKey(), {});
                                }
                            },
                            function () {
                                element.html("Access Denied");
                            }
                        );
                    }
                }

                function setBlock(type, element) {
                    if (type) {
                        scope.hasControl = type;
                        if (scope.hasControl != CUSTOMER_CARE_ACCESS_TYPES.AGENT) {
                            if (!$(element).hasClass("agent-mirror")) {
                                $(element).addClass("agent-mirror");
                            }
                        } else {
                            if ($(element).hasClass("agent-mirror")) {
                                $(element).removeClass("agent-mirror");
                            }
                        }
                    }
                }

                element.on('$destroy', function () {
                    if (scope.subscriptions) {
                        for (var ind in scope.subscriptions) {
                            if (scope.subscriptions.hasOwnProperty(ind)) {
                                var subscription = scope.subscriptions[ind];
                                $log.debug("Unsubscribe " + subscription.channel);
                                cometd.unsubscribe(subscription);
                            }
                        }
                        delete scope.subscriptions;
                    }
                });

                scope.$on('fieldChanged', function (event, viewId, fieldName, fieldValue, isCollection) {
                    if (scope.hasControl == CUSTOMER_CARE_ACCESS_TYPES.AGENT) {
                        cometd.publish("/care/fieldUpdated/" + scope.interaction, {
                            viewId: viewId,
                            fieldName: fieldName,
                            fieldValue: fieldValue,
                            isCollection: isCollection,
                            sessionId: scope.interaction,
                            clientId: $rootScope.user.channelClientId,
                            micrositeId: scope.customerCareInfo.micrositeId
                        });
                    }
                });

                cometd.connect(function () {
                    scope.subscriptions = cometContent();
                });
                getContent(scope);
            }
        };
    }]);

 frontendApp.directive('controlCustomerCare', ['$rootScope', 'commonService', '$interval', 'CUSTOMER_CARE_ACCESS_TYPES', 'cometd', '$log',
    function ($rootScope, commonService, $interval, CUSTOMER_CARE_ACCESS_TYPES, cometd, $log) {
        return {
            restrict: 'A',
            scope: true,
            template: '<div><div ng-click="execute($event)" class="btn btn-primary btn-lg" ng-disabled="hasControl == \'AGENT_REQUEST\'">{{title}}</div>' +
            '<div ng-show="hasControl == \'AGENT_REQUEST\'">Wait for customer decision.</div>' +
            '</div>',
            link: function (scope, element, attrs) {
                scope.title = "Get Access";
                scope.hasControl = CUSTOMER_CARE_ACCESS_TYPES.CUSTOMER;
                scope.websiteTemplateId = attrs.controlCustomerCare;

                scope.execute = function ($event) {
                    $event.stopPropagation();
                    var interaction = scope.saleSessionManager.getInteractionKey();
                    var clientId = scope.user.channelClientId;
                    var accessType = scope.hasControl == CUSTOMER_CARE_ACCESS_TYPES.CUSTOMER
                        ? CUSTOMER_CARE_ACCESS_TYPES.AGENT_REQUEST : CUSTOMER_CARE_ACCESS_TYPES.CUSTOMER;
                    $log.debug("Sending /care/setAccessType/ for " + clientId + "/" + interaction + ": " + accessType + ": " + scope.websiteTemplateId);
                    cometd.publish('/care/setAccessType/' + clientId + '/' + interaction + "/" + scope.websiteTemplateId, {
                        ccAccessType: accessType
                    });
                };

                element.on('$destroy', function () {
                    if (scope.subscription) {
                        $log.debug("Unsubscribe " + scope.subscription.channel);
                        cometd.unsubscribe(scope.subscription);
                        delete scope.subscription;
                    }
                    scope.$destroy();
                });

                function subscribe(scope) {
                    var interaction = scope.saleSessionManager.getInteractionKey();
                    $log.debug("Subscribe on" + '/care/hasAccess/' + interaction + ": " + scope.websiteTemplateId);
                    return cometd.subscribe('/care/hasAccess/' + interaction + "/" + scope.websiteTemplateId, function (message) {
                        $log.debug("Incoming message from " + message.channel);
                        if (message.data.status == CUSTOMER_CARE_ACCESS_TYPES.AGENT) {
                            scope.title = "Pass Access";
                        } else if (message.data.status == CUSTOMER_CARE_ACCESS_TYPES.CUSTOMER) {
                            scope.title = "Get Access";
                        }
                        scope.hasControl = message.data.status;
                        $rootScope.$broadcast("updateAccessType", scope.hasControl);
                    });
                }

                scope.subscription = subscribe(scope);
            }
        };
    }]);

frontendApp.directive('floatingPanelContent', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope, element, attrs) {
            scope.$on(attrs.listen, function (event, content) {
                element.html(content);
                $compile(element.contents())(scope);
            });
        }
    };
}]);


frontendApp.directive('sharedActiveSession', ['commonService', function (commonService) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        template: '<div>Now viewing user: <div>{{id}}</div><div>{{firstName}}</div><div>{{lastName}}</div></div>',
        link: function (scope) {
            scope.id = "";
            scope.firstName = "";
            scope.lastName = "";
            var interaction = scope.saleSessionManager.getInteractionKey();
            commonService.get({
                    module: 'session',
                    action: 'get-session-info',
                    interaction: interaction
                },
                function (result) {
                    scope.id = result.id;
                    scope.firstName = result.firstName;
                    scope.lastName = result.firstName;
                });
        }
    };
}]);

frontendApp.directive('connectionLost', ['$interval', '$http', 'lockOperationsFacade', function ($interval, $http, lockOperationsFacade) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope) {
            scope.connectionLost = false;

            $interval(function () {
                var config = {
                    method: 'GET',
                    url: location.protocol + '//' + location.host,
                    responseType: 'text/plain'
                };
                $http(config)
                    .success(
                        function () {
                            scope.connectionLost = false;
                        }).error(function () {
                    scope.connectionLost = true;
                    lockOperationsFacade.unlock(true);
                });
            }, 300000);

            scope.$on('connectionLost', function () {
                scope.connectionLost = true;
                lockOperationsFacade.unlock(true);
            });

            scope.$on('connectionBack', function () {
                scope.connectionLost = false;
            });

            scope.ok = function () {
                scope.connectionLost = false;
            };

            scope.getConnectionLostStatus = function () {
                return scope.connectionLost;
            };
        }
    };
}]);

frontendApp.directive('capturePhotoWebCam', ['commonService', '$location', 'processFacade', 'cometd', '$route', 'lockOperationsFacade', '$log', '$window',
    function (commonService, $location, processFacade, cometd, $route, lockOperationsFacade, $log, $window) {
        return {
            restrict: 'A',
            replace: false,
            transclude: true,
            scope: true,
            template: '<div id="my_camera" style="width:640px; height:480px;" class="videoArea" ></div>' +
            ' <div class="snapButArea" ng-show="!snapImage.showImg">' +
            '     <div class="snapButAreaValigner1">' +
            '         <div class="snapButAreaValigner2">' +
            '             <div ng-click="takeSnapshot($event)" class="takeSnapBut"><span title="Take Snapshot"></span></div>' +
            '             <div class="snapButAreaText1">Line up the customers face and press anywhere on the screen to take a photo</div>' +
            '         </div>' +
            '     </div>' +
            ' </div>' +
            ' <div class="snapButArea snapButAreaLeft" ng-show="!snapImage.showImg">' +
            '     <div class="snapButAreaValigner1">' +
            '         <div class="snapButAreaValigner2">' +
            '             <div ng-click="takeSnapshot($event)" class="takeSnapBut"><span title="Take Snapshot"></span></div>' +
            '             <div class="snapButAreaText1">Line up the customers face and press anywhere on the screen to take a photo</div>' +
            '         </div>' +
            '     </div>' +
            ' </div>' +
            ' <div id="my_result" class="resultPicArea" ng-show="snapImage.showImg"></div>' +
            ' <div class="applyButsArea" ng-show="snapImage.showImg">' +
            '     <div class="applyButsAreaIn">' +
            '         <button ng-click="applyImage($event)" class="btn btn-lg btn-apply">Apply</button>' +
            '         <button ng-click="oneMore($event)" class="btn btn-lg btn-more">One More</button>' +
            '     </div>' +
            ' </div>' +
            ' <script type="text/javascript">' +
            '     Webcam.attach("#my_camera");' +
            ' </script>',
            compile: function () {
                return {
                    pre: function () {
                    },
                    post: function (scope, element) {
                        scope.angle = 0;

                        var orientations = {
                            'landscape-primary': 0,
                            'portrait-primary': 90,
                            'landscape-secondary': 180,
                            'portrait-secondary': -90
                        };

                        window.localStorage.setItem('visited', 2);

                        Webcam.set({
                            width: 640,
                            height: 480,
                            dest_width: 640,
                            dest_height: 480,
                            image_format: "png",
                            jpeg_quality: 90
                        });

                        Webcam.setSnapFunction(function (data_uri) {
                            document.getElementById('my_result').innerHTML = '<img src="' + data_uri + '"/>';
                            scope.snapImage.src = data_uri;
                            scope.snapImage.showImg = true;
                            //scope.$apply();
                        });

                        scope.takeSnapshot = function ($event) {
                            $event.stopPropagation();
                            Webcam.snap(Webcam.snapFunction);
                        };

                        scope.rotate = function () {
                            var orientation = screen.orientation ? screen.orientation : screen.msOrientation;
                            $log.debug("Orientation changed to " + orientation);
                            scope.angle = orientations[orientation];
                            $log.debug("Orientation angle is " + scope.angle);
                            Webcam.rotate(scope.angle);
                        };

                        Webcam.on('live', function () {
                            if (!scope.snapImage.flashPlayer) {
                                scope.snapImage.showFace = true;
                               // scope.$apply();
                            }
                            scope.rotate();
                        });

                        Webcam.on('flashLoad', function () {
                            scope.snapImage.showFace = false;
                            scope.snapImage.flashPlayer = true;
                            //scope.$apply();
                        });

                        if ($window.matchMedia) {
                            var mql = $window.matchMedia("(orientation: portrait)");
                            mql.addListener(scope.rotate);
                            scope.isListening = true;
                            element.on('$destroy', function () {
                                if (scope.isListening) {
                                    scope.isListening = false;
                                    mql.removeListener(scope.rotate);
                                    Webcam.reset();
                                }
                            });
                        }

                        scope.applyImage = function ($event) {
                            $event.stopPropagation();
                            lockOperationsFacade.lock();
                            Webcam.upload(scope.snapImage.src, '/service/upload/upload-image?interaction=' + scope.saleSessionManager.getInteractionKey(),
                                function (code, result) {
                                    if (code === 200 && scope.defaultActionId) {
                                        scope.$emit("imageUploaded", result);
                                    } else {
                                        lockOperationsFacade.unlock(true);
                                        //scope.$apply();
                                    }
                                }
                            );
                        };

                        scope.oneMore = function ($event) {
                            $event.stopPropagation();
                            scope.snapImage.showImg = false;
                            scope.snapImage.snapImage = null;
                        };

                        scope.$on('takeSnapshot', function () {
                            Webcam.snap(Webcam.snapFunction);
                        });
                    }
                };
            }
        };
    }]);

frontendApp.directive('capturePhotoIos', ['commonService', 'lockOperationsFacade',
    function (commonService, lockOperationsFacade) {
        return {
            restrict: 'A',
            transclude: true,
            replace: false,
            scope: true,
            template: '' +
            '<div class="iosLayout">' +
            ' <div class="noImage" ng-show="!snapImage.showImg">' +
            '   <div class="imageAreaCaption"><div class="mainCaption">Take a photo then resize it so the customers face fits within the screen. Don&prime;t forget to remove your glasses.</div><div class="subCaption">&nbsp;</div></div>' +
            '   <div class="imageArea" width="640" height="480"></div>' +
            '   <div class="snapButArea">' +
            '       <div class="snapButAreaValigner1">' +
            '         <div class="snapButAreaValigner2">' +
            '           <div class="takePictureBox">             ' +
            '              <input type="file" id="photoCam" capture="camera" accept="image/*" id="takePictureField" ng-file-select="onImageSelect($files)"/>' +
            '              <label label:for="photoCam">Take photo</label>' +
            '           </div>' +
            '           <div class="picRotate">Rotate photo</div>' +
            '           <div class="picReset disabled">Reset photo</div>' +
            '         </div>' +
            '       </div>' +
            '   </div>' +
            ' </div>' +
            ' <div class="hasImage" ng-show="snapImage.showImg">' +
            '   <div class="imageAreaCaption">' +
            '     <div class="mainCaption">Resize and reposition the photo so that the face fits within screen.</div>' +
            '     <div class="subCaption">Pinch with two fingers to resize the photo, drag the photo to reposition.</div>' +
            '   </div>' +
            '   <div class="imageArea" width="640" height="480" style="position:relative;">' +
            '     <canvas id="capturedPhoto" drawing width="640" height="480"></canvas>' +
            '   </div>' +
            '   <div class="snapButArea">' +
            '     <div class="snapButAreaValigner1">' +
            '         <div class="snapButAreaValigner2">' +
            '           <div class="takePictureBox">' +
            '              <input type="file" id="photoCam" capture="camera" accept="image/*" id="takePictureField" ng-file-select="onImageSelect($files)"/>' +
            '              <label label:for="photoCam">Retake photo</label>' +
            '           </div>' +
            '           <div class="picRotate" ng-click="rotate()">Rotate photo</div>' +
            '           <div class="picReset" ng-click="reset()" ng-disabled="!snapImage.changes" ng-class="{\'disabled\' : !snapImage.changes}">Reset photo</div>' +
            '         </div>' +
            '     </div>' +
            '   </div>' +
            '   <div class="imageGood" ng-show="snapImage.showImg">' +
            '     <div class="imageGoodIn">' +
            '         <div class="imageGoodQuest">Can you clearly see the outline of your face?</div><button ng-click="applyImage()" class="btn btn-lg">Yes, continue</button>' +
            '     </div>' +
            '   </div>' +
            ' </div>' +
            '</div>',
            compile: function () {
                return {
                    pre: function (scope) {
                        scope.slider = {
                            lower: 1,
                            lowerLabel: "<strong>1</strong>",
                            higher: 10,
                            higherLabel: "<strong>10</strong>",
                            valueLabel: "",
                            step: 1
                        };
                    },
                    post: function (scope) {
                        scope.photoCanvas = document.getElementById("capturedPhoto");
                        scope.onImageSelect = function ($files) {
                            var $file = $files[0];
                            if ($file.type.indexOf('image') > -1) {
                                var windowURL = window.URL || window.webkitURL;
                                scope.snapImage.src = windowURL.createObjectURL($file);
                                scope.createImageObject();
                            }
                        };

                        scope.createImageObject = function () {
                            scope.photo = new Image();
                            scope.photo.onload = function () {
                                scope.angle = 0;
                                switch (window.orientation) {
                                    case 0:
                                        scope.angle = 90;
                                        break;
                                    case -90:
                                        scope.angle = 180;
                                        break;
                                    case 180:
                                        scope.angle = -90;
                                        break;
                                    case 90:
                                        scope.angle = 0;
                                        break;
                                }
                                scope.$broadcast("initialDraw", scope.snapImage.src);
                            };
                            scope.snapImage.showImg = true;
                            scope.photo.src = scope.snapImage.src;
                        };


                        scope.applyImage = function () {
                            lockOperationsFacade.lock();
                            Webcam.upload(scope.photoCanvas.toDataURL(), '/service/upload/upload-image?interaction=' + scope.saleSessionManager.getInteractionKey(),
                                function (code, result) {
                                    if (code === 200 && scope.defaultActionId) {
                                        scope.$emit("imageUploaded", result);
                                    } else {
                                        lockOperationsFacade.unlock(true);
                                        //scope.$apply();
                                    }
                                }
                            );
                        };

                        scope.oneMore = function () {
                            scope.snapImage.showImg = false;
                            scope.snapImage.snapImage = null;
                            scope.photo = null;
                            scope.snapImage.src = "";
                            scope.$broadcast("clearImage");
                        };

                        scope.rotate = function () {
                            scope.angle += 90;
                            if (scope.angle == 360) {
                                scope.angle = 0;
                            }
                            scope.$broadcast("rotateImageOn90");
                        };

                        scope.reset = function () {
                            scope.$broadcast("resetPhoto");
                        };

                        scope.setChanges = function (changes) {
                            scope.snapImage.changes = changes;
                        };
                    }
                };
            }
        };
    }]);

frontendApp.directive("capturePhoto", ['$location', '$route', 'processFacade', 'cometd', '$window', 'actions-manager', 'lockOperationsFacade',
    function ($location, $route, processFacade, cometd, $window, executor, lockOperationsFacade) {
        return {
            restrict: 'A',
            replace: false,
            transclude: true,
            scope: true,
            template: '<div> <div ng-if="iOS"  class="ios-box">' +
            '<div capture-photo-ios  ></div>' +
            '</div>' +
            '<div ng-if="!iOS"   class="not-ios-box" ng-click="snapPhoto($event)">' +
            '<div capture-photo-web-cam  ></div>' +
            '</div>' +
            ' <div class="faceContourArea" ng-class="{\'iosFace\': iOS}" ng-transclude ng-show="snapImage.showFace" style="pointer-events:none;">' +
            '     /*frame content goes here*/' +
            ' </div>',
            compile: function () {
                return {
                    pre: function (scope) {
                        scope.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                        scope.snapImage = {
                            src: '',
                            showImg: false,
                            showFace: scope.iOS,
                            flashPlayer: false,
                            changes: false
                        };
                    },
                    post: function (scope, element, attrs) {
                        scope.imageName = attrs.imageName;
                        scope.defaultActionId = attrs.defaultActionId;
                        scope.$on("showFace", function () {
                            scope.snapImage.showFace = true;
                        });

                        scope.snapPhoto = function ($event) {
                            if (!scope.snapImage.showImg) {
                                $event.stopPropagation();
                                scope.$broadcast("takeSnapshot");
                            }
                        };

                        scope.snapPhotoByKey = function ($event) {
                            if (!scope.snapImage.showImg) {
                                $event.stopPropagation();
                                if (13 == $event.keyCode) {
                                    scope.$broadcast("takeSnapshot");
                                }
                            }
                        };

                        angular.element($window).on('keypress', scope.snapPhotoByKey);

                        element.on('$destroy', function () {
                            angular.element($window).off('keypress', scope.snapPhotoByKey);
                        });


                        scope.$on("imageUploaded", function (event, result) {
                            var processDefinition = scope.processInfo ? scope.processInfo.processDefinitionId : null;
                            var micrositeId = scope.customerCareInfo && scope.customerCareInfo.micrositeId ? scope.customerCareInfo.micrositeId : "";
                            var parameters = {
                                processDefinitionId: processDefinition,
                                microsite: micrositeId,
                                submit: true
                            };
                            var res = eval('(' + result + ')');
                            parameters[scope.imageName ? scope.imageName : 'imageName'] = res.string;
                            var viewId = scope.viewId ? scope.viewId : attrs.viewId;
                            executor.submitForm(scope, viewId, scope.defaultActionId, parameters)
                                .then(function () {
                                    lockOperationsFacade.unlock(true);
                                });
                            /*executionServices.execute(scope, {
                                    actionId: scope.defaultActionId,
                                    viewId: scope.viewId,
                                    parameters: parameters
                                },
                                function (result) {
                                    if (result.path && result.path != null) {
                                        if (scope.processInfo && scope.processInfo.processDefinitionId) {
                                            if (result.path.indexOf('http') === 0) {
                                                window.top.location.href = result.path;
                                            } else {
                                                if (result.absolute) {
                                                    $location.url(result.path);
                                                } else {
                                                    processFacade.updateProcessView(scope.processInfo.processDefinitionId);
                                                }
                                            }
                                        } else {
                                            if ($location.url() === result.path) {
                                                $location.url(result.path);
                                                // reload current page with same route.
                                                $route.reload();
                                            } else {
                                                $location.url(result.path);
                                            }
                                        }
                                    }
                                    if (cometd.isConnected()) {
                                        cometd.publish("/care/page/" + scope.interactionKey, {});
                                    }
                                });*/
                        });
                    }
                };
            }
        };
    }]);

frontendApp.directive("drawing", [function () {
    return {
        restrict: "A",
        scope: true,
        link: function (scope, element) {
            scope.ctx = element[0].getContext('2d');
            var scaleFactor = 1.1;
            var lastX = element[0].width / 2;
            var lastY = element[0].height / 2;
            var dragStart;
            var oldScale = 1;

            var zoom = function (factor) {
                var pt = scope.ctx.transformedPoint(lastX, lastY);
                scope.ctx.translate(pt.x, pt.y);
                scope.ctx.scale(factor, factor);
                scope.ctx.translate(-pt.x, -pt.y);
                redraw();
            };

            var pinchZoom = function (event) {
                if (!scope.snapImage.changes) {
                    scope.setChanges(true);
                    scope.$apply();
                }
                var scaleFactor = 1;
                if (oldScale > event.scale) {
                    scaleFactor = Math.pow(1.01, -1.001);
                } else if (event.scale > oldScale) {
                    scaleFactor = Math.pow(1.01, 1.001);
                }
                oldScale = event.scale;
                zoom(scaleFactor);
            };


            var start = function (evt) {
                evt.preventDefault();
                if (!scope.moveDisabled) {
                    document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';
                    lastX = evt.offsetX || (evt.pageX - element[0].offsetLeft);
                    lastY = evt.offsetY || (evt.pageY - element[0].offsetTop);
                    dragStart = scope.ctx.transformedPoint(lastX, lastY);
                }
            };
            var move = function (evt) {
                evt.preventDefault();
                if (!scope.moveDisabled) {
                    if (evt.targetTouches && evt.targetTouches.length >= 2) { //pinch
                        pinchZoom(evt);
                    } else if (!evt.targetTouches || evt.targetTouches.length == 1) {
                        lastX = evt.offsetX || (evt.pageX - element[0].offsetLeft);
                        lastY = evt.offsetY || (evt.pageY - element[0].offsetTop);
                        if (dragStart) {
                            if (!scope.snapImage.changes) {
                                scope.setChanges(true);
                                scope.$apply();
                            }
                            var pt = scope.ctx.transformedPoint(lastX, lastY);
                            scope.ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
                            redraw();
                        }
                    }
                }
            };
            var cancel = function (evt) {
                evt.preventDefault();
                dragStart = null;
            };
            element[0].addEventListener('mousedown', start, false);
            element[0].addEventListener('touchstart', start, false);

            element[0].addEventListener('mousemove', move, false);
            element[0].addEventListener('touchmove', move, false);

            element[0].addEventListener('mouseup', cancel, false);
            element[0].addEventListener('touchend', cancel, false);
            element[0].addEventListener('touchcancel', cancel, false);

            scope.$on("zoom", function (event, clicks, postFunction) {
                var factor = Math.pow(scaleFactor, clicks);
                zoom(factor);
                if (postFunction) {
                    postFunction();
                }
            });

            scope.$on("initialDraw", function () {
                lastX = element[0].width / 2;
                lastY = element[0].height / 2;
                scope.ctx.setTransform(1, 0, 0, 1, 0, 0);
                redraw();
            });
            function redraw() {
                scope.clear();
                if (!scope.angle || scope.angle == 0) {
                    scope.ctx.drawImage(scope.photo, 0, 0, 640, 480);
                } else {
                    scope.rotate();
                }
            }

            scope.$on('rotateImageOn90', function () {
                if (!scope.snapImage.changes) {
                    scope.setChanges(true);
                }
                scope.rotate();
            });
            scope.$on('clearImage', function () {
                scope.clear();
            });

            scope.clear = function () {
                var p1 = scope.ctx.transformedPoint(0, 0);
                var p2 = scope.ctx.transformedPoint(element[0].width, element[0].height);
                scope.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
            };

            scope.rotate = function () {
                scope.clear();
                scope.ctx.save();
                scope.ctx.translate(320, 240);
                scope.ctx.rotate(scope.angle * Math.PI / 180);
                scope.ctx.drawImage(scope.photo, -320, -240, 640, 480);
                scope.ctx.restore();
            };

            scope.$on("resetPhoto", function () {
                if (scope.snapImage.changes) {
                    scope.setChanges(false);
                    scope.createImageObject();
                }
            });

            var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            var xform = svg.createSVGMatrix();
            scope.ctx.getTransform = function () {
                return xform;
            };

            var savedTransforms = [];
            var save = scope.ctx.save;
            scope.ctx.save = function () {
                savedTransforms.push(xform.translate(0, 0));
                return save.call(scope.ctx);
            };
            var restore = scope.ctx.restore;
            scope.ctx.restore = function () {
                xform = savedTransforms.pop();
                return restore.call(scope.ctx);
            };

            var scale = scope.ctx.scale;
            scope.ctx.scale = function (sx, sy) {
                xform = xform.scaleNonUniform(sx, sy);
                return scale.call(scope.ctx, sx, sy);
            };
            var rotate = scope.ctx.rotate;
            scope.ctx.rotate = function (radians) {
                xform = xform.rotate(radians * 180 / Math.PI);
                return rotate.call(scope.ctx, radians);
            };
            var translate = scope.ctx.translate;
            scope.ctx.translate = function (dx, dy) {
                xform = xform.translate(dx, dy);
                return translate.call(scope.ctx, dx, dy);
            };
            var transform = scope.ctx.transform;
            scope.ctx.transform = function (a, b, c, d, e, f) {
                var m2 = svg.createSVGMatrix();
                m2.a = a;
                m2.b = b;
                m2.c = c;
                m2.d = d;
                m2.e = e;
                m2.f = f;
                xform = xform.multiply(m2);
                return transform.call(scope.ctx, a, b, c, d, e, f);
            };
            var setTransform = scope.ctx.setTransform;
            scope.ctx.setTransform = function (a, b, c, d, e, f) {
                xform.a = a;
                xform.b = b;
                xform.c = c;
                xform.d = d;
                xform.e = e;
                xform.f = f;
                return setTransform.call(scope.ctx, a, b, c, d, e, f);
            };
            var pt = svg.createSVGPoint();
            scope.ctx.transformedPoint = function (x, y) {
                pt.x = x;
                pt.y = y;
                return pt.matrixTransform(xform.inverse());
            };
        }
    };
}]);

frontendApp.directive("zoomDrawing", ['lockOperationsFacade', function (lockOperationsFacade) {
    return {
        restrict: "A",
        transclude: true,
        replace: false,
        scope: true,
        template: '' +
        '<div class="imageArea" ng-show="imgSrc">' +
        '   <canvas id="imageCanvas" drawing width="640" height="480"></canvas>' +
        '   <div class="zoomLbl">zoom</div>' +
        '   <div class="zoomIn" ng-click="zoomIn($event)">Zoom In</div>' +
        '   <div class="zoomOut"  ng-click="zoomOut($event)">Zoom Out</div>' +
        '   <div class="uploadImage" ng-show="defaultActionId" ng-clock="uploadImage($event)">upload image</div>' +
        '</div>',
        compile: function () {
            return {
                pre: function () {
                },
                post: function (scope, element, attrs) {
                    scope.photoCanvas = document.getElementById("imageCanvas");
                    scope.imgSrc = attrs.src;
                    scope.defaultActionId = attrs.defaultActionId;
                    scope.moveDisabled = true;

                    var saveData = function () {
                        if (!scope.photoCanvas) {
                            scope.photoCanvas = document.getElementById("imageCanvas");
                        }
                        scope.dataURL = scope.photoCanvas.toDataURL();
                    };

                    scope.zoomIn = function ($event) {
                        $event.stopPropagation();
                        scope.$broadcast("zoom", 1, saveData);
                    };
                    scope.zoomOut = function ($event) {
                        $event.stopPropagation();
                        scope.$broadcast("zoom", -1, saveData);
                    };
                    if (scope.imgSrc) {
                        scope.photo = new Image();
                        scope.photo.crossOrigin = 'anonymous';
                        scope.photo.onload = function () {
                            scope.$broadcast("initialDraw", scope.imgSrc);
                        };
                        scope.photo.src = location.protocol + '//' + location.host + '/custom/' + scope.imgSrc.substr(scope.imgSrc.indexOf('snapShot'));
                    }

                    scope.uploadImage = function ($event) {
                        $event.stopPropagation();
                        lockOperationsFacade.lock();
                        scope.uploaded = true;
                        if (!scope.photoCanvas) {
                            scope.photoCanvas = document.getElementById("imageCanvas");
                        }
                        Webcam.upload(scope.photoCanvas.toDataURL(), '/service/upload/upload-image?interaction=' + scope.saleSessionManager.getInteractionKey(),
                            function (code, result) {
                                if (code === 200 && scope.defaultActionId) {
                                    scope.$emit("imageUploaded", result);
                                } else {
                                    lockOperationsFacade.unlock(true);
                                    scope.$apply();
                                }
                            }
                        );
                    };

                    element.on("$destroy", function () {
                        if (!scope.uploaded && scope.dataURL) {
                            scope.uploaded = true;
                            var name = scope.imgSrc.substr(scope.imgSrc.lastIndexOf("/") + 1, scope.imgSrc.lastIndexOf("."));
                            Webcam.upload(scope.dataURL, '/service/upload/upload-image?interaction=' + scope.saleSessionManager.getInteractionKey() + '&imageName=' + name,
                                function (code, result) {
                                    if (code === 200) {
                                        scope.$emit("imageUploaded", result);
                                    } else {
                                        lockOperationsFacade.unlock(true);
                                        scope.$apply();
                                    }
                                }
                            );
                        }
                    });
                }
            };
        }
    };
}]);

frontendApp.directive("passedTime", ["$interval", function ($interval) {
    return {
        restrict: "A",
        scope: true,
        template: "<div>{{clock.hours}}:{{clock.minutes}}:{{clock.seconds}}</div>",
        link: function (scope, element, attrs) {
            scope.lastModified = attrs.time;
            scope.timeDifference = Math.floor((new Date().getTime() - scope.lastModified) / 1000);
            scope.clock = {hours: getHours(), minutes: getMinutes(), seconds: getSeconds()};

            function getSeconds() {
                var sec = scope.timeDifference - getHours() * 3600 - getMinutes() * 60;
                return sec < 10 ? "0" + sec : sec;
            }

            function getMinutes() {
                var min = Math.floor((scope.timeDifference - getHours() * 60) / 60);
                return min < 10 ? "0" + min : min;
            }

            function getHours() {
                var hours = Math.floor(scope.timeDifference / 3600);
                return hours < 10 ? "0" + hours : hours;
            }

            var stopTime = $interval(function () {
                scope.timeDifference++;
                scope.clock = {hours: getHours(), minutes: getMinutes(), seconds: getSeconds()};
            }, 1000);

            // listen on DOM destroy (removal) event, and cancel the next UI update
            // to prevent updating time after the DOM element was removed.
            element.bind('$destroy', function () {
                $interval.cancel(stopTime);
            });

            scope.$on('EVT_USER_SESSION_EXPIRED', function () {
                $interval.cancel(stopTime);
            });
        }
    };
}]);

frontendApp.directive('modalLayout', ['$modal', function ($modal) {
    return {
        restrict: 'E',
        link: function (scope, element, attrs) {
            $modal.open({
                template: '<div class="modal-body">' + attrs.content + '</div> ' +
                '<div class="modal-footer"> ' +
                '<a class="btn btn-default" ng-click="ok();" role="button">OK</a> ' +
                '</div>',
                controller: ['$scope', '$modalInstance',
                    function ($scope, $modalInstance) {
                        $scope.ok = function () {
                            $modalInstance.close();
                        };
                    }],
                backdrop: true
            });
        }
    };
}]);
/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, $, cometdLib, location, window, setTimeout, webSocketUrl */

frontendApp.factory('dragDropFacade', ['$rootScope', 'actions-manager',
    function ($rootScope, executor) {

        var hasCompareTray = false;
        var isDragAddCompare = undefined;
        var timer = undefined;
        var dragData;
        var removeCompareAction;

        function addToCompare(scope) {
            if (dragData) {
                executor.submitForm(scope, dragData.viewId, 'Add to compare', dragData);
            }
        }

        function cancelDrag() {
            isDragAddCompare = false;
            dragData = undefined;
            $rootScope.$broadcast('cancelDragAddCompare');
            $('#dragMarker').css('display', 'none');
        }

        return {
            setHasCompareTray: function (hasCompare) {
                hasCompareTray = hasCompare;
            },
            dragAddCompare: function (data) {
                if (hasCompareTray) {
                    $rootScope.$broadcast('dragAddCompare');
                    dragData = data;
                    if (data.position) {
                        timer = setTimeout(function () {
                            $('#dragMarker').css('display', 'block');
                            $('#dragMarker').css({
                                top: data.position.pageY,
                                left: data.position.pageX
                            });
                            isDragAddCompare = true;
                        }, 500);
                    } else {
                        isDragAddCompare = true;
                    }
                }
            },
            moveAddCompare: function (event) {
                if (isDragAddCompare) {
                    $('#dragMarker').css({
                        top: event.pageY,
                        left: event.pageX
                    });
                    $rootScope.$broadcast('CancelScroll');
                    event.preventDefault();
                } else {
                    if (Math.abs(event.pageY - dragData.position.pageY) > 10) {
                        clearTimeout(timer);
                    }
                }
            },
            cancelAddCompare: function () {
                cancelDrag();
            },
            dropAddCompare: function (touchEvent, scope) {
                clearTimeout(timer);
                $('#dragMarker').css('display', 'none');
                if (!isDragAddCompare) {
                    cancelDrag();
                    return;
                }
                if (touchEvent) {
                    if (touchEvent.pageY >= $('.comparePanel').offset().top) {
                        // add to compare for tablet
                        addToCompare(scope);
                    }
                } else {
                    // add to compare for PC
                    addToCompare(scope);
                }

                cancelDrag();
            },
            dragRemoveCompare: function (data) {
                if (!angular.isDefined(removeCompareAction)) {
                    removeCompareAction = {};
                    removeCompareAction.type = data.type;
                    removeCompareAction.subType = data.subType;
                    removeCompareAction.id = data.id;
                    $rootScope.$broadcast('dragRemoveCompare');
                }
            },
            dropRemoveCompare: function () {
                if (angular.isDefined(removeCompareAction)) {
                    $rootScope.$broadcast('RemoveComparable', removeCompareAction.type, removeCompareAction.subType, removeCompareAction.id);
                    removeCompareAction = undefined;
                    $rootScope.$broadcast('cancelDragRemoveCompare');
                }
            }
        };
    }]);

frontendApp.factory('applicationFacade', [function () {
    return {
        isMicrosite: function () {
            return false;
        },
        getContentModule: function () {
            return 'content';
        }
    };
}]);

frontendApp.service('cometd', ['$rootScope', function ($rootScope) {

    var cometd = new cometdLib.CometD();

    // Configure cometd
    cometd.configure({
        url: webSocketUrl,
        logLevel: 'debug'
    });

    return {
        connect: function (handshakeCallback) {
            // Handshake with the server
            if (cometd.isDisconnected()) {
                cometd.handshake(function () {
                    handshakeCallback(cometd);
                });
            } else {
                if (handshakeCallback) {
                    handshakeCallback(cometd);
                }
            }
        },

        isConnected: function () {
            return !cometd.isDisconnected();
        },

        addListener: function (channel, scope, callback) {
            cometd.addListener(channel, scope, function () {
                var args = arguments;
                $rootScope.$apply(function () {
                    callback.apply(cometd, args);
                });
            });
        },

        removeListener: function (subscription) {
            cometd.removeListener(subscription);
        },

        subscribe: function (channel, scope, callback, subscribeProps, subscribeCallback) {
            return cometd.subscribe(channel, scope, callback, subscribeProps, subscribeCallback);
        },

        unsubscribe: function (subscription, unsubscribeProps) {
            cometd.unsubscribe(subscription, unsubscribeProps);
        },

        publish: function (channel, content, publishProps, publishCallback) {
            cometd.publish(channel, content, publishProps, publishCallback);
        },

        disconnect: function (sync, disconnectProps) {
            cometd.disconnect(sync, disconnectProps);
        },

        batch: function (scope, callback) {
            cometd.batch(scope, function () {
                var args = arguments;
                $rootScope.$apply(function () {
                    callback.apply(cometd, args);
                });
            });
        }
    };
}]);
/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, restUrl, window, Blob, document, URL */

/* Services */
frontendApp.factory('commonService', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/:module/:action', {},
        {
            query: {
                method: 'GET',
                isArray: true,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            get: {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            put: {
                method: 'PUT'
            },
            post: {
                method: 'POST'
            },
            update: {
                method: 'POST',
                isArray: true
            },
            remove: {
                method: 'DELETE'
            }
        }
    );
}]);

frontendApp.factory('loginService', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/user/frontend/:action', {},
        {
            authenticate: {
                method: 'POST',
                params: {'action': 'authenticate'},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },

            autoLogin: {
                method: 'GET',
                params: {'action': 'auto-auth'}
            },

            invalidate: {
                method: 'GET',
                params: {'action': 'invalidate'}
            },

            logout: {
                method: 'GET',
                params: {'action': 'logout'}
            },

            getNewSession: {
                method: 'POST',
                params: {'action': 'getNewSession'},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },

            restoreSession: {
                method: 'POST',
                params: {'action': 'restoreSession'},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }
        }
    );
}]);

frontendApp.factory('sessionService', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/session/:action', {},
        {
            query: {
                method: 'GET',
                isArray: true,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            get: {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            put: {
                method: 'PUT'
            },
            post: {
                method: 'POST'
            },
            remove: {
                method: 'DELETE'
            }
        }
    );
}]);

frontendApp.factory('contentService', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/content/:action', {},
        {
            query: {
                method: 'GET',
                isArray: true,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            get: {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            put: {
                method: 'PUT'
            },
            post: {
                method: 'POST'
            },
            update: {
                method: 'POST',
                isArray: true
            },
            remove: {
                method: 'DELETE'
            }
        }
    );
}]);

frontendApp.factory('contextService',
    ['$q', '$rootScope', 'tokenFacade', '$location', 'loginService', '$http', 'commonService',
        'contentService', 'callCenterService', 'saleSessionHolder', 'isEnabledCookies',
        function ($q, $rootScope, tokenFacade, $location, loginService, $http, commonService,
                  contentService, callCenterService, saleSessionHolder, isEnabledCookies) {
            return {
                loadUser: function () {
                    if (undefined == $rootScope.user) {
                        var defer = $q.defer();
                        $http.defaults.headers.common['Auth-Token'] = tokenFacade.getToken();
                        loginService.invalidate(
                            function (result) {
                                $rootScope.user = result;
                                if (isEnabledCookies) {
                                    var ws = new Date();
                                    ws.setMonth(ws.getMonth() + 1);
                                    document.cookie = 'activeClient=' + result.channelClientId + '; expires' + ws.toUTCString();
                                }
                                commonService.get({module: 'locations', action: 'currentLocation'}, result.token,
                                    function (result) {
                                        $rootScope.locationInfo = angular.copy(result);
                                    },
                                    function () {
                                    });

                                callCenterService.init(saleSessionHolder.getInteractionKey());
                                defer.resolve(result);
                            },
                            function (result) {
                                tokenFacade.destroy();
                                if (isEnabledCookies) {
                                    var ws = new Date();
                                    document.cookie = 'activeClient=;expires=' + ws.toUTCString;
                                }
                                //$rootScope.oldLocation = $location.path();
                                defer.resolve(result);
                            }
                        );
                        return defer.promise;
                    } else {
                        return $rootScope.user;
                    }
                },
                autoLogin: function () {
                    if (tokenFacade.isTokenEmpty()) {
                        var defer = $q.defer();
                        $http.defaults.headers.common['Auth-Token'] = undefined;
                        loginService.autoLogin(function (user) {
                            $http.defaults.headers.common['Auth-Token'] = user.token;
                            tokenFacade.setToken(user.token);
                            defer.resolve({message: "success"});
                            if (user.locationId) {
                                commonService.post({module: 'locations', action: 'store'}, user.locationId,
                                    function (result) {
                                        $rootScope.locationInfo = angular.copy(result);
                                    },
                                    function () {
                                    });
                            }
                        }, function (result) {
                            if (result.status == 511) {
                                defer.resolve({message: "restricted"});
                            } else {
                                defer.resolve({message: "failed"});
                            }
                        });
                        return defer.promise;
                    } else {
                        return {message: "success"};
                    }
                }
            };
        }]);

frontendApp.factory('actions-service', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/action/:action', {},
        {
            query: {
                method: 'GET',
                isArray: true,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            get: {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            put: {
                method: 'PUT'
            },
            post: {
                method: 'POST'
            },
            remove: {
                method: 'DELETE'
            }
        }
    );
}]);

frontendApp.factory('questionnaireService', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/questionnaire/:action', {},
        {
            get: {
                method: 'GET',
                isArray: true,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            query: {
                method: 'GET',
                isArray: false,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            post: {
                method: 'POST'
            }
        }
    );
}]);

frontendApp.factory('pdfService', ['$http', 'layoutFacade',
    function ($http, layoutFacade) {
        function addInteraction(param) {
            return param ? 'interaction=' + param : '';
        }

        function addViewId(param) {
            return param ? '&viewId=' + param : '';
        }

        function addPreOrderItemId(param) {
            return param ? '&preOrderItemId=' + param : '';
        }

        function addProcessDefinitionId(param) {
            return param && param.processDefinitionId ? '&processDefinitionId=' + param.processDefinitionId : '';
        }

        return {
            getPDF: function (scope, options, unlockFn) {
                var agent = window.navigator.userAgent;
                var params = '';
                params += addInteraction(scope.saleSessionManager.getInteractionKey());

                for (var i in options.offeringDatas) {
                    if (options.offeringDatas.hasOwnProperty(i)) {
                        if (options.offeringDatas[i]['templateId']) {
                            params += '&templateId=' + options.offeringDatas[i]['templateId'];
                            for (var j in options.offeringDatas[i].items) {
                                if (options.offeringDatas[i].items.hasOwnProperty(j)) {
                                    if (options.offeringDatas[i].items[j]) {
                                        params += '&offeringIds=' + options.offeringDatas[i].items[j].id;
                                    }
                                }
                            }
                        }
                    }
                }
                params += addViewId(options['printViewId']);

                if (agent.indexOf('MSIE 9.0') > -1
                    || agent.indexOf('MSIE 8.0') > -1) {
                    window.open('service/print/retrieve-pdf-file?' + params);
                } else {
                    var config = {
                        method: 'GET',
                        url: 'service/print/retrieve-pdf-file?' + params,
                        headers: {
                            'Accept': 'application/pdf'
                        },
                        responseType: 'arraybuffer'
                    };
                    $http(config)
                        .success(function (data) {
                            var file = new Blob([data], {type: 'application/pdf'});
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(file);
                            } else {
                                var fileURL = URL.createObjectURL(file);
                                window.open(fileURL);
                            }
                            if (unlockFn) {
                                unlockFn(true);
                            }
                        }).error(function () {
                        if (unlockFn) {
                            unlockFn(true);
                        }
                    });
                }
            },
            getFormPDF: function (scope, options, unlockFn) {
                var agent = window.navigator.userAgent;
                var params = '';
                params += addInteraction(scope.saleSessionManager.getInteractionKey());
                params += addPreOrderItemId(options['preOrderItemId']);
                params += addViewId(options['viewId']);
                params += addProcessDefinitionId(scope.processInfo);

                if (agent.indexOf('MSIE 9.0') > -1
                    || agent.indexOf('MSIE 8.0') > -1) {
                    window.open('service/print/retrieve-form-pdf-file?' + params);
                } else {
                    var config = {
                        method: 'GET',
                        url: 'service/print/retrieve-form-pdf-file?' + params,
                        headers: {
                            'Accept': 'application/pdf'
                        },
                        responseType: 'arraybuffer'
                    };
                    $http(config)
                        .success(function (data) {
                            var file = new Blob([data], {type: 'application/pdf'});
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(file);
                            } else {
                                window.open(URL.createObjectURL(file));
                            }
                            if (unlockFn) {
                                unlockFn();
                            }
                        }).error(function () {
                        if (unlockFn) {
                            unlockFn();
                        }
                    });
                }
            },
            postFormPDF: function (scope, options, unlockFn) {
                var agent = window.navigator.userAgent;
                var params = '';
                params += addInteraction(scope.saleSessionManager.getInteractionKey());
                params += addViewId(options['viewId']);
                params += addProcessDefinitionId(scope.processInfo);
                if (agent.indexOf('MSIE 9.0') > -1
                    || agent.indexOf('MSIE 8.0') > -1) {
                    window.open('service/print/retrieve-form-pdf-file?' + params);
                } else {
                    var config = {
                        method: 'POST',
                        url: 'service/print/retrieve-form-pdf-file?' + params,
                        headers: {
                            'Accept': 'application/pdf'
                        },
                        responseType: 'arraybuffer',
                        data: options
                    };
                    $http(config)
                        .success(function (data) {
                            var file = new Blob([data], {type: 'application/pdf'});
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(file);
                            } else {
                                var wnd = window.open(URL.createObjectURL(file));
                                if (!wnd) {
                                    layoutFacade.showTopMessages("Pop-ups are not allowed.");
                                }
                            }
                            if (unlockFn) {
                                unlockFn();
                            }
                        }).error(function () {
                        if (unlockFn) {
                            unlockFn();
                        }
                    });
                }
            },
            getPreorderPDF: function (scope, options, unlockFn) {
                var agent = window.navigator.userAgent;
                var params = '';
                params += addInteraction(scope.saleSessionManager.getInteractionKey());
                params += addPreOrderItemId(options['preOrderItemId']);
                params += addViewId(options['viewId']);
                params += addProcessDefinitionId(scope.processInfo);
                if (agent.indexOf('MSIE 9.0') > -1
                    || agent.indexOf('MSIE 8.0') > -1) {
                    window.open('service/print/retrieve-preorder-pdf-file?' + params);
                } else {
                    var config = {
                        method: 'GET',
                        url: 'service/print/retrieve-preorder-pdf-file?' + params,
                        headers: {
                            'Accept': 'application/pdf'
                        },
                        responseType: 'arraybuffer'
                    };
                    $http(config)
                        .success(function (data) {
                            var file = new Blob([data], {type: 'application/pdf'});
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(file);
                            } else {
                                window.open(URL.createObjectURL(file));
                            }
                            if (unlockFn) {
                                unlockFn();
                            }
                        }).error(function () {
                        if (unlockFn) {
                            unlockFn();
                        }
                    });
                }
            }
        };
    }]);

frontendApp.service('callCenterService', ['$rootScope', 'commonService', '$window', '$routeParams', 'attributesTool', '$route',
    function ($rootScope, commonService, $window, $routeParams, attributesTool, $route) {
        return {
            init: function (interaction) {
                var request = attributesTool.buildAttributes($route.current.params, {}, $window);
                request['module'] = 'call-center';
                request['action'] = 'set-resolution';
                request['interaction'] = interaction;
                commonService.get(request,
                    function (result) {
                        $rootScope.layoutClass = result.string;
                        $rootScope.$broadcast('loginSuccessEvt');
                    },
                    function () {
                        $rootScope.layoutClass = "";
                    });
            }
        };
    }]);

frontendApp.service('saleSessionHolder', ['$q', '$cookies', 'isEnabledCookies',
    function ($q, $cookies, isEnabledCookies) {
        var interactionKey = undefined;
        var useCookies = isEnabledCookies;
        if (useCookies) {
            var cookies = document.cookie.match(new RegExp('(^|;)\\s*' + 'customerInteraction' + '\\s*=\\s*([^;]+)'));
            if (cookies) {
                var cookieValue = cookies.pop();
                if (cookieValue != null && cookieValue != '') {
                    interactionKey = cookieValue;
                }
            }
        }
        return {
            getInteractionKey: function () {
                return interactionKey;
            },
            setInteractionKey: function (key) {
                if (key && key != null && interactionKey == null) {
                    interactionKey = key;
                    if (useCookies) {
                        var ws = new Date();
                        ws.setMonth(ws.getMonth() + 1);
                        document.cookie = 'customerInteraction=' + key + '; expires' + ws.toUTCString();
                    }
                }
            },
            destroy: function () {
                interactionKey = undefined;
                if (useCookies) {
                    var ws = new Date();
                    document.cookie = 'customerInteraction=;expires=' + ws.toUTCString;
                }
            }
        };
    }]);

frontendApp.factory('saleSessionManagerProvider', ['$rootScope', '$q', 'commonService', 'layoutFacade', '$log',
    function ($rootScope, $q, commonService, layoutFacade, $log) {
        return {
            $get: function (scope, sessionHolder) {
                scope.interactionKey = sessionHolder.getInteractionKey();
                var $instance = {
                    isSessionStarted: function () {
                        return scope.interactionKey != undefined;
                    },
                    getInteractionKey: function () {
                        return scope.interactionKey;
                    },
                    setInteractionKey: function (key) {
                        scope.interactionKey = key;
                        if (sessionHolder && sessionHolder.setInteractionKey) {
                            sessionHolder.setInteractionKey(key);
                        }
                    },
                    resumeSession: function (interaction) {
                        var defer = $q.defer();
                        commonService.get({module: 'session', action: 'flow-resume', interaction: interaction},
                            function (result) {
                                if (result && result.key) {
                                    $log.debug('Sale session resumed: ' + result.key);
                                    $instance.setInteractionKey(result.key);
                                    $rootScope.$broadcast('updateBasketContent');
                                }
                                defer.resolve(result);
                            },
                            function () {
                                defer.reject();
                            }
                        );
                        return defer.promise;
                    },
                    findFree: function (data) {
                        var defer = $q.defer();
                        commonService.update({module: 'session', action: 'flow-resume-ext'}, data,
                            function (results) {
                                if (results.length === 1) {
                                    var result = results[0];
                                    defer.resolve(result);
                                } else {
                                    defer.reject(results);
                                }
                            },
                            function () {
                                defer.reject([]);
                            }
                        );
                        return defer.promise;
                    },
                    dropSession: function () {
                        scope.interactionKey = undefined;
                        sessionHolder.destroy();
                    },
                    releaseSession: function (interactionKey, attributes) {
                        var defer = $q.defer();
                        if (!attributes) {
                            attributes = {};
                        }
                        attributes['module'] = 'session';
                        attributes['action'] = 'flow-release';
                        attributes['interaction'] = interactionKey;

                        commonService.get(attributes,
                            function () {
                                scope.interactionKey = undefined;
                                sessionHolder.destroy();
                                defer.resolve();
                            }, function () {
                                defer.reject();
                            }
                        );
                        return defer.promise;
                    }
                };
                return $instance;
            }
        };
    }]);

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp */

frontendApp.constant('SLIDER_REPLACEMENT', {
    LOWER: '{lower}',
    HIGHER: '{higher}',
    VALUE: '{value}'
});

frontendApp.constant('CUSTOM_EVENTS', {
    RPM_SWIPE_LEFT: 'RpmSwipeLeft',
    RPM_SWIPE_RIGHT: 'RpmSwipeRight',
    RPM_PREV: 'RpmPrev',
    RPM_NEXT: 'RpmNext'
});

frontendApp.constant('FORM_FILED_ROLES', {
    UNKNOWN: 'UNKNOWN',
    CUSTOMER_TITLE: 'CUSTOMER_TITLE',
    CUSTOMER_FIRST_NAME: 'CUSTOMER_FIRST_NAME',
    CUSTOMER_LAST_NAME: 'CUSTOMER_LAST_NAME',
    CUSTOMER_PHONE_NUMBER: 'CUSTOMER_PHONE_NUMBER',
    CUSTOMER_ACCOUNT_NUMBER: 'CUSTOMER_ACCOUNT_NUMBER',
    CUSTOMER_ADDRESS: 'CUSTOMER_ADDRESS',
    CUSTOMER_DATE_OF_BIRTH: 'CUSTOMER_DATE_OF_BIRTH',
    CUSTOMER_EMAIL: 'CUSTOMER_EMAIL',
    CUSTOMER_NUMBER: 'CUSTOMER_NUMBER'
});

frontendApp.constant('SORT_ORDERS', {
    ASC: 'ASC',
    DESC: 'DESC'
});

//frontendApp.constant('ACTION_EVENTS', {
//    PAGE_REFRESH: 'refreshPage',
//    UPDATE_STATIC_CONTENT: 'UpdateFormData',
//    REFRESH_METER: 'JourneyMeterRefresh',
//    REFRESH_BASKET : 'updateBasketContent',
//    REFRESH_COMPARE : 'refreshCompare',
//    REFRESH_RECENT : 'refreshRecentItems',
//    PRINT_FORM: 'printForm',
//    CLOSE_BROWSER: 'closeBrowser'
//});
//
frontendApp.constant('CUSTOMER_CARE_ACCESS_TYPES', {
    CUSTOMER: "CUSTOMER",
    AGENT: "AGENT",
    AGENT_REQUEST: "AGENT_REQUEST"
});

frontendApp.constant("FORM_SELECT_EMPTY_VALUE", "IS_NULL");

frontendApp.constant("DISABLE_BUTTON_CLASS", "rippl-disable");
/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp */

frontendApp.directive('filterMultipleSelect', ['filterFacade', 'FILTER_TEMPLATES', function (filterFacade, FILTER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
            filters: "=",
            filter: "=",
            owner: "@"
        },
        template: FILTER_TEMPLATES.FILTER_MULTI_SELECT,
        link: function (scope) {

            scope.$on('FilterChangedEvent', function (event, filter) {
                if (filter.id === scope.filter.id && filter.scope === scope.filter.scope) {
                    if (!event.defaultPrevented) {
                        if (event.preventDefault) {
                            event.preventDefault();
                        }
                        filterFacade.filterMultiSelectUpdated(scope.owner, filter, scope.$parent.saleSessionManager.getInteractionKey());
                    }
                }
            });

            scope.$on('FilterUpdateReceivedEvent', function (event, filter) {
                if (filter.id === scope.filter.id && filter.scope === scope.filter.scope) {
                    scope.filters[scope.filter.id] = filter;
                    scope.filter = filter;
                }
            });
        }
    };
}]);

frontendApp.directive('filterDropDown', ['filterFacade', 'FILTER_TEMPLATES', function (filterFacade, FILTER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
            filters: "=",
            filter: "=",
            owner: "@"
        },
        template: FILTER_TEMPLATES.FILTER_DROPDOWN,
        link: function (scope) {

            scope.$on('FilterChangedEvent', function (event, filter) {
                if (filter.id === scope.filter.id && filter.scope === scope.filter.scope) {
                    if (!event.defaultPrevented) {
                        if (event.preventDefault) {
                            event.preventDefault();
                        }
                        filterFacade.filterOptionUpdated(scope.owner, filter, scope.$parent.saleSessionManager.getInteractionKey());
                    }
                }
            });

            scope.$on('FilterUpdateReceivedEvent', function (event, filter) {
                if (filter.id === scope.filter.id && filter.scope === scope.filter.scope) {
                    scope.filters[scope.filter.id] = filter;
                    scope.filter = filter;
                }
            });
        }
    };
}]);

frontendApp.directive('filterButtons', ['filterFacade', 'FILTER_TEMPLATES', function (filterFacade, FILTER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
            filters: "=",
            filter: "=",
            owner: "@"
        },
        template: FILTER_TEMPLATES.FILTER_BUTTONS,
        link: function (scope) {

            scope.$on('FilterChangedEvent', function (event, filter) {
                if (filter.id === scope.filter.id && filter.scope === scope.filter.scope) {
                    if (!event.defaultPrevented) {
                        if (event.preventDefault) {
                            event.preventDefault();
                        }
                        filterFacade.filterMultiSelectUpdated(scope.owner, filter, scope.$parent.saleSessionManager.getInteractionKey());
                    }
                }
            });

            scope.$on('FilterUpdateReceivedEvent', function (event, filter) {
                if (filter.id === scope.filter.id && filter.scope === scope.filter.scope) {
                    scope.filters[scope.filter.id] = filter;
                    scope.filter = filter;
                }
            });

            scope.isChecked = function (index) {
                var value = scope.filter.options[index].value;
                for (var i = 0; i < scope.filter.selected.length; i++) {
                    if (scope.filter.selected[i] == value) {
                        return true;
                    }
                }
                return false;
            };

            scope.updateOption = function (index) {
                var value = scope.filter.options[index].value;
                for (var i = 0; i < scope.filter.selected.length; i++) {
                    if (scope.filter.selected[i] == value) {
                        scope.filter.selected.splice(i, 1);
                        return;
                    }
                }
                scope.filter.selected.push(value);
            };
        }
    };
}]);

frontendApp.directive('filterSlider', ['FILTER_TEMPLATES', function (FILTER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
            filter: "=",
            owner: "="
        },
        template: FILTER_TEMPLATES.FILTER_SLIDER,
        link: function () {
        }
    };
}]);

frontendApp.directive('filterSliderExtended', ['filterFacade', 'FILTER_TEMPLATES', function (filterFacade, FILTER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
            filters: "=",
            filter: "=",
            owner: "@"
        },
        template: FILTER_TEMPLATES.FILTER_SLIDER_EXT,
        link: function (scope) {
            scope.$on('FilterChangedEvent', function (event, filter) {
                if (filter.id === scope.filter.id && filter.scope === scope.filter.scope) {
                    if (!event.defaultPrevented) {
                        if (event.preventDefault) {
                            event.preventDefault();
                        }
                        filterFacade.filterSliderUpdated(scope.owner, filter, scope.$parent.saleSessionManager.getInteractionKey());
                    }
                }
            });
        }
    };
}]);





/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, location, ripplMultiLanguage, restUrl */

frontendApp.directive('viewForm', ['$rootScope', '$compile', 'commonService', '$q', '$location', '$route', 'FORM_FILED_ROLES',
    'applicationFacade', 'CUSTOMER_CARE_ACCESS_TYPES', 'lockOperationsFacade', 'cometd', 'pdfService', 'actions-manager', '$log',
    function ($rootScope, $compile, commonService, $q, $location, $route, FORM_FILED_ROLES,
              applicationFacade, CUSTOMER_CARE_ACCESS_TYPES, lockOperationsFacade, cometd, pdfService, executor, $log) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element, attrs) {
                        scope.viewId = attrs.viewId;
                        scope.formId = attrs.formId;
                        scope.partial = attrs.partial;
                        scope.data = {};
                        scope.allValidationPromise = [];
                        scope.defaultActionId = attrs.defaultActionId;
                    },
                    post: function (scope, element, attrs) {
                        scope.viewId = attrs.viewId;

                        var interactionKey = scope.saleSessionManager.getInteractionKey();

                        function formViewCollectValidations() {
                            var promises = [];
                            for (var ind in scope.allValidationPromise) {
                                if (scope.allValidationPromise.hasOwnProperty(ind)) {
                                    promises.push(scope.allValidationPromise[ind]());
                                }
                            }
                            return $q.all(promises);
                        }

                        function formViewValidate(validators) {
                            $log.debug('Validating data for: ' + scope.viewId + ' ' + JSON.stringify(validators));
                            var formValid = true;
                            for (var ind in validators) {
                                if (validators.hasOwnProperty(ind)) {
                                    var validator = validators[ind];
                                    if (!validator.valid) {
                                        formValid = false;
                                        $log.debug('Data not valid for: ' + scope.viewId + ' [' + JSON.stringify(validator) + ']');
                                    }
                                }
                            }

                            if (!formValid) {
                                lockOperationsFacade.unlock(true);
                            }

                            return formValid;
                        }

                        function formViewCreatePayload(payload) {
                            for (var key in scope.data) {
                                if (scope.data.hasOwnProperty(key)) {
                                    var ob = scope.data[key];
                                    if (ob.values != undefined) {
                                        var v = '';
                                        for (var i = 0; i < ob.values.length; i++) {
                                            var val = ob.values[i];
                                            if (v.length > 0) {
                                                v += ';';
                                            }
                                            v += val;
                                        }
                                        payload['form.' + key] = v;
                                    } else {
                                        payload['form.' + key] = ob.value;
                                    }
                                }
                            }
                            return payload;
                        }

                        function formViewCollectData(formValid) {
                            $log.debug('Collecting data for: ' + scope.viewId + ' with valid = ' + formValid);
                            var deferred = $q.defer();
                            if (formValid) {
                                deferred.resolve(formViewCreatePayload({
                                    formId: attrs.formId,
                                    partial: attrs.partial,
                                    interaction: interactionKey,
                                    processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null,
                                    submit: true
                                }));
                            } else {
                                deferred.reject();
                            }
                            return deferred.promise;
                        }

                        scope.$on('sessionReloaded', function () {
                            commonService.get({
                                    module: applicationFacade.getContentModule(),
                                    action: 'view-form',
                                    viewId: scope.viewId,
                                    interaction: interactionKey
                                },
                                function (result) {
                                    scope.data = result.data;
                                    scope.$broadcast("formDataReloaded");
                                    $rootScope.$broadcast('UpdateFormData');
                                }
                            );
                        });

                        scope.getParameters = function () {
                            var params = {};
                            if (scope.data) {
                                for (var k in scope.data) {
                                    if (scope.data.hasOwnProperty(k)) {
                                        if (scope.data[k]) {
                                            if (scope.data[k].value) {
                                                params[k] = scope.data[k].value;
                                            } else if (scope.data[k].values) {
                                                params[k] = JSON.stringify(scope.data[k].values);
                                            }
                                        }
                                    }
                                }
                            }
                            return params;
                        };

                        scope.isListening = true;
                        element.on('$destroy', function () {
                            if (scope.isListening) {
                                scope.isListening = false;
                                scope.$destroy();
                            }
                        });

                        scope.printForm = function () {
                            var source = {};
                            source['viewId'] = scope.viewId ? scope.viewId : attrs.viewId;
                            for (var key in scope.data) {
                                if (scope.data.hasOwnProperty(key)) {
                                    var ob = scope.data[key];
                                    if (ob.values != undefined) {
                                        var v = '';
                                        for (var va in ob.values) {
                                            if (v.length > 0) {
                                                v += ';';
                                            }
                                            v += va;
                                        }
                                        source['forms.' + key] = v;
                                    } else {
                                        source['forms.' + key] = ob.value;
                                    }
                                }
                            }
                            pdfService.postFormPDF(scope, source, lockOperationsFacade.unlock);
                            lockOperationsFacade.unlock(true);
                        };

                        scope.executeFormSubmit = function (actionId) {
                            return formViewCollectValidations()
                                .then(formViewValidate)
                                .then(formViewCollectData)
                                .then(function (payload) {
                                    if (scope.onFormSubmit) {
                                        scope.onFormSubmit(scope.formId, payload);
                                    } else {
                                        $log.debug('Submitting form for: ' + scope.viewId);
                                        var viewId = scope.viewId ? scope.viewId : attrs.viewId;
                                        if (scope.onFormSubmitFinish) {
                                            scope.onFormSubmitFinish(scope.formId, payload);
                                        }
                                        return executor.submitForm(scope, viewId, actionId, payload);
                                    }
                                })
                                .then(function () {
                                    $log.debug('Form submission completed for: ' + scope.viewId);
                                    $rootScope.$broadcast('UpdateFormData');
                                });
                        };

                        scope.save = function () {
                            return scope.executeFormSubmit(scope.defaultActionId);
                        };

                        scope.getFormData = function (submitParam) {
                            if (submitParam === false || submitParam === 'false') {
                                var deferred = $q.defer();
                                deferred.resolve({
                                    formId: attrs.formId,
                                    partial: attrs.partial,
                                    interaction: interactionKey,
                                    processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null,
                                    submit: false
                                });
                                return deferred.promise;
                            } else {
                                return formViewCollectValidations()
                                    .then(formViewValidate)
                                    .then(formViewCollectData);
                            }
                        };

                        scope.executeFormCancel = function () {
                            var payload = {
                                formId: attrs.formId,
                                partial: attrs.partial,
                                interaction: interactionKey,
                                processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null,
                                submit: false
                            };
                            if (scope.onFormCancel) {
                                scope.onFormCancel();
                            } else {
                                var viewId = scope.viewId ? scope.viewId : attrs.viewId;
                                return executor.submitForm(scope, viewId, scope.defaultActionId, payload);
                            }
                            if (cometd.isConnected()) {
                                var interactionKey = scope.saleSessionManager.getInteractionKey();
                                cometd.publish("/care/page/" + interactionKey, {});
                            }
                        };

                        scope.$on('customerCareFormUpdate', function (event, data) {
                            if (scope.viewId == data.viewId) {
                                if (true === data.isCollection) {
                                    scope.data[data.fieldName].values = data.fieldValue.split(',');
                                } else {
                                    scope.data[data.fieldName].value = data.fieldValue;
                                }
                                scope.$broadcast("formDataReloaded", data.fieldName);
                            }
                        });

                        scope.$on('fieldChanged', function (event, viewId, fieldName, fieldValue, isCollection) {
                            if (applicationFacade.isMicrosite()
                                && CUSTOMER_CARE_ACCESS_TYPES.AGENT != applicationFacade.getControlType()) {
                                if (cometd.isConnected()) {
                                    cometd.publish("/care/fieldUpdated/" + scope.saleSessionManager.getInteractionKey(), {
                                        viewId: viewId,
                                        fieldName: fieldName,
                                        fieldValue: fieldValue,
                                        isCollection: isCollection,
                                        sessionId: interactionKey,
                                        url: location.host
                                    });
                                }
                            }
                        });

                        if (scope.allValidationPromise.length > 0) {
                            scope.$on('userChangedDataEvent', function (event, field, validationMethod) {
                                scope.$broadcast('checkSubmitButtonState', field, validationMethod);
                            });
                        }
                    }
                };
            }
        };
    }]);

frontendApp.directive('formInput', ['$q', '$filter', 'FORM_FIELD_TEMPLATES', function ($q, $filter, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_INPUT,
        link: function (scope, element, attrs) {
            scope.multiLanguage = ripplMultiLanguage;
            scope.title = attrs.title;
            scope.requiredLabel = attrs.required;
            scope.min = attrs.min;
            scope.max = attrs.max;
            scope.validationPattern = attrs.validationPattern;
            scope.validationMessage = attrs.validationMessage;
            scope.length = attrs.maxLength ? attrs.maxLength : 255;
            scope.required = angular.isDefined(attrs.required);
            scope.type = attrs.type;
            scope.inputType = attrs.type == "INT" || attrs.type == "DECIMAL" ? "number" : "text";
            scope.errorMessage = null;
            scope.failed = false;
            scope.valid = false;
            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };
            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;
            if (undefined == scope.value) {
                scope.value = "";
                scope.failed = false;
            }

            scope.placeholderTranslationKey = "";
            scope.placeholderDefaultValue = "";
            setPlaceholderValue();

            function setPlaceholderValue() {
                if (attrs.inputPlaceholder) {
                    var inputPlaceholderParts = attrs.inputPlaceholder.split("|");
                    if (inputPlaceholderParts.length == 1) {
                        scope.placeholderTranslationKey = inputPlaceholderParts[0].trim();
                    } else {
                        if (inputPlaceholderParts.length == 2) {
                            scope.placeholderTranslationKey = inputPlaceholderParts[0].trim();
                            scope.placeholderDefaultValue = inputPlaceholderParts[1].trim();
                        }
                    }
                }
            }

            if (ripplMultiLanguage) {
                updateTranslations();
                scope.$on('updateTranslations', function () {
                    updateTranslations();
                });
            }

            function updateTranslations() {
                scope.errorMessageData = {
                    fieldName: $filter("translate")(scope.title),
                    max: scope.max,
                    min: scope.min
                };
                attrs.$set("title", $filter("translate")(scope.title));
            }

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.$watch('value', function (val) {
                if (scope.data[attrs.field]) {
                    scope.data[attrs.field].value = val;
                    scope.failed = false;
                }
            });

            scope.validateValue = function (val) {
                if (scope.type && val != "") {
                    if (scope.type == "INT") {
                        return /^[0-9]+$/.test(val);
                    }
                    if (scope.type == "DECIMAL") {
                        return /^\d+\.\d{0,2}$/.test(val);
                    }
                }
                return true;
            };

            function whoIsBigger(min, max) {
                return min * 1 <= max * 1;
            }

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && scope.value == "") {
                    scope.failed = true;
                    scope.valid = false;
                    if (ripplMultiLanguage) {
                        scope.errorMessage = "SYS_FIELD_REQUIRED_ERROR";
                    } else {
                        scope.errorMessage = "The " + scope.title + " is required.";
                    }
                    deferred.resolve({message: scope.errorMessage, valid: false});
                } else {
                    if (scope.validateValue(scope.value)) {
                        if ((!scope.min || whoIsBigger(scope.min, scope.value)) && (!scope.max || whoIsBigger(scope.value, scope.max))) {
                            scope.failed = false;
                            scope.valid = true;
                            scope.errorMessage = null;
                            deferred.resolve({valid: true});
                        } else {
                            scope.failed = true;
                            scope.valid = false;
                            if (!scope.min && scope.max) {
                                if (ripplMultiLanguage) {
                                    scope.errorMessage = "SYS_FIELD_BIGGER_ERROR";
                                } else {
                                    scope.errorMessage = "The " + scope.title + " is bigger than " + scope.max;
                                }
                            } else if (!scope.max && scope.min) {
                                if (ripplMultiLanguage) {
                                    scope.errorMessage = "SYS_FIELD_LESS_ERROR";
                                } else {
                                    scope.errorMessage = "The " + scope.title + " is less than " + scope.min;
                                }
                            } else {
                                if (ripplMultiLanguage) {
                                    scope.errorMessage = "SYS_FIELD_RANGE_ERROR";
                                } else {
                                    scope.errorMessage = "The " + scope.title + " is not in range between " + scope.min + "-" + scope.max;
                                }
                            }
                            deferred.resolve({message: scope.errorMessage, valid: false});
                        }
                    } else {
                        scope.failed = true;
                        scope.valid = false;
                        if (ripplMultiLanguage) {
                            scope.errorMessage = "SYS_FIELD_FORMAT_ERROR";
                        } else {
                            scope.errorMessage = "The " + scope.title + " format is not valid.";
                        }
                        deferred.resolve({message: scope.errorMessage, valid: false});
                    }
                }

                return deferred.promise;
            };

            scope.signal = function () {
                if (scope.initValue != scope.value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    scope.initValue = scope.value;
                }
            };

            scope.allValidationPromise.push(scope.validateField);

            scope.checkValue = function ($event) {
                var keyCode = $event.which || $event.keyCode;
                var limit = parseInt(scope.length);
                var regex = new RegExp(scope.validationPattern, 'i');
                var value = scope.value + String.fromCharCode(keyCode);
                var match = value.match(regex);
                if (value.length <= limit && ((!scope.validationPattern && scope.validateValue(value))
                    || (scope.validationPattern && match && match[0] == value))) {
                    scope.failed = false;
                    scope.valid = true;
                    scope.errorMessage = '';
                } else {
                    if (scope.validationMessage) {
                        scope.errorMessage = scope.validationMessage;
                        scope.failed = true;
                        scope.valid = false;
                    }
                    $event.preventDefault();
                }
            };
        }
    };
}]);

frontendApp.directive('formArea', ['$q', '$filter', 'FORM_FIELD_TEMPLATES', function ($q, $filter, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_AREA,
        link: function (scope, element, attrs) {
            scope.multiLanguage = ripplMultiLanguage;
            scope.title = attrs.title;
            scope.errorMessage = null;
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.validationPattern = attrs.validationPattern;
            scope.validationMessage = attrs.validationMessage;
            scope.maxLength = attrs.maxLength ? attrs.maxLength : 2000;
            scope.failed = false;
            scope.valid = false;
            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };
            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;
            if (undefined == scope.value) {
                scope.value = "";
                scope.failed = false;
            }

            scope.placeholderTranslationKey = "";
            scope.placeholderDefaultValue = "";
            setPlaceholderValue();

            function setPlaceholderValue() {
                if (attrs.inputPlaceholder) {
                    var inputPlaceholderParts = attrs.inputPlaceholder.split("|");
                    if (inputPlaceholderParts.length == 1) {
                        scope.placeholderTranslationKey = inputPlaceholderParts[0].trim();
                    } else {
                        if (inputPlaceholderParts.length == 2) {
                            scope.placeholderTranslationKey = inputPlaceholderParts[0].trim();
                            scope.placeholderDefaultValue = inputPlaceholderParts[1].trim();
                        }
                    }
                }
            }

            if (ripplMultiLanguage) {
                updateTranslations();
                scope.$on('updateTranslations', function () {
                    updateTranslations();
                });
            }

            function updateTranslations() {
                scope.errorMessageData = {
                    fieldName: $filter("translate")(scope.title)
                };
                attrs.$set("title", $filter("translate")(scope.title));
            }

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.$watch('value', function (val) {
                scope.data[attrs.field].value = val;
            });

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && scope.value == "") {
                    if (ripplMultiLanguage) {
                        scope.errorMessage = "SYS_FIELD_REQUIRED_ERROR";
                    } else {
                        scope.errorMessage = "The " + scope.title + " is required.";
                    }
                    scope.failed = true;
                    scope.valid = false;
                    deferred.resolve({message: scope.errorMessage, valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.signal = function () {
                if (scope.initValue != scope.value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    scope.initValue = scope.value;
                }
            };

            scope.allValidationPromise.push(scope.validateField);

            scope.checkValue = function ($event) {
                var keyCode = $event.which || $event.keyCode;
                var limit = parseInt(scope.maxLength);
                var regex = new RegExp(scope.validationPattern, 'i');
                var value = scope.value + String.fromCharCode(keyCode);
                var match = value.match(regex);
                if (value.length <= limit && (!scope.validationPattern || (scope.validationPattern && match && match[0] == value))) {
                    scope.failed = false;
                    scope.valid = true;
                    scope.errorMessage = '';
                } else {
                    if (scope.validationMessage) {
                        scope.errorMessage = scope.validationMessage;
                        scope.failed = true;
                        scope.valid = false;
                    }
                    $event.preventDefault();
                }
            };
        }
    };
}]);

frontendApp.directive('formHidden', ['$q', '$timeout', 'FORM_FIELD_TEMPLATES', function ($q, $timeout, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_HIDDEN,
        link: function (scope, element, attrs) {
            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };
            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;
            element.contents('input').val(scope.value);
            scope.valid = false;

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                        initRelatedElements(scope.value);
                    }
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            if (attrs.formValue && !scope.value) {
                scope.value = attrs.formValue;
            }

            scope.$watch('value', function (val) {
                if (scope.initValue != scope.value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    scope.initValue = scope.value;
                }
                scope.data[attrs.field].value = val;
            });

            scope.validateField = function () {
                var deferred = $q.defer();

                scope.value = element.contents('input').val();
                if (scope.required && scope.value == "") {
                    scope.failed = true;
                    scope.valid = false;
                    deferred.resolve({message: 'Field required', valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.allValidationPromise.push(scope.validateField);

            initRelatedElements();

            function initRelatedElements(value) {
                if (attrs.formInit) {
                    $timeout(function () {
                        var functionInit = eval(attrs.formInit);
                        if (typeof functionInit == 'function') {
                            functionInit(value);
                        }
                    }, 100);
                }
            }
        }
    };
}]);

frontendApp.directive('formNameTitle', ['$q', 'FORM_FIELD_TEMPLATES', function ($q, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_NAME_TITLE,
        link: function (scope, element, attrs) {
            scope.title = attrs.title;
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.failed = false;
            scope.valid = false;

            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };
            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;
            if (undefined == scope.value) {
                scope.value = "";
                scope.failed = false;
            }

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.$watch('value', function (val) {
                scope.data[attrs.field].value = val;
            });

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && scope.value == "") {
                    scope.failed = true;
                    scope.valid = false;
                    deferred.resolve({message: 'Field required', valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.signal = function () {
                if (scope.initValue != scope.value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    scope.initValue = scope.value;
                }
            };

            scope.allValidationPromise.push(scope.validateField);
        }
    };
}]);

frontendApp.directive('formDropdown', ['$q', 'FORM_FIELD_TEMPLATES', '$log', '$filter', function ($q, FORM_FIELD_TEMPLATES, $log, $filter) {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            callback: "&",
            selected: "="
        },
        template: FORM_FIELD_TEMPLATES.FORM_DROP_DOWN,
        link: function (scope, element, attrs) {
            scope.multiLanguage = ripplMultiLanguage;
            scope.title = attrs.title;
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.errorMessage = null;
            scope.failed = false;
            scope.valid = false;
            scope.data = scope.$parent.data;
            var regex = /^(.+) +for +(.+)$/g;
            var parsed = [];
            var labelField = [];
            try {
                parsed = regex.exec(attrs.formOptions);
                if (parsed) {
                    labelField.push(parsed[1]);
                    if (parsed[1].indexOf("(") > -1) {
                        var fields = parsed[1].split("+");
                        for (var i = 0; i < fields.length; i++) {
                            labelField.push(fields[i].replace("(", "").replace(")", "").trim().replace("'", "").replace("'", ""));
                        }
                    }
                }
            } catch (e) {
                $log.log(e);
            }
            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };
            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;
            if (undefined == scope.value) {
                scope.value = "";
                scope.failed = false;
            } else if (scope.value == "" || scope.value == null || scope.selected != null) {
                scope.value = String(scope.selected);
                scope.failed = false;
            }

            if (ripplMultiLanguage) {
                updateTranslations();
                scope.$on('updateTranslations', function () {
                    updateTranslations();
                });
            }

            function updateTranslations() {
                scope.errorMessageData = {
                    fieldName: $filter("translate")(scope.title)
                };
                attrs.$set("title", $filter("translate")(scope.title));
            }

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    scope.data = scope.$parent.data;
                    scope.value = scope.data[attrs.field].value;
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.changed = false;
            scope.$watch('value', function (val) {
                scope.data[attrs.field].value = val;
                if (scope.changed) {
                    scope.changed = false;
                    scope.callback();
                }
                scope.$emit('userChangedDataEvent', attrs.field, scope.validateField);
            });

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && (scope.value == "undefined" || scope.value == "" || scope.value == null)) {
                    scope.failed = true;
                    scope.valid = false;

                    if (ripplMultiLanguage) {
                        scope.errorMessage = "SYS_FIELD_REQUIRED_ERROR";
                    } else {
                        scope.errorMessage = "The " + scope.title + " is required.";
                    }
                    deferred.resolve({message: scope.errorMessage, valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            function getFormDropDownValues() {
                var list = attrs.options.split(",");
                //var list = scope.$parent.dropDownList[attrs.field];
                var res = [];
                for (var i = 0; i < list.length; i++) {
                    var label = "";
                    if (parsed && parsed.length === 3 && parsed[1] != parsed[2]) {
                        for (var j = 1; j < labelField.length; j++) {
                            if (list[i][labelField[j]] != null) {
                                label += list[i][labelField[j]];
                            } else {
                                label += labelField[j];
                            }
                        }
                        res.push({label: label, value: list[i][parsed[2]]});
                    } else {
                        res.push({label: list[i], value: list[i]});
                    }
                }
                return res;
            }

            var ddValues = getFormDropDownValues();

            scope.getDropDownValues = function () {
                return ddValues;
            };

            scope.change = function () {
                scope.changed = true;
            };

            scope.signal = function () {
                if (scope.initValue != scope.value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    scope.initValue = scope.value;
                }
            };

            scope.$parent.allValidationPromise.push(scope.validateField);
        }
    };
}]);

/**
 * Example usage:
 *
 * <div form-multi-select field="MANUFACRUTE">inner content with '<div multi-select-item></div>' </div>
 */
frontendApp.directive('formMultiSelect', ['$q', 'FORM_FIELD_TEMPLATES', function ($q, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        transclude: true,
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_MULTI_SELECT,
        link: function (scope, element, attrs, ctrl, transclude) {
            // init selected values.
            scope.initValue = attrs.fieldValues ? attrs.fieldValues : "";
            scope.values = attrs.fieldValues ? getValues(attrs.fieldValues.split(",")) : [];
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.title = attrs.field;
            scope.data[attrs.field] = {
                value: null,
                values: scope.values,
                role: attrs.role
            };
            scope.failed = false;

            function getValues(valuesArr) {
                var res = [];
                for (var i in valuesArr) {
                    if (valuesArr.hasOwnProperty(i)) {
                        res[i] = valuesArr[i].replace("[", "").replace("]", "").trim();
                    }
                }
                return res;
            }

            // insert content into directive.
            transclude(scope, function (clone) {
                element.contents('input').after(clone);
            });

            element.contents('input').val(scope.values);
            scope.valid = false;

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.values = scope.data[attrs.field].values;
                    }
                    scope.valid = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.isArraysEquals = function (arr1, arr2) {
                if (arr1.length !== arr2.length) {
                    return false;
                } else {
                    for (var i = 0; i < arr1.length; i++) {
                        if (arr1[i] !== arr2[i]) {
                            return false;
                        }
                    }
                }
                return true;
            };

            scope.$watchCollection('values', function (val) {
                if (scope.data[attrs.field]) {
                    scope.data[attrs.field].values = val;
                    var str = val.join(',');
                    if (!scope.isArraysEquals(scope.initValue, val)) {
                        scope.$emit('fieldChanged', scope.viewId, attrs.field, str, true);
                        scope.initValue = angular.copy(val);
                    }
                }
            });


            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && scope.values.length == 0) {
                    scope.failed = true;
                    scope.valid = false;
                    deferred.resolve({message: 'Field required', valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.allValidationPromise.push(scope.validateField);
        }
    };
}]);

/**
 * Example usage:
 *
 * <div multi-select-item value="APPLE">inner content</div>
 */
frontendApp.directive('multiSelectItem', ['FORM_FIELD_TEMPLATES', function (FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        transclude: true,
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_MULTI_SELECT_ITEM,
        link: function (scope, element, attrs) {
            // init value
            scope.value = attrs.value;
            scope.selected = scope.$parent.values.indexOf(scope.value) != -1;

            // select value handler.
            scope.selectValue = function () {
                if (scope.selected) {
                    scope.$parent.values.splice(scope.$parent.values.indexOf(scope.value), 1);
                    scope.selected = false;
                } else {
                    scope.$parent.values.push(scope.value);
                    scope.selected = true;
                }
            };
        }
    };
}]);


frontendApp.directive('formAddress', ['sessionService', 'commonService', '$q', 'FORM_FIELD_TEMPLATES',
    function (sessionService, commonService, $q, FORM_FIELD_TEMPLATES) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.FORM_ADDRESS,
            link: function (scope, element, attrs) {
                scope.title = attrs.title;
                scope.required = angular.isDefined(attrs.required);
                scope.requiredLabel = attrs.required;
                scope.failed = false;
                scope.errorMessage = null;
                scope.valid = false;

                scope.data[attrs.field] = {
                    value: attrs.fieldValue,
                    values: null,
                    role: attrs.role
                };
                scope.initValue = attrs.fieldValue;
                scope.value = attrs.fieldValue;
                if (undefined == scope.value) {
                    scope.value = "";
                    scope.failed = false;
                }

                scope.$on('formDataReloaded', function (event, fieldName) {
                    if (fieldName == undefined || attrs.field == fieldName) {
                        if (scope.data[attrs.field]) {
                            scope.value = scope.data[attrs.field].value;
                        }
                        scope.failed = false;
                        if (!scope.$$phase) {
                            scope.$digest();
                        }
                    }
                });

                scope.$watch('value', function (val) {
                    scope.data[attrs.field].value = val;
                    scope.failed = false;
                });

                scope.getAddresses = function (addressValue) {
                    return commonService.get({
                        module: 'integration',
                        action: 'get-addresses-by-partial',
                        address: addressValue
                    })
                        .$promise.then(function (result) {
                            if (result.message) {
                                scope.errorMessage = result.message;
                                scope.failed = true;
                                scope.valid = false;
                            } else {
                                scope.errorMessage = null;
                                scope.failed = false;
                                scope.valid = true;
                            }
                            return result.addresses || [];
                        });
                };

                scope.validateField = function () {
                    var deferred = $q.defer();

                    if (scope.required && scope.value == "") {
                        scope.failed = true;
                        scope.errorMessage = "The address is required.";
                        scope.valid = false;
                        deferred.resolve({message: 'Address is required', valid: false});
                    } else {
                        deferred.resolve({valid: !scope.failed});
                        scope.valid = true;
                        scope.errorMessage = null;
                    }

                    return deferred.promise;
                };

                scope.signal = function () {
                    if (scope.initValue != scope.value) {
                        scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                        scope.initValue = scope.value;
                    }
                };

                scope.allValidationPromise.push(scope.validateField);
            }
        };
    }]);

frontendApp.directive('formPhoneNumber', ['sessionService', '$q', 'FORM_FIELD_TEMPLATES',
    function (sessionService, $q, FORM_FIELD_TEMPLATES) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.FORM_PHONE_NUMBER,
            link: function (scope, element, attrs) {
                scope.title = attrs.title;
                scope.required = angular.isDefined(attrs.required);
                scope.requiredLabel = attrs.required;
                scope.hideLookup = angular.isDefined(attrs.hidelookup);
                scope.field = attrs.field;
                scope.failed = false;
                scope.errorMessage = null;
                scope.valid = false;

                scope.data[attrs.field] = {
                    value: attrs.fieldValue,
                    values: null,
                    role: attrs.role
                };
                scope.initValue = attrs.fieldValue;
                scope.value = attrs.fieldValue;
                if (undefined == scope.value) {
                    scope.value = "";
                    scope.failed = false;
                }
                scope.$watch('value', function (val) {
                    scope.data[attrs.field].value = val;
                    scope.failed = false;
                });

                scope.placeholderTranslationKey = "";
                scope.placeholderDefaultValue = "";
                setPlaceholderValue();

                function setPlaceholderValue() {
                    if (attrs.inputPlaceholder) {
                        var inputPlaceholderParts = attrs.inputPlaceholder.split("|");
                        if (inputPlaceholderParts.length == 1) {
                            scope.placeholderTranslationKey = inputPlaceholderParts[0].trim();
                        } else {
                            if (inputPlaceholderParts.length == 2) {
                                scope.placeholderTranslationKey = inputPlaceholderParts[0].trim();
                                scope.placeholderDefaultValue = inputPlaceholderParts[1].trim();
                            }
                        }
                    }
                }

                scope.$on('formDataReloaded', function (event, fieldName) {
                    if (fieldName == undefined || attrs.field == fieldName) {
                        if (scope.data[attrs.field]) {
                            scope.value = scope.data[attrs.field].value;
                        }
                        scope.failed = false;
                        if (!scope.$$phase) {
                            scope.$digest();
                        }
                    }
                });

                scope.validatePhone = function (phone) {
                    var re = /^[0-9]+$/;
                    return re.test(phone);
                };

                scope.validateField = function () {
                    var deferred = $q.defer();

                    if (scope.required && scope.value == "") {
                        scope.failed = true;
                        scope.valid = false;
                        scope.errorMessage = "The phone is required.";
                        deferred.resolve({valid: false});
                    } else {
                        if (scope.value == "") {
                            scope.failed = false;
                            scope.valid = true;
                            scope.errorMessage = null;
                            deferred.resolve({valid: true});
                        } else {
                            if (scope.validatePhone(scope.value)) {
                                scope.failed = false;
                                scope.valid = true;
                                scope.errorMessage = null;
                                deferred.resolve({valid: true});
                            } else {
                                scope.failed = true;
                                scope.valid = false;
                                scope.errorMessage = "Mobile Phone number cannot contain alphabets and special characters.";
                                deferred.resolve({valid: false});
                            }
                        }
                    }

                    return deferred.promise;
                };

                scope.signal = function () {
                    if (scope.initValue != scope.value) {
                        scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                        scope.initValue = scope.value;
                    }
                };

                scope.allValidationPromise.push(scope.validateField);

                scope.lookup = function () {
                    sessionService.query({action: 'prev-sessions', phone: scope.value},
                        function (result) {
                            scope.$emit('OpenRecallSessionDialog', result, scope.value, null);
                        },
                        function () {
                        }
                    );
                };
            }
        };
    }]);

frontendApp.directive('formName', ['sessionService', '$q', 'FORM_FIELD_TEMPLATES',
    function (sessionService, $q, FORM_FIELD_TEMPLATES) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.FORM_NAME,
            link: function (scope, element, attrs) {
                scope.capitalizeFirstLetter = 'capitalizeFirstLetter' in attrs;
                scope.title = attrs.title;
                scope.required = angular.isDefined(attrs.required);
                scope.requiredLabel = attrs.required;
                scope.field = attrs.field;
                scope.failed = false;
                scope.errorMessage = null;
                scope.valid = false;
                scope.initValue = attrs.fieldValue;
                scope.data[attrs.field] = {
                    value: attrs.fieldValue,
                    values: null,
                    role: attrs.role
                };
                scope.value = attrs.fieldValue;
                if (undefined == scope.value) {
                    scope.value = "";
                    scope.failed = false;
                }

                scope.$watch('value', function (val) {
                    scope.data[attrs.field].value = val;
                    scope.failed = false;
                });

                scope.$on('formDataReloaded', function (event, fieldName) {
                    if (fieldName == undefined || attrs.field == fieldName) {
                        if (scope.data[attrs.field]) {
                            scope.value = scope.data[attrs.field].value;
                        }
                        scope.failed = false;
                        if (!scope.$$phase) {
                            scope.$digest();
                        }
                    }
                });

                scope.validateName = function (value) {
                    return /^[A-Za-z -]+$/.test(value);
                };

                scope.validateCapital = function (value) {
                    return /^[A-Z][A-Za-z -]+$/.test(value);
                };

                scope.validateField = function () {
                    var deferred = $q.defer();

                    if (scope.required && scope.value == "") {
                        scope.failed = true;
                        scope.valid = false;
                        scope.errorMessage = "The " + scope.title + " is required.";
                        deferred.resolve({valid: false});
                    } else {
                        if (scope.value == "") {
                            scope.failed = false;
                            scope.valid = true;
                            scope.errorMessage = null;
                            deferred.resolve({valid: true});
                        } else {
                            if (scope.validateName(scope.value)) {
                                if (scope.validateCapital(scope.value)) {
                                    scope.failed = false;
                                    scope.valid = true;
                                    scope.errorMessage = null;
                                    deferred.resolve({valid: true});
                                } else {
                                    scope.failed = true;
                                    scope.valid = false;
                                    scope.errorMessage = "Name must start with capital letter.";
                                    deferred.resolve({valid: false});
                                }

                            } else {
                                scope.failed = true;
                                scope.valid = false;
                                scope.errorMessage = "Name cannot contain numbers and whitespaces at the begin and at the end.";
                                deferred.resolve({valid: false});
                            }
                        }
                    }

                    return deferred.promise;
                };

                scope.signal = function () {
                    if (scope.initValue != scope.value) {
                        scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                        scope.initValue = scope.value;
                    }
                };

                scope.allValidationPromise.push(scope.validateField);
            }
        };
    }]);

frontendApp.directive('formEmail', ['sessionService', 'commonService', '$q', 'FORM_FIELD_TEMPLATES',
    function (sessionService, commonService, $q, FORM_FIELD_TEMPLATES) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.FORM_EMAIL,
            link: function (scope, element, attrs) {
                scope.title = attrs.title;
                scope.required = angular.isDefined(attrs.required);
                scope.requiredLabel = attrs.required;
                scope.hideLookup = angular.isDefined(attrs.hidelookup);
                scope.field = attrs.field;
                scope.failed = false;
                scope.errorMessage = null;
                scope.valid = false;
                scope.data[attrs.field] = {
                    value: attrs.fieldValue,
                    values: null,
                    role: attrs.role
                };
                scope.initValue = attrs.fieldValue;
                scope.value = attrs.fieldValue;
                if (undefined == scope.value) {
                    scope.value = "";
                    scope.failed = false;
                }

                scope.$watch('value', function (val) {
                    scope.data[attrs.field].value = val;
                    scope.failed = false;
                });

                scope.placeholderTranslationKey = "";
                scope.placeholderDefaultValue = "";
                setPlaceholderValue();

                function setPlaceholderValue() {
                    if (attrs.inputPlaceholder) {
                        var inputPlaceholderParts = attrs.inputPlaceholder.split("|");
                        if (inputPlaceholderParts.length == 1) {
                            scope.placeholderTranslationKey = inputPlaceholderParts[0].trim();
                        } else {
                            if (inputPlaceholderParts.length == 2) {
                                scope.placeholderTranslationKey = inputPlaceholderParts[0].trim();
                                scope.placeholderDefaultValue = inputPlaceholderParts[1].trim();
                            }
                        }
                    }
                }

                scope.$on('formDataReloaded', function (event, fieldName) {
                    if (fieldName == undefined || attrs.field == fieldName) {
                        if (scope.data[attrs.field]) {
                            scope.value = scope.data[attrs.field].value;
                        }
                        scope.failed = false;
                        if (!scope.$$phase) {
                            scope.$digest();
                        }
                    }
                });

                scope.validateEmail = function (email) {
                    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                    return re.test(email);
                };

                scope.validateField = function () {
                    var deferred = $q.defer();

                    if (scope.required && scope.value == "") {
                        scope.failed = true;
                        scope.valid = false;
                        scope.errorMessage = "The email is required.";
                        deferred.resolve({valid: false});
                    } else {
                        if (scope.value != "") {
                            if (!scope.validateEmail(scope.value)) {
                                scope.failed = true;
                                scope.valid = false;
                                scope.errorMessage = "Incorrect Email format.";
                                deferred.reject();
                            } else {
                                deferred.promise = commonService.get({
                                        module: 'integration',
                                        action: "validate-email",
                                        email: scope.value
                                    },
                                    function (result) {
                                        deferred.resolve(result);
                                        scope.failed = !result.valid;
                                        scope.valid = result.valid;
                                        scope.errorMessage = result.message;
                                    }, function () {
                                        scope.failed = true;
                                        scope.valid = false;
                                        scope.errorMessage = null;
                                        deferred.reject();
                                    }
                                ).$promise;
                            }
                        } else {
                            deferred.resolve({valid: true});
                            scope.failed = false;
                            scope.valid = true;
                            scope.errorMessage = null;
                        }
                    }

                    return deferred.promise;
                };

                scope.signal = function () {
                    if (scope.initValue != scope.value) {
                        scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                        scope.initValue = scope.value;
                    }
                };

                scope.allValidationPromise.push(scope.validateField);

                scope.lookup = function () {
                    sessionService.query({action: 'prev-sessions', email: scope.value},
                        function (result) {
                            scope.$emit('OpenRecallSessionDialog', result, null, scope.value);
                        },
                        function () {
                        }
                    );
                };
            }
        };
    }]);

frontendApp.directive('formBankAccount', ['commonService', '$q', 'FORM_FIELD_TEMPLATES',
    function (commonService, $q, FORM_FIELD_TEMPLATES) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.FORM_BANK_ACCOUNT,
            link: function (scope, element, attrs) {
                var fieldNameNumber = attrs.field + '.number';
                var fieldNameCode = attrs.field + '.code';
                scope.title = attrs.title;
                scope.required = angular.isDefined(attrs.required);
                scope.requiredLabel = attrs.required;
                scope.failed = false;
                scope.errorMessage = null;
                scope.valid = false;

                scope.data[fieldNameNumber] = {
                    value: attrs.fieldValuenumber,
                    values: null,
                    role: attrs.role
                };
                scope.numberInit = parseInt(attrs.fieldValuenumber);
                scope.number = parseInt(attrs.fieldValuenumber);
                if (undefined == scope.number || isNaN(scope.number)) {
                    scope.number = "";
                }

                scope.data[fieldNameCode] = {
                    value: attrs.fieldValuecode,
                    values: null,
                    role: attrs.role
                };
                scope.codeInit = attrs.fieldValuecode;
                scope.code = attrs.fieldValuecode;
                if (undefined == scope.code) {
                    scope.code = "";
                }

                //scope.updateInProgress = false;
                scope.$watch('number', function (val) {
                    scope.data[fieldNameNumber].value = val;
                    scope.failed = false;
                    //if (!scope.updateInProgress) {
                    //    scope.$emit('fieldChanged', scope.viewId, fieldNameNumber, scope.number);
                    //}
                });

                scope.$watch('code', function (val) {
                    scope.data[fieldNameCode].value = val;
                    scope.failed = false;
                    //if (!scope.updateInProgress) {
                    //    scope.$emit('fieldChanged', scope.viewId, fieldNameCode, scope.code);
                    //}
                });

                scope.$on('formDataReloaded', function (event, fieldName) {
                    //scope.updateInProgress = true;
                    if (fieldName == undefined || fieldName.startsWith(attrs.field)) {
                        if (scope.data[fieldNameNumber]) {
                            scope.number = parseInt(scope.data[fieldNameNumber].value);
                            if (undefined == scope.number || isNaN(scope.number)) {
                                scope.number = "";
                            }
                        }
                        if (scope.data[fieldNameCode]) {
                            scope.code = scope.data[fieldNameCode].value;
                        }
                        scope.failed = false;
                        if (!scope.$$phase) {
                            scope.$digest();
                        }
                    }
                    //scope.updateInProgress = false;
                });

                scope.validateAccountNumber = function (value) {
                    var re = /^(\d){8}$/;
                    return re.test(value);
                };

                scope.validateSortCode = function (value) {
                    var re = /^(\d){2}-(\d){2}-(\d){2}$/;
                    return re.test(value);
                };

                scope.validateField = function () {
                    var deferred = $q.defer();

                    if (scope.required && (scope.number == "" || scope.code == "")) {
                        scope.failed = true;
                        scope.valid = false;
                        scope.errorMessage = "The Account Number and Source Code is required.";
                        deferred.resolve({valid: false});
                    } else {
                        if (scope.number == "" && scope.code == "") {
                            deferred.resolve({valid: true});
                            scope.failed = false;
                            scope.valid = true;
                            scope.errorMessage = null;
                        } else {
                            if (!scope.validateAccountNumber(scope.number)) {
                                scope.failed = true;
                                scope.valid = false;
                                scope.errorMessage = "Account Number incorrect format. Required 8 digit";
                                deferred.resolve({valid: false});
                            } else {
                                if (!scope.validateSortCode(scope.code)) {
                                    scope.failed = true;
                                    scope.valid = false;
                                    scope.errorMessage = "Sort Code incorrect format. Required 6 digit e.g. 01-01-01";
                                    deferred.resolve({valid: false});
                                } else {
                                    deferred.promise = commonService.get({
                                            module: 'integration',
                                            action: "isDirectDebitAvailable",
                                            accountNumber: scope.number,
                                            sortCode: scope.code
                                        },
                                        function (result) {
                                            deferred.resolve(result);
                                            scope.failed = !result.valid;
                                            scope.valid = result.valid;
                                            scope.errorMessage = "Account Number validation failed";
                                        }, function () {
                                            scope.failed = true;
                                            scope.valid = false;
                                            scope.errorMessage = null;
                                            deferred.reject();
                                        }
                                    ).$promise;
                                }
                            }
                        }
                    }
                    return deferred.promise;
                };

                scope.signalNumber = function () {
                    if (scope.numberInit != scope.number) {
                        scope.$emit('fieldChanged', scope.viewId, fieldNameNumber, scope.number);
                        scope.numberInit = scope.number;
                    }
                };

                scope.signalCode = function () {
                    if (scope.codeInit != scope.code) {
                        scope.$emit('fieldChanged', scope.viewId, fieldNameCode, scope.code);
                        scope.codeInit = scope.code;
                    }
                };

                scope.allValidationPromise.push(scope.validateField);
            }
        };
    }]);

frontendApp.directive('formSlider', ['SLIDER_REPLACEMENT', 'FORM_FIELD_TEMPLATES', '$compile', '$filter', '$q', function (SLIDER_REPLACEMENT, FORM_FIELD_TEMPLATES, $compile, $filter, $q) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        link: function (scope, element, attrs) {
            scope.title = attrs.title;
            scope.subtitle = attrs.subtitle;
            scope.multiLanguage = ripplMultiLanguage;
            scope.required = angular.isDefined(attrs.required);

            if (ripplMultiLanguage) {
                scope.slider = {
                    lower: attrs.lower,
                    lowerLabel: $filter("translate")("SYS_SLIDER_LOWER_LABEL"),
                    higher: attrs.higher,
                    higherLabel: $filter("translate")("SYS_SLIDER_HIGHER_LABEL"),
                    valueLabel: $filter("translate")("SYS_SLIDER_VALUE_LABEL"),
                    step: attrs.step,
                    lowText: attrs.lowText,
                    highText: attrs.highText,
                    prefix: attrs.prefix,
                    visibility: attrs.labelsVisibility
                };
                updateTitleTranslations();

                scope.$on('updateTranslations', function () {
                    scope.slider.lowerLabel = $filter("translate")("SYS_SLIDER_LOWER_LABEL");
                    scope.slider.higherLabel = $filter("translate")("SYS_SLIDER_HIGHER_LABEL");
                    scope.slider.valueLabel = $filter("translate")("SYS_SLIDER_VALUE_LABEL");
                    updateTitleTranslations();
                });

            } else {
                scope.slider = {
                    lower: attrs.lower,
                    lowerLabel: "<strong>" + attrs.prefix + SLIDER_REPLACEMENT.LOWER + "</strong>" + attrs.lowtext,
                    higher: attrs.higher,
                    lowText: attrs.lowText,
                    highText: attrs.highText,
                    prefix: attrs.prefix,
                    higherLabel: "<strong>" + attrs.prefix + SLIDER_REPLACEMENT.HIGHER + "</strong>" + attrs.hightext,
                    valueLabel: attrs.prefix + SLIDER_REPLACEMENT.VALUE,
                    step: attrs.step,
                    visibility: attrs.labelsVisibility
                };
            }

            function updateTitleTranslations() {
                attrs.$set("title", $filter("translate")(scope.title));
                attrs.$set("subtitle", $filter("translate")(scope.subtitle));
                scope.errorMessageData = {
                    fieldName: $filter("translate")(scope.title)
                };
            }

            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };

            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;

            if (undefined == scope.value) {
                scope.value = "";
                scope.failed = false;
            }

            scope.updateInProgress = false;
            scope.$watch('value', function (val) {
                scope.data[attrs.field].value = val;
                if (scope.initValue != scope.value) {
                    if (!scope.updateInProgress) {
                        scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    }
                    scope.initValue = scope.value;
                }
                scope.$emit('userChangedDataEvent', attrs.field, scope.validateField);
            });

            scope.$on('formDataReloaded', function (event, fieldName) {
                scope.updateInProgress = true;
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
                scope.updateInProgress = false;
            });

            scope.validateField = function () {
                var deferred = $q.defer();
                if (scope.required && (!scope.data[attrs.field].value || scope.data[attrs.field].value == attrs.lower)) {
                    scope.failed = true;
                    scope.valid = false;
                    if (ripplMultiLanguage) {
                        scope.errorMessage = "SYS_FIELD_REQUIRED_ERROR";
                    } else {
                        scope.errorMessage = "The " + scope.title + " is required.";
                    }
                    deferred.resolve({valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    scope.errorMessage = null;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.allValidationPromise.push(scope.validateField);

            element.html($compile(FORM_FIELD_TEMPLATES.FORM_SLIDER)(scope));
        }
    };
}]);

frontendApp.directive('formSliderComplex', ['$compile', '$q', '$filter', 'FORM_FIELD_TEMPLATES', function ($compile, $q, $filter, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        link: function (scope, element, attrs) {

            scope.multiLanguage = ripplMultiLanguage;
            scope.title = attrs.title;
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.items = scope.$eval("[" + attrs.options + "]");

            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };

            if (ripplMultiLanguage) {
                updateTranslations();
                scope.$on('updateTranslations', function () {
                    updateTranslations();
                });
            }

            function updateTranslations() {
                scope.errorMessageData = {
                    fieldName: $filter("translate")(scope.title)
                };
                attrs.$set("title", $filter("translate")(scope.title));
            }

            scope.getIndex = function (value) {
                var optionIndex;
                for (var indx in scope.items) {
                    if (scope.items.hasOwnProperty(indx) && scope.items[indx][attrs.valueIndex] == value) {
                        optionIndex = indx;
                    }
                }
                return optionIndex;
            };

            scope.createLabels = function () {
                var labels = [];
                for (var indx in scope.items) {
                    if (scope.items.hasOwnProperty(indx)) {
                        labels.push(scope.items[indx][attrs.labelIndex]);
                    }
                }
                return labels;
            };

            scope.labels = scope.createLabels();

            scope.slider = {
                lower: 0,
                higher: scope.items.length - 1,
                step: 1,
                labels: scope.labels
            };

            scope.initValue = attrs.fieldValue;
            scope.initField = function () {
                if (scope.data[attrs.field]) {
                    var value = scope.data[attrs.field].value;
                    if (value) {
                        var index = scope.getIndex(value);
                        if (index || 0 === index) {
                            scope.value = index;
                        }
                    }
                }
            };
            scope.initField();

            scope.$watch('value', function (val) {
                if (val || 0 === val) {
                    scope.val = scope.items[val][attrs.valueIndex];
                    scope.data[attrs.field].value = scope.items[val][attrs.valueIndex];
                    if (scope.initValue != scope.val) {
                        scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.val);
                        scope.initValue = scope.val;
                    }
                } else {
                    scope.data[attrs.field].value = null;
                }
                scope.data[attrs.field].values = null;
                scope.data[attrs.field].role = attrs.role;
            });


            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    scope.initField();
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && (!scope.value && scope.value != 0)) {
                    scope.failed = true;
                    scope.valid = false;
                    if (ripplMultiLanguage) {
                        scope.errorMessage = "SYS_FIELD_REQUIRED_ERROR";
                    } else {
                        scope.errorMessage = "The " + scope.title + " is required.";
                    }
                    deferred.resolve({valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    scope.errorMessage = null;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.allValidationPromise.push(scope.validateField);

            element.html($compile(FORM_FIELD_TEMPLATES.FORM_SLIDER_COMPLEX)(scope));
        }
    };
}]);

frontendApp.directive('formDate', ['$q', '$filter', 'FORM_FIELD_TEMPLATES', function ($q, $filter, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_DATE,
        link: function (scope, element, attrs) {

            scope.title = attrs.title;
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.failed = false;
            scope.errorMessage = null;
            scope.valid = false;

            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };
            scope.initValue = attrs.fieldValue;

            parseDate();

            scope.$watch('day', function () {
                scope.data[attrs.field].value = createDate();
            });
            scope.$watch('month', function () {
                scope.data[attrs.field].value = createDate();
            });
            scope.$watch('year', function () {
                scope.data[attrs.field].value = createDate();
            });

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        parseDate();
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && (scope.day == "" || scope.month == "" || scope.year == "")) {
                    scope.failed = true;
                    scope.valid = false;
                    scope.errorMessage = "The date is required.";
                    deferred.resolve({valid: false});
                } else {
                    if ((scope.day == "" || scope.day == null)
                        && (scope.month == "" || scope.month == null)
                        && (scope.year == "" || scope.year == null)) {

                        scope.failed = false;
                        scope.valid = true;
                        deferred.resolve({valid: true});
                    } else {
                        if (scope.processDateOfBirth() == -1 || scope.day == "" || scope.day == null
                            || scope.month == "" || scope.month == null || scope.year == "" || scope.year == null) {
                            scope.failed = true;
                            scope.valid = false;
                            deferred.resolve({valid: false});
                        } else {
                            scope.failed = false;
                            scope.valid = true;
                            deferred.resolve({valid: true});
                        }
                    }
                    scope.errorMessage = null;
                }

                return deferred.promise;
            };

            scope.allValidationPromise.push(scope.validateField);

            function parseDate() {
                var source = scope.data[attrs.field].value;
                if (source != null) {
                    var firstDelimiter = source.indexOf("-");
                    scope.day = source.substring(0, firstDelimiter);
                    var buffer = source.substring(firstDelimiter + 1, source.length);
                    var secondDelimiter = buffer.indexOf("-");
                    scope.month = buffer.substring(0, secondDelimiter);
                    scope.year = buffer.substring(secondDelimiter + 1, buffer.length);
                }
            }

            function createDate() {
                if (!(scope.day != null && scope.month != null && scope.year != null
                    && scope.day.length > 0 && scope.month.length > 0 && scope.year.length > 0)) {
                    return "";
                }
                return scope.day + "-" + scope.month + "-" + scope.year;
            }

            scope.processDateOfBirth = function () {
                var month = 0;
                if (scope.year == null || scope.month == null || scope.day == null) {
                    return -1;
                }

                var months = $filter('range')([], 'MONTH');
                for (var key in months) {
                    if (months.hasOwnProperty(key) && scope.month == months[key]) {
                        month = key;
                        return;
                    }
                }
                var date = new Date(Date.UTC(scope.year, month, scope.day, 0, 0, 0, 0));
                return isNaN(date.getMonth()) || date.getMonth() === month ? date : -1;
            };

            scope.signal = function () {
                if (scope.initValue != scope.data[attrs.field].value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.data[attrs.field].value);
                    scope.initValue = scope.data[attrs.field].value;
                }
            };
        }
    };
}]);

frontendApp.directive('formDatePicker', ['$q', '$filter', 'FORM_FIELD_TEMPLATES', function ($q, $filter, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_DATE_PICKER,
        link: function (scope, element, attrs) {

            scope.title = attrs.title;
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.failed = false;
            scope.errorMessage = attrs.errorMessage ? attrs.errorMessage : null;
            scope.valid = false;

            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };
            scope.initValue = attrs.fieldValue;

            scope.date = parseDate(scope.data[attrs.field].value);
            scope.opened = false;

            scope.$watch('date', function () {
                scope.data[attrs.field].value = $filter('date')(scope.date, 'd-MMMM-yyyy');
                scope.failed = false;
            });

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.date = parseDate(scope.data[attrs.field].value);
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            function parseDate(source) {
                if (source) {
                    var firstDelimiter = source.indexOf("-");
                    var day = source.substring(0, firstDelimiter);
                    var buffer = source.substring(firstDelimiter + 1, source.length);
                    var secondDelimiter = buffer.indexOf("-");
                    var month = buffer.substring(0, secondDelimiter);
                    var year = buffer.substring(secondDelimiter + 1, buffer.length);
                    var months = $filter('range')([], 'MONTH');
                    for (var key in months) {
                        if (months.hasOwnProperty(key) && month == months[key]) {
                            month = key;
                            break;
                        }
                    }
                    return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
                }

                return null;
            }

            scope.validateField = function () {
                var deferred = $q.defer();
                if (scope.required && (scope.date == null || scope.date == "")) {
                    scope.failed = true;
                    scope.valid = false;
                    if (scope.date === undefined) {
                        scope.errorMessage = attrs.errorMessage ? attrs.errorMessage : "Please enter valid date.";
                    } else {
                        scope.errorMessage = "The date is required.";
                    }
                    deferred.resolve({valid: false});
                } else {
                    if (scope.date === undefined) {
                        scope.errorMessage = attrs.errorMessage ? attrs.errorMessage : "Please enter valid date.";
                        scope.failed = true;
                        scope.valid = false;
                        deferred.resolve({valid: false});
                    } else {
                        scope.failed = false;
                        scope.valid = true;
                        deferred.resolve({valid: true});
                    }
                }
                return deferred.promise;
            };

            scope.allValidationPromise.push(scope.validateField);

            scope.open = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                scope.opened = true;
            };

            scope.dateOptions = {
                formatYear: 'yyyy',
                startingDay: 1
            };
        }
    };
}]);

frontendApp.directive('formRadioButton', ['$q', 'FORM_FIELD_TEMPLATES', function ($q, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_RADIO_BUTTONS,
        link: function (scope, element, attrs) {

            scope.title = attrs.title;
            scope.options = attrs.options.split(',');
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.failed = false;
            scope.valid = false;
            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };

            scope.value = attrs.fieldValue;
            if (undefined == scope.value) {
                scope.value = "";
                scope.failed = false;
            }

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.changeValue = function (val) {
                scope.value = val;
                scope.data[attrs.field].value = val;
            };

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && scope.value == "") {
                    scope.failed = true;
                    scope.valid = false;
                    deferred.resolve({valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.allValidationPromise.push(scope.validateField);
        }
    };
}]);
frontendApp.directive('formConfirmation', ['FORM_FIELD_TEMPLATES', function (FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_CONFIRMATION,
        link: function (scope, element, attrs) {
            scope.title = attrs.title;
            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };

            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;
            scope.$watch('value', function (val) {
                scope.data[attrs.field].value = val;
            });

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.signal = function () {
                if (scope.initValue != scope.value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    scope.initValue = scope.value;
                }
            };
        }
    };
}]);

frontendApp.directive('formCustomerType', ['$q', 'FORM_FIELD_TEMPLATES', function ($q, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_CUSTOMER_TYPE,
        link: function (scope, element, attrs) {
            scope.title = attrs.title;
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.failed = false;
            scope.valid = false;

            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };

            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;
            if (undefined == scope.value) {
                scope.value = "";
                scope.failed = false;
            }

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.$watch('value', function (val) {
                scope.data[attrs.field].value = val;
            });

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && scope.value == "") {
                    scope.failed = true;
                    scope.valid = false;
                    deferred.resolve({valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.signal = function () {
                if (scope.initValue != scope.value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    scope.initValue = scope.value;
                }
            };

            scope.allValidationPromise.push(scope.validateField);
        }
    };
}]);

frontendApp.directive('formPayType', ['$q', 'FORM_FIELD_TEMPLATES', function ($q, FORM_FIELD_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FORM_FIELD_TEMPLATES.FORM_PAY_TYPE,
        link: function (scope, element, attrs) {
            scope.title = attrs.title;
            scope.required = angular.isDefined(attrs.required);
            scope.requiredLabel = attrs.required;
            scope.failed = false;
            scope.valid = false;

            scope.data[attrs.field] = {
                value: attrs.fieldValue,
                values: null,
                role: attrs.role
            };

            scope.initValue = attrs.fieldValue;
            scope.value = attrs.fieldValue;
            if (undefined == scope.value) {
                scope.value = "";
                scope.failed = false;
            }

            scope.$on('formDataReloaded', function (event, fieldName) {
                if (fieldName == undefined || attrs.field == fieldName) {
                    if (scope.data[attrs.field]) {
                        scope.value = scope.data[attrs.field].value;
                    }
                    scope.failed = false;
                    if (!scope.$$phase) {
                        scope.$digest();
                    }
                }
            });

            scope.$watch('value', function (val) {
                scope.data[attrs.field].value = val;
            });

            scope.validateField = function () {
                var deferred = $q.defer();

                if (scope.required && scope.value == "") {
                    scope.failed = true;
                    scope.valid = false;
                    deferred.resolve({valid: false});
                } else {
                    scope.failed = false;
                    scope.valid = true;
                    deferred.resolve({valid: true});
                }

                return deferred.promise;
            };

            scope.signal = function () {
                if (scope.initValue != scope.value) {
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                    scope.initValue = scope.value;
                }
            };

            scope.allValidationPromise.push(scope.validateField);
        }
    };
}]);

frontendApp.directive('formSubmit', ['lockOperationsFacade', function (lockOperationsFacade) {
    return {
        restrict: 'A',
        scope: true,
        replace: true,
        transclude: true,
        template: '<div class="ngc-forms-submit">{{title}}</div>',
        link: function (scope, element, attrs) {
            scope.title = attrs.title;

            element.bind('click', function () {
                lockOperationsFacade.lock();
                scope.executeFormSubmit(scope.defaultActionId)['finally'](function () {
                    //lockOperationsFacade.unlock(true);
                });
            });
        }
    };
}]);

frontendApp.directive('formSubmitWithConfirmation', ['lockOperationsFacade', 'FORM_FIELD_TEMPLATES', '$modal',
    function (lockOperationsFacade, FORM_FIELD_TEMPLATES, $modal) {
        return {
            restrict: 'A',
            scope: true,
            replace: true,
            transclude: true,
            template: '<div class="ngc-forms-submit">{{title}}</div>',
            link: function (scope, element, attrs) {
                scope.title = attrs.title;
                scope.executeSave = function () {
                    var staticModal;
                    if (attrs.modalViewId) {
                        staticModal = '<div class="modal-content" static-modal view-id="' + attrs.modalViewId + '"></div>';
                    } else {
                        staticModal = "<div class='ng-scope' style='text-align:center;'>" +
                            "<p>" + attrs.confirmationQuestion + "</p>" +
                            "<br/>" +
                            "<div>" +
                            "<div  class='btn btn-primary btn-lg ng-scope' ng-click='formSave($event);'>Yes</div>" +
                            "<div  class='btn btn-primary btn-lg ng-scope' ng-click='formCancel($event)'>No</div>" +
                            "</div>" +
                            "</div>";
                    }
                    $modal.open({
                        template: staticModal,
                        controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                            $scope.$on('EVT_USER_SESSION_EXPIRED', function () {
                                $modalInstance.dismiss();
                            });
                            $scope.formSave = function ($event) {
                                if (attrs.field && attrs.submitValue) {
                                    scope.data[attrs.field] = {
                                        value: attrs.submitValue,
                                        values: null,
                                        role: attrs.role
                                    };
                                }
                                $event.stopPropagation();
                                $modalInstance.close();
                                lockOperationsFacade.lock();
                                scope.executeFormSubmit(scope.defaultActionId)['finally'](function () {
                                    lockOperationsFacade.unlock(!scope.defaultActionId || scope.defaultActionId == "");
                                });
                            };
                            $scope.formCancel = function ($event) {
                                $event.stopPropagation();
                                $modalInstance.close();
                            };
                        }],
                        backdrop: true
                    });
                };
            }
        };
    }]);

frontendApp.directive('formCancel', ['FORM_FIELD_TEMPLATES', 'lockOperationsFacade', function (FORM_FIELD_TEMPLATES, lockOperationsFacade) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: '<div class="ngc-forms-cancel">{{title}}</div>',
        link: function (scope, element, attrs) {
            scope.title = attrs.title;
            element.bind('click', function ($event) {
                $event.stopPropagation();
                lockOperationsFacade.lock();
                scope.executeFormCancel()['finally'](function () {
                    lockOperationsFacade.unlock();
                });
            });
        }
    };
}]);

frontendApp.directive('formComplexRadioButton', ['$q', 'FORM_FIELD_TEMPLATES', 'contentService', '$filter',
    function ($q, FORM_FIELD_TEMPLATES, contentService, $filter) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.FORM_COMPLEX_RADIO_BUTTONS,
            link: function (scope, element, attrs) {
                var interactionKey = scope.saleSessionManager.getInteractionKey();
                scope.multiLanguage = ripplMultiLanguage;
                scope.title = attrs.title;
                scope.titleWithoutSpaces = scope.title.replace(" ", "");
                contentService.query({
                        action: "get-select-options",
                        fieldName: attrs.field,
                        formId: scope.formId,
                        interaction: interactionKey
                    },
                    function (result) {
                        scope.options = result;
                    });

                scope.required = angular.isDefined(attrs.required);
                scope.requiredLabel = attrs.required;
                scope.failed = false;
                scope.valid = false;
                scope.initValue = attrs.fieldValue;
                scope.data[attrs.field] = {
                    value: attrs.fieldValue,
                    values: null,
                    role: attrs.role
                };

                scope.selected = {value: attrs.fieldValue};
                if (undefined == scope.selected.value) {
                    scope.selected = {value: ""};
                    scope.failed = false;
                }

                if (ripplMultiLanguage) {
                    updateTranslations();
                    scope.$on('updateTranslations', function () {
                        updateTranslations();
                    });
                }

                function updateTranslations() {
                    scope.errorMessageData = {
                        fieldName: $filter("translate")(scope.title)
                    };
                    attrs.$set("title", $filter("translate")(scope.title));
                }

                scope.$on('formDataReloaded', function (event, fieldName) {
                    if (fieldName == undefined || attrs.field == fieldName) {
                        if (scope.data[attrs.field]) {
                            scope.selected.value = scope.data[attrs.field].value;
                        }
                        scope.failed = false;
                        if (!scope.$$phase) {
                            scope.$digest();
                        }
                    }
                });

                scope.$watch('selected.value', function () {
                    //scope.selected.value = val;
                    scope.data[attrs.field].value = scope.selected.value;
                    scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.selected.value);
                    scope.initValue = scope.selected.value;
                });

                scope.validateField = function () {
                    var deferred = $q.defer();

                    if (scope.required && scope.selected.value == "") {
                        if (ripplMultiLanguage) {
                            scope.errorMessage = "SYS_FIELD_REQUIRED_ERROR";
                        } else {
                            scope.errorMessage = "The " + scope.title + " is required.";
                        }
                        scope.failed = true;
                        scope.valid = false;
                        deferred.resolve({message: scope.errorMessage, valid: false});
                    } else {
                        scope.failed = false;
                        scope.valid = true;
                        deferred.resolve({valid: true});
                    }

                    return deferred.promise;
                };

                scope.signal = function () {
                    scope.validateField();
                };

                scope.allValidationPromise.push(scope.validateField);
            }
        };
    }]);

frontendApp.directive('formSelect', ['$q', 'FORM_FIELD_TEMPLATES', 'commonService', '$rootScope',
    'applicationFacade', 'formSelectUIService', '$sce', '$filter', 'FORM_SELECT_EMPTY_VALUE', 'lockOperationsFacade',
    function ($q, FORM_FIELD_TEMPLATES, commonService, $rootScope,
              applicationFacade, formSelectUIService, $sce, $filter, FORM_SELECT_EMPTY_VALUE, lockOperationsFacade) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.FORM_SELECT,
            link: function (scope, element, attrs) {
                var interactionKey = scope.saleSessionManager.getInteractionKey();
                scope.multiLanguage = ripplMultiLanguage;
                scope.title = attrs.title;
                scope.subtitle = attrs.subtitle;
                scope.values = [];
                scope.data[attrs.field] = {
                    value: attrs.fieldValue,
                    values: scope.values,
                    role: attrs.role
                };
                scope.priorityCheck = false;

                var maxSelection = 1;
                var minSelection = 1;
                var visibleButton = false;
                formSelectUIService.init();

                commonService.get({
                    module: 'forms',
                    action: 'attribute',
                    clientId: $rootScope.user.channelClientId,
                    formId: scope.formId,
                    attribute: attrs.field
                }, function (result) {
                    if (result.title) {
                        scope.title = result.title;
                    }
                    if (result.subtitle) {
                        scope.subtitle = result.subtitle;
                    }
                });

                commonService.get({
                        module: applicationFacade.getContentModule(),
                        action: 'get-form-select-data',
                        formId: scope.formId,
                        fieldName: attrs.field,
                        interaction: interactionKey,
                        processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null
                    },
                    function (result) {
                        var formField = result.data[attrs.field];
                        var previousValues = formField.values;
                        maxSelection = formField.maxOptionsSelect;
                        minSelection = formField.minOptionsSelect;
                        visibleButton = formField.actionButtonIsPresented;
                        scope.options = formField.options;
                        scope.priorityCheck = formField.priorityCheck;

                        if (scope.priorityCheck) {
                            attrs.$set("class", attrs.class + " rippl-form-select-priority-type");
                        }

                        if (previousValues) {
                            scope.values = previousValues;
                            scope.data[attrs.field].values = previousValues;

                            for (var i = 0; i < scope.options.length; i++) {
                                var option = scope.options[i];
                                var foundIndex = scope.values.indexOf(option.value);
                                if (foundIndex !== -1) {
                                    option.selected = true;
                                    if (scope.priorityCheck) {
                                        option.priority = foundIndex + 1;
                                    }
                                }
                            }
                        }

                        formSelectUIService.setVisibleButton(visibleButton);
                        if (visibleButton) {
                            formSelectUIService.setDisableButton(scope.isRequiredValuesNotSelected());
                        }

                        scope.$watch('values', function () {
                            scope.$emit('userChangedDataEvent', attrs.field, scope.validateField);
                        }, true);
                    }
                );

                scope.required = angular.isDefined(attrs.required);
                scope.requiredLabel = attrs.required;
                scope.failed = false;
                scope.valid = false;
                scope.initValue = attrs.fieldValue;

                scope.isImagePresented = function (option) {
                    return option && option.imageSource && option.imageSource.length > 0;
                };

                scope.getImage = function (option) {
                    if (scope.isImagePresented(option)) {
                        if(option.imageSource.toLowerCase().startsWith("http")) {
                            return $sce.getTrustedHtml("<img src='" + option.imageSource + "' />");
                        } else {
                            return $sce.getTrustedHtml("<img src='" + restUrl + "/custom/" + option.imageSource + "' />");
                        }
                    } else {
                        return "";
                    }
                };

                scope.validateField = function () {
                    var deferred = $q.defer();

                    if ((scope.required && scope.isRequiredValuesNotSelected()) || scope.hasEmptyElements()) {
                        scope.failed = true;
                        scope.valid = false;
                        deferred.resolve({valid: false});
                    } else {
                        scope.failed = false;
                        scope.valid = true;
                        deferred.resolve({valid: true});
                    }

                    return deferred.promise;
                };

                scope.hasEmptyElements = function () {
                    try {
                        return scope.priorityCheck && scope.values.includes(FORM_SELECT_EMPTY_VALUE);
                    } catch (e) {
                        // do nothing
                    }
                };

                scope.isRequiredValuesNotSelected = function () {
                    return scope.values.length < minSelection;
                };

                scope.selectValue = function (option) {

                    if(option.noPreference) {
                        if(option.selected) {
                            angular.forEach(scope.options, function(opt) {
                                opt.selected = false;
                                if (opt.value == option.value) {
                                    opt.disabled = false;
                                } else {
                                    opt.disabled = false;
                                }
                            });
                        } else {
                            angular.forEach(scope.options, function(opt) {
                                if (opt.value == option.value) {
                                    opt.selected = true;
                                } else {
                                    if(scope.values.includes(opt.value)) {
                                        scope.values.splice(scope.values.indexOf(opt.value), 1);
                                    }
                                    opt.selected = false;
                                    opt.disabled = true;
                                }
                            });
                        }
                    }

                    var selectedValue = option.value;
                    var foundIndex = scope.values.indexOf(selectedValue);

                    if (scope.priorityCheck) {
                        if (foundIndex === -1) {
                            var firstEmptyElement = scope.values.indexOf(FORM_SELECT_EMPTY_VALUE);
                            if (firstEmptyElement > -1) {
                                scope.values[firstEmptyElement] = selectedValue;
                                option.selected = true;
                                option.priority = firstEmptyElement + 1;
                            } else {
                                if (scope.values.length < maxSelection) {
                                    scope.values.push(selectedValue);
                                    option.selected = true;
                                    option.priority = scope.values.length;
                                }
                            }
                        } else {
                            scope.values.splice(foundIndex, 1);
                            angular.forEach(scope.options, function(opt) {
                                if (opt.selected && option !== opt && opt.priority > option.priority) {
                                    opt.priority --;
                                }
                            });
                            option.selected = false;
                            option.priority = null;
                        }
                    } else {
                        if (foundIndex === -1 && scope.values.length < maxSelection) {
                            scope.values.push(selectedValue);
                            option.selected = true;
                        } else if (foundIndex > -1) {
                            scope.values.splice(foundIndex, 1);
                            option.selected = false;
                        }
                    }

                    if (visibleButton) {
                        formSelectUIService.setDisableButton(scope.isRequiredValuesNotSelected());
                    } else {
                        if (maxSelection === 1) {
                            lockOperationsFacade.lock();
                        }
                        formSelectUIService.clickActionButton(scope.values.length === maxSelection && !scope.hasEmptyElements());
                    }
                };

                if (ripplMultiLanguage) {
                    scope.errorMessage = "SYS_FIELD_REQUIRED_ERROR";
                    scope.$on('updateTranslations', function () {
                        updateTranslations();
                    });
                    updateTranslations();
                } else {
                    scope.errorMessage = "The " + scope.title + " is required.";
                }

                function updateTranslations() {
                    scope.errorMessageData = {
                        fieldName: $filter("translate")(scope.title)
                    };

                    if (scope.title) {
                        attrs.$set("title", $filter("translate")(scope.title));
                    }
                    if (scope.subtitle) {
                        attrs.$set("subtitle", $filter("translate")(scope.subtitle));
                    }
                }

                scope.allValidationPromise.push(scope.validateField);
            }
        };
    }]);


frontendApp.directive("simpleDatalist", [function () {
    return {
        restrict: 'E',
		template: function(element, attr){
			if(attr.options){
				var datalistElement = document.createElement('datalist');
				datalistElement.id = 'igsDataList';
                var datalistOptionsArray = attr.options.split('|');
                for (var i = 0; i < datalistOptionsArray.length; i++) {
                    var optionElement = document.createElement('option');
                    optionElement.value = datalistOptionsArray[i].trim();
                    datalistElement.appendChild(optionElement);
                }
				return datalistElement.outerHTML;
            }
			return '<datalist id="igsDataList"></datalist>';
		},
        link: function () {
			setTimeout(function () {
                var options = document.getElementById('igsDataList').options;
                var values = [];
                var i = 0, len = options.length;
                while (i < len)
                {
                    values.push(options[i++].value);
                }
                document.querySelector('.rippl-form-common-input input').setAttribute('list', 'igsDataList');
                document.querySelector('.rippl-form-common-input input').setAttribute('pattern', values.join('|'));
			}, 100);
        }
    };
}]);

frontendApp.directive("simpleMap", ['inputUIService', function (inputUIService) {
    return {
        restrict: 'E',
		template: '<div id="map">&nbsp;</div><div class="rippl-form rippl-form-common-input" field="Response1" form-common-input="" ng-hide="true" required><!-- Don`t place content into this block --></div></div><div class="loading" id="loadingV2"><div class="loadingBox"><div class="loadingBoxIn"><div class="loadingPic">&nbsp;</div><div class="loadingLabel custom-font-2" translate="SYS_LOADING">LOADING...</div></div></div></div>',
        link: function (scope, element, attr) {

            inputUIService.init();
            inputUIService.setDisableButton(true);

            // Scripts need to be loaded in sync mode
            var loadScript = function (url, scriptName) {
                var script = document.createElement('script');
                script.src = url;
                document.getElementsByTagName('head')[0].appendChild(script);

                script.onload = script.onreadystatechange = function () {
                    if (!this.readyState || this.readyState == 'complete') {
                        if(scriptName == 'mapDataJsUrl') {
                            console.debug('mapDataJsUrl loaded');
                            loadScript(attr.mapJsUrl, "mapJsUrl");
                        } else if(scriptName == 'mapJsUrl') {
                            console.debug('mapJsUrl loaded');
                            loadScript(attr.selectJsUrl, "selectJsUrl");
                        } else {
                            console.debug('selectJsUrl loaded');
                            if(attr.mapRegion == 'WORLD') {
                                simplemaps_select.map=simplemaps_worldmap; // eslint-disable-line no-undef

                                // World map select doesn't have click_state
                                // Set its click_region property
                                simplemaps_select.map.plugin_hooks.click_region.push(display_selected); // eslint-disable-line no-undef
                            } else if(attr.mapRegion == 'CANADA') {
                                simplemaps_select.map=simplemaps_canadamap; // eslint-disable-line no-undef
                            } else if(attr.mapRegion == 'AUSTRALIA') {
                                simplemaps_select.map=simplemaps_australiamap; // eslint-disable-line no-undef
                            } else if(attr.mapRegion == 'EUROPE') {
                                simplemaps_select.map=simplemaps_europemap; // eslint-disable-line no-undef
                            } else if(attr.mapRegion == 'USA') {
                                simplemaps_select.map=simplemaps_usmap; // eslint-disable-line no-undef
                            } else {
                                simplemaps_select.map=simplemaps_countrymap; // eslint-disable-line no-undef
                            }
                            simplemaps_select.map.plugin_hooks.click_state.push(display_selected); // eslint-disable-line no-undef
                            simplemaps_select.map.plugin_hooks.click_location.push(display_selected); // eslint-disable-line no-undef
                            simplemaps_select.max = attr.mapSelectCount; // eslint-disable-line no-undef
                            simplemaps_select.min = attr.mapSelectMin; // eslint-disable-line no-undef
                            simplemaps_select.select_type = attr.mapType; // eslint-disable-line no-undef
                            document.getElementById("loadingV2").style.display = 'none';
                        }
                    }
                };
            };

            loadScript(attr.mapDataJsUrl, 'mapDataJsUrl');

            // Input string creation and validation on map click
            function display_selected() {
                setTimeout(function () {
                    let inpFld = document.querySelector('.rippl-form-common-input input');
                    let selectedItems = [];

                    if (simplemaps_select.selected.length >= simplemaps_select.min) { // eslint-disable-line no-undef
                        // inpFld.value = attr.mapSelectCount == 1 ? simplemaps_select.selected[0] : JSON.stringify(simplemaps_select.selected); // eslint-disable-line no-undef
                        if (attr.mapType == 'state') { // eslint-disable-line no-undef
							// is state
							for (let i = 0; i < simplemaps_select.selected.length; i++){ // eslint-disable-line no-undef
								let igs_id = simplemaps_select.map.mapdata.state_specific[simplemaps_select.selected[i]].igs_id; // eslint-disable-line no-undef
								selectedItems.push(igs_id); // eslint-disable-line no-undef
							}
						} else if (attr.mapType == 'location') {
							// is location
							for (let i = 0; i < simplemaps_select.selected.length; i++){ // eslint-disable-line no-undef
								let igs_loc_id = simplemaps_select.map.mapdata.locations[simplemaps_select.selected[i]].igs_loc_id; // eslint-disable-line no-undef
								selectedItems.push(igs_loc_id); // eslint-disable-line no-undef
							}
						} else {
							// is location
							for (let i = 0; i < simplemaps_select.selected.length; i++){ // eslint-disable-line no-undef
								let igs_loc_id = simplemaps_select.map.mapdata.regions[simplemaps_select.selected[i]].igs_loc_id; // eslint-disable-line no-undef
								selectedItems.push(igs_loc_id); // eslint-disable-line no-undef
							}
						}

						inpFld.value = JSON.stringify(selectedItems);

                        inputUIService.setDisableButton(false);
                        inpFld.dispatchEvent(new Event('input'));
                    } else {
                        inputUIService.setDisableButton(true);
                        inpFld.value = '';
                        inpFld.dispatchEvent(new Event('input'));
					}
                }, 100);
            }
        }
    };
}]);

frontendApp.directive("simpleMapV2", ['inputUIService', function (inputUIService) {
    return {
        restrict: 'E',
		template: `
		    <div id="map">&nbsp;</div>
            <div class="rippl-form rippl-form-common-input" id="displayInputField" field="Response1" form-common-input="" ng-hide="true" required>
            </div>
            <div class="rippl-form rippl-form-common-input" id="hubbleInputField" field="MAP_IGS_ID" form-common-input="" ng-hide="true" required>
            </div>
            <div class="loading" id="loadingV2">
              <div class="loadingBox">
                <div class="loadingBoxIn">
                  <div class="loadingPic">&nbsp;</div>
                  <div class="loadingLabel custom-font-2" translate="SYS_LOADING">LOADING...</div>
                </div>
              </div>
            </div>
		`,
        link: function (scope, element, attr) {

            let mapType = attr.mapType;
            let selectionType = attr.selectionType;
            let maxSelectedCount = attr.maxSelectedCount;
            let minSelectedCount = attr.minSelectedCount;
            let dataCaptureValue = attr.captureValue;
            let responsesValueText = attr.responsesValueText;

            //backward compatibility code starts
            if(!mapType && attr.mapRegion) {
                mapType = attr.mapRegion;
                console.log("mapType: "+ mapType);
            }

            if(!selectionType) {
                selectionType = "state";
                console.log("selectionType: "+ selectionType);
            }

            if(!maxSelectedCount && attr.mapSelectCount) {
                maxSelectedCount = attr.mapSelectCount;
                console.log("maxSelectedCount: "+ maxSelectedCount);
            }

            if(!minSelectedCount && attr.mapSelectCount) {
                minSelectedCount = attr.mapSelectCount;
                console.log("minSelectedCount: "+ minSelectedCount);
            }
            //backward compatibility code ends

            if(!responsesValueText) {
                responsesValueText = "name";
            }

            inputUIService.init();
            if (minSelectedCount == 0) {
                inputUIService.setDisableButton(false);
            } else {
                inputUIService.setDisableButton(true);
            }

            // Scripts need to be loaded in sync mode
            var loadScript = function (url, scriptName) {
                var script = document.createElement('script');
                script.src = url;
                document.getElementsByTagName('head')[0].appendChild(script);

                script.onload = script.onreadystatechange = function () {
                    if (!this.readyState || this.readyState == 'complete') {
                        if(scriptName == 'mapDataJsUrl') {
                            console.debug('mapDataJsUrl loaded');
                            loadScript(attr.mapJsUrl, "mapJsUrl");
                        } else if(scriptName == 'mapJsUrl') {
                            console.debug('mapJsUrl loaded');
                            loadScript(attr.selectJsUrl, "selectJsUrl");
                        } else {
                            console.debug('selectJsUrl loaded');

                            console.log("mapType value is: "+ mapType);
                            if(mapType == 'WORLD') {
                                simplemaps_select.map=simplemaps_worldmap; // eslint-disable-line no-undef
                            } else if(mapType == 'CANADA') {
                                simplemaps_select.map=simplemaps_canadamap; // eslint-disable-line no-undef
                            } else if(mapType == 'AUSTRALIA') {
                                simplemaps_select.map=simplemaps_australiamap; // eslint-disable-line no-undef
                            } else if(mapType == 'EUROPE') {
                                simplemaps_select.map=simplemaps_europemap; // eslint-disable-line no-undef
                            } else if(mapType == 'USA_STATE') {
                                simplemaps_select.map=simplemaps_usmap; // eslint-disable-line no-undef
                            } else if(mapType == 'UNITED_KINGDOM') {
                                simplemaps_select.map=simplemaps_ukmap; // eslint-disable-line no-undef
                            } else if(mapType == 'USA_CONGRESS') {
                                simplemaps_select.map=simplemaps_congressmap; // eslint-disable-line no-undef
                            } else if(mapType == 'NORTH_AMERICA') {
                                simplemaps_select.map=simplemaps_namap; // eslint-disable-line no-undef
                            } else if(mapType == 'USA_COUNTY') {
                                simplemaps_select.map=simplemaps_countymap; // eslint-disable-line no-undef
                            }  else {
                                console.warn("mapType is invalid or undefined");
                                simplemaps_select.map=simplemaps_countrymap; // eslint-disable-line no-undef
                            }

                            console.log("selectionType value is: "+ selectionType);
                            if (selectionType == 'state') {
                                simplemaps_select.map.plugin_hooks.click_state.push(display_selected); // eslint-disable-line no-undef
                            } else if (selectionType == 'location') {
                                simplemaps_select.map.plugin_hooks.click_location.push(display_selected); // eslint-disable-line no-undef
                            } else if (selectionType == 'region') {
                                simplemaps_select.map.plugin_hooks.click_region.push(display_selected); // eslint-disable-line no-undef
                            } else {
                                console.warn("selectionType is invalid or undefined");
                                simplemaps_select.map.plugin_hooks.click_region.push(display_selected); // eslint-disable-line no-undef
                            }

                            simplemaps_select.max = maxSelectedCount; // eslint-disable-line no-undef
                            simplemaps_select.min = minSelectedCount; // eslint-disable-line no-undef
                            simplemaps_select.select_type = selectionType; // eslint-disable-line no-undef
                            document.getElementById("loadingV2").style.display = 'none';

                            if (minSelectedCount == 0) {
                                document.querySelector('#displayInputField input').required = false;
                                document.querySelector('#hubbleInputField input').required = false;
                            }
                        }
                    }
                };
            };

            loadScript(attr.mapDataJsUrl, 'mapDataJsUrl');

            // Input string creation and validation on map click
            function display_selected() {
                setTimeout(function () {
                    let displayInputField = document.querySelector('#displayInputField input');
                    let hubbleInputField = document.querySelector('#hubbleInputField input');

                    let minSelectedCount = simplemaps_select.min; // eslint-disable-line no-undef
                    if(!minSelectedCount) {
                        minSelectedCount = 1;
                    }

                    if (simplemaps_select.selected.length >= minSelectedCount) {  // eslint-disable-line no-undef

                        let masterData;
                        console.log("selectionType value is: "+ selectionType);
                        if (selectionType == 'state') {
                            masterData = simplemaps_select.map.mapdata.state_specific; // eslint-disable-line no-undef
                        } else if (selectionType == 'location') {
                            masterData = simplemaps_select.map.mapdata.locations; // eslint-disable-line no-undef
                        } else if (selectionType == 'region') {
                            masterData = simplemaps_select.map.mapdata.regions; // eslint-disable-line no-undef
                        } else {
                            console.log("Can't decide on masterData");
                        }

                        let displayValues = [];
                        let hubbleKeys = [];
                        for (let i = 0; i < simplemaps_select.selected.length; i++){ // eslint-disable-line no-undef
                            if (masterData) {
                                let displayValue = responsesValueText ? masterData[simplemaps_select.selected[i]][responsesValueText] : simplemaps_select.selected[i]; // eslint-disable-line no-undef
                                if (displayValue) {
                                    displayValues.push(displayValue);
                                } else {
                                    displayValues.push(simplemaps_select.selected[i]);  // eslint-disable-line no-undef
                                }
                                let hubbleKey = dataCaptureValue ? masterData[simplemaps_select.selected[i]][dataCaptureValue] : simplemaps_select.selected[i]; // eslint-disable-line no-undef
                                if (hubbleKey) {
                                    hubbleKeys.push(hubbleKey);
                                } else {
                                    hubbleKeys.push(simplemaps_select.selected[i]);  // eslint-disable-line no-undef
                                }
                            } else {
                                console.log("masterData: " + masterData);
                                console.log("responsesValueText: " + responsesValueText);
                                console.log("dataCaptureValue: " + dataCaptureValue);
                                displayValues.push(simplemaps_select.selected[i]); // eslint-disable-line no-undef
                                hubbleKeys.push(simplemaps_select.selected[i]); // eslint-disable-line no-undef
                            }
                        }

                        displayInputField.value = displayValues; // eslint-disable-line no-undef
                        hubbleInputField.value = hubbleKeys; // eslint-disable-line no-undef

                        inputUIService.setDisableButton(false);

                        displayInputField.dispatchEvent(new Event('input'));
                        hubbleInputField.dispatchEvent(new Event('input'));
                    } else {
                        inputUIService.setDisableButton(true);

                        displayInputField.value = '';
                        hubbleInputField.value = '';

                        displayInputField.dispatchEvent(new Event('input'));
                        hubbleInputField.dispatchEvent(new Event('input'));
					}
                }, 100);
            }
        }
    };
}]);

frontendApp.directive('formSelectActionButton', ['FORM_FIELD_TEMPLATES', 'formSelectUIService',
    function (FORM_FIELD_TEMPLATES, formSelectUIService) {
        return {
            restrict: 'A',
            replace: true,
            transclude: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.FORM_SELECT_ACTION_BUTTON,
            link: function (scope, element) {
                // wait information about visibility of button.
                scope.visible = false;
                scope.$watch(function () {
                    return formSelectUIService.isVisibleButton();
                }, function (visible) {
                    scope.visible = visible;
                });


                // enable action button if at least 1 element was selected.
                scope.disabled = true;
                scope.$watch(function () {
                    return formSelectUIService.isDisableButton();
                }, function (disabled) {
                    scope.disabled = disabled;
                });

                // perform click on button if button is hidden and max element was reached.
                scope.$watch(function () {
                    return formSelectUIService.getButtonListener();
                }, function (fire) {
                    if (fire) {
                        angular.element(element).find('div[action-id]:first-of-type').trigger('click');
                    }
                });
            }
        };
    }]);

frontendApp.directive('inputActionButton', ['FORM_FIELD_TEMPLATES', 'inputUIService',
    function (FORM_FIELD_TEMPLATES, inputUIService) {
        return {
            restrict: 'A',
            replace: true,
            transclude: true,
            scope: true,
            template: FORM_FIELD_TEMPLATES.INPUT_ACTION_BUTTON,
            link: function (scope) {

                scope.disabled = false;
                scope.$watch(function () {
                    return inputUIService.isDisableButton();
                }, function (disabled) {
                    scope.disabled = disabled;
                });
            }
        };
    }]);

frontendApp.directive("validation", [function () {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elm, attrs) {
            var limit = parseInt(attrs.maxlength);
            var pattern = attrs.validationPattern;
            var regex = new RegExp(pattern, 'i');
            var message = attrs.validationMessage;

            angular.element(elm).on("keypress", function (e) {
                if (this.value.length == limit || !this.value.match(regex)) {
                    e.preventDefault();
                    if (message) {
                        scope.$parent.errorMessage = message;
                    }
                } else {
                    scope.$parent.errorMessage = '';
                }
            });
        }
    };
}]);

frontendApp.directive('formCommonInput', ['$q', '$filter', '$compile', 'FORM_FIELD_TEMPLATES', '$log',
    function ($q, $filter, $compile, FORM_FIELD_TEMPLATES, $log) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            link: function (scope, element, attrs) {
                scope.multiLanguage = ripplMultiLanguage;
                scope.title = attrs.title;
                scope.placeholder = attrs.placeholder;
                scope.required = angular.isDefined(attrs.required);
                scope.type = attrs.type;
                scope.pattern = attrs.pattern;
                scope.errorMessage = attrs.errorMessage;
                scope.isCustomErrorMessageUi = angular.isDefined(attrs.customErrorMessageUi);
                scope.onClickCustomHandler = attrs.onClickCustomHandler;
                scope.inputId = "rippl-form-input-" + new Date().getTime();

                scope.failed = false;
                scope.valid = false;

                var valueFromAttr;
                if (scope.type == "number") {
                    if (isNumeric(attrs.fieldValue)) {
                        valueFromAttr = Number(attrs.fieldValue);
                    }
                } else {
                    valueFromAttr = attrs.fieldValue;
                }
                scope.data[attrs.field] = {
                    value: valueFromAttr,
                    values: null,
                    role: attrs.role
                };
                scope.initValue = valueFromAttr;
                scope.initValueReadOnly = valueFromAttr;
                scope.value = valueFromAttr;
                if (undefined == scope.value) {
                    scope.value = "";
                    scope.failed = false;
                }

                // for support old (current unused) functionality ("Recall sale session")
                scope.$on('formDataReloaded', function (event, fieldName) {
                    if (fieldName == undefined || attrs.field == fieldName) {
                        if (scope.data[attrs.field]) {
                            scope.value = scope.data[attrs.field].value;
                        }
                        scope.failed = false;
                        if (!scope.$$phase) {
                            scope.$digest();
                        }
                    }
                });

                function isNumeric(value) {
                    return /^-{0,1}\d+$/.test(value);
                }

                scope.executeOnClickCustomHandler = function () {
                    var handler = new Function("return " + scope.onClickCustomHandler)();
                    handler();
                };

                scope.buildTemplate = function () {
                    var inputFieldHTML = FORM_FIELD_TEMPLATES.FORM_COMMON_INPUT_HEADER +
                        "<input class='rippl-form-input-field' id='" + scope.inputId + "' type='" + scope.type + "'";
                    if (scope.placeholder) {
                        inputFieldHTML = inputFieldHTML + " placeholder='{{placeholder | translate}}'";
                    }
                    if (scope.required) {
                        inputFieldHTML = inputFieldHTML + " required";
                    }
                    if (scope.pattern) {
                        inputFieldHTML = inputFieldHTML + " pattern='" + scope.pattern + "'";
                    }
                    if (scope.onClickCustomHandler) {
                        inputFieldHTML = inputFieldHTML + " ng-click='executeOnClickCustomHandler()'";
                    }
                    if (scope.isCustomErrorMessageUi) {
                        inputFieldHTML = inputFieldHTML + " oninvalid='event.preventDefault()'";
                    } else {
                        inputFieldHTML = inputFieldHTML + " ng-keydown='onKeyDown($event)'";
                    }

                    inputFieldHTML = inputFieldHTML + " ng-model='value' ng-blur='signal()'/>";
                    inputFieldHTML = inputFieldHTML + FORM_FIELD_TEMPLATES.FORM_COMMON_INPUT_FOOTER;

                    return inputFieldHTML;
                };

                scope.onKeyDown = function ($event) {
                    var keyCode = $event.which || $event.keyCode;
                    if (keyCode == 13) {
                        $event.preventDefault();
                    }
                    $log.debug("Key code: " + keyCode);
                };

                scope.validateField = function () {
                    var deferred = $q.defer();
                    var input = element.find("#" + scope.inputId)[0];

                    if (input.checkValidity()) {
                        if (!scope.isCustomErrorMessageUi) {
                            try {
                                input.reportValidity();
                            } catch (e) {
                                // do nothing
                            }
                        }
                        scope.failed = false;
                        scope.valid = true;
                        deferred.resolve({valid: true});
                    } else {
                        if (!scope.isCustomErrorMessageUi) {
                            if (scope.errorMessage) {
                                if (ripplMultiLanguage) {
                                    input.setCustomValidity($filter("translate")(scope.errorMessage));
                                } else {
                                    input.setCustomValidity(scope.errorMessage);
                                }
                            }
                            try {
                                input.reportValidity();
                            } catch (e) {
                                // do nothing
                            }
                        }

                        // If it has initial value that means that already valid. Fix for ODT015432-4024
                        if (scope.initValueReadOnly !== '' && scope.initValueReadOnly === scope.value) {
                            scope.failed = false;
                            scope.valid = true;
                        } else {
                            scope.failed = true;
                            scope.valid = false;
                        }

                        deferred.resolve({message: scope.errorMessage, valid: scope.valid});
                    }
                    return deferred.promise;
                };

                scope.signal = function () {
                    if (scope.initValue != scope.value) {
                        scope.$emit('fieldChanged', scope.viewId, attrs.field, scope.value);
                        scope.initValue = scope.value;
                    }
                };

                scope.$watch('value', function (val) {
                    if (scope.data[attrs.field]) {
                        scope.data[attrs.field].value = val;
                        scope.failed = false;
                    }
                    if (scope.errorMessage) {
                        element.find("#" + scope.inputId)[0].setCustomValidity('');
                    }
                    $log.debug('Value has been changed.');
                    scope.$emit('userChangedDataEvent', attrs.field, scope.validateField);
                });

                if (ripplMultiLanguage) {
                    scope.$on('updateTranslations', function () {
                        updateTranslations();
                    });
                    updateTranslations();
                }

                function updateTranslations() {
                    if (scope.title) {
                        element.find("#" + scope.inputId).attr("title", $filter("translate")(scope.title));
                    }
                }

                scope.allValidationPromise.push(scope.validateField);

                element.html($compile(scope.buildTemplate())(scope));

                element.attr("title", "");
            }
        };
    }]);

frontendApp.directive('formApolloSlider', ['SLIDER_REPLACEMENT', 'FORM_FIELD_TEMPLATES', '$compile', '$filter', '$q', '$rootScope', 'commonService',
    function (SLIDER_REPLACEMENT, FORM_FIELD_TEMPLATES, $compile, $filter, $q, $rootScope, commonService) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        compile: function () {
            return {
                pre: function (scope) {
                    scope.clientId = $rootScope.user.channelClientId;
                    commonService.get({
                            module: 'lookup',
                            action: 'get-form-dictionary',
                            clientId: scope.clientId
                        },
                        function (result) {
                            scope.apolloSliderGridLegendLoockup = result.apolloSliderGridLegendLoockup;
                            scope.apolloSliderValuePositionLoockup = result.apolloSliderValuePositionLoockup;
                        }
                    );

                },
                post: function (scope, element, attrs) {
                    scope.multiLanguage = ripplMultiLanguage;
                    scope.clientId = $rootScope.user.channelClientId;

                    commonService.get({
                        module: 'forms',
                        action: 'attribute',
                        clientId: scope.clientId,
                        formId: scope.formId,
                        attribute: attrs.field
                    }, function (result) {
                        scope.attribute = result;
                        angular.forEach(scope.attribute.sliders, function(slider) {
                            initSlider(slider);
                            if ('RANGE' == slider.type) {
                                scope.data[slider.attrName] = {
                                    values: slider.value,
                                    role: attrs.role
                                };
                            } else {
                                scope.data[slider.attrName] = {
                                    value: slider.value,
                                    role: attrs.role
                                };
                            }
                        });
                        updateTranslations();
                    });

                    function initSlider(slider) {
                        slider.value = [slider.initial, slider.initial2];
                        if ('RANGE' == slider.type) {
                            slider.value = [slider.initial, slider.initial2];
                        } else {
                            slider.value = slider.initial;
                        }
                    }

                    scope.rangeInit = function(slider) {
                        if ('RANGE' == slider.type) {
                            return 'true';
                        } else {
                            return 'min';
                        }
                    };

                    scope.initSliderValue = function(slider) {
                        var result = "";
                        if ('RANGE' == slider.type) {
                            result = "values: [";
                            result += slider.initial;
                            result += " ,";
                            result += slider.initial2;
                            return result + "]";
                        } else {
                            result = "value: ";
                            result += slider.initial;
                            return result + "";
                        }
                    };

                    scope.range = function(slider) {
                        var minV = slider.min,
                            maxV = slider.max,
                            stepV = slider.step;
                        var input = [];
                        for (var i = minV; i <= maxV; i += stepV) {
                            input.push(i);
                        }
                        return input;
                    };

                    scope.applyClasses = function(slider) {
                        var result = "";
                        if ('RANGE' == slider.type) {
                            result = 'igs-slider--range';
                        }
                        if (slider.showMinMax) {
                            result = result + " igs-slider--minmax";
                        }
                        if (slider.transparentBar) {
                            result = result + " igs-slider--trnspBar";
                        }
                        if (scope.apolloSliderGridLegendLoockup) {
                            result = result + " " + scope.apolloSliderGridLegendLoockup[slider.gridLegend];
                        }
                        if (scope.apolloSliderValuePositionLoockup) {
                            result = result + " " + scope.apolloSliderValuePositionLoockup[slider.valuePosition];
                        }
                        return result;
                    };

                    scope.getInitialFirstValueStyle = function(slider,  orientation) {
                        var minV = slider.min,
                            maxV = slider.max,
                            stepV = slider.step,
                            stpNum = (maxV - minV)/stepV,
                            cssParam1,
                            elVal1;

                        if ('VERTICAL' ==  orientation) {
                            cssParam1 = 'bottom';
                        } else {
                            cssParam1 = 'left';
                        }

                        if ('RANGE' == slider.type) {
                            elVal1 = slider.value[0];

                            var pos1 = 100 / stpNum * (elVal1 - minV) / stepV;

                            return cssParam1 + ":" + pos1 + "%";
                        } else {
                            elVal1 = slider.value;
                            var pos = 100 / stpNum * (elVal1 - minV)/stepV;
                            return cssParam1 + ":" + pos + "%";
                        }
                    };

                    scope.getInitialSecondValueStyle = function(slider,  orientation) {
                        var minV = slider.min,
                            maxV = slider.max,
                            stepV = slider.step,
                            stpNum = (maxV - minV)/stepV,
                            cssParam1,
                            elVal2;

                        if ('VERTICAL' ==  orientation) {
                            cssParam1 = 'bottom';
                        } else {
                            cssParam1 = 'left';
                        }
                        if ('RANGE' == slider.type) {
                            elVal2 = slider.value[1];

                            var pos2 = 100 / stpNum * (elVal2 - minV) / stepV;

                            return cssParam1 + ":" + pos2 + "%";
                        } else {
                            return "";
                        }
                    };

                    scope.getInitialFillValueStyle = function(slider,  orientation) {
                        var minV = slider.min,
                            maxV = slider.max,
                            stepV = slider.step,
                            stpNum = (maxV - minV)/stepV,
                            cssParam1,
                            cssParam2,
                            elVal1,
                            elVal2;

                        if ('VERTICAL' ==  orientation) {
                            cssParam1 = 'bottom';
                            cssParam2 = 'height';
                        } else {
                            cssParam1 = 'left';
                            cssParam2 = 'width';
                        }

                        if ('RANGE' == slider.type) {
                            elVal1 = slider.value[0];
                            elVal2 = slider.value[1];

                            var pos1 = 100 / stpNum * (elVal1 - minV) / stepV;
                            var pos3 = 100 / stpNum * (elVal2 - elVal1) / stepV;

                            if (slider.transparentBar) {
                                return 'background: transparent;' + cssParam1 + ": " + pos1 + "%; " + cssParam2 + ":" + pos3 + "%";
                            } else {
                                return cssParam1 + ": " + pos1 + "%; " + cssParam2 + ":" + pos3 + "%";
                            }
                        } else {
                            elVal1 = slider.value;
                            var pos = 100 / stpNum * (elVal1 - minV)/stepV;
                            return cssParam1 + ": 0; " + cssParam2 + ":" + pos + "%";
                        }
                    };

                    scope.getValueStyle = function(slider,  orientation) {
                        var minV = slider.min,
                            maxV = slider.max,
                            stepV = slider.step,
                            stpNum = (maxV - minV)/stepV,
                            cssParam1,
                            elVal1;

                        if ('VERTICAL' ==  orientation) {
                            cssParam1 = 'bottom';
                        } else {
                            cssParam1 = 'left';
                        }
                        elVal1 = slider.initial;
                        var pos = 100 / stpNum * (elVal1 - minV)/stepV;
                        return cssParam1 + ":" + pos + "%";

                    };

                    scope.$watch(function () {
                        if (scope.attribute && scope.attribute.sliders) {
                            angular.forEach(scope.attribute.sliders, function(slider) {
                                if ('RANGE' == slider.type) {
                                    scope.data[slider.attrName] = {
                                        values: slider.value,
                                        role: attrs.role
                                    };
                                } else {
                                    scope.data[slider.attrName] = {
                                        value: slider.value,
                                        role: attrs.role
                                    };
                                }
                            });
                        }
                    });

                    if (ripplMultiLanguage) {
                        scope.$on('updateTranslations', function () {
                            updateTranslations();
                        });
                    }

                    function updateTranslations() {
                        scope.attribute.title = $filter("translate")(scope.attribute.title);
                        scope.attribute.subTitle = $filter("translate")(scope.attribute.subTitle);
                        scope.attribute.buttonLabel = $filter("translate")(scope.attribute.buttonLabel);
                        angular.forEach(scope.attribute.sliders, function(slider, index) {
                            scope.attribute.sliders[index].title = $filter("translate")(slider.title);
                            scope.attribute.sliders[index].minText = $filter("translate")(slider.minText);
                            scope.attribute.sliders[index].maxText = $filter("translate")(slider.maxText);
                        });
                    }
                    element.html($compile(FORM_FIELD_TEMPLATES.FORM_APOLLO_SLIDER)(scope));
                }
            };
        }
    };
}]);

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp */

frontendApp.directive('sliderCommon', ['SLIDER_REPLACEMENT', 'SLIDER_TEMPLATES', function (SLIDER_REPLACEMENT, SLIDER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            filters: "=",
            options: "=",
            owner: "=",
            target: "=",
            mouseup: "&onMouseup"
        },
        template: SLIDER_TEMPLATES.SLIDER_COMMON,
        link: function (scope) {
            if (scope.options.lowerLabel) {
                scope.lowerLabel = scope.options.lowerLabel.replace(SLIDER_REPLACEMENT.LOWER, scope.options.lower);
            }

            if (scope.options.higherLabel) {
                scope.higherLabel = scope.options.higherLabel.replace(SLIDER_REPLACEMENT.HIGHER, scope.options.higher);
            }

            scope.dots = new Array(Math.floor((scope.options.higher - scope.options.lower) / scope.options.step));
            scope.dotWidth = 100 / scope.dots.length;

            scope.dotLabel = function (index) {
                var step = parseInt(scope.options.step);
                var prefix = scope.options.prefix ? scope.options.prefix : '';
                return index > 0 ? prefix + (step * index + parseInt(scope.options.lower)) : '';
            };

            scope.$watch('target', function (value) {
                if (value != null) {
                    scope.valueLabelLeft = value / scope.options.step * scope.dotWidth;

                    if (scope.options.valueLabel) {
                        scope.valueLabel = scope.options.valueLabel.replace(SLIDER_REPLACEMENT.VALUE, value);
                    }
                }
            });

            scope.$on('FilterUpdateReceivedEvent', function (event, filter) {
                if (filter.id === scope.options.id && filter.scope === scope.options.scope) {
                    scope.options.selected = filter.selected;
                    scope.filters[filter.id] = filter;
                    scope.target = null;
                    scope.valueLabel = null;
                }
            });
        }
    };
}]);

frontendApp.directive('sliderCommonTranslate', ['SLIDER_REPLACEMENT', 'SLIDER_TEMPLATES', function (SLIDER_REPLACEMENT, SLIDER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            filters: "=",
            options: "=",
            owner: "=",
            target: "=",
            mouseup: "&onMouseup"
        },
        template: SLIDER_TEMPLATES.SLIDER_COMMON,
        link: function (scope) {

            initLabels();
            scope.dots = new Array(Math.floor((scope.options.higher - scope.options.lower) / scope.options.step));
            scope.dotWidth = 100 / scope.dots.length;

            scope.dotLabel = function (index) {
                var step = parseInt(scope.options.step);
                var prefix = scope.options.prefix ? scope.options.prefix : '';
                return index > 0 ? prefix + (step * index + parseInt(scope.options.lower)) : '';
            };

            scope.$watch('target', function (value) {
                if (value != null) {
                    scope.valueLabelLeft = value / scope.options.step * scope.dotWidth;

                    if (scope.options.valueLabel) {
                        scope.valueLabel = scope.options.valueLabel.replace(SLIDER_REPLACEMENT.VALUE, value);
                    }
                }
            });

            scope.$on('FilterUpdateReceivedEvent', function (event, filter) {
                if (filter.id === scope.options.id && filter.scope === scope.options.scope) {
                    scope.options.selected = filter.selected;
                    scope.filters[filter.id] = filter;
                    scope.target = null;
                    scope.valueLabel = null;
                }
            });

            scope.$on('updateTranslations', function () {
                initLabels();
                if (scope.options.valueLabel && scope.target) {
                    scope.valueLabel = scope.options.valueLabel.replace(SLIDER_REPLACEMENT.VALUE, scope.target);
                }
            });

            function initLabels() {
                if (scope.options.lowerLabel) {
                    scope.lowerLabel = scope.options.lowerLabel.replace(SLIDER_REPLACEMENT.LOWER, scope.options.lower);
                }

                if (scope.options.higherLabel) {
                    scope.higherLabel = scope.options.higherLabel.replace(SLIDER_REPLACEMENT.HIGHER, scope.options.higher);
                }
            }
        }
    };
}]);

frontendApp.directive('sliderComplex', ['SLIDER_TEMPLATES', function (SLIDER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            filters: "=",
            options: "=",
            owner: "=",
            target: "=",
            mouseup: "&onMouseup"
        },
        template: SLIDER_TEMPLATES.SLIDER_COMPLEX,
        link: function (scope) {
            scope.dots = new Array(Math.floor((scope.options.higher - scope.options.lower) / scope.options.step));
            scope.dotWidth = 100 / scope.dots.length;
            scope.$watch('target', function (value) {
                scope.valueLabelLeft = (value) / scope.dots.length * 100;
                scope.valueLabel = scope.options.labels[value];
            });
        }
    };
}]);

frontendApp.directive('sliderExtendedRules', ['SLIDER_REPLACEMENT', 'SLIDER_TEMPLATES', '$timeout',
    function (SLIDER_REPLACEMENT, SLIDER_TEMPLATES, $timeout) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                filters: "=",
                options: "=",
                filter: "=",
                owner: "=",
                mouseup: "&onMouseup"
            },
            template: SLIDER_TEMPLATES.SLIDER_EXTENDED,
            link: function (scope) {

                function setValue() {
                    if (scope.filter.defaultValue
                        && !scope.filter.selected) {
                        var min = parseInt(scope.lower),
                            max = parseInt(scope.higher);
                        scope.valueLabel = scope.options[scope.filter.defaultValue].label;
                        scope.valueLabelLeft = (scope.optionIndex - min ) / ( max - min ) * 100;
                        scope.filter.selected = scope.options[scope.filter.defaultValue];
                    }
                    if (scope.filter.selected) {
                        for (var i = 0; i < scope.filter.options.length; i++) {
                            if (scope.filter.options[i].value == scope.filter.selected) {
                                scope.optionIndex = i;
                                scope.valueLabelLeft = scope.optionIndex / scope.higher * 100;
                                break;
                            }
                        }
                    }
                }

                scope.lower = 0;
                scope.step = 1;
                if (scope.options && scope.options.length > 0) {
                    scope.higher = scope.options.length - 1;
                    scope.lowerLabel = scope.options[0].label;
                    scope.higherLabel = scope.options[scope.options.length - 1].label;
                    scope.optionIndex = scope.filter.defaultValue;
                    if (!scope.filter.selected && scope.filter.selectedOptionId) {
                        scope.filter.selected = scope.filter.selectedOptionId;
                    }
                }
                scope.dots = new Array(Math.floor((scope.higher - scope.lower) / scope.step));
                scope.dotWidth = 100 / scope.dots.length;
                $timeout(setValue, 0, true);
                scope.$watch('optionIndex', function (value) {
                    if (value != null) {
                        var max = parseInt(scope.higher);
                        scope.valueLabelLeft = value / max * 100;

                        scope.filter.selected = scope.options[value].value;

                        if (scope.options) {
                            scope.valueLabel = scope.options[value].label;
                        }
                    }
                });

                scope.$on('FilterUpdateReceivedEvent', function (event, filter) {
                    if (filter.id === scope.filter.id && filter.scope === scope.filter.scope) {
                        scope.filters[scope.filter.id] = filter;
                        scope.filter.selected = filter.selected;
                        if (!scope.filter.selected) {
                            scope.optionIndex = null;
                            scope.valueLabelLeft = null;
                            scope.valueLabel = null;
                        }
                    }
                });
            }
        };
    }]);

frontendApp.directive('swipeSlider', function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attrs, ngModel) {

            scope.isChanged = false;

            var position = element.offset().left;
            var ratio = (attrs.max - attrs.min) / element.width();
            var lastDate;
            var valueIndex = 0;

            element.bind("touchstart", function (eventObject) {
                process(eventObject);
                position = element.offset().left;
                ratio = (attrs.max - attrs.min) / element.width();
                lastDate = new Date();

                if (Array.isArray(ngModel.$viewValue)) {
                    var startPosition = 1 * Math.round((eventObject.originalEvent.changedTouches[0].pageX - position) * ratio / attrs.step) * attrs.step + 1 *attrs.min;
                    valueIndex = (startPosition <= ngModel.$viewValue[0]) ? 0 : 1;
                }
            });

            element.bind("touchmove", function (eventObject) {
                process(eventObject);
                var curDate = new Date();
                if (curDate.getTime - lastDate.getTime() < 300) {
                    return;
                } else {
                    lastDate = curDate;
                }
                calculateNewValue(eventObject);
            });

            element.bind("touchend", function (eventObject) {
                process(eventObject);
                if (scope.isChanged && scope.mouseup) {
                    scope.mouseup();
                }
                scope.isChanged = false;
            });

            function calculateNewValue(eventObject) {
                var delta = Math.round((eventObject.originalEvent.changedTouches[0].pageX - position) * ratio / attrs.step) * attrs.step;
                var newValue = 1 * delta + 1 * attrs.min;
                if (newValue < attrs.min) {
                    newValue = attrs.min;
                }
                if (newValue > attrs.max) {
                    newValue = attrs.max;
                }
                if (Array.isArray(ngModel.$viewValue)) {
                    if (valueIndex === 1
                        && ngModel.$viewValue[0] == attrs.max
                        && ngModel.$viewValue[0] == ngModel.$viewValue[1]) {
                        valueIndex = 0;
                    }
                    if (newValue != ngModel.$viewValue[valueIndex]) {
                        ngModel.$viewValue[valueIndex] = newValue;
                        ngModel.$render();
                        scope.$apply();
                        scope.isChanged = true;
                    }
                } else {
                    if (newValue != ngModel.$viewValue) {
                        ngModel.$setViewValue(newValue);
                        ngModel.$render();
                        scope.$apply();
                        scope.isChanged = true;
                    }
                }
            }

            function process(eventObject) {
                if (eventObject.preventDefault) {
                    eventObject.preventDefault();
                }
                if (eventObject.stopPropagation) {
                    eventObject.stopPropagation();
                }
            }
        }
    };
});

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular */

frontendApp.directive('viewTable', ['commonService', 'collectionsUtils', 'filterFacade', '$rootScope',
    function (commonService, collectionsUtils, filterFacade, $rootScope) {
        return {
            restrict: 'EA',
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element, attrs) {
                        scope.viewId = attrs.viewId;
                        scope.itemsPerPage = attrs.itemsPerPage;
                        scope.defaultLayout = attrs.defaultLayout;
                        scope.filters = {};
                        scope.sortedFilters = [];
                        scope.page = {};
                        scope.page.showView = true;
                        scope.page.selectFilter = false;
                        scope.showFilterPanel = false;
                        scope.filterSelectedValues = {};
                    },
                    post: function (scope, element, attrs) {

                        scope.loadFilters = function () {
                            commonService.query({
                                    module: 'filters',
                                    action: 'table-filters',
                                    viewId: scope.viewId,
                                    interaction: scope.saleSessionManager.getInteractionKey()
                                },
                                function (result) {
                                    scope.filters = collectionsUtils.createMap(result);
                                    scope.sortedFilters = result;
                                    scope.refreshAllFiltersSelectedValues();
                                }
                            );
                        };

                        scope.loadFilters();

                        scope.isFiltersAvailable = function () {
                            return collectionsUtils.isNotEmpty(scope.filters);
                        };

                        scope.onFilterPanelVisibilityChanged = function () {
                            if (scope.showFilterPanel) {
                                scope.showFilterPanel = false;
                                scope.page.selectFilter = false;
                            } else {
                                scope.showFilterPanel = true;
                                scope.page.selectFilter = true;
                            }
                        };

                        scope.isInSelectedFilterValue = function (value, selected) {
                            if (typeof selected === 'string') {
                                return value === selected;
                            } else if (selected.value) {
                                return value === selected.value;
                            } else {
                                for (var i = 0; i < selected.length; i++) {
                                    if (value === selected[i]
                                        || value === selected[i].value) {
                                        return true;
                                    }
                                }
                            }
                            return false;
                        };

                        scope.isFilterSelectionPanelVisible = function () {
                            if (scope.filters != null) {
                                for (var key in scope.filters) {
                                    if (scope.filters.hasOwnProperty(key)) {
                                        var currentFilter = scope.filters[key];
                                        if (currentFilter.selected instanceof Array) {
                                            if (currentFilter.selected.length > 0) {
                                                return true;
                                            }
                                        } else {
                                            if (currentFilter.selected != null) {
                                                return true;
                                            }
                                        }
                                    }
                                }
                            }

                            return false;
                        };

                        scope.refreshAllFiltersSelectedValues = function () {
                            scope.filterSelectedValues = {};

                            for (var key in scope.filters) {
                                if (scope.filters.hasOwnProperty(key)) {
                                    var filter = scope.filters[key];
                                    scope.updateFilterSelectedValues(filter);
                                }
                            }
                        };

                        scope.updateFilterSelectedValues = function (filter) {
                            if (filter.selected != null) {
                                var arr = [];
                                if (filter.options) {
                                    for (var i = 0; i < filter.options.length; i++) {
                                        if (scope.isInSelectedFilterValue(filter.options[i].value, filter.selected)) {
                                            arr.push(filter.options[i]);
                                        }
                                    }
                                } else {
                                    if (filter.selected instanceof Array) {
                                        for (var j = 0; j < filter.selected.length; j++) {
                                            arr.push({label: filter.selected[j], value: filter.selected[j]});
                                        }
                                    } else {
                                        arr.push({label: filter.selected, value: filter.selected});
                                    }
                                }
                                scope.filterSelectedValues[filter.id] = arr;
                            }
                        };

                        scope.clearFilters = function () {
                            for (var filterIndex in scope.filters) {
                                var filter = scope.filters[filterIndex];
                                if (filter.filterType == 'BUTTON' || filter.filterType == 'LIST_BOX_MULTI_SELECT') {
                                    for (var k = 0; k < filter.selected.length; k++) {
                                        filter.selected.splice(k, 1);
                                    }
                                } else {
                                    filter.selected = null;
                                }
                            }
                            filterFacade.clearAllFilters(scope.viewId, scope.saleSessionManager.getInteractionKey());
                        };

                        scope.clearFilterOption = function (filter, option) {
                            var interaction = scope.saleSessionManager.getInteractionKey();
                            if (filter.filterType == 'BUTTON' || filter.filterType == 'LIST_BOX_MULTI_SELECT') {
                                var index = -1;
                                for (var k = 0; k < filter.selected.length; k++) {
                                    if (filter.selected[k] === option || filter.selected[k] === option.value) {
                                        index = k;
                                    }
                                }
                                if (index > -1) {
                                    filter.selected.splice(index, 1);
                                }
                                filterFacade.filterMultiSelectUpdated(scope.viewId, filter, interaction);
                            } else {
                                filter.selected = null;
                                if (filter.filterType == 'SLIDER' || filter.filterType == 'RANGED_SLIDER') {
                                    filterFacade.filterSliderUpdated(scope.viewId, filter, interaction);
                                } else {
                                    filterFacade.filterOptionUpdated(scope.viewId, filter, interaction);
                                }
                            }
                        };

                        scope.$on('CriteriaUpdatedEvent', function (event, result) {
                            scope.page.selectFilter = true;
                            if (result && result.filtersUpdateInfo) {
                                var filtersInfo = result.filtersUpdateInfo[scope.viewId];
                                if (filtersInfo) {
                                    for (var ind in collectionsUtils.getKeys(filtersInfo)) {
                                        if (collectionsUtils.getKeys(filtersInfo).hasOwnProperty(ind)) {
                                            scope.$broadcast('FilterUpdateReceivedEvent', filtersInfo[collectionsUtils.getKeys(filtersInfo)[ind]]);
                                            scope.updateFilterSelectedValues(filtersInfo[collectionsUtils.getKeys(filtersInfo)[ind]]);
                                        }
                                    }
                                    scope.$broadcast("UpdateTableBodyEvent");
                                } else {
                                    scope.refreshAllFiltersSelectedValues();
                                }
                            } else {
                                scope.$broadcast("UpdateTableBodyEvent");
                                scope.refreshAllFiltersSelectedValues();
                            }
                        });

                        scope.$on('ViewShow', function (resultViewId) {
                            if (resultViewId != scope.viewId) {
                                scope.page.showView = true;
                            }
                        });

                        scope.$on('ViewHide', function (resultViewId) {
                            if (resultViewId != scope.viewId) {
                                scope.page.showView = false;
                            }
                        });

                        if (attrs.selectedCount) {
                            setTimeout(function () {
                                $rootScope.$broadcast('contentSelectedItemsUpdate', scope.viewId, attrs.selectedCount);
                            }, 100);
                        }
                    }
                };
            }
        };
    }]);

frontendApp.directive('viewTableBody', ['$compile', 'contentService', '$routeParams', 'attributesTool',
    function ($compile, contentService, $routeParams, attributesTool) {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element, attrs) {
                        scope.totalItems = 0;
                        scope.loadedPagesCount = 1;
                        scope.currentLayout = scope.defaultLayout;
                        scope.sorting = {};

                        scope.setLoadedPagesCount = function (count) {
                            scope.loadedPagesCount = count;
                        };

                        scope.load = function () {
                            var request = {
                                action: 'table-layout',
                                viewId: scope.viewId,
                                layoutType: scope.currentLayout,
                                pricingMatrixId: attrs.pricingMatrixId,
                                processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null,
                                micrositeId: scope.customerCareInfo && scope.customerCareInfo.micrositeId ? scope.customerCareInfo.micrositeId : "",
                                interaction: scope.saleSessionManager.getInteractionKey()
                            };

                            var attris = attributesTool.buildAttributes($routeParams, scope.requestAttributes);
                            for (var prop in attris) {
                                if (attris.hasOwnProperty(prop)) {
                                    request[prop] = attris[prop];
                                }
                            }

                            contentService.get(request,
                                function (result) {
                                    scope.totalItems = result.totalItems;
                                    element.html($compile(result.content)(scope));
                                });
                        };

                        scope.$on('UpdateTableBodyEvent', function () {
                            scope.load();
                        });

                        scope.changeCurrentLayout = function (layout) {
                            scope.currentLayout = layout;
                            scope.load();
                        };

                        scope.applySorting = function (fieldName, sortingMode) {
                            scope.sorting.fieldName = fieldName;
                            scope.sorting.sortingMode = sortingMode;
                            scope.$broadcast('onSortOrderChanged', fieldName);
                        };
                    },
                    post: function (scope) {
                        scope.load();
                    }
                };
            }
        };
    }]);

frontendApp.directive('viewTableContent', ['$compile', 'contentService', '$routeParams', 'attributesTool',
    function ($compile, contentService, $routeParams, attributesTool) {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return {
                    pre: function () {
                    },
                    post: function (scope, element, attrs) {
                        // Reload content when sorting was updated
                        scope.scrollInProgress = false;
                        var baseRequest = {
                            action: 'table-content',
                            viewId: scope.viewId,
                            layoutType: scope.currentLayout,
                            pageCount: scope.loadedPagesCount,
                            pricingMatrixId: attrs.pricingMatrixId,
                            processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null,
                            micrositeId: scope.customerCareInfo ? scope.customerCareInfo.micrositeId : null,
                            interaction: scope.saleSessionManager.getInteractionKey()
                        };

                        var attris = attributesTool.buildAttributes($routeParams, scope.requestAttributes);
                        for (var prop in attris) {
                            if (attris.hasOwnProperty(prop)) {
                                baseRequest[prop] = attris[prop];
                            }
                        }

                        scope.$on('onSortOrderChanged', function () {
                            var request = angular.copy(baseRequest);
                            request['startPage'] = 1;
                            request['sortingField'] = (typeof scope.sorting.fieldName !== 'undefined') ? scope.sorting.fieldName : null;
                            request['sortingMode'] = (typeof scope.sorting.sortingMode !== 'undefined') ? scope.sorting.sortingMode : null;
                            contentService.get(request, attributesTool.buildAttributes($routeParams, scope.requestAttributes),
                                function (result) {
                                    element.html($compile(result.string)(scope));
                                });
                        });

                        // Bind event for infinite scroll mode.
                        angular.element(document.querySelector('#scrollScreen')).off('scroll').on('scroll', function (event) {
                            if (!scope.scrollInProgress && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
                                // End of scroll bar.
                                scope.scrollInProgress = true;
                                var request = angular.copy(baseRequest);
                                request['startPage'] = scope.loadedPagesCount + 1;
                                request['pageCount'] = 1;
                                request['sortingField'] = scope.sorting.fieldName;
                                request['sortingMode'] = scope.sorting.sortingMode;
                                contentService.get(request,
                                    function (result) {
                                        element.append($compile(result.string)(scope));
                                        scope.setLoadedPagesCount(scope.loadedPagesCount + 1);
                                        scope.scrollInProgress = false;
                                    }, function () {
                                        scope.scrollInProgress = false;
                                    });
                            }
                        });
                    }
                };
            }
        };
    }]);

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp*/

frontendApp.directive('viewVerticalTable', [
    function () {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element, attrs) {
                        scope.viewId = attrs.viewId;
                        scope.asyncColumns = [];
                    },
                    post: function () {
                    }
                };
            }
        };
    }]
);

frontendApp.directive('verticalTableXContent', ['contentService', '$q',
    function (contentService, $q) {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element, attrs) {
                        scope.label = attrs.label;
                        scope.column = attrs.column - 1;
                        scope.pricingMatrixId = attrs.pricingMatrixId;

                        scope.load = function () {
                            var request = {
                                action: 'vertical-table-content',
                                viewId: scope.viewId,
                                pricingMatrixId: scope.pricingMatrixId,
                                processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null,
                                interaction: scope.saleSessionManager.getInteractionKey(),
                                condition: scope.label
                            };

                            var defer = $q.defer();
                            scope.asyncColumns[scope.column] = defer.promise;

                            contentService.get(request,
                                function (result) {
                                    var value = {
                                        label: scope.label,
                                        values: result.strings
                                    };
                                    defer.resolve(value);
                                }
                            );
                        };
                    },
                    post: function (scope) {
                        scope.load();
                    }
                };
            }
        };
    }]
);

frontendApp.directive('verticalTableYContent', ['$compile',
    function ($compile) {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return {
                    pre: function (scope, element, attrs) {
                        scope.label = attrs.label;
                        scope.row = attrs.row - 1;
                        scope.column = attrs.column - 1;
                    },
                    post: function (scope, element) {
                        scope.asyncColumns[scope.column].then(
                            function (result) {
                                var content = '';
                                if (result.label === scope.label && result.values[scope.row]) {
                                    content = result.values[scope.row];
                                }
                                element.removeClass("dataLoading");
                                element.html(content);
                                $compile(element.contents())(scope);
                            }
                        );
                    }
                };
            }
        };
    }]
);



/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular */

frontendApp.directive('viewModal', ['$rootScope', 'contentService', '$compile', '$interval', '$modal', '$routeParams',
    function ($rootScope, contentService, $compile, $interval, $modal, $routeParams) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            templateUrl: '',
            link: function (scope, element, attrs) {
                scope.MILLISECOND = 1000;
                scope.inFocusId = null;
                for (var key in $routeParams) {
                    if ($routeParams.hasOwnProperty(key)
                        && key == 'xItemId') {
                        scope.inFocusId = $routeParams[key];
                    }
                }
                scope.viewId = attrs.viewId;
                scope.modalTimer = null;
                scope.listItems = [];
                if (angular.isDefined(scope.inFocusId)) {
                    contentService.query(
                        {action: 'view-modal-content', viewId: scope.viewId, inFocusId: scope.inFocusId},
                        function (result) {
                            for (var ind in result) {
                                if (result.hasOwnProperty(ind)) {
                                    scope.listItems.push(angular.copy(result[ind]));
                                }
                            }
                            if (scope.listItems.length > 0) {
                                scope.startModalTimer(0);
                            }
                        },
                        function () {
                        }
                    );
                }

                scope.startModalTimer = function (index) {
                    scope.modalTimer = $interval(function () {
                        scope.openModal(index);
                    }, scope.MILLISECOND);
                };

                scope.openModal = function (index) {
                    if ((angular.isDefined($rootScope.lastScreenActivity))) {
                        var idle = (new Date().getTime()) - ($rootScope.lastScreenActivity.getTime() + (scope.listItems[index].delay * scope.MILLISECOND));
                        if (idle > 0) {
                            $modal.open({
                                template: scope.listItems[index].content,
                                controller: ['$scope', '$modalInstance',
                                    function ($scope, $modalInstance) {
                                        $scope.items = scope.listItems;
                                        $scope.viewIndex = index;

                                        $scope.skipModal = function () {
                                            $modalInstance.close();
                                            $scope.viewIndex = $scope.viewIndex + 1;
                                            if ($scope.viewIndex < $scope.items.length) {
                                                scope.startModalTimer($scope.viewIndex);
                                            }
                                        };

                                        $scope.okModal = function () {
                                            $modalInstance.close();
                                        };
                                    }],
                                backdrop: true,
                                windowClass: 'trigger-dialog'
                            });
                            scope.stopTimer();
                        }
                    }
                };

                scope.stopTimer = function () {
                    if (angular.isDefined(scope.modalTimer)) {
                        $interval.cancel(scope.modalTimer);
                        scope.modalTimer = undefined;
                    }
                };

                element.bind('$destroy', function () {
                    scope.stopTimer();
                });
            }
        };
    }]);

frontendApp.directive('viewMatrix', ['contentService', '$compile',
    function (contentService, $compile) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element, attrs) {
                var viewId = attrs.id;
                var vr = {};
                vr.viewId = viewId;
                vr.salableView = false;
                vr.parameters = scope.page ? angular.copy(scope.page.pageParameters) : {};
                var serviceOptions = {
                    action: 'view-matrix'
                };

                if (scope.saleSessionManager.getInteractionKey() != undefined) {
                    // frontend required interaction instead of microsite.
                    angular.extend(serviceOptions, {
                        interaction: scope.saleSessionManager.getInteractionKey()
                    });
                }

                contentService.post(serviceOptions, vr, function (result) {
                    scope.view = result;
                    while (element.firstChild) element.removeChild(element.firstChild);
                    element.append($compile(result.string)(scope));
                });
            }
        };
    }]);

frontendApp.directive('viewMatrixRow', ['contentService', '$compile',
    function (contentService, $compile) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element, attrs) {

                updateContent();

                scope.$on('updatePage', function () {
                    updateContent();
                });

                function updateContent() {
                    var viewId = attrs.viewId;
                    var vr = {};
                    vr.viewId = viewId;
                    vr.salableView = false;
                    vr.parameters = scope.page ? angular.copy(scope.page.pageParameters) : {};
                    vr.parameters['condition'] = attrs.condition;
                    vr.parameters['labels'] = attrs.matrixLabel;
                    vr.parameters['pricingMatrixId'] = attrs.pricingMatrixId;
                    vr.parameters['processDefinitionId'] = scope.processInfo.processDefinitionId;

                    var serviceOptions = {
                        action: 'view-matrix-row'
                    };

                    if (scope.saleSessionManager.getInteractionKey() != undefined) {
                        // frontend required interaction instead of microsite.
                        angular.extend(serviceOptions, {
                            interaction: scope.saleSessionManager.getInteractionKey()
                        });
                    }

                    contentService.post(serviceOptions, vr, function (result) {
                        scope.view = result;
                        var elements = element.children();
                        var ce1 = angular.element(elements[1]);
                        ce1.replaceWith($compile(result.strings[0])(scope));

                        for (var i = 1; i <= result.strings.length; i++) {
                            var childElement = angular.element(elements[i + 2]);
                            childElement.replaceWith($compile(result.strings[i])(scope));
                        }
                    });
                }
            }
        };
    }]);

frontendApp.directive('viewMatrixRowSimple', ['contentService', '$compile',
    function (contentService, $compile) {
        return {
            restrict: 'A',
            replace: false,
            scope: true,
            link: function (scope, element, attrs) {
                var showRowsHeader = attrs.showRowsHeader;
                scope.viewId = scope.viewId || attrs.viewId;

                updateContent();

                scope.$on('updatePage', function () {
                    updateContent();
                });

                function updateContent() {
                    var viewId = attrs.viewId;
                    var vr = {};
                    vr.viewId = viewId;
                    vr.salableView = false;
                    vr.parameters = scope.page ? angular.copy(scope.page.pageParameters) : {};
                    vr.parameters['condition'] = attrs.condition;
                    vr.parameters['labels'] = attrs.matrixLabel;
                    vr.parameters['pricingMatrixId'] = attrs.pricingMatrixId;
                    vr.parameters['processDefinitionId'] = scope.processInfo ? scope.processInfo.processDefinitionId : null;

                    var serviceOptions = {
                        action: 'view-matrix-row'
                    };

                    if (scope.saleSessionManager.getInteractionKey() != undefined) {
                        // frontend required interaction instead of microsite.
                        angular.extend(serviceOptions, {
                            interaction: scope.saleSessionManager.getInteractionKey()
                        });
                    }

                    contentService.post(serviceOptions, vr, function (result) {
                        scope.view = result;
                        var elements = element.children();
                        for (var i = 0; i <= result.strings.length; i++) {
                            var cei = (showRowsHeader == 'true') ? i + 1 : i;
                            var ce2 = angular.element(elements[cei]);
                            ce2.replaceWith($compile(result.strings[i])(scope));
                        }
                    });
                }
            }
        };
    }]);

frontendApp.directive('coverageChecker', ['contentService', 'loadGoogleMapAPI', function (contentService, loadGoogleMapAPI) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: function (scope, element, attrs) {
            scope.viewId = attrs.viewId;

            scope.data = {};
            scope.data.generalLayer = null;
            scope.postCode = null;
            scope.errorMessage = null;

            // Loads google map script
            loadGoogleMapAPI.then(function () {
                scope.$broadcast('GoogleMapAPILoaded');
                contentService.get({
                    action: 'coverage-checker-layer',
                    viewId: scope.viewId
                }, function (result) {
                    if (angular.isDefined(result.string) && result.string != null) {
                        scope.$broadcast('GeneralLayerLoaded', result.string);
                    }
                });
            }, function () {
            });

            scope.resetData = function () {
                scope.data.titles = {};
                scope.data.layers = {};
                scope.data.descriptions = {};
                scope.data.labels = {};
                scope.data.lat = null;
                scope.data.lng = null;
                scope.data.found = false;
            };

            scope.resetData();

            scope.clickSearchButton = function () {
                if (scope.postCode == null || scope.postCode == "") {
                    scope.errorMessage = "Post Code required";
                } else {
                    scope.errorMessage = null;
                    scope.activeDetailsType = null;

                    contentService.get({
                            action: 'coverage-checker',
                            viewId: scope.viewId,
                            postCode: scope.postCode
                        }, function (result) {
                            scope.data = result;
                            if (scope.data.found) {
                                scope.errorMessage = null;
                                var layersKeys = Object.keys(scope.data.layers);
                                layersKeys = layersKeys.sort(function (a, b) {
                                    return a.substr(a.length-2, 1) - b.substr(b.length-2, 1);
                                });
                                var defaultKey = layersKeys[layersKeys.length - 1];
                                scope.$broadcast('CoordinatesChanged', scope.data.lat, scope.data.lng,
                                    scope.data.generalLayer, scope.data.content, defaultKey);
                            } else {
                                scope.resetData();
                                scope.$broadcast('CoordinatesReset');
                                scope.errorMessage = "No data found for this Post Code";
                            }
                        },
                        function () {
                            scope.resetData();
                            scope.$broadcast('CoordinatesReset');
                            scope.errorMessage = "No data found for this Post Code";
                        }
                    );
                }
            };

            scope.changeLayer = function (type) {
                if (type != null) {
                    scope.$broadcast('LayerChanged', scope.data.layers[type]);
                } else {
                    scope.$broadcast('LayerChanged', null);
                }
            };
        }
    };
}]);

frontendApp.directive('coverageCheckerContent', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        link: function (scope, element) {
            scope.activeDetailsType = null;

           scope.clickChangeType = function (type) {
                scope.$parent.changeLayer(type);
                scope.activeDetailsType = type;
            };

            scope.$on('CoordinatesChanged', function (event, lat, lng, layer, content, defaultKey) {
                scope.activeDetailsType = defaultKey;
                element.html($compile(content)(scope));
            });
            scope.$on('CoordinatesReset', function () {
                element.html("");
            });
        }
    };
}]);

frontendApp.directive('staticContent', [function () {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element, attrs) {
            scope.viewId = attrs.staticContent;
        }
    };
}]);

frontendApp.directive('updatableStaticContent', ['contentService', '$compile', '$routeParams', 'attributesTool', '$interval', '$log',
    function (contentService, $compile, $routeParams, attributesTool, $interval, $log) {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, element, attrs) {
                scope.viewId = attrs.updatableStaticContent;

                $log.debug('Rendering updatable static view ' + scope.viewId);

                var refresh = function (processDefinitionId) {
                    var request = attributesTool.buildAttributes($routeParams);
                    request['action'] = 'view-static';
                    request['viewId'] = attrs.updatableStaticContent;
                    request['interaction'] = scope.saleSessionManager.getInteractionKey();
                    if (processDefinitionId) {
                        request['processDefinitionId'] = processDefinitionId;
                    }
                    contentService.get(request, function (result) {
                        $log.debug('Update content for: ' + scope.viewId);
                        element.html($compile(result.string)(scope));
                    });
                };

                element.bind('$destroy', scope.$on('UpdateFormData', refresh));

                if (attrs.interval) {
                    $log.debug('Bind refresh');
                    var stopTime = $interval(refresh, attrs.interval * 1000);

                    // listen on DOM destroy (removal) event, and cancel the next UI update
                    // to prevent updating time after the DOM element was removed.
                    element.bind('$destroy', function () {
                        if (stopTime) {
                            $interval.cancel(stopTime);
                        }
                    });

                    var userSessionExpired = scope.$on('EVT_USER_SESSION_EXPIRED', function () {
                        if (stopTime) {
                            $interval.cancel(stopTime);
                        }
                    });
                    element.bind('$destroy', userSessionExpired);
                }

                var refreshInnerPage = scope.$on("refreshInnerPage", function () {
                    $log.debug('refreshInnerPage');
                    refresh();
                });
                element.bind('$destroy', refreshInnerPage);
            }
        };
    }]);

frontendApp.directive('viewTableXsell', [function () {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element, attributes) {
            scope.viewId = attributes.viewId;
            scope.inFocusId = attributes.inFocusId;
            scope.inFocusViewId = attributes.inFocusViewId;
            scope.inFocusViewRuleId = attributes.inFocusViewRuleId;
            scope.loadedPagesCount = 1;

            scope.sorting = {};

            scope.setLoadedPagesCount = function (count) {
                scope.loadedPagesCount = count;
            };

            scope.applySorting = function (fieldName, sortingMode) {
                scope.sorting.fieldName = fieldName;
                scope.sorting.sortingMode = sortingMode;
                scope.$broadcast('onSortOrderChanged');
            };

            scope.$broadcast('onSortOrderChanged');
        }
    };
}]);

frontendApp.directive('viewTableContentXsell', ['$compile', 'contentService', '$routeParams', 'attributesTool',
    function ($compile, contentService, $routeParams, attributesTool) {
        return {
            restrict: 'A',
            scope: true,
            replace: false,
            link: function (scope, element) {
                // Reload content when sorting was updated
                scope.scrollInProgress = false;
                scope.request = null;

                scope.initRequest = function () {
                    scope.request = {
                        action: 'table-content-xsell',
                        viewId: scope.viewId,
                        inFocusId: scope.inFocusId,
                        inFocusViewId: scope.inFocusViewId,
                        inFocusViewRuleId: scope.inFocusViewRuleId,
                        startPage: 1,
                        pageCount: scope.loadedPagesCount,
                        sortingField: (typeof scope.sorting.fieldName !== 'undefined') ? scope.sorting.fieldName : null,
                        sortingMode: (typeof scope.sorting.sortingMode !== 'undefined') ? scope.sorting.sortingMode : null,
                        processDefinitionId: scope.processInfo ? scope.processInfo.processDefinitionId : null,
                        micrositeId: scope.customerCareInfo ? scope.customerCareInfo.micrositeId : null,
                        interaction: scope.saleSessionManager.getInteractionKey()
                    };

                    var attris = attributesTool.buildAttributes($routeParams, scope.requestAttributes);
                    for (var prop in attris) {
                        if (attris.hasOwnProperty(prop)) {
                            scope.request[prop] = attris[prop];
                        }
                    }
                };

                scope.$on('onSortOrderChanged', function () {

                    if (scope.request == null) {
                        scope.initRequest();
                    }

                    contentService.get(scope.request,
                        function (result) {
                            element.html($compile(result.string)(scope));
                        });
                });

                // Bind event for infinite scroll mode.
                angular.element(document.querySelector('#scrollScreen')).off('scroll').on('scroll', function (event) {
                    if (!scope.scrollInProgress && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
                        // End of scroll bar.
                        scope.scrollInProgress = true;
                        scope.request['startPage'] = scope.loadedPagesCount + 1;
                        scope.request['sortingField'] = scope.sorting.fieldName;
                        scope.request['sortingMode'] = scope.sorting.sortingMode;
                        contentService.get(scope.request,
                            function (result) {
                                element.append($compile(result.string)(scope));
                                scope.setLoadedPagesCount(scope.loadedPagesCount + 1);
                                scope.scrollInProgress = false;
                            }, function () {
                                scope.scrollInProgress = false;
                            });
                    }
                });
            }
        };
    }
]);

frontendApp.directive('staticModal', ['$compile', 'contentService', function ($compile, contentService) {
    return {
        restrict: 'A',
        scope: true,
        replace: false,
        link: function (scope, element, attributes) {

            for (var prop in attributes) {
                if (attributes.hasOwnProperty(prop)) {
                    scope[prop] = attributes[prop];
                }
            }

            contentService.get({action: 'view-static', viewId: attributes.viewId, interaction: scope.interaction},
                function (result) {
                    element.append($compile(result.string)(scope));
                }
            );
        }
    };
}]);


/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, $, google, resourceUrl, ripplMultiLanguage, isEmbeddedMode */

frontendApp.directive('emptyStyle', [function () {
    return {
        restrict: 'A',
        replace : false,
        link: function (scope, element, attrs) {

            if (element.html() == '' || (angular.isDefined(element.context) && angular.isDefined(element.context.children)
                    && angular.isDefined(element.context.children[0]) && angular.isDefined(element.context.children[0].style)
                    && element.context.children[0].style.display == 'none')) {

                element.attr('style', attrs.emptyStyle);
            }
        }
    };
}]);

frontendApp.directive('specialCharacter', [function () {
    return {
        restrict: 'A',
        replace : false,
        link: function (scope, element) {
            scope.code = angular.copy(element.html());
            element.html("&" + scope.code + ";");
        }
    };
}]);

frontendApp.directive('simpleHtml', [function () {
    return function (scope, element, attr) {
        scope.$watch(attr.simpleHtml, function (value) {
            element.html(scope.$eval(value));
        });
    };
}]);

frontendApp.directive('complexHtml', ['$compile', function ($compile) {
    return function (scope, element, attr) {
        scope.$watch(attr.complexHtml, function (value) {
            element.html(value);
            $compile(element.contents())(scope);
        });
    };
}]);

frontendApp.directive('sortButton', ['FILTER_TEMPLATES', function (FILTER_TEMPLATES) {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: FILTER_TEMPLATES.SORT_BUTTON,
        link: function (scope, element, attrs) {
            scope.defaultField = scope.$eval(attrs.defaultField);
            scope.field = scope.$eval(attrs.field);
            scope.asc = !(scope.defaultField && scope.defaultField.order == 'DESC');
            scope.prevFieldName = '';
            if (scope.defaultField && scope.defaultField.name == scope.field.name) {
                scope.prevFieldName = scope.field.name;
            }

            scope.$on('ChangeTableSort', function (event, arg) {
                if (arg) {
                    scope.prevFieldName = arg.name;
                    if (arg.order) {
                        scope.asc = arg.order == 'ASC';
                    }
                } else {
                    scope.prevFieldName = '';
                    scope.asc = true;
                }
            });
        }
    };
}]);

frontendApp.directive('columnSorting', [function () {
    return {
        restrict: 'A',
        replace: true,
        scope: true,
        template: '<div class="sortingBox"><div class="sortingIcon proIcons" ng-click="sortAscending();" ng-class="{\'active\': sortingMode == \'ASC\'}">j</div>' +
        '{{displayName}}' +
        '<div class="sortingIcon proIcons" ng-click="sortDescending();" ng-class="{\'active\': sortingMode == \'DESC\'}">i</div></div>',
        link: function (scope, element, attrs) {
            scope.displayName = attrs.displayName;
            scope.sortingMode = false;

            scope.sortAscending = function () {
                scope.sortingMode = 'ASC';
                scope.applySorting(attrs.fieldName, scope.sortingMode);
            };

            scope.sortDescending = function () {
                scope.sortingMode = 'DESC';
                scope.applySorting(attrs.fieldName, scope.sortingMode);
            };

            scope.$on('onSortOrderChanged', function (event, fieldName) {
                if (attrs.fieldName != fieldName) {
                    delete scope.sortingMode;
                }
            });
        }
    };
}]);

frontendApp.directive('customLayout', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        replace: true,
        link: function (scope, element, attrs) {

            scope.$watch(
                function (scope) {
                    // watch the 'compile' expression for changes
                    return scope.$eval(attrs.content);
                },
                function (value) {
                    // when the 'compile' expression changes
                    // assign it into the current DOM
                    element.html(value);

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don't get into infinite loop compiling ourselves
                    $compile(element.contents())(scope);
                }
            );

        }
    };
}]);

frontendApp.directive('customLayout2', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        replace: true,
        link: function (scope, element, attrs) {

            scope.$watch(
                function (scope) {
                    // watch the 'compile' expression for changes
                    return scope.$eval(attrs.content);
                },
                function (value) {
                    var e = $compile(value)(scope);
                    element.replaceWith(e);
                }
            );

        }
    };
}]);

frontendApp.directive('smoothScroll', ['$location', '$anchorScroll', function ($location, $anchorScroll) {
    return {
        link: function (scope, element, attrs) {
            element.bind('touchmove', function () {
                // fixed common slider behaviour
            });

            scope.$on('scrollTop', function() {
                var off = scope.$on('$locationChangeStart', function (event) {
                    off();
                    event.preventDefault();
                });
                $location.hash(attrs.id);
                $anchorScroll();
            });
        }
    };
}]);

frontendApp.directive('backButton', ['$window', 'trackingServices', function ($window, trackingServices) {
    return {
        restrict: 'A',
        link: function (scope, element) {
            trackingServices.trackAction(scope, 'backButton');
            element.on('click', function () {
                $window.history.back();
            });
        }
    };
}]);

frontendApp.directive('filtered', ['$filter', function ($filter) {

    function getCaretPosition(elem) {
        var position = {
            start: 0,
            end: 0
        };

        if (elem !== null) {
            if (document.selection) {
                // IE
                elem.focus();
                var range = document.selection.createRange();
                range.moveStart('character', -elem.value.length);
                position.start = position.end = range.text.length;
            } else {
                if (typeof elem.selectionStart == 'number') {
                    position.start = elem.selectionStart;
                }
                if (typeof elem.selectionEnd == 'number') {
                    position.end = elem.selectionEnd;
                }
            }
        }

        return position;
    }

    function setCaretPosition(elem, position) {
        if (elem !== null) {
            if (document.selection) {
                // IE
                var range = elem.createTextRange();
                range.move('character', position.start);
                range.select();
            } else {
                elem.focus();
                elem.setSelectionRange(position.start, position.end);
            }
        }
    }

    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            var filtered = function (data) {
                var capitalized = $filter('capitalize')(data, attrs.filtered);
                if (capitalized != data) {
                    var position = getCaretPosition(elem[0]);
                    ctrl.$setViewValue(capitalized);
                    ctrl.$render();
                    setCaretPosition(elem[0], position);
                }
                return capitalized;
            };

            ctrl.$parsers.unshift(filtered); //convert data from view format to model format
            ctrl.$formatters.unshift(filtered); //convert data from model format to view format

            filtered(scope[attrs.ngModel]);  //capitalize initial value
        }
    };
}]);

frontendApp.directive('ajaxLoader', [function () {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            ajaxLoader: "="
        },
        template: '<div role="status" style="background: RGBA(0,0,0,.8); position: absolute; top: 0; left: 0; height: 100%; width: 100%"><img src="' + resourceUrl + '/img/ajax-loader.gif" style="position: absolute; top: 50%; left: 50%; margin: -10px 0 0 -10px" width="20" height="20" /></div>',
        link: function (scope, element) {

            scope.$watch('ajaxLoader', function (val) {
                if (val)
                    $(element).show();
                else
                    $(element).hide();
            });
        }
    };
}]);


frontendApp.directive('filterActionSelect', ['FILTER_TEMPLATES', 'commonService', function (FILTER_TEMPLATES, commonService) {
    return {
        restrict: 'A',
        scope: {
            filters: "=",
            filter: "=",
            owner: "="
        },
        template: FILTER_TEMPLATES.FILTER_ACTION_SELECT,
        link: function (scope) {
            scope.getOpenBundleAction = function () {
                return null;
            };

            scope.execute = function (result) {
                scope.filter.selected = {
                    name: result.name,
                    image: result.image,
                    externalId: result.externalId,
                    offeringTypeId: result.offeringTypeId
                };
                commonService.post({
                        module: 'filters',
                        action: 'update-action-filter',
                        pageId: scope.$root.activePageId,
                        viewId: scope.owner,
                        filterId: scope.filter.id,
                        interaction: scope.saleSessionManager.getInteractionKey()
                    },
                    scope.filter.selected,
                    function (result) {
                        scope.$emit('CriteriaUpdatedEvent', result);
                    },
                    function () {
                    });
            };
        }
    };
}]);

// ------------------------------ Directives for forms -----------------------------------------------------------------

frontendApp.directive('orientationChange', ['$rootScope', 'callCenterService', function ($state, callCenterService) {
    return {
        restrict: 'A',
        scope: true,
        replace: true,
        link: function postLink($scope, element) {
            element.bind('orientationchange', function () {
                callCenterService.init($scope.saleSessionManager.getInteractionKey());
                $state.go($state.current, {}, {reload: true});
            });
        }
    };
}]);

frontendApp.directive('googleMap', ['$compile', function ($compile) {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        link: function (scope, element, attrs) {

            scope.generalCoverageLayerType = null;
            scope.map = null;
            scope.marker = null;
            scope.containerId = "map_container" + new Date().getTime();

            var content = "<div id='" + scope.containerId + "'";
            if (attrs.style != null) {
                content = content + " style='" + attrs.style + "'";
            }
            content = content + "/>";

            var e = $compile(content)(scope);
            element.replaceWith(e);

            scope.initMap = function () {
                var containerElement = document.getElementById(scope.containerId);
                scope.map = new google.maps.Map(containerElement, {
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });

                scope.marker = new google.maps.Marker({
                    map: scope.map,
                    title: "You are here"
                });
            };

            scope.resetMap = function () {
                scope.marker.setPosition(null);
                scope.map.setCenter(new google.maps.LatLng(52.5, 0));
                scope.map.setZoom(6);
                scope.map.overlayMapTypes.clear();
                if (scope.generalCoverageLayerType != null) {
                    scope.map.overlayMapTypes.push(scope.generalCoverageLayerType);
                }
            };

            scope.$on('GoogleMapAPILoaded', function () {
                scope.initMap();
                scope.resetMap();
            });

            scope.$on('CoordinatesChanged', function (event, lat, lng, layer) {
                scope.map.overlayMapTypes.clear();
                if (scope.generalCoverageLayerType != null) {
                    scope.map.overlayMapTypes.push(scope.generalCoverageLayerType);
                } else {
                    if (angular.isDefined(layer) && layer != null) {
                        scope.generalCoverageLayerType = new google.maps.ImageMapType({
                            getTileUrl: function (coord, zoom) {
                                return layer + "&zoom=" + zoom + "&x=" + coord.x + "&y=" + coord.y;
                            },
                            tileSize: new google.maps.Size(256, 256),
                            opacity: 0.5,
                            maxZoom: 20,
                            name: 'Coverage'
                        });
                        scope.map.overlayMapTypes.push(scope.generalCoverageLayerType);
                    }
                }
                var latLng = new google.maps.LatLng(lat, lng);
                scope.map.setCenter(latLng);
                scope.marker.setPosition(latLng);
                scope.map.setZoom(12);
            });

            scope.$on('LayerChanged', function (event, layer) {
                scope.map.overlayMapTypes.clear();

                if (angular.isDefined(layer) && layer != null) {
                    scope.map.overlayMapTypes.push(new google.maps.ImageMapType({
                        getTileUrl: function (coord, zoom) {
                            return layer + "&zoom=" + zoom + "&x=" + coord.x + "&y=" + coord.y;
                        },
                        tileSize: new google.maps.Size(256, 256),
                        opacity: 0.5,
                        maxZoom: 20,
                        name: 'Coverage'
                    }));
                } else {
                    if (scope.generalCoverageLayerType != null) {
                        scope.map.overlayMapTypes.push(scope.generalCoverageLayerType);
                    }
                }
            });

            scope.$on('GeneralLayerLoaded', function (event, layer) {
                if (angular.isDefined(layer) && layer != null) {
                    scope.generalCoverageLayerType = new google.maps.ImageMapType({
                        getTileUrl: function (coord, zoom) {
                            return layer + "&zoom=" + zoom + "&x=" + coord.x + "&y=" + coord.y;
                        },
                        tileSize: new google.maps.Size(256, 256),
                        opacity: 0.5,
                        maxZoom: 20,
                        name: 'Coverage'
                    });
                    scope.map.overlayMapTypes.push(scope.generalCoverageLayerType);
                }
            });

            scope.$on('CoordinatesReset', function () {
                scope.resetMap();
            });

        }
    };
}]);

frontendApp.directive('capitalization', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            var capitalize = function (inputValue) {
                if (scope.capitalizeFirstLetter) {
                    if (inputValue === undefined) {
                        inputValue = '';
                    }
                    var capitalized = inputValue.charAt(0).toUpperCase() +
                        inputValue.substring(1);
                    if (capitalized !== inputValue) {
                        modelCtrl.$setViewValue(capitalized);
                        modelCtrl.$render();
                    }
                    return capitalized;
                } else {
                    return inputValue;
                }
            };
            modelCtrl.$parsers.push(capitalize);
        }
    };
});

// use <div language-switcher languages="en-GB,fr-CA"></div>
frontendApp.directive('languageSwitcher', [ '$rootScope', '$compile', '$translate', function ($rootScope, $compile, $translate) {
    return {
        restrict: 'A',
        replace: false,
        scope: true,
        link: {
            pre: function (scope) {
                if (!isEmbeddedMode && ripplMultiLanguage) {
                    scope.selectedLanguage = $translate.use();
                    scope.$on('updateTranslations', function (event, lang) {
                        scope.selectedLanguage = lang;
                    });
                }
            },
            post: function (scope, element, attrs) {
                if (!isEmbeddedMode && ripplMultiLanguage) {
                    var defaultLabels = {};
                    defaultLabels["en-GB"] = "English";
                    defaultLabels["en-CA"] = "English";
                    defaultLabels["en-US"] = "English";
                    defaultLabels["fr-CA"] = "Fran&ccedil;ais";
                    defaultLabels["fr-FR"] = "Fran&ccedil;ais";
                    defaultLabels["de-DE"] = "Deutsch";
                    defaultLabels["it-IT"] = "Italiano";
                    defaultLabels["ru-RU"] = "Русский";
                    defaultLabels["es-ES"] = "Español";
                    // TODO: add other languages

                    var content = "";
                    scope.languages = [];
                    if (attrs.languages) {
                        scope.languages = attrs.languages.split(",");
                    } else {
                        if ($rootScope.supportedLanguages) {
                            scope.languages = $rootScope.supportedLanguages.split(",");
                        }
                    }

                    if (scope.languages.length > 1) {
                        content = content + "<div ng-show='!checkPredefined()' style='display: inherit;'>";
                        for (var i = 0; i < scope.languages.length; i++) {
                            var currLang = scope.languages[i].trim();
                            var langName = defaultLabels[currLang];
                            var translationKey = "LANGUAGE_SWITCHER_" + currLang.toUpperCase().replace("-", "_") + "_LABEL";
                            content = content + "<div class='rippl-switcher-item' ng-class=\"{'rippl-switcher-item-selected' : selectedLanguage == '" + currLang + "'} \" ng-click=\"$emit('changeLanguage', '" + currLang + "')\" " + " translate='" + translationKey + "'>" + langName + "</div>";
                        }
                        content = content + "</div>";
                        element.html($compile(content)(scope));
                    }
                }

                scope.checkPredefined = function () {
                    return $rootScope.predefinedLanguage && scope.languages.indexOf($rootScope.predefinedLanguage) >= 0;
                };
            }
        }
    };
}]);

frontendApp.directive('saveScrollPosition', ['$rootScope', function ($rootScope) {
    return {
        link: function (scope, element, attrs) {
            element.on('click', function () {
                $rootScope.scrollParent = attrs.saveScrollPosition ? attrs.saveScrollPosition : ".rippl-flow-box-wrapper";
                var scrollParent = angular.element(document.querySelector($rootScope.scrollParent));
                $rootScope.scrollPosition = scrollParent.length ? scrollParent.scrollTop() : null;
            });
        }
    };
}]);

frontendApp.directive('restoreScrollPosition', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
    return {
        link: function (scope, element) {
            element.ready(function () {
                if ($rootScope.scrollParent && $rootScope.scrollPosition) {
                    $timeout(function () {
                        var scrollParent = angular.element(document.querySelector($rootScope.scrollParent));
                        if (scrollParent.length) {
                            scrollParent.scrollTop($rootScope.scrollPosition);
                        }
                        $rootScope.scrollParent = null;
                        $rootScope.scrollPosition = null;
                    }, 100);
                }
            });
        }
    };
}]);

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp */

frontendApp.factory('alertMessageFacade', ['$modal', 'saleSessionManagerProvider', '$log',
    function ($modal, saleSessionManagerProvider, $log) {
        return {
            showModal: function (content) {
                return $modal.open({
                    template: '<div class="modal-header"></div>' +
                        '<div class="modal-body">' + content + '</div>' +
                        '<div class="modal-footer">' +
                        '<div>' +
                        '<a class="btn btn-default" ng-click="ok();" role="button">OK</a>' +
                        '<a class="btn btn-default" ng-click="cancel();" role="button">Cancel</a>' +
                        '</div>' +
                        '</div>',
                    controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                        $scope.ok = function () {
                            $modalInstance.close();
                        };
                        $scope.cancel = function () {
                            $modalInstance.dismiss();
                        };
                    }],
                    backdrop: true
                });
            },
            openWithHtml: function (content) {
                return $modal.open({
                    template: '<div class="modal-header"></div>' +
                        '<div class="modal-body" complex-html="content"/>' +
                        '<div class="modal-footer">' +
                        '<div>' +
                        '<a class="btn btn-default" ng-click="ok();" role="button">OK</a>' +
                        '</div>' +
                        '</div>',
                    controller: ['$scope', '$modalInstance', 'content',
                        function ($scope, $modalInstance, content) {
                            $scope.content = content;
                            $scope.ok = function () {
                                $modalInstance.close();
                            };
                        }],
                    backdrop: true,
                    resolve: {
                        content: function () {
                            return content;
                        }
                    }
                });
            },
            openCustomerCare: function ($scope, message, websiteTemplateId, interaction) {
                var holder = {
                    getInteractionKey: function () {
                        return interaction;
                    },
                    setInteractionKey: function () {
                        $log.warn('Unexpected set interaction key');
                    }
                };
                var isolatedScope = $scope.$new(true);
                isolatedScope.saleSessionManager = saleSessionManagerProvider.$get(isolatedScope, holder);
                isolatedScope.user = $scope.user;
                isolatedScope.customerCareModal = $modal.open({
                    template: '<div class="modal-header">' +
                        '<div class="active-session" shared-active-session></div>' +
                        '<div class="shared-sessions" shared-sessions-list=""></div></div>' +
                        '<div class="modal-body">' +
                        '<div complex-html="message"/>' +
                        '<div><div control-customer-care="{{websiteTemplateId}}"></div></div>' +
                        '<div microsite-checker="{{websiteTemplateId}}" class="agent-mirror"></div></div>' +
                        '<div class="modal-footer">' +
                        '<div>' +
                        '<a class="btn btn-default" ng-click="ok();" role="button">OK</a>' +
                        '</div>' +
                        '</div>',
                    scope: isolatedScope,
                    controller: ['$scope', '$modalInstance',
                        function ($scope) {
                            $scope.message = message;
                            $scope.websiteTemplateId = websiteTemplateId;
                            $scope.interaction = interaction;
                            $scope.ok = function () {
                                $scope.closeCustomerCare();
                            };
                        }],
                    backdrop: true
                });

                isolatedScope.closeCustomerCare = function (callback) {
                    isolatedScope.customerCareModal.close();
                    if (callback) {
                        callback($scope);
                    }
                };
                return isolatedScope.customerCareModal;
            },
            openWithoutFooterHeader: function (content, interaction) {
                return $modal.open({
                    template: '<div complex-html="content"/>',
                    controller: ['$scope', '$modalInstance', 'content',
                        function ($scope, $modalInstance, content) {
                            $scope.content = content;
                            $scope.interaction = interaction;
                            $scope.ok = function () {
                                $modalInstance.close();
                            };
                        }],
                    backdrop: true,
                    resolve: {
                        content: function () {
                            return content;
                        }
                    }
                });
            }
        };
    }]);

frontendApp.factory('filterFacade', ['$rootScope', 'commonService', function ($rootScope, commonService) {
    return {
        filterMultiSelectUpdated: function (viewId, filter, interaction) {
            commonService.post({
                    module: 'filters',
                    action: 'update-multi-select',
                    pageId: $rootScope.activePageId, viewId: viewId, filterId: filter.id, scope: filter.scope,
                    interaction: interaction
                }, filter.selected,
                function (result) {
                    $rootScope.$broadcast('CriteriaUpdatedEvent', result);
                },
                function () {
                }
            );
        },
        filterSliderUpdated: function (viewId, filter, interaction) {
            if (filter.options && filter.options.length > 0) {
                this.filterOptionUpdated(viewId, filter, interaction);
            } else {
                commonService.get({
                        module: 'filters', action: 'update-slider', pageId: $rootScope.activePageId, viewId: viewId,
                        filterId: filter.id, scope: filter.scope, value: filter.selected,
                        interaction: interaction
                    },
                    function (result) {
                        $rootScope.$broadcast('CriteriaUpdatedEvent', result);
                    },
                    function () {
                    }
                );
            }
        },
        filterOptionUpdated: function (viewId, filter, interaction) {
            var value = filter.selected;
            if (value && value.value) {
                value = value.value;
            }
            commonService.get({
                    module: 'filters',
                    action: 'update-select',
                    pageId: $rootScope.activePageId,
                    viewId: viewId,
                    filterId: filter.id,
                    scope: filter.scope,
                    value: value,
                    interaction: interaction
                },
                function (result) {
                    $rootScope.$broadcast('CriteriaUpdatedEvent', result);
                },
                function () {
                }
            );
        },
        clearAllFilters: function (viewId, interaction) {
            commonService.remove({module: 'filters', action: 'clear-filters', pageId: $rootScope.activePageId, viewId: viewId, interaction: interaction},
                function (result) {
                    $rootScope.$broadcast('CriteriaUpdatedEvent', result);
                },
                function () {
                }
            );
        }
    };
}]);

frontendApp.service('tokenFacade', ['isEnabledCookies', function (isEnabledCookies) {
    var token = null;
    var useCookies = isEnabledCookies;
    if (useCookies) {
        var cookieValue = null;
        var cookies = document.cookie.match(new RegExp('(^|;)\\s*' + 'token' + '\\s*=\\s*([^;]+)'));
        if (cookies) {
            cookieValue = cookies.pop();
        }
        if (cookieValue != null && cookieValue != '') {
            token = cookieValue;
        }
    }

    return {
        isTokenEmpty: function () {
            return token == null;
        },
        getToken: function () {
            return token;
        },
        setToken: function (value) {
            token = value;
            if (useCookies) {
                var ws = new Date();
                ws.setUTCFullYear(ws.getUTCFullYear() + 1);
                document.cookie = 'token=' + value + '; expires' + ws.toUTCString();
            }
        },
        destroy: function () {
            token = null;
            if (useCookies) {
                document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
        }
    };
}]);

frontendApp.service('lockOperationsFacade', ['$rootScope', function ($rootScope) {
    var isSimplePage = true;
    return {
        setSimplePage: function (isSimple) {
            isSimplePage = isSimple;
        },
        lock: function () {
            $rootScope.lockContent = true;
        },
        unlock: function (obligatory) {
            if (isSimplePage || obligatory) {
                $rootScope.lockContent = false;
            }
        }
    };
}]);

frontendApp.factory('viewVisibilityFacade', ['$rootScope', function ($rootScope) {
    return {
        showViews: function (resultViewId) {
            $rootScope.$broadcast('ViewShow', resultViewId);
        },
        hideViews: function (resultViewId) {
            $rootScope.$broadcast('ViewHide', resultViewId);
        }
    };
}]);

frontendApp.factory('layoutFacade', ['$rootScope', '$log', function ($rootScope, $log) {
    return {
        showTopMessage: function (message) {
            $rootScope.$broadcast('ShowTopMessage', message);
        },
        showTopMessages: function (messages) {
            if (messages) {
                for (var ind in messages) {
                    if (messages.hasOwnProperty(ind)) {
                        $rootScope.$broadcast('ShowTopMessage', messages[ind]);
                    }
                }
            }
        },
        hideLeft: function () {
            $rootScope.$broadcast("HideLeftMenu");
        },
        toggleLeftMenu: function () {
            // @todo remove
            $rootScope.$broadcast("toggleLeftMenu");
        },
        hideRight: function () {
            $rootScope.$broadcast("HideRightMenu");
        },
        toggleRightMenu: function (hasClass) {
            $rootScope.$broadcast("toggleRightMenu", hasClass);
        },
        updateBasket: function () {
            $rootScope.$broadcast("updateBasketContent");
        },
        updateMiniBasket: function () {
            $rootScope.$broadcast("updateMiniBasketContent");
        },
        hideMiniBasket: function () {
            $rootScope.$broadcast("hideMiniBasket");
        },
        refreshJourneyMeter: function () {
            $log.debug('refreshJourneyMeter from layoutFacade');
            $rootScope.$broadcast('JourneyMeterRefresh');
        },
        startJourneyMeter: function () {
            $rootScope.$broadcast('StartJourney');
        },
        updateFloatingTop: function (content) {
            $rootScope.$broadcast("updateFloatingTop", content);
        },
        updateFloatingBottom: function (content) {
            $rootScope.$broadcast("updateFloatingBottom", content);
        },
        updateComparePanel: function () {
            $rootScope.$broadcast("refreshCompare");
        },
        updateRecentPanel: function () {
            $rootScope.$broadcast('refreshRecentItems');
        },
        markAsSelected: function (data) {
            $rootScope.$broadcast('markAsSelected', [data]);
        },
        updateSelectedCount: function (viewId, event) {
            $rootScope.$broadcast('contentSelectedItemsUpdate', viewId, event.count);
        },
        reloadBuildBundle: function () {
            $rootScope.$broadcast('reloadBuildBundle');
        },
        reloadExpressions: function () {
            $rootScope.$broadcast('UpdateFormData');
        },
        showAddons: function (event) {
            if (event.show) {
                $rootScope.$broadcast("showAddonRecommendations", event.offeringId, event.offeringTypeId);
            } else {
                $rootScope.$emit("closeAddon");
            }
        },
        updateContent: function () {
            $rootScope.$broadcast('UpdateContent');
        },
        populateRpm: function (event) {
            if (event.fields) {
                $rootScope.$broadcast("Popular", event);
            }
        },
        changeAccess: function (key, templateId) {
            $rootScope.$broadcast('changeAccess', key, templateId);
        },
        changePin: function (boolean) {
            $rootScope.changePin = boolean;
        },
        user: function (user) {
            //todo: redesign
            if (user) {
                $rootScope.user = user;
            } else {
                return $rootScope.user;
            }
        },
        locationInfo: function (info) {
            //todo: redesign
            if (info) {
                $rootScope.locationInfo = info;
            } else {
                return $rootScope.locationInfo;
            }
        }
    };
}]);
/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, ripplMultiLanguage */

frontendApp.filter('pageApply', [function () {
    return function (array, curPage, perPage) {

        var result = [];
        var begin = (curPage - 1) * perPage;
        for (var i = begin; i < (begin + perPage); i++) {
            if (i >= array.length) {
                break;
            }
            result.push(array[i]);
        }
        return result;
    };
}]);

frontendApp.filter('range', ['$locale', '$filter', function ($locale, $filter) {
    return function (input, type, count) {
        if ('DAY' === type) {
            for (var day = 1; day <= 31; day++) {
                input.push('' + day);
            }
            return input;
        } else if ('MONTH' === type) {
            for (var ind in $locale.DATETIME_FORMATS.MONTH) {
                if ($locale.DATETIME_FORMATS.MONTH.hasOwnProperty(ind)) {
                    input.push($locale.DATETIME_FORMATS.MONTH[ind]);
                }
            }

            return input;
        } else if ('YEAR' === type) {
            var now = new Date();
            var endYear = parseInt($filter('date')(now, 'yyyy'));
            endYear = endYear - 16;
            var startYear = endYear - 120;
            for (var year = endYear; year >= startYear; year--) {
                input.push('' + year);
            }
            return input;
        } else if ('NUMBER' === type) {
            for (var item = 0; item < count; item++) {
                input.push(item);
            }
            return input;
        } else {
            return input;
        }
    };
}]);

frontendApp.filter('escapeSpecialSymbolsInHtml', [function () {
    return function (str) {
        return String(str).replace("&lt;", "<").replace("&gt;", ">").replace("&pound;", "£");
    };
}]);

frontendApp.filter('fieldname', [function () {
    return function (input) {
        return input.localName != null && input.localName != '' ? input.localName : input.name;
    };
}]);

frontendApp.filter('filterWithAlternativeLabel', [function () {
    return function (input) {
        var ret = input.name;
        if (angular.isDefined(input.alternativeLabel) && input.alternativeLabel != null && input.alternativeLabel != '') {
            ret = input.alternativeLabel;
        } else {
            if (angular.isDefined(input.localName) && input.localName != null && input.localName != '') {
                ret = input.localName;
            }
        }

        return ret;
    };
}]);

frontendApp.filter('fieldNameNotEquals', [function () {
    return function (items, value) {
        var result = [];
        for (var ind in items) {
            if (items.hasOwnProperty(ind) && items[ind].name != value) {
                result.push(items[ind]);
            }
        }
        return result;
    };
}]);

/**
 * Capitalize string.
 *
 * Supported types:
 * 'FIRST_UP'            : hello WORLD -> Hello WORLD
 * 'EACH_FIRST_UP'       : hello world -> Hello World
 * 'FIRST_UP_OTHER_DOWN' : HELLO WORLD -> Hello world
 * 'LOWER'               : Hello WORLD -> hello world
 * 'UPPER'               : Hello WORLD -> HELLO WORLD
 */
frontendApp.filter('capitalize', [function () {
    return function (input, type) {
        if (input) {
            if ('FIRST_UP' == type) {
                return input.substring(0, 1).toUpperCase() + input.substring(1);
            } else if ('EACH_FIRST_UP' == type) {
                return input.replace(/([^\W_]+[^\s-]*) */g,
                    function (txt) {
                        return txt.substring(0, 1).toUpperCase() + txt.substr(1).toLowerCase();
                    });
            } else if ('FIRST_UP_OTHER_DOWN' == type) {
                return input.substring(0, 1).toUpperCase() + input.substring(1).toLowerCase();
            } else if ('LOWER' == type) {
                return input.toLowerCase();
            } else if ('UPPER' == type) {
                return input.toUpperCase();
            }
        }
        return input;
    };
}]);

/**
 * Choose suffix to day of month.
 *
 * Example: 1st, 2nd, 3rd...
 */
frontendApp.filter('daysuffix', ['$filter', function ($filter) {
    return function (input) {
        if (input) {
            var day = parseInt($filter('date')(input, 'dd'));
            if (day >= 1 && day <= 31) {
                switch (day) {
                    case 1:
                    case 21:
                    case 31:
                        return day + "st";
                    case 2:
                    case 22:
                        return day + "nd";
                    case 3:
                    case 23:
                        return day + "rd";
                    default:
                        return day + "th";
                }
            }
        }
        return input;
    };
}]);

frontendApp.filter('users', [function () {
    return function (array, name) {

        if (0 === name.length) {
            return array;
        }
        var result = [];
        for (var ind = 0; ind < array.length; ind++) {
            if (array.hasOwnProperty(ind)
                && (array[ind].firstName.toLowerCase().indexOf(name.toLowerCase()) > -1
                || array[ind].lastName.toLowerCase().indexOf(name.toLowerCase()) > -1)) {
                result.push(array[ind]);
            }
        }
        return result;
    };
}]);

frontendApp.filter('translateRippl', ['$filter', function($filter) {
    var translateFilter = function (input, defaultValue, param) {
        var ret = defaultValue;
        if (ripplMultiLanguage) {
            var translated = $filter('translate')(input, param);
            if (translated != input) {
                ret = translated;
            }
        }

        return ret;
    };
    return translateFilter;
}]);

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular, restUrl, window, document */

/* Services */
frontendApp.factory('feedbackService', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/feedback/', {},
        {
            send: {method: 'POST'}
        }
    );
}]);

frontendApp.factory('trackingServices', ['actions-service', '$rootScope', '$q', '$log', function (actionService, $rootScope, $q, $log) {
    var ga = window.ga;
    return {
        trackAnalytics: function (eventName) {
            if (ga) {
                ga('send', {
                    'hitType': 'event',
                    'eventCategory': 'event',
                    'eventAction': eventName,
                    'eventLabel': 'clicked'
                });
            }
        },
        pushAnalytics: function (source) {
            if (ga) {
                ga('send', {
                    'hitType': 'event',
                    'eventCategory': 'action',
                    'eventAction': source.actionId,
                    'eventLabel': source.eventLabel
                });
            }
        },
        pushEvent: function (source) {
            if (ga) {
                ga('send', {
                    'hitType': 'event',
                    'eventCategory': source.eventCategory,
                    'eventAction': source.eventAction,
                    'eventLabel': source.eventLabel,
                    'interactionKey': source.interactionKey
                });
            }
        },
        doTrack: function (scope, action, source) {
            $log.debug('Track action: ' + JSON.stringify(action));
            var defer = $q.defer();
            var params = {action: action};
            if (angular.isDefined(scope.saleSessionManager)) {
                var interactionKey = scope.saleSessionManager.getInteractionKey();
                if (interactionKey) {
                    params.interaction = interactionKey;
                }
            }
            actionService.post(params, source,
                function (result) {
                    defer.resolve(result);
                },
                function () {
                    defer.reject();
                }
            );
            return defer.promise;
        },
        trackAction: function (scope, source) {
            return this.doTrack(scope, 'trackAction', {
                externalFeatureId: source.actionId,
                externalPageId: scope.activePageId,
                externalViewId: source.viewId
            });
        }
    };
}]);

// Lazy loading of Google Map API
frontendApp.service('loadGoogleMapAPI', ['$window', '$q', function ($window, $q) {

    var deferred = $q.defer();

    function loadScript() {
        if ($window.google && $window.google.maps) {
            deferred.resolve();
        } else {
            var script = document.createElement("script");
            script.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + "maps.googleapis.com/maps/api/js?sensor=false&language=en&callback=initMap";
            if (angular.isDefined($window.googleMapAccount) && $window.googleMapAccount != null && $window.googleMapAccount != "") {
                script.src = script.src + "&key=" + $window.googleMapAccount;
            }
            document.body.appendChild(script);
        }
    }

    // Script loaded callback, send resolve
    $window.initMap = function () {
        deferred.resolve();
    };

    loadScript();

    return deferred.promise;
}]);

frontendApp.factory('processFacade', ['$rootScope', function ($rootScope) {
    return {
        updateProcessView: function (processDefinitionId) {
            $rootScope.$broadcast("refreshInnerPage", processDefinitionId);
        }
    };
}]);

frontendApp.factory('preorderService', ['$resource', function ($resource) {
    return $resource(restUrl + '/service/preorder/:action', {},
        {
            get: {
                method: 'GET',
                isArray: true,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            },
            post: {
                method: 'POST',
                isArray: false
            }
        }
    );
}]);

frontendApp.factory('formSelectUIService', [function () {
    var disableButton = true;
    var visibleButton = false;
    var fireClickButton = false;

    return {
        init: function () {
            //todo: provide holder for more that 1 instance.
            disableButton = true;
            visibleButton = false;
            fireClickButton = false;
        },
        isDisableButton: function () {
            return disableButton;
        },
        setDisableButton: function (disabled) {
            disableButton = disabled;
        },
        isVisibleButton: function () {
            return visibleButton;
        },
        setVisibleButton: function (visible) {
            visibleButton = visible;
        },
        getButtonListener: function () {
            return fireClickButton;
        },
        clickActionButton: function (fire) {
            fireClickButton = fire;
        }
    };
}]);

frontendApp.factory('inputUIService', [function () {
    var disableButton = false;
    return {
        init: function () {
            disableButton = false;
        },
        isDisableButton: function () {
            return disableButton;
        },
        setDisableButton: function (disabled) {
            disableButton = disabled;
        }
    };
}]);

angular.module('UtilServices', []).factory("isEnabledCookies", function () {
    var ws = new Date();
    ws.setMinutes(ws.getMinutes() + 1);
    document.cookie = "cookieForTest=Test string.; expires=" + ws.toUTCString();
    return document.cookie.length > 0;
});

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp */

frontendApp.constant('FILTER_TEMPLATES', {

    FILTER_ACTION_SELECT: '<div class="col-xs-16 col-sm-8 col-md-4">' +
                          '    <div>' +
                          '        <span content="filter.selected.image" custom-layout></span>' +
                          '        <span content="filter.selected.name" custom-layout></span>' +
                          '    </div>' +
                          '    <div>' +
                          '        <a ng-click="execute();" class="btn {{getOpenBundleAction(owner).buttonClass}}">Select Element</a>' +
                          '    </div>' +
                          '</div>',

    FILTER_BUTTONS:       '<div class="filter-basic filter-buttonBox filter-buttonView filter-buttonView-2Cols">'+
                          '    <label class="caption">{{filter | filterWithAlternativeLabel}}</label>'+
                          '    <div class="clearfix">'+
                          '        <div class="temp-{{view.buttonWidth}} btn-col" ng-repeat="option in filter.options">'+
                          '            <div class="checkboxStyled">' +
                          '                <input id="filter{{filter.name}}{{$index}}" type="checkbox" ng-checked="isChecked($index)" ng-click="updateOption($index);$emit(\'FilterChangedEvent\', filter);">'+
                          '                <label for="filter{{filter.name}}{{$index}}" title="{{option.label}}" ng-bind-html="option.customLabel"></label>'+
                          '            </div>' +
                          '        </div>' +
                          '    </div>' +
                          '</div>',

    FILTER_DROPDOWN:      '<div class="filter-basic filter-dropDown">' +
                          '    <label class="caption" for="fdd{{filter.id}}">{{filter | filterWithAlternativeLabel}}</label>' +
                          '    <select id="fdd{{filter.id}}" class="form-control" ng-model="filter.selected" ng-options="option.value as option.label for option in filter.options" ng-change="$emit(\'FilterChangedEvent\', filter)" ng-disabled="filter.options.length < 1">' +
                          '        <option value="" label="Choose {{filter | filterWithAlternativeLabel}}"></option>' +
                          '    </select>' +
                          '</div>',

    FILTER_MULTI_SELECT:  '<div class="filter-basic filter-multipleSelect">' +
                          '    <label class="caption" for="ddCriteria">{{filter | filterWithAlternativeLabel}}</label>' +
                          '    <select id="ddCriteria" multiple size="5" class="form-control" ng-model="filter.selected" ng-options="option.value as option.label for option in filter.options" ng-change="$emit(\'FilterChangedEvent\', filter)">' +
                          '        <option value=""></option>' +
                          '    </select>' +
                          '</div>',

    FILTER_SLIDER:        '<div class="filter-basic filterSlider">' +
                          '    <label class="caption">{{filter | filterWithAlternativeLabel}}</label>' +
                          '    <div slider-common target="filter.selected" options="filter" on-mouseup="$emit(\'FilterChangedEvent\', filter)"/>' +
                          ' </div>',

    FILTER_SLIDER_EXT:    '<div class="filter-basic filterSlider">' +
                          '    <label class="caption">{{filter | filterWithAlternativeLabel}}</label>' +
                          '    <div ng-if="filter.options != null">' +
                          '        <div slider-extended-rules filter="filter" filters="filters" options="filter.options" owner="owner" on-mouseup="$emit(\'FilterChangedEvent\', filter)"/>' +
                          '    </div>' +
                          '    <div ng-if="filter.options == null">' +
                          '        <div slider-common target="filter.selected" filters="filters" options="filter" owner="owner" on-mouseup="$emit(\'FilterChangedEvent\', filter)"/>' +
                          '    </div>' +
                          '</div>',

    SORT_BUTTON:          '<div>' +
                          '    <button type="button" class="btn btn-md btn-primary" ng-click="asc = !asc; prevFieldName = field.name; applySort(asc, field);" ng-class="{\'active\': prevFieldName == field.name, \'inactive\': prevFieldName == \'\' || prevFieldName != field.name}">' +
                          '        <span class="glyphicon" ng-class="{\'glyphicon-sort-by-alphabet\': asc == true, \'glyphicon-sort-by-alphabet-alt\': asc == false}"></span>' +
                          '    </button>' +
                          '</div>'
});

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp */

frontendApp.constant('FORM_FIELD_TEMPLATES', {
    FORM_INPUT: '<div class="form-group ngc-forms-input">' +
    '   <label for="{{title}}" class="control-label">{{title | translate}}<pre ng-show="requiredLabel">{{requiredLabel | translate}}</pre></label>' +
    '   <div class="form-group-iconBox">' +
    '       <input id="{{title}}" class="form-control" placeholder="{{ placeholderTranslationKey | translateRippl:placeholderDefaultValue }}" type="{{inputType}}" ng-model="value" ng-blur="signal();" maxlength="{{length}}" ng-keypress="checkValue($event)"/>' +
    '       <span ng-show="failed" class="proIcons proIconsRed ng-hide">W</span>' +
    '       <span ng-show="valid" class="proIcons proIconsGreen ng-hide">V</span>' +
    '       <div ng-if="!multiLanguage && failed" class="rippl-form-alert alert alert-danger"ng-cloak>' +
    '           <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '       </div>' +
    '       <div ng-if="multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '           <div ng-show="errorMessage != null && errorMessage != \'\'">{{ errorMessage | translate:errorMessageData }}</div>' +
    '       </div>' +
    '   </div>' +
    '</div>',

    FORM_AREA: '<div class="ngc-forms-textarea">' +
    '   <span>{{title | translate}}<pre ng-show="requiredLabel">{{requiredLabel | translate}}</pre></span>' +
    '   <textarea cols="60" rows="10" ng-model="value" ng-blur="signal();" maxlength="{{maxLength}}" placeholder="{{ placeholderTranslationKey | translateRippl:placeholderDefaultValue }}" ng-keypress="checkValue($event)"/>' +
    '   <div ng-if="!multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '       <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '   </div>' +
    '   <div ng-if="multiLanguage && failed" class="rippl-form-alert alert alert-danger"ng-cloak>' +
    '       <div ng-show="errorMessage != null && errorMessage != \'\'">{{ errorMessage | translate:errorMessageData }}</div>' +
    '   </div>' +
    '</div>',

    FORM_HIDDEN: '<div class="ngc-forms-hidden">' +
    '   <input type="text" ng-model="value" ng-show="false"/>' +
    '</div>',

    FORM_NAME_TITLE: '<div class="form-group ngc-forms-name_title">' +
    '  <label for="title" class="control-label">{{title}} &nbsp;<pre ng-show="requiredLabel">{{requiredLabel}}</pre></label>' +
    '  <select id="title" class="form-control" ng-model="value" ng-blur="signal();">' +
    '   <option value="Mr" selected="selected">Mr</option>' +
    '   <option value="Mrs">Mrs</option>' +
    '   <option value="Miss">Miss</option>' +
    '   <option value="Dr">Dr</option>' +
    '  </select>' +
    '  <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '       The {{title}} is required.' +
    '  </div>' +
    '</div>',

    FORM_DROP_DOWN: '<div class="form-group form-drop-down">' +
    '   <label for="title" class="control-label">{{title | translate}} &nbsp;<pre ng-show="requiredLabel">{{requiredLabel | translate}}</pre></label>' +
    '   <select ng-if="!multiLanguage" id="title" class="form-control" ng-model="$parent.value" ng-options="v.value as v.label for v in getDropDownValues()" ng-change="change()" ng-blur="signal();">' +
    '      <option value=""> -- Please select --</option>' +
    '   </select>' +
    '   <select ng-if="multiLanguage" id="title" class="form-control" ng-model="$parent.value" ng-options="v.value as v.label | translate for v in getDropDownValues()" ng-change="change()" ng-blur="signal();">' +
    '      <option value="">{{"SYS_PLEASE_SELECT" | translate}}</option>' +
    '   </select>' +
    '       <div ng-if="!multiLanguage && failed" class="rippl-form-alert alert alert-danger"ng-cloak>' +
    '           <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '       </div>' +
    '       <div ng-if="multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '           <div ng-show="errorMessage != null && errorMessage != \'\'">{{ errorMessage | translate:errorMessageData }}</div>' +
    '       </div>' +
    '</div>',

    FORM_MULTI_SELECT: '<div class="ngc-form-multiselect">' +
    '    <input type="text" ng-model="values" ng-show="false"/>' +
    '    <div class="alert alert-danger" ng-if="failed" ng-cloak>The {{title}} is required.</div>' +
    '</div>',

    FORM_MULTI_SELECT_ITEM: '<div ng-class="{\'selected\': selected}" ng-click="selectValue();" ng-transclude></div>',

    FORM_ADDRESS: '<div class="form-group ngc-forms-address">' +
    '     <label for="address" class="control-label">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></label>' +
    '     <div class="form-group-iconBox">' +
    '        <input ng-model="value" class="form-control" maxlength="140" type="text" placeholder="Start typing a postcode, street or address" typeahead="address for address in getAddresses($viewValue)" id="address" ng-blur="signal();"/>' +
    '       <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '           <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '          <div ng-show="errorMessage == null || errorMessage == \'\'">Please enter a valid uk address or postcode.</div>' +
    '       </div>' +
    '     </div>' +
    '</div>',

    FORM_PHONE_NUMBER: '<div class="form-group ngc-forms-phone-number">' +
    '     <div class="row">' +
    '         <div class="col-xs-16 col-sm-7 col-md-5 col-lg-4">' +
    '             <label for="phoneNumber" class="control-label">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></label>' +
    '             <div class="form-group-iconBox">' +
    '                 <input type="tel"  class="form-control" ng-model="value" placeholder="{{ placeholderTranslationKey | translateRippl:placeholderDefaultValue }}" maxlength="11" id="phoneNumber" ng-blur="signal();"/>' +
    '                 <span ng-show="failed" class="proIcons proIconsRed ng-hide">W</span>' +
    '                 <span ng-show="valid" class="proIcons proIconsGreen ng-hide">V</span>' +
    '                 <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '                     <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '                </div>' +
    '            </div>' +
    '        </div>' +
    '        <div class="col-xs-16 col-sm-3" ng-show="!hideLookup">' +
    '            <div class="form-group-label-vSpacer"></div>' +
    '            <input class="btn" type="button" ng-disabled="value == \'\'" value="LOOK UP" ng-click="lookup();"/>' +
    '        </div>' +
    '    </div>' +
    '</div>',

    FORM_NAME: '<div class="form-group ngc-form-name">' +
    '            <label for="formName" class="control-label">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></label>' +
    '            <div class="form-group-iconBox">' +
    '                <input type="text" class="form-control" ng-model="value" id="formName" ng-blur="signal();" capitalization/>' +
    '                <span ng-show="failed" class="proIcons proIconsRed ng-hide">W</span>' +
    '                <span ng-show="valid" class="proIcons proIconsGreen ng-hide">V</span>' +
    '                <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '                    <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '                </div>' +
    '            </div>' +
    '</div>',

    FORM_EMAIL: '<div class="form-group ngc-forms-email">' +
    '   <div class="row">' +
    '       <div class="col-xs-16 col-sm-7 col-md-5 col-lg-4">' +
    '           <label for="customerEmail" class="control-label">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></label>' +
    '           <div class="form-group-iconBox">' +
    '               <input type="email" class="form-control" ng-model="value" placeholder="{{ placeholderTranslationKey | translateRippl:placeholderDefaultValue }}" maxlength="64" id="customerEmail" ng-blur="signal();"/>' +
    '               <span ng-show="failed" class="proIcons proIconsRed ng-hide">W</span>' +
    '               <span ng-show="valid" class="proIcons proIconsGreen ng-hide">V</span>' +
    '               <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '                   <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '                   <div ng-show="errorMessage == null || errorMessage == \'\'">The email address is not valid.</div>' +
    '               </div>' +
    '           </div>' +
    '       </div>' +
    '       <div class="col-xs-16 col-sm-3" ng-show="!hideLookup">' +
    '       <div class="form-group-label-vSpacer"></div>' +
    '           <input class="btn" type="button" ng-disabled="value == \'\'" value="LOOK UP" ng-click="lookup();"/>' +
    '       </div>' +
    '   </div>' +
    '</div>',

    FORM_BANK_ACCOUNT: '<div class="form-group ngc-form-bank-account">' +
    '    <div class="row">' +
    '        <div  class="col-xs-9 col-sm-5 col-md-4">' +
    '            <label class="control-label">ACCOUNT NUMBER</label>' +
    '            <div class="form-group-iconBox">' +
    '                <input type="text" ng-model="number" ng-blur="signalNumber();" class="form-control"/>' +
    '            </div>' +
    '        </div>' +
    '        <div class="col-xs-7 col-sm-6 col-md-3">' +
    '            <label class="control-label">SORT CODE</label>' +
    '            <div class="form-group-iconBox">' +
    '                <input type="text" ng-model="code" ng-blur="signalCode();"  class="form-control"/>' +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '        <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '        <div ng-show="errorMessage == null || errorMessage == \'\'">The account is not valid</div>' +
    '    </div>' +
    '</div>',

    FORM_SLIDER: '<div class="form-group ngc-forms-slider ngc-forms-slider-basic" ng-class="{\'ngc-forms-slider-ranged\': slider.visibility && slider.visibility.indexOf(\'RANGE\') != -1}">' +
    '  <label class="control-label rippl-form-caption">{{title | translate}}</label>' +
    '  <div class="rippl-form-subcaption" ng-show="(subtitle && subtitle.length > 0)">' +
    '       <span class="rippl-form-sub-caption-lbl">{{subtitle | translate}}</span>' +
    '  </div>' +
    '  <div class="form-group">' +
    '      <div class="valueSlider row">' +
    '          <div ng-if="!multiLanguage" slider-common target="$parent.value" options="slider"/>' +
    '          <div ng-if="multiLanguage" slider-common-translate target="$parent.value" options="slider"/>' +
    '          <div ng-if="!multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '              <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '          </div>' +
    '          <div ng-if="multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '              <div ng-show="errorMessage != null && errorMessage != \'\'">{{ errorMessage | translate:errorMessageData }}</div>' +
    '          </div>' +
    '      </div>' +
    '  </div>' +
    '</div>',

    FORM_SLIDER_COMPLEX: '<div class="form-group ngc-forms-slider ngc-forms-slider-complex">' +
    '  <label class="control-label">{{title | translate}}<pre ng-show="requiredLabel">{{requiredLabel | translate}}</pre></label>' +
    '  <div class="form-group">' +
    '      <div class="valueSlider row">' +
    '           <div slider-complex target="value" options="slider"/>' +
    '           <div ng-if="!multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '               <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '           </div>' +
    '           <div ng-if="multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '               <div ng-show="errorMessage != null && errorMessage != \'\'">{{ errorMessage | translate:errorMessageData }}</div>' +
    '           </div>' +
    '      </div>' +
    '  </div>' +
    '</div>',

    FORM_DATE: '<div class="form-group ngc-forms-date">' +
    '    <div class="row">' +
    '        <div class="col-xs-16">' +
    '            <label class="control-label">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></label>' +
    '        </div>' +
    '        <div class="col-xs-4 col-sm-2">' +
    '            <select id="day" class="form-control" name="day" ng-model="day" ng-options="day as day for day in [] | range:\'DAY\'" ng-blur="signal();">' +
    '                <option value="" selected>DD</option>' +
    '            </select>' +
    '        </div>' +
    '        <div class="col-xs-7 col-sm-3 col-lg-2">' +
    '            <select id="month" class="form-control" name="month" ng-model="month" ng-options="month as month for month in [] | range:\'MONTH\'" ng-blur="signal();">' +
    '                <option value="" selected>MM</option>' +
    '            </select>' +
    '        </div>' +
    '        <div class="col-xs-5 col-sm-3 col-md-2">' +
    '            <select id="year" class="form-control" name="year" ng-model="year" ng-options="year as year for year in [] | range:\'YEAR\'" ng-blur="signal();">' +
    '                <option value="" selected>YYYY</option>' +
    '            </select>' +
    '        </div>' +
    '        <br>' +
    '    </div>' +
    '    <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '        <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '        <div ng-show="errorMessage == null || errorMessage == \'\'">Please enter valid date.</div>' +
    '    </div>' +
    '</div>',

    FORM_DATE_PICKER: '<div class="form-group ngc-forms-date">' +
    '    <div class="row">' +
    '        <div class="col-xs-16">' +
    '            <label class="control-label">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></label>' +
    '        </div>' +
    '        <div class="col-xs-16">' +
    '            <p class="input-group">' +
    '            <input type="text" class="form-control" datepicker-popup="dd/MM/yyyy" ng-model="date" is-open="opened" datepicker-options="dateOptions" close-text="Close" />' +
    '            <span class="input-group-btn">' +
    '            <button type="button" class="btn btn-default" ng-click="open($event)"><i class="glyphicon glyphicon-calendar"></i></button>' +
    '            </span>' +
    '            </p>' +
    '        </div>' +
    '        <br>' +
    '    </div>' +
    '    <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '        <div>{{errorMessage}}</div>' +
    '    </div>' +
    '</div>',

    FORM_CONFIRMATION: '<div class="form-group customerCheckbox checkboxStyled ngc-forms-confirmation">' +
    '    <input type="checkbox" ng-model="value" ng-true-value="true" ng-false-value="false" id="heppyOffers" ng-blur="signal();"/>' +
    '    <label class="control-label" for="heppyOffers">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></label>' +
    '</div>',

    FORM_CUSTOMER_TYPE: '<div class="form-group ngc-forms-customer-type">' +
    '   <div class="ngc-forms-caption ngc-forms-customer-type-caption">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></div>' +
    '   <div class="form-control" id="customerType">' +
    '       <label class="radio-inline">' +
    '          <input type="radio" name="customerTypeOptions" ng-model="value" value="NEW" ng-blur="signal();">NEW' +
    '       </label>' +
    '       <label class="radio-inline">' +
    '           <input type="radio" name="customerTypeOptions" ng-model="value" value="UPGRADING" ng-blur="signal();">UPGRADING' +
    '       </label>' +
    '       <label class="radio-inline">' +
    '           <input type="radio" name="customerTypeOptions" ng-model="value" value="BUSINESS" ng-blur="signal();">BUSINESS' +
    '       </label>' +
    '       <label class="radio-inline">' +
    '           <input type="radio" name="customerTypeOptions" ng-model="value" value="PAYG" ng-blur="signal();">PAYG' +
    '       </label>' +
    '   </div>' +
    '   <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '       The {{title}} is required.' +
    '   </div>' +
    '</div>',

    FORM_PAY_TYPE: '<div class="form-group ngc-forms-customer-type">' +
    '   <div class="ngc-forms-caption ngc-forms-customer-type-caption">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></div>' +
    '   <div class="form-control" id="payType">' +
    '       <label class="radio-inline">' +
    '           <input type="radio" name="payTypeOptions" ng-model="value" value="CASH" ng-blur="signal();">CASH' +
    '       </label>' +
    '       <label class="radio-inline">' +
    '          <input type="radio" name="payTypeOptions" ng-model="value" value="CARD" ng-blur="signal();">CARD' +
    '       </label>' +
    '   </div>' +
    '   <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '       The {{title}} is required.' +
    '   </div>' +
    '</div>',

    FORM_SELECT: '<div class="rippl-form-frontend">' +
    '   <div class="rippl-form-caption" ng-show="(title && title.length > 0)">' +
    '       <span class="rippl-form-caption-lbl" ng-bind-html="title | translate"></span>' +
    '       <span class="rippl-form-required" ng-show="requiredLabel">{{requiredLabel | translate}}</span>' +
    '   </div>' +
    '   <div class="rippl-form-subcaption" ng-show="(subtitle && subtitle.length > 0)">' +
    '       <span class="rippl-form-sub-caption-lbl"  ng-bind-html="subtitle | translate"></span>' +
    '   </div>' +
    '   <div class="rippl-form-box" itemqty="{{options.length}}">' + // rows and columns count are based on itemqty attribute. used in css.
    '       <input type="hidden" ng-model="values">' +
    '       <div ng-repeat="option in options" class="rippl-form-item">' +
    '       <div action-id="{{option.action}}" class="igs-iconInfo" ng-show="(option.action && option.action.length > 0)"><svg class="icon-svg"><use xlink:href="#icon-info"></use></svg></div>' +
    '           <div class="rippl-form-item-box" ng-class="{\'rippl-form-item-selected\': option.selected, \'rippl-disable\': option.disabled}" ng-click="selectValue(option);">' +
    '               <div class="rippl-form-item-image" ng-bind-html="getImage(option)" ng-show="isImagePresented(option)"></div>' +
    '               <div class="rippl-form-item-options" >' +
    '                   <div class="rippl-form-item-title" ng-show="(option.title && option.title.length > 0)" ng-bind-html="option.title | translate"></div>' +
    '                   <div class="rippl-form-item-label" ng-show="(option.label && option.label.length > 0)" ng-bind-html="option.label | translate"></div>' +
    '                   <div class="rippl-form-item-description" ng-show="(option.description && option.description.length > 0)" ng-bind-html="option.description | translate"></div>' +
    '               </div>' +
    '               <div class="rippl-form-item-priority" ng-show="option.priority">{{option.priority}}</div>' +
    '           </div>' +
    '       </div>' +
    '   </div>' +
    '   <div ng-if="!multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '       <div>{{errorMessage}}</div>' +
    '   </div>' +
    '   <div ng-if="multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '       <div>{{ errorMessage | translate:errorMessageData }}</div>' +
    '   </div>' +
    '</div>',

    FORM_SELECT_ACTION_BUTTON: '<div ng-show="visible" ng-class="{\'rippl-disable\': disabled}" ng-transclude></div>',

    INPUT_ACTION_BUTTON: '<div ng-class="{\'rippl-disable\': disabled}" ng-transclude></div>',

    FORM_RADIO_BUTTONS: '<div class="form-group ngc-forms-radio">' +
    '     <div class="ngc-forms-caption ngc-forms-radio-caption">{{title}}<pre ng-show="requiredLabel">{{requiredLabel}}</pre></div>' +
    '     <div class="ngc-forms-radio-box" id="formRadioButton">' +
    '       <label class="radio-inline" ng-repeat="option in options">' +
    '          <input type="radio" name="formRadioButtonOptions" ng-change="changeValue(option)" ng-model="value" ng-blur="signal();">{{option}}' +
    '       </label>' +
    '     </div>' +
    '     <div class="rippl-form-alert alert alert-danger" ng-if="failed" ng-cloak>' +
    '       The {{title}} is required.' +
    '     </div>' +
    '</div>',

    FORM_COMPLEX_RADIO_BUTTONS: '<div class="form-group">' +
    '   <div class="ngc-forms-caption ngc-forms-radio-complex-caption">{{title | translate}}<pre ng-show="requiredLabel">{{requiredLabel | translate}}</pre></div>' +
    '   <div class="ngc-forms-radio-box" id="formRadioButton">' +
    '     <div ng-repeat="option in options" class="ngc-forms-radio-item" ng-class="{\'selected\': selected.value == option.value}">' +
    '         <input id="radioID{{title}}{{option.value}}" type="radio" name="{{titleWithoutSpaces}}" value="{{option.value}}" ng-model="selected.value" ng-change="signal();">' +
    '         <label for="radioID{{title}}{{option.value}}" class="radio-inline" >' +
    '           <span>{{ option.label | translate }}</span>' +
    '         </label>' +
    '     </div>' +
    '   </div>' +
    '   <div ng-if="!multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '       <div ng-show="errorMessage != null && errorMessage != \'\'">{{errorMessage}}</div>' +
    '   </div>' +
    '   <div ng-if="multiLanguage && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '       <div ng-show="errorMessage != null && errorMessage != \'\'">{{ errorMessage | translate:errorMessageData }}</div>' +
    '   </div>' +
    '</div>',

    FORM_COMMON_INPUT_HEADER:
    '<div class="rippl-form-box">' +
    '   <label ng-show="title" for="{{inputId}}" title="{{title | translate}}" class="rippl-form-label">{{title | translate}}</label>' +
    '   <div class="rippl-form-input-body">',

    FORM_COMMON_INPUT_FOOTER:
    '   </div>' +
    '   <div ng-show="isCustomErrorMessageUi && failed" class="rippl-form-alert alert alert-danger" ng-cloak>' +
    '       <div>{{ errorMessage | translate }}</div>' +
    '   </div>' +
    '</div>',

    FORM_APOLLO_SLIDER:
            '<div class="slider-viewBox{{attribute.style}}">' +
                '<div class="igs-forms-sliderBox" ng-class="{\'igs-sliderType--horizontal\': attribute.apolloSliderOrientation==\'HORIZONTAL\', \'igs-sliderType--vertical\':attribute.apolloSliderOrientation==\'VERTICAL\'}">' +
                    '<div class="rippl-form-caption"><span class="rippl-form-caption-lbl" ng-bind-html="attribute.title"></span></div>' +
                    '<div class="rippl-form-subcaption"><span class="rippl-form-subcaption-lbl" ng-bind-html="attribute.subTitle"></span></div>' +
                    '<div class="igs-slider--container">' +
                        '<div class="igs-slider" field="{{slider.attrName}}" ng-repeat="slider in attribute.sliders" id="igsSlider{{$index}}" ng-class="applyClasses(slider)">' +
                            '<div class="igs-slider--title">' +
                                '<span class="igs-slider--titleName" ng-bind-html="slider.title | translate"></span>' +
                                '<span class="igs-slider--titleVal">' +
                                    '<div ng-if="slider.value[1]" class="igs-slider--val">{{slider.value[0]}}</div>' +
                                    '<div ng-if="!slider.value[1]" class="igs-slider--val">{{slider.value}}</div>' +
                                '</span>' +
                            '</div>' +
                            '<div class="igs-slider--staticVal igs-slider--staticValTop">' +
                                '<div ng-if="slider.value[1]" class="igs-slider--val">{{slider.value[0]}}</div>' +
                                '<div ng-if="!slider.value[1]" class="igs-slider--val">{{slider.value}}</div>' +
                            '</div>' +
                            '<div class="igs-slider--mainContent">' +
                                '<div class="igs-slider--lgndVal igs-slider--lgndMinVal" ng-show="slider.showMinMax">' +
                                    '<div class="igs-slider--mmVal" ng-hide="slider.showMinMaxText">' +
                                        '<em ng-if="\'BEFORE\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>{{slider.min}}<em ng-if="\'AFTER\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>' +
                                    '</div>' +
                                    '<div class="igs-slider--mmValDescr">{{slider.minText | translate}}</div>' +
                                '</div>' +
                                '<div ui-slider="{range: \'{{rangeInit(slider)}}\', stop: mouseup, orientation:\'{{attribute.apolloSliderOrientation.toLowerCase()}}\', {{initSliderValue(slider)}} }" swipe-slider=""' +
                                     'class="igs-slider--body ui-slider ui-corner-all ui-widget ui-widget-content"' +
                                     'min="{{slider.min}}" max="{{slider.max}}" step="{{slider.step}}" ng-model="slider.value">' +
                                    '<!-- Dot legend top/left -->' +
                                    '<div class="igs-slider--dots igs-slider--dotsTop">' +
                                        '<div ng-repeat="num in range(slider)"><span>' +
                                            '<em ng-if="\'BEFORE\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>{{num}}<em ng-if="\'AFTER\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>' +
                                        '</span></div>' +
                                    '</div>' +
                                    '<!-- /Dot legend top/left -->' +
                                    '<!-- color bar -->' +
                                    '<div class="igs-slider--colorBar ui-slider--range ui-corner-all ui-widget-header ui-slider-range-min" style="{{getInitialFillValueStyle(slider, attribute.apolloSliderOrientation)}}"></div>' +
                                    '<!-- /color bar -->' +
                                    '<!-- handles -->' +
                                    '<span tabindex="0" class="igs-slider--handle igs-slider--handle1 ui-slider-handle ui-corner-all ui-state-default" style="{{getInitialFirstValueStyle(slider, attribute.apolloSliderOrientation)}}"></span>' +
                                    '<span tabindex="0" class="igs-slider--handle igs-slider--handle2 ui-slider-handle ui-corner-all ui-state-default" style="{{getInitialSecondValueStyle(slider, attribute.apolloSliderOrientation)}}"></span>' +
                                    '<div class="igs-slider--handleVal igs-slider--handleVal1" style="{{getInitialFirstValueStyle(slider, attribute.apolloSliderOrientation)}}">' +
                                        '<div class="igs-slider--val">' +
                                            '<em ng-if="\'BEFORE\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>' +
                                            '<span ng-if="slider.value[1]">{{slider.value[0]}}</span>' +
                                            '<span ng-if="!slider.value[1]">{{slider.value}}</span>' +
                                            '<em ng-if="\'AFTER\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>' +
                                        '</div>' +
                                    '</div>' +
                                    '<div class="igs-slider--handleVal igs-slider--handleVal2" style="{{getInitialSecondValueStyle(slider, attribute.apolloSliderOrientation)}}">' +
                                        '<div class="igs-slider--val">' +
                                            '<em ng-if="\'BEFORE\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>{{slider.value[1]}}<em ng-if="\'AFTER\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>' +
                                        '</div>' +
                                    '</div>' +
                                    '<!-- /handles -->' +
                                    '<!-- Dot legend bot/right -->' +
                                    '<div class="igs-slider--dots igs-slider--dotsBot">' +
                                        '<div ng-repeat="num in range(slider)"><span>' +
                                            '<em ng-if="\'BEFORE\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>{{num}}<em ng-if="\'AFTER\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>' +
                                        '</span></div>' +
                                    '</div>' +
                                    '<!-- Dot legend bot/right -->' +
                                '</div>' +
                                '<div class="igs-slider--lgndVal igs-slider--lgndMaxVal" ng-show="slider.showMinMax">' +
                                    '<div class="igs-slider--mmVal" ng-hide="slider.showMinMaxText">' +
                                        '<em ng-if="\'BEFORE\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>{{slider.max}}<em ng-if="\'AFTER\' == slider.addPrefixText" class="igs-slider-prefix">{{slider.prefixText | translate}}</em>' +
                                    '</div>' +
                                    '<div class="igs-slider--mmValDescr">{{slider.maxText | translate}}</div>' +
                                '</div>' +
                            '</div>' +
                            '<div class="igs-slider--staticVal igs-slider--staticValBot">' +
                                '<div ng-if="slider.value[1]" class="igs-slider--val">{{slider.value[0]}}</div>' +
                                '<div ng-if="!slider.value[1]" class="igs-slider--val">{{slider.value}}</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>'
});

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp */

frontendApp.constant('SLIDER_TEMPLATES', {

    SLIDER_COMMON: '<div>' +
    '<span class="col-xs-4 col-sm-2 baseValue minValue" ng-if="lowerLabel != null && options.visibility.indexOf(\'LOW\') != -1" ng-bind-html="lowerLabel"></span>' +
    '    <div ui-slider="{range: \'min\', stop: mouseup }" swipe-slider class="col-xs-8 col-sm-12 valueSliderBox" min="{{options.lower}}" max="{{options.higher}}" step="{{options.step}}" ng-model="target">' +
    '        <div class="valueSliderLbl" ng-class="{\'valueSliderLblMin\': target != null && target == options.lower, \'valueSliderLblMax\': target != null && target == options.higher}" ' +
    ' ng-style="{\'left\': valueLabelLeft + \'%\'}" ng-if="valueLabel != null && \'\' != target && options.visibility.indexOf(\'VALUE\') != -1">' +
    '            <span ng-bind-html="valueLabel"></span>' +
    '        </div>' +
    '        <div class="dots">' +
    '            <div ng-style="{\'width\': dotWidth + \'%\'}" ng-repeat="dot in dots track by $index"><span ng-if="options.visibility && options.visibility.indexOf(\'RANGE\') != -1" ng-bind-html="dotLabel($index)"></span></div>' +
    '        </div>' +
    '    </div>' +
    '    <span class="col-xs-4 col-sm-2 baseValue maxValue" ng-if="higherLabel != null && options.visibility.indexOf(\'HIGH\') != -1" ng-bind-html="higherLabel"></span>' +
    '</div>',

    SLIDER_COMPLEX: '<div ui-slider="{range: \'min\', stop: mouseup }" swipe-slider class="valueSliderBox viewSliderBox" min="{{options.lower}}" max="{{options.higher}}" step="{{options.step}}" ng-model="target">' +
    '    <div class="valueSliderLbl" ng-class="{\'valueSliderLblMin\': target != null && target == options.lower, \'valueSliderLblMax\': target != null && target == options.higher}" ' +
    ' ng-style="{\'left\': valueLabelLeft + \'%\'}" ng-if="valueLabel != null">' +
    '        <span ng-bind-html="valueLabel | translate"></span>' +
    '    </div>' +
    '    <div class="dots">' +
    '        <div ng-style="{\'width\': dotWidth + \'%\'}" ng-repeat="dot in dots track by $index"></div>' +
    '    </div>' +
    '</div>',

    SLIDER_EXTENDED: '<div>' +
    '    <span class="col-xs-4 col-sm-2 baseValue minValue" ng-if="lowerLabel != null" ng-bind-html="lowerLabel"></span>' +
    '    <div ui-slider="{range: \'min\', stop: mouseup }" swipe-slider class="col-xs-8 col-sm-12 valueSliderBox" min="{{lower - lower}}" max="{{higher - lower}}" step="{{step}}" ng-model="optionIndex">' +
    '        <div class="valueSliderLbl" ng-class="{\'valueSliderLblMin\': optionIndex != null && optionIndex == lower, \'valueSliderLblMax\': optionIndex != null && optionIndex == higher}"' +
    ' ng-style="{\'left\': valueLabelLeft + \'%\'}" ng-if="valueLabel != null">' +
    '            <span ng-bind-html="valueLabel"></span>' +
    '        </div>' +
    '        <div class="dots">' +
    '            <div ng-style="{\'width\': dotWidth + \'%\'}" ng-repeat="dot in dots track by $index"></div>' +
    '        </div>' +
    '    </div>' +
    '    <span class="col-xs-4 col-sm-2 baseValue maxValue" ng-if="higherLabel != null" ng-bind-html="higherLabel"></span>' +
    '</div>'
});

/*
 * Copyright 2020 Conversity Ltd.
 */
/* global frontendApp, angular */

frontendApp.factory('dataTool', [function () {
    var PREFIXES = {
        MB: 'MB',
        GB: 'GB'
    };
    return {

        /**
         * Format data value. Examples:
         *   200 -> 200MB
         *   1501 -> 1.5GB
         *
         * @param data value in MB
         * @param toFixed fractionalDigits (0 by default)
         * @returns {string} formatted data
         */
        formatData: function (data, toFixed) {
            var value = parseInt(data);
            var fixed = toFixed === undefined ? 0 : toFixed;

            if (0 <= value && value < 1000) {
                return value + PREFIXES.MB;
            } else {
                return (value / 1024).toFixed(fixed) + PREFIXES.GB;
            }
        }
    };
}]);

/* Animations */
frontendApp.animation('.slide-element', [function () {
    return {
        beforeAddClass: function (element, className, done) {
            element.slideUp(done);
        },
        removeClass: function (element, className, done) {
            element.hide().slideDown(done);
        }
    };
}]);

frontendApp.factory('collectionsUtils', [function () {
    return {
        getKeys: function (source) {
            var result = [];
            for (var prop in source) {
                if (source.hasOwnProperty(prop)) {
                    result.push(prop);
                }
            }
            return result;
        },
        createArray: function (source) {
            var keys = this.getKeys(source);
            var result = new Array(keys.length);
            for (var i = 0; i < (keys.length); i++) {
                var filter = source[keys[i]];
                result[filter.index] = filter;
            }
            return result;
        },
        createMap: function (source) {
            var result = {};
            for (var i = 0; i < source.length; i++) {
                result[source[i].filterId] = source[i].info;
            }
            return result;
        },
        isNotEmpty: function (source) {
            for (var key in source) {
                if (source.hasOwnProperty(key)) {
                    return true;
                }
            }
            return false;
        }
    };
}]);

frontendApp.factory('attributesTool', [function () {
    return {
        buildAttributes: function(routeParams, attrs, window) {
            var attributes = {};
            if (attrs) {
                for (var attr in attrs) {
                    if (attrs.hasOwnProperty(attr)) {
                        attributes[attr] = attrs[attr];
                    }
                }
            }
            if (window) {
                attributes['width'] = window.outerWidth || window.document.documentElement.clientWidth;
                attributes['lsqId'] = routeParams.questionnaireId;
            }
            for (var rp in routeParams) {
                if (routeParams.hasOwnProperty(rp)) {
                    attributes[rp] = routeParams[rp];
                }
            }
            return attributes;
        }
    };
}]);

//IE 8 fix indexOf method
if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (elt /*, from*/) {
        var len = this.length >>> 0;
        var from = Number(arguments[1]) || 0;
        from = (from < 0) ? Math.ceil(from) : Math.floor(from);
        if (from < 0) from += len;

        for (; from < len; from++) {
            if (from in this && this[from] === elt) return from;
        }
        return -1;
    };
}

frontendApp.factory('messagesService', ['$modal', function ($modal) {
    return {
        confirm: function (message) {
            return $modal.open({
                backdrop: true,
                template: '<div class="modal-body">' +
                ' <p>' + message + '</p>' +
                '</div>' +
                '<div class="modal-footer">' +
                ' <div>' +
                '  <a class="btn btn-default" ng-click="yes();" role="button">Yes</a>' +
                '  <a class="btn btn-default" ng-click="no();" role="button">No</a>' +
                ' </div>' +
                '</div>',
                controller: ['$scope', '$modalInstance',
                    function ($scope, $modalInstance) {
                        $scope.$on('EVT_USER_SESSION_EXPIRED', function() {
                           $modalInstance.dismiss();
                        });
                        $scope.yes = function () {
                            $modalInstance.close();
                        };
                        $scope.no = function () {
                            $modalInstance.dismiss();
                        };
                    }]
            }).result;
        },
        warn: function (message) {
            return $modal.open({
                backdrop: true,
                template: '<div class="modal-content ng-scope"><div class="customerEmailModal custom-font-2 ng-scope">' +
                '<div class="modal-header">' +
                '   <div class="closeStaticModal flowIcons" ng-click="close($event)">c</div>' +
                '   <h3 class="custom-color-1">Warning</h3>' +
                '</div>' +
                '<div class="modal-body">' +
                '  <div class="modalValigner">' +
                '    <p class="modalMainText custom-color-1">' + message + '</p>' +
                '    <div class="text-center" style="margin:0 -8px">' +
                '      <div class="btn btn-lg" ng-click="close($event);" style="margin:8px;width:200px;">Ok</div>' +
                '    </div>' +
                '  </div>' +
                '</div>' +
                '</div></div>',
                controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                    $scope.$on('EVT_USER_SESSION_EXPIRED', function() {
                        $modalInstance.dismiss();
                    });
                    $scope.close = function () {
                        $modalInstance.close();
                    };
                }]
            }).result;
        },
        notifyAll: function (messages) {
            var msg = '<div class="modal-content ng-scope"><div class="customerEmailModal custom-font-2 ng-scope">' +
                '<div class="modal-header">' +
                '   <div class="closeStaticModal flowIcons" ng-click="close($event)">c</div>' +
                '   <h3 class="custom-color-1">Warning</h3>' +
                '</div>' +
                '<div class="modal-body">' +
                '  <div class="modalValigner">';
            angular.forEach(messages, function (message) {
                msg += '    <p class="modalMainText custom-color-1">' + message + '</p>';
            });
            msg += '    <div class="text-center" style="margin:0 -8px">' +
                '      <div class="btn btn-lg" ng-click="close($event);" style="margin:8px;width:200px;">Ok</div>' +
                '    </div>' +
                '  </div>' +
                '</div>' +
                '</div></div>';
            return $modal.open({
                backdrop: true,
                template: msg,
                controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                    $scope.$on('EVT_USER_SESSION_EXPIRED', function() {
                        $modalInstance.dismiss();
                    });
                    $scope.close = function () {
                        $modalInstance.close();
                    };
                }]
            }).result;
    }
};
}]);

/*
 jQuery UI Slider plugin wrapper
 */
/* global window */

(function (window, angular, undefined) {'use strict';

    angular.module('ui.slider', []).value('uiSliderConfig', {}).directive('uiSlider', ['uiSliderConfig', '$timeout', function (uiSliderConfig, $timeout) {
        uiSliderConfig = uiSliderConfig || {};
        return {
            require: 'ngModel',
            compile: function () {
                return function (scope, elm, attrs, ngModel) {

                    function parseNumber(n, decimals) {
                        return (decimals) ? parseFloat(n) : parseInt(n);
                    }

                    var options = angular.extend(scope.$eval(attrs.uiSlider) || {}, uiSliderConfig);
                    // Object holding range values
                    var prevRangeValues = {
                        min: null,
                        max: null
                    };

                    // convenience properties
                    var properties = ['min', 'max', 'step'];
                    var useDecimals = (!angular.isUndefined(attrs.useDecimals)) ? true : false;

                    var init = function () {
                        // When ngModel is assigned an array of values then range is expected to be true.
                        // Warn user and change range to true else an error occurs when trying to drag handle
                        if (angular.isArray(ngModel.$viewValue) && options.range !== true) {
                            options.range = true;
                        }

                        // Ensure the convenience properties are passed as options if they're defined
                        // This avoids init ordering issues where the slider's initial state (eg handle
                        // position) is calculated using widget defaults
                        // Note the properties take precedence over any duplicates in options
                        angular.forEach(properties, function (property) {
                            if (angular.isDefined(attrs[property])) {
                                options[property] = parseNumber(attrs[property], useDecimals);
                            }
                        });

                        elm.slider(options);
                        init = angular.noop;
                    };

                    // Find out if decimals are to be used for slider
                    angular.forEach(properties, function (property) {
                        // support {{}} and watch for updates
                        attrs.$observe(property, function (newVal) {
                            if (newVal) {
                                init();
                                elm.slider('option', property, parseNumber(newVal, useDecimals));
                            }
                        });
                    });
                    attrs.$observe('disabled', function (newVal) {
                        init();
                        elm.slider('option', 'disabled', !!newVal);
                    });

                    // Watch ui-slider (byVal) for changes and update
                    scope.$watch(attrs.uiSlider, function (newVal) {
                        init();
                        if (newVal != undefined) {
                            elm.slider('option', newVal);
                        }
                    }, true);

                    // Late-bind to prevent compiler clobbering
                    $timeout(init, 0, true);

                    // Update model value from slider
                    elm.bind('slide', function (event, ui) {
                        ngModel.$setViewValue(ui.values || ui.value);
                        scope.$apply();
                    });

                    // Update slider from model value
                    ngModel.$render = function () {
                        init();
                        var method = options.range === true ? 'values' : 'value';

                        if (isNaN(ngModel.$viewValue) && !(ngModel.$viewValue instanceof Array))
                            ngModel.$viewValue = 0;

                        // Do some sanity check of range values
                        if (options.range === true) {

                            // Check outer bounds for min and max values
                            if (angular.isDefined(options.min) && options.min > ngModel.$viewValue[0]) {
                                ngModel.$viewValue[0] = options.min;
                            }
                            if (angular.isDefined(options.max) && options.max < ngModel.$viewValue[1]) {
                                ngModel.$viewValue[1] = options.max;
                            }

                            // Check min and max range values
                            if (ngModel.$viewValue[0] >= ngModel.$viewValue[1]) {
                                // Min value should be less to equal to max value
                                if (prevRangeValues.min >= ngModel.$viewValue[1])
                                    ngModel.$viewValue[0] = prevRangeValues.min;
                                // Max value should be less to equal to min value
                                if (prevRangeValues.max <= ngModel.$viewValue[0])
                                    ngModel.$viewValue[1] = prevRangeValues.max;
                            }

                            // Store values for later user
                            prevRangeValues.min = ngModel.$viewValue[0];
                            prevRangeValues.max = ngModel.$viewValue[1];

                        }
                        elm.slider(method, ngModel.$viewValue);
                    };

                    scope.$watch(attrs.ngModel, function () {
                        if (options.range === true) {
                            ngModel.$render();
                        }
                    }, true);

                    function destroy() {
                        try {
                            elm.slider('destroy');
                        } catch (e) {
                            // Hot fix. See: https://github.com/angular-ui/ui-slider/pull/75
                            // https://github.com/samy100/ui-slider/commit/40ad375abbaca11ba805869ea41cbfc314763803
                        }
                    }

                    elm.bind('$destroy', destroy);
                };
            }
        };
    }]);
})(window, window.angular);
/*
 * Copyright 2020 Conversity Ltd..
 */
/*
 Defines service to drawing chart.
 */

/*
 Info structure:
 Title;
 */
(function (window, angular) {'use strict';

    angular.module('Chart', []).
        service('drawChartService', function () {

            var title_x = 40;
            var title_y = 15;

            var canvasWidth;
            var canvasHeight;

            var chartPlotX;
            var chartPlotY;
            var chartPlotWidth;
            var chartPlotHeight;
            var chartPlotY0;

            var stepAxisX;
            var stepAxisY;
            var stepX;
            var stepY;
            var yMax;
            var YIteration;

            var analyticsHeight = 60;
            var analyticsY;

            var legendHeight = 45;
            var legendX;
            var legendY;

            var yAxisStep;

            function init(canvas, info) {
                canvasWidth = canvas.width;
                canvasHeight = canvas.height;

                legendX = canvasWidth / 3;
                legendY = canvasHeight - legendHeight;

                analyticsY = legendY - analyticsHeight;

                chartPlotX = 40;
                chartPlotWidth = canvasWidth - chartPlotX - 5;
                chartPlotY = title_y + 10;
                chartPlotHeight = canvasHeight - chartPlotY - legendHeight - analyticsHeight - 40;
                chartPlotY0 = chartPlotY + chartPlotHeight;

                stepX = chartPlotWidth / info.axisX.length;
                stepAxisX = stepX;
                if (stepAxisX < 50) {
                    stepAxisX = 50;
                }
                yMax = calculateMaxY(info.series);
                YIteration = 5;
                if (yMax < YIteration) {
                    YIteration = yMax;
                }
                stepAxisY = chartPlotHeight / YIteration;
                yAxisStep = yMax / YIteration;
                stepY = chartPlotHeight / yMax;
            }

            function initArea(ctx) {
                //ctx.beginPath();
                //ctx.closePath();
                ctx.strokeStyle = 'black';
                setNormalFont(ctx);
                ctx.clearRect(0, 0, canvasWidth, canvasHeight);
                ctx.strokeRect(0, 0, canvasWidth, canvasHeight);
            }

            function outputTitle(ctx, info) {
                ctx.fillStyle = 'black';
                ctx.fillText(info.title, title_x, title_y);
            }

            function outputPlot(ctx) {
                ctx.fillStyle = 'darkgrey';
                ctx.fillRect(chartPlotX, chartPlotY, chartPlotWidth, chartPlotHeight);
            }

            function outputAxisX(ctx, axisX) {
                var lastTestX = 0;
                var currentTextX = lastTestX;
                ctx.fillStyle = 'black';
                angular.forEach(axisX, function (pointX, index) {
                    ctx.moveTo(chartPlotX + (index + 1) * stepX, chartPlotY0);
                    ctx.lineTo(chartPlotX + (index + 1) * stepX, chartPlotY0 + 5);
                    ctx.stroke();
                    currentTextX = chartPlotX + (index + 1) * stepX - 10;
                    if (currentTextX - lastTestX > 40) {
                        ctx.fillText(pointX, currentTextX, chartPlotY0 + 15);
                        lastTestX = currentTextX;
                    }
                });
            }

            function outputAxisY(ctx, suffix) {
                ctx.fillStyle = 'black';
                for (var i = 0; i++ < YIteration;) {
                    ctx.moveTo(chartPlotX, chartPlotY0 - i * stepAxisY);
                    ctx.lineTo(chartPlotX - 5, chartPlotY0 - i * stepAxisY);
                    ctx.stroke();
                    ctx.fillText(Math.round(i * yAxisStep) + suffix, 5, chartPlotY0 - i * stepAxisY + 4);
                }
            }

            function calculateMaxY(series) {
                var result = 0;
                angular.forEach(series, function (item) {
                    angular.forEach(item.values, function (pointY) {
                        if (pointY > result) {
                            result = pointY;
                        }
                    });
                });
                return result;
            }

            function outputSingleChart(ctx, series, idx) {
                ctx.beginPath();
                ctx.closePath();
                ctx.strokeStyle = getColor(idx);
                angular.forEach(series.values, function (pointY, index) {
                    if (0 === index) {
                        ctx.moveTo(chartPlotX + (index + 1) * stepX, chartPlotY0 - pointY * stepY);
                    } else {
                        ctx.lineTo(chartPlotX + (index + 1) * stepX, chartPlotY0 - pointY * stepY);
                    }
                });
                ctx.stroke();
            }

            function outputLegend(ctx, info) {
                angular.forEach(info.series, function (series, index) {
                    ctx.strokeStyle = getColor(index);
                    ctx.beginPath();
                    ctx.moveTo(legendX, legendY + 15 * index);
                    ctx.lineTo(legendX + 20, legendY + 15 * index);
                    ctx.closePath();
                    ctx.stroke();
                    ctx.fillText(series.legendName, legendX + 25, legendY + 15 * index + 2);
                });
            }

            function outputAnalytics(ctx, info) {
                angular.forEach(info.analytics, function (item, index) {
                    var x = chartPlotX + 130 * index;
                    setNormalFont(ctx);
                    ctx.fillText(item.text, x, analyticsY);
                    setBigFont(ctx);
                    ctx.fillText(item.value + info.suffix, x + 10, analyticsY + 30);
                });
                setNormalFont(ctx);
            }

            this.drawChart = function (canvas, ctx, info) {
                init(canvas, info);
                initArea(ctx);
                outputTitle(ctx, info);
                outputPlot(ctx);
                outputAxisX(ctx, info.axisX);
                outputAxisY(ctx, info.suffix);
                outputAnalytics(ctx, info);
                angular.forEach(info.series, function (series, index) {
                    outputSingleChart(ctx, series, index);
                });
                outputLegend(ctx, info);
            };

            function setNormalFont(ctx) {
                ctx.font = "normal 10pt Sans-Serif";
            }

            function setBigFont(ctx) {
                ctx.font = "normal 22pt Sans-Serif";
            }

            function getColor(index) {
                switch (index) {
                    case 0:
                        return 'green';
                    case 1:
                        return '#FC0FC0';
                    case 2:
                        return '#CC5500';
                    default:
                        return 'blue';
                }
            }
        }
    );
})(window, window.angular);